import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep3.png';

const AntibodyStep3 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 3</h2>

            <h3 className="antibody-step-description">Wash Hands Vigorously</h3>

            <p className="has-text-centered">
                Wash hands for two full minutes in warm/hot soapy water, scrubbing hands and flexing fingers, to improve
                blood circulation. Dry hands.
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep3;
