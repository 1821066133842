import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep8.png';
import shippingExclamation from '../../Resources/img/single-shipping-exclamation.png';
const AntibodyStep8 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 8</h2>

            <h3 className="antibody-step-description">Return Sample</h3>

            <p className="has-text-centered">
                Place sealed biohazard bag into box. Apply FedEx return label to the shipping bag. Place box into
                shipping bag and seal tightly. Send box back to My Labs Direct the same day test is performed.
            </p>
            <img
                src={shippingExclamation}
                alt="Testing on Friday? Please call or text 877-355-7978 for a Saturday delivery return label!"
            />
        </section>
    </AntibodyLayout>
);
export default AntibodyStep8;
