import styled, {css} from 'styled-components';
import Spinner from '../../components/Spinner';
import {Device} from '../../services/constants';
import DataGrid from 'react-data-grid';
import DatePicker from 'react-datepicker';

export const PageContainer = styled.div`
    /* width: calc(100% - 320px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

export const Overlay = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
`;

export const OverlayPhoto = styled.img`
    z-index: 3;
`;

export const AdminPage = styled.div`
    margin: 32px 16px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 0;
    /* width: 100%; */
    width: calc(100vw - 354px);
`;

export const BannerText = styled.div`
    min-height: 45px;
    flex-grow: 0;
    text-align: left;
`;

export const TabContainer = styled.div`
    height: 20px;
    display: flex;
`;
export const TabUl = styled.ul`
    display: flex;
    gap: 32px;
    list-style-type: none;
    margin-block-start: 0;
    padding-inline-start: 10px;
`;
export const TabLi = styled.li`
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        color: #70dbd4;
        cursor: pointer;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Title = styled.div`
    letter-spacing: 1.3px;
    text-align: left;
`;

export const Paragraph = styled.p`
    display: inline;
    margin-left: 10px;
`;

export const LabelContainer = styled.div``;

export const AddTestLabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Label = styled.label``;

export const Input = styled.input`
    width: 110px;
    margin-left: 10px;
    margin-right: 30px;
    padding: 2px;
`;

export const Select = styled.select`
    width: 110px;
    margin-left: 10px;
    margin-right: 30px;
    padding: 2px;
`;

export const TextArea = styled.textarea`
    width: 210px;
    margin-left: 10px;
    margin-right: 30px;
    padding: 2px;
`;

export const InputEmail = styled(Input)`
    width: 170px;
`;

export const PasswordErrorsContainer = styled.div``;

export const SubmitContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

export const Button = styled.button`
    max-width: 110px;
`;

export const WorkingText = styled.p`
    display: inline;
    margin-left: 10px;
    color: #70dbd4;
`;

export const Toast = styled.p<{error: boolean}>`
    display: inline;
    margin-left: 10px;
    color: ${(props) => (props.error ? 'var(--error-500)' : '#70DBD4')};
`;
export const PasswordError = styled(Toast)`
    display: block;
    margin-left: 0;
`;

export const UserGrid = styled(DataGrid)`
    width: 100%;
    height: 100%;
    min-height: 500px;
    margin-top: -10px;
`;

export const PhotoContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const Photo = styled.div<{url: string}>`
    width: 124px;
    height: 60px;
    padding: 24px;
    border: 2px dashed var(--neutral-400);
    border-radius: 8px;
    background: ${(props) => `url('${props.url}')`};
    background-size: cover;
    background-position: center;

    &:hover {
        cursor: pointer;
    }
`;

export const FormInput = styled.input`
    width: calc(100% - 24px - 15px);
    height: 35px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 1px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
`;

export const StyledDatePicker = styled(DatePicker)<{error: boolean | undefined}>`
    width: 29%;
    height: 32px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;
    cursor: pointer;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}

    @media ${Device.mobile} {
        width: calc(100% - 68px);
    }
`;

export const RadioInput = styled(FormInput)<{error: boolean}>`
    border: ${(props) => (props.error ? 'solid 1px var(--error-500)' : 'solid 1px var(--neutral-600)')};
    width: 20px;
    height: 20px;
    margin-top: 12px;
    &:hover {
        cursor: pointer;
    }
`;

export const LeftIcon = styled.i`
    font-size: 20px;
    position: absolute;
    left: 12px;
    top: 16px;
    color: var(--neutral-300);
    transition: 0.2s;
    z-index: 1;
`;

export const ModalBackgroundOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    background-color: rgba(21, 21, 21, 0.6);
    display: flex;
    justify-content: center;
`;

export const ModalWindow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: auto;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
`;

export const ModalTabContainer = styled.div`
    height: 64px;
    min-height: 64px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 24px;
    background-color: var(--neutral-200);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const TabButtonContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    padding: 0;
`;

export const TabButton = styled.div<{selected: boolean}>`
    height: 29px;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.selected ? 'var(--primary-300)' : 'var(--text-600)')};
    cursor: pointer;
`;

export const TinyImg = styled.i`
    width: 24px;
    height: 24px;
    font-size: 24px;
    flex-grow: 0;
    object-fit: contain;
    cursor: pointer;
    color: var(--error-900);
`;

export const ModalFieldGroup = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
    padding: 24px;
`;

export const RowContainer = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 0;
`;

export const FieldContainer = styled.div<{width: string}>`
    width: ${(props) => props.width};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    padding: 0;
`;

export const FieldLabel = styled.div`
    flex-grow: 1;
    text-align: left;
    color: var(--text-600);
    padding-left: 14px;
`;

export const RadioButtonLabel = styled.div`
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--text-600);
`;

export const IconFieldContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const RadioFieldContainer = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 12px 0 0 14px;
`;

export const FieldDescription = styled.div`
    flex-grow: 1;
    font-family: Nunito;
    font-size: 0.833rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--text-600);
`;

export const TextInputField = styled.input<{hasIcon?: boolean}>`
    height: 48px;
    width: ${(props) => (props.hasIcon ? 'calc(100% - 52px)' : 'calc(100% - 26px)')};
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 ${(props) => (props.hasIcon ? '40px' : '12px')};
    border-radius: 8px;
    border: solid 1px var(--neutral-600);
    background-color: var(--neutral-0);
`;

export const SelectField = styled.select`
    width: 100%;
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 1px var(--neutral-600);
    background-color: var(--neutral-0);

    :hover {
        cursor: pointer;
    }
`;

export const DatePickerField = styled(DatePicker)<{error: boolean | undefined}>`
    width: calc(100% - 64px);
    height: 34px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;
    cursor: pointer;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;

export const ActionButtonContainer = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 24px;
`;

export const VerticalLine = styled.div`
    width: 1px;
    height: 32px;
    flex-grow: 0;
    background-color: var(--neutral-200);
`;

export const VerificationLabelButton = styled.div<{isVerified: boolean}>`
    height: 24px;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.isVerified ? 'var(--success-500)' : 'var(--error-500)')};
    cursor: pointer;

    :hover {
        color: ${(props) => (props.isVerified ? 'var(--success-50)' : 'var(--error-50)')};
    }
`;

export const RegularButton = styled.button`
    height: 36px;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    border-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 8px 24px;
    border-radius: 24px;
    background-color: ${(props) => (props.disabled ? 'var(--neutral-500)' : 'var(--primary-500)')};
    color: var(--text-0);

    &:hover {
        cursor: ${(props) => (props.disabled ? '' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '' : 'var(--primary-300)')};
    }

    &:active {
        background-color: var(--primary-50);
    }
`;

export const PrimaryButton = styled.button`
    height: 48px;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    border-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 32px;
    border-radius: 24px;
    background-color: ${(props) => (props.disabled ? 'var(--neutral-500)' : 'var(--primary-500)')};
    color: var(--text-0);

    &:hover {
        cursor: ${(props) => (props.disabled ? '' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '' : 'var(--primary-300)')};
    }

    &:active {
        background-color: var(--primary-50);
    }
`;

export const SecondaryButton = styled.button`
    height: 48px;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    border-width: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 32px;
    border-radius: 24px;
    background-color: ${(props) => (props.disabled ? 'var(--neutral-500)' : 'var(--warning-500)')};
    color: var(--text-0);

    &:hover {
        cursor: ${(props) => (props.disabled ? '' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '' : 'var(--warning-300)')};
    }

    &:active {
        background-color: var(--warning-50);
    }
`;

export const RadioButton = styled.input`
    transform: scale(2);
    cursor: pointer;
`;

export const OverlayingSpinner = styled(Spinner)`
    && {
        // overrides the style of original spinner
        top: 40%;
        z-index: 2;
    }
`;
