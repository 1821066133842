import React from 'react';
import {BackButton, BackButtonBar, BackIcon, LogoLink, MLDLogo, Bar} from './styles/TopBar';

interface Props {
    location?: string; //where the link leads
    logo?: string;
    buttonvisible?: boolean; //if the backbutton is visible
    onClick?: Function; //function the backbutton uses
    isBackBar?: boolean; //changes styling to BackButtonBar
}

const TopBar: React.FC<Props> = (props) => {
    const {location, logo, buttonvisible, onClick, isBackBar} = props;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <>
            {isBackBar && (
                <BackButtonBar>
                    {buttonvisible && (
                        <BackButton onClick={handleClick}>
                            <BackIcon className="fa-solid fa-square-left" />
                        </BackButton>
                    )}
                    <LogoLink to={location ? location : '/login'}>
                        <MLDLogo src={logo} />
                    </LogoLink>
                </BackButtonBar>
            )}
            {!isBackBar && (
                <Bar>
                    <LogoLink to={location ? location : '/login'}>
                        <MLDLogo src={logo} />
                    </LogoLink>
                </Bar>
            )}
        </>
    );
};

export default TopBar;
