import styled from 'styled-components';

export const BarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
`;
export const Bar = styled.div<{color: string; height: number}>`
    width: 40px;
    height: ${(props) => props.height}px;
    max-height: 148px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-0);
    background-color: ${(props) => props.color};
    border-radius: 24px;
    font-size: 12px;
    z-index: 1;
`;
export const DateLabel = styled.p`
    font-size: 0.75rem;
`;
