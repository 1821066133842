import styled from 'styled-components';
import {Link} from 'react-router-dom';

//the $ makes it a transient prop, thus not being passed to the actual Link
export const Alert = styled(Link)<{$visible: boolean}>`
    width: calc(100% - 64px);
    height: ${(props) => (props.$visible ? '21px' : '0')};
    color: var(--text-0);
    text-align: center;
    text-decoration: none;
    overflow: hidden;
    line-height: 1.6;
    margin: ${(props) => (props.$visible ? '0px 24px' : '0 24px')};
    padding: ${(props) => (props.$visible ? '8px' : '0')};
    border-radius: 8px;
    background-color: ${(props) => (props.$visible ? 'var(--link-300)' : 'rgba(165, 20, 0, 0)')};
    transition: height 0.2s, margin 0.2s, padding 0.2s, background-color 0.2s 0.2s;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const ArrowIcon = styled.i`
    position: absolute;
    padding-left: 24px;
    font-size: 20px;
`;
