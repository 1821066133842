import React from 'react';
import PrimaryButton from './PrimaryButton';
import Spinner from './Spinner';
import {SubmitContainer, SubmitFields, Cancel} from './styles/SettingsFooter';

interface Props {
    onClick: Function;
    spinnerCondition?: boolean;
    buttonValue: string;
}

const SettingsFooter: React.FC<Props> = (props) => {
    const {onClick, spinnerCondition, buttonValue} = props;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <>
            {buttonValue === 'Close' ? (
                <SubmitFields>
                    <SubmitContainer>
                        <PrimaryButton value={buttonValue} width="100%" onClick={handleClick} />
                    </SubmitContainer>
                </SubmitFields>
            ) : (
                <SubmitFields>
                    <SubmitContainer>
                        {spinnerCondition && <Spinner left="calc(50% - 105px)" />}
                        {buttonValue && <PrimaryButton value={buttonValue} width="100%" />}
                    </SubmitContainer>
                    <Cancel className="ParagraphNormalRegular" onClick={handleClick}>
                        Cancel
                    </Cancel>
                </SubmitFields>
            )}
        </>
    );
};

export default SettingsFooter;
