import React from 'react';

interface Props {
    section: number;
    value: number;
}

const AccordionChart: React.FC<Props> = ({section, value}) => {
    return (
        <svg style={{overflow: 'visible'}} width="100%" height="20px">
            {/* 5 background colored bars */}
            {/* 1st section */}
            <rect
                fill="#a5f18a"
                x="0"
                y="0"
                width="20%"
                height="20px"
                stroke="#36cf00"
                stroke-width={section === 1 ? '2px' : '0px'}
                rx="8px"
                transform="translate(6, 0)"
            />
            {/* 5th section */}
            <rect
                fill="#b55bfd"
                x="80%"
                y="0%"
                width="20%"
                height="20px"
                stroke="#8f21e7"
                stroke-width={section === 5 ? '2px' : '0px'}
                rx="8px"
                transform="translate(-6, 0)"
            />
            {/* 2nd section */}
            <rect
                fill="#ffe485"
                x="20%"
                width="20%"
                height="20"
                stroke="#f6ca2c"
                stroke-width={section === 2 ? '2px' : '0px'}
            />
            {/* 3rd section */}
            <rect
                fill="#ffb951"
                x="40%"
                width="20%"
                height="20"
                stroke="#f39b17"
                stroke-width={section === 3 ? '2px' : '0px'}
            />
            {/* 4th section */}
            <rect
                fill="#fc6a56"
                x="60%"
                width="20%"
                height="20"
                stroke="#ed3b23"
                stroke-width={section === 4 ? '2px' : '0px'}
            />
            {/* border for the fifth section */}
            {section === 5 && (
                <rect fill="#8f21e7" x="80%" y="-1" width="2px" height="22px" transform="translate(-2px, 0)" />
            )}
            {/* border for the first section */}
            {section === 1 && (
                <rect fill="#36cf00" x="20%" y="-1" width="2px" height="22px" transform="translate(-2px, 0)" />
            )}
            {/* tick mark */}
            <rect
                fill="white"
                rx="3px"
                width="6px"
                height="30px"
                y="-5px"
                x={`${value}%`}
                stroke="var(--primary-500)"
                stroke-width="2px"
            />
        </svg>
    );
};

export default AccordionChart;
