import React from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import PrimaryButton from '../../components/PrimaryButton';
import SupportAlert from '../../components/SupportAlert';
import {Form, FormSection, Image, ImageContainer, Header, Paragraph, SubmitContainer, BackButton} from './styles/Error';

export const Error: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        // const submitter = event.nativeEvent.submitter.value;

        // if (submitter === 'At an MLD location') {
        //     setState((prevState) => ({
        //         ...prevState,
        //         location: 'mld',
        //     }));
        // } else if (submitter === 'At my location') {
        //     setState((prevState) => ({
        //         ...prevState,
        //         location: 'mobile',
        //     }));
        // }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <Header className="Heading05Bold">Payment Error</Header>
            </FormSection>
            <FormSection>
                <ImageContainer>
                    <Image className="fa-light fa-shop-slash" />
                </ImageContainer>
            </FormSection>
            <SupportAlert visible={true} />
            <FormSection>
                <Paragraph className="ParagraphNormalRegular">
                    There was an error processing your payment. Please try again or contact support
                </Paragraph>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    {/* TODO: make this button actually redirect to payment */}
                    <PrimaryButton
                        value="Try again"
                        onClick={() => {
                            props.goToNamedStep?.('payment');
                        }}
                        width="100%"
                        radius="24px"
                    />
                </SubmitContainer>
                <BackButton
                    className="ParagraphNormalRegular"
                    onClick={() => {
                        props.goToNamedStep?.('step0');
                    }}>
                    Cancel kit registration
                </BackButton>
            </FormSection>
        </Form>
    );
};
