import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../Resources/img/logo.png';
function Header(props) {
    return (
        <header>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link to="/" className="navbar-item">
                            <img src={Logo} alt="My Labs Direct" />
                        </Link>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
