import React, {useState, ChangeEvent} from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import PrimaryButton from '../../components/PrimaryButton';
import {
    Form,
    FormSection,
    Error,
    SubmitContainer,
    BackButton,
    Header,
    LeftIcon,
    Label,
    LabelText,
    Select,
} from './styles/Step2';

export const Step2: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const [state, setState] = useState<{[x: string]: any}>({
        kitId1: '',
        kitId2: '',
        promoCode1: '',
        promoCode2: '',
        company: null,
        contact: '',
        symptoms: '',
        vaccinated: '',
        firstName: '',
        lastName: '',
        birthday: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        gender: '',
        ethnicity: '',
        location: '',
    });

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: '',
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        if (state.company === null) {
            setError((prevError) => ({...prevError, company: true, message: 'Please select a company'}));
            return;
        } else if (state.company !== 'varsity-spirit') {
            setState((prevState) => ({...prevState, vaccinated: ''}));
        }

        props.goToNamedStep?.('step3');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'kitId1' || name === 'kitId2') {
            const digitReg = /^[0-9]$/;
            const lowerReg = /^[a-z]$/;
            const letterReg = /^[A-Z]$/;

            if (lowerReg.test(value[value.length - 1])) {
                value = value.toUpperCase();
            }

            if (
                value.length === 3 ||
                (letterReg.test(value[0]) && value.length === 6) ||
                (digitReg.test(value[0]) && value.length === 8) ||
                (letterReg.test(value[0]) && value.length === 11)
            ) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (
                (value !== '' && !digitReg.test(value[value.length - 1]) && !letterReg.test(value[value.length - 1])) ||
                (letterReg.test(value[value.length - 1]) && value.length > 3) ||
                (digitReg.test(value[0]) && value.length > 12) ||
                value.length > 15
            ) {
                return;
            }
        }

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }
        }

        if (name === 'zip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <Header className="Heading05Bold">Select your organization</Header>
            </FormSection>
            <Error className="ParagraphSmallRegular" hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                <Label error={error.company} className="Heading05Regular">
                    <LabelText>Organization Name</LabelText>
                    <LeftIcon className="fa-light fa-briefcase" error={error.company ? 1 : 0} />
                    <Select
                        error={error.company}
                        name="company"
                        value={state.company || ''}
                        onChange={handleChange}
                        className="ParagraphNormalRegular">
                        <option disabled value="">
                            {' '}
                            -- select an option --{' '}
                        </option>
                        <option value="baylor-student-on-campus">Baylor Student On-Campus</option>
                        <option value="baylor-student-off-campus">Baylor Student Off-Campus</option>
                        <option value="baylor-faculty">Baylor Faculty</option>
                        <option value="baylor-staff">Baylor Staff</option>
                        <option value="varsity-spirit">Varsity Spirit</option>
                        <option value="other">Other</option>
                    </Select>
                </Label>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    <PrimaryButton value="Continue" width="100%" radius="24px" />
                </SubmitContainer>
                <BackButton
                    className="ParagraphNormalRegular"
                    onClick={() => {
                        props.goToNamedStep?.('step1');
                    }}>
                    Go back
                </BackButton>
            </FormSection>
        </Form>
    );
};
