import React from 'react';
import {Layout} from '../Components';
import TestPrepUncapTube from '../Resources/img/AntibodyStep4.png';

const Step7 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={TestPrepUncapTube} alt="Prepare Test" />
            </div>
            <h6 className="required-record">
                this step <strong>must</strong> be recorded{' '}
                <span className="record-icon">
                    <span></span>
                </span>
            </h6>
            <h2 className="antibody-step-number">Step 5</h2>

            <h3 className="antibody-step-description">Prepare To Test</h3>

            <p className="has-text-centered">
                Uncap test tube. Remove swab from packaging without touching the cotton tip. Hold cotton swab in one
                hand and test tube in the other.
            </p>
        </section>
    </Layout>
);
export default Step7;
