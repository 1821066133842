import React, {useState} from 'react';
import WatchVideoComputer from '../../Resources/img/Step3.png';
import ModalVideo from 'react-modal-video';
import {AntibodyLayout} from '../../Components';

const AntibodyStepVideo = () => {
    const [open, setOpen] = useState(false);
    return (
        <AntibodyLayout>
            <section>
                <div className="helper-media">
                    <ModalVideo
                        autoplay="true"
                        modestbranding="1"
                        showinfo="0"
                        channel="youtube"
                        isOpen={open}
                        videoId="bS22h4F7lDY"
                        onClose={() => setOpen(false)}
                    />
                    <img
                        src={WatchVideoComputer}
                        alt="Video Tutorial"
                        onClick={() => setOpen(true)}
                        style={{cursor: 'pointer'}}
                    />
                </div>
                <br />
                <h2 className="antibody-step-number">Visual Learner?</h2>
                <br />
                <p className="has-text-centered">
                    Click above to watch a quick tutorial video on how to administer your test, or click next to view
                    step-by-step instructions.
                </p>
            </section>
        </AntibodyLayout>
    );
};
export default AntibodyStepVideo;
