import React from 'react';
import {CardContainer, CardIcon, CardLink, Subtitle, Separator} from './styles/EmptyHealthSummaryCard';

const DashboardCard: React.FC = () => {
    return (
        <CardContainer>
            <CardIcon className="fa-light fa-heart-pulse" />
            <p className="Heading05Bold">You have no health metrics</p>
            <Subtitle className="ParagraphSmallRegular">
                Your latest and historical health metrics will be shown here after you take a blood test
            </Subtitle>
            <Separator />
            <p className="ParagraphMediumRegular">
                To get started,{' '}
                <CardLink href="https://mylabsdirect.com/wellness/" target="_blank">
                    {' '}
                    shop for a blood test
                </CardLink>
            </p>
        </CardContainer>
    );
};

export default DashboardCard;
