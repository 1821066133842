import styled, {css} from 'styled-components';

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    padding: 24px;
    gap: 24px;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 120px;
    height: 120px;
    border-radius: 32px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;
export const Image = styled.i`
    font-size: 60px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Paragraph = styled.p`
    white-space: pre-line;
    text-align: center;
`;

export const SubmitContainer = styled.div`
    width: 100%;
    position: relative;
`;
