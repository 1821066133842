import styled from 'styled-components';

export const ResultContainer = styled.div`
    max-width: 676px;
    width: calc(100% - 32px);
    margin: 0 8px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--neutral-0);

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 24px;
    }
`;
export const ResultAccent = styled.div<{color: string}>`
    width: 8px;
    min-width: 8px;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px;
    background-color: ${(props) => props.color};
`;

export const ResultContent = styled.div`
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: stretch;
    padding: 16px;
`;

export const ResultHeader = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;

export const MicroscopePhoto = styled.div<{color: string}>`
    width: 26.4px;
    height: 26.4px;
    padding: 6.7px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
`;

export const ResultBody = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;

export const ResultItem = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-top: 16px;
        margin-bottom: 16px;
    }
`;

export const Icon = styled.i`
    font-size: 24px;
    width: 24px;
    align-self: flex-start;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-right: 16px;
    }
`;

export const WarningIcon = styled.i`
    font-size: 24px;
    align-self: flex-start;
    color: var(--warning-50);

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-right: 16px;
    }
`;
