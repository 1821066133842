import React from 'react';
import TopBar from '../components/TopBar';
import {PageContainer, FormContainer, Header, Caption, LinkFrame, LinkText, LeftIcon} from './styles/Referral';

const Referral: React.FC = () => {
    return (
        <PageContainer>
            <TopBar />
            <FormContainer>
                <Header className="Heading05Bold">Dashboard Setup</Header>
                <Caption style={{color: 'white'}}>Choose an option to get started</Caption>
                <LinkFrame to="/login?ref=acuity">
                    <Caption>I already have a MyLabsDirect account</Caption>
                    <LinkText className="ParagraphLargeBold">
                        <LeftIcon className="fa-solid fa-right-to-bracket" />
                        Sign In
                    </LinkText>
                </LinkFrame>
                <LinkFrame to="/register?ref=acuity">
                    <Caption>I need a MyLabsDirect account</Caption>
                    <LinkText className="ParagraphLargeBold">
                        <LeftIcon className="fa-solid fa-user-plus" />
                        Create an account
                    </LinkText>
                </LinkFrame>
                {/* <div>
                <InfoIcon icon={faInfoCircle} />
                <Caption style={{color: 'white', fontWeight: 'normal', fontSize: '.8rem'}}>If you've activated a kit with us before, you already have an account</Caption>
                </div> */}
            </FormContainer>
        </PageContainer>
    );
};

export default Referral;
