import styled from 'styled-components';

export const MetricInfoContainer = styled.div`
    width: calc(100% - 16px);
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
`;
export const MetricInfoHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
export const MetricInfoImage = styled.i`
    font-size: 24px;
    color: var(--text-600);
`;
export const TestName = styled.p`
    font-weight: 600;
    letter-spacing: normal;
`;
export const MetricInfoText = styled.p`
    white-space: pre-line;
`;
MetricInfoText.defaultProps = {
    className: 'ParagraphNormalRegular',
};
export const MetricList = styled.ul`
    margin: 0;
`;
MetricList.defaultProps = {
    className: 'ParagraphNormalRegular',
};
