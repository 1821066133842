import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import TopBar from '../components/TopBar';
import Spinner from '../components/Spinner';
import Error from '../components/Error';
import settings from '../settings';
import axios from 'axios';
import {useAuth} from '../hooks/useAuth';
import {
    PageContainer,
    FormContainer,
    FormBody,
    Form,
    Header,
    Footer,
    SubmitContainer,
    ConfirmIcon,
    Caption,
    LoginLink,
} from './styles/Auth';

const Auth: React.FC = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [verified, setVerified] = useState(false);
    const {token} = useParams<{token: string}>();
    const [password, setPassword] = useState('');
    const [error, setError] = useState<{[index: string]: boolean}>({});
    const [errorMsg, setErrorMsg] = useState('');
    const [show, setShow] = useState('password');
    const {login} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setHasSubmitted(false);
            setVerifying(true);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({token: token}),
                withCredentials: true,
            };

            const res = await axios(`${settings.apiURL}/api/auth`, requestOptions).catch(() => {
                setHasSubmitted(true);
                setError({login: true});
                setVerifying(false);
                setErrorMsg('Could not log you in, please close this tab and try again.');
                return;
            });

            if ((res && res.status !== 200) || !res) {
                setHasSubmitted(true);
                setError({login: true});
                setVerifying(false);
                setErrorMsg('Could not log you in, please close this tab and try again.');
                return;
            }

            const data = await res.data;
            login(data.result);
            navigate('/');
        })();
    }, []);

    const togglePassword = () => {
        setShow(show === 'password' ? 'text' : 'password');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // event.preventDefault();
        // const { name, value } = event.target;
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'password') {
            const passwordUppercaseReg = /[A-Z]/;
            const passwordLowercaseReg = /[a-z]/;
            const passwordNumberReg = /[\d]/;

            setError({
                ...error,
                passwordLetters: !passwordUppercaseReg.test(value) || !passwordLowercaseReg.test(value),
                passwordNumber: !passwordNumberReg.test(value),
                passwordLength: value.length <= 8,
            });
        }

        setPassword(value);
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setHasSubmitted(true);
        setError({});

        const passwordUppercaseReg = /[A-Z]/;
        const passwordLowercaseReg = /[a-z]/;
        const passwordNumberReg = /[\d]/;

        const error: {[key: string]: any} = {
            passwordLetters: !passwordUppercaseReg.test(password) || !passwordLowercaseReg.test(password),
            passwordNumber: !passwordNumberReg.test(password),
            passwordLength: password.length <= 8,
        };

        setError(error);

        for (const key in error) {
            if (error[key]) {
                setErrorMsg('Please enter a valid password');
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                return;
            }
        }

        setVerifying(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify({password: password}),
        };

        const res = await axios(`${settings.apiURL}/api/verify/${token}`, requestOptions);
        setVerifying(false);
        if (res.status !== 200) {
            setErrorMsg('Verification failed');
            console.log(res.data);
            return;
        }
        setVerified(true);
    };

    return (
        <PageContainer>
            <TopBar location={'/login'} />
            <FormContainer>
                {/* {!verified && <Caption>Verifying your account...</Caption>} */}
                {!verified && (
                    <Form onSubmit={handleSubmit}>
                        <FormBody>
                            <Header>Attempting to log you in</Header>
                            <Error
                                message={
                                    !!Object.keys(error).filter((k) => error[k]).length && hasSubmitted ? errorMsg : ''
                                }
                            />
                            {/* <PasswordLabel
                                error={
                                    hasSubmitted &&
                                    (error.passwordLetters || error.passwordNumber || error.passwordLength)
                                }>
                                <LabelText>Password</LabelText>
                                <LeftIcon icon={faKey} />
                                <RightIcon icon={show === 'text' ? faEyeSlash : faEye} onClick={togglePassword} />
                                <FormInput
                                    error={
                                        hasSubmitted &&
                                        (error.passwordLetters || error.passwordNumber || error.passwordLength)
                                    }
                                    type={show}
                                    name="password"
                                    value={password || ''}
                                    onChange={handleChange}
                                />
                            </PasswordLabel> */}
                            {/* <PasswordErrors>
                                <PasswordError>
                                    <PasswordErrorIcon
                                        className={
                                            error.passwordLength || password === ''
                                                ? 'fa-regular fa-circle-xmark'
                                                : 'fa-light fa-circle-check'
                                        }
                                        color={
                                            error.passwordLength || password === ''
                                                ? 'var(--error-500)'
                                                : 'var(--success-300)'
                                        }
                                    />
                                    <PasswordErrorText className="ParagraphSmallRegular">
                                        At least 9 characters long
                                    </PasswordErrorText>
                                </PasswordError>
                                <PasswordError>
                                    <PasswordErrorIcon
                                        className={
                                            error.passwordLetters || password === ''
                                                ? 'fa-regular fa-circle-xmark'
                                                : 'fa-light fa-circle-check'
                                        }
                                        color={
                                            error.passwordLetters || password === ''
                                                ? 'var(--error-500)'
                                                : 'var(--success-300)'
                                        }
                                    />
                                    <PasswordErrorText className="ParagraphSmallRegular">
                                        Include at least 1 uppercase and lowercase letter
                                    </PasswordErrorText>
                                </PasswordError>
                                <PasswordError>
                                    <PasswordErrorIcon
                                        className={
                                            error.passwordNumber || password === ''
                                                ? 'fa-regular fa-circle-xmark'
                                                : 'fa-light fa-circle-check'
                                        }
                                        color={
                                            error.passwordNumber || password === ''
                                                ? 'var(--error-500)'
                                                : 'var(--success-300)'
                                        }
                                    />
                                    <PasswordErrorText className="ParagraphSmallRegular">
                                        Include at least 1 number
                                    </PasswordErrorText>
                                </PasswordError>
                            </PasswordErrors> */}
                        </FormBody>
                        <Footer>
                            <SubmitContainer>
                                {verifying && <Spinner size="50px" centered={true} />}
                                {/* <PrimaryButton value="Set Password" width="100%" /> */}
                            </SubmitContainer>
                        </Footer>
                    </Form>
                )}
                {/* {!!verified && <Redirect to={url} />} */}
                {!!verified && (
                    <>
                        <ConfirmIcon className="fa-solid fa-check-circle" />
                        <Caption>
                            Your account has been verified and your password has been set! Please{' '}
                            <LoginLink to={ref ? `/login?ref=${ref}` : '/login'}>login</LoginLink>.
                        </Caption>
                    </>
                )}
            </FormContainer>
        </PageContainer>
    );
};

export default Auth;
