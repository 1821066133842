import {useMediaQuery} from 'react-responsive';

export const Desktop = ({children}: any) => {
    const isDesktop = useMediaQuery({minWidth: 801});
    return isDesktop ? children : null;
};

// may want tablet size in the future

export const Mobile = ({children}: any) => {
    const isMobile = useMediaQuery({maxWidth: 800});
    return isMobile ? children : null;
};
