import React from 'react';
import {Layout} from '../Components';
import TiltHeadBackCollection from '../Resources/img/Step7.png';

const Step8 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={TiltHeadBackCollection} alt="Collect Sample" />
            </div>

            <h6 className="required-record">
                this step <strong>must</strong> be recorded{' '}
                <span className="record-icon">
                    <span></span>
                </span>
            </h6>

            <h2 className="antibody-step-number">Step 6</h2>

            <h3 className="antibody-step-description">Collecting Swab Sample</h3>

            <p className="has-text-centered">
                Tilt head back slightly. Insert cotton swab into nostril until you feel pressure, approximately half of
                an inch. You should not be in pain. Rotate the swab 3 times around the nasal wall touching the entire
                inner wall of nostril. Remove swab. Repeat this procedure in the opposite nostril using the same swab.
            </p>
        </section>
    </Layout>
);
export default Step8;
