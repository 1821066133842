import React from 'react';
import {SkeletonBody, SkeletonContainer, SkeletonContent, SkeletonHeader, SkeletonItem} from './styles/Skeleton';

interface Props {
    variant: number;
}

const Skeleton: React.FC<Props> = ({variant}) => {
    return (
        <SkeletonContainer>
            <SkeletonItem width={8} height={-1} />
            <SkeletonContent>
                <SkeletonHeader>
                    {/* <SkeletonItem width={400} height={40} />
                    <SkeletonItem width={40} height={40} /> */}
                    <SkeletonItem width={-1} height={40} />
                    <SkeletonItem width={-1} height={40} />
                </SkeletonHeader>
                <SkeletonBody>
                    {/* <SkeletonItem width={440} height={26} />
                    <SkeletonItem width={251} height={26} />
                    <SkeletonItem width={300} height={26} /> */}
                    <SkeletonItem width={-1} height={26} />
                    <SkeletonItem width={-1} height={26} />
                    <SkeletonItem width={-1} height={26} />
                </SkeletonBody>
                {variant === 1 && <SkeletonItem width={-1} height={36} style={{margin: '0 auto'}} />}
            </SkeletonContent>
        </SkeletonContainer>
    );
};

export default Skeleton;
