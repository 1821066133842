import React from 'react';
import {Bar, BarContainer, DateLabel} from './styles/HistogramBar';

interface Props {
    test: any;
    color: string;
    height: number;
}

const HistogramBar: React.FC<Props> = ({test, color, height}) => {
    return (
        <BarContainer>
            <Bar color={color} height={height}>
                {/* fontsize subject to change, just changed to make it fit better in the bar for now */}
                <p style={{fontSize: '0.7rem'}} className="ParagraphSmallRegular">
                    {test.result}
                </p>
            </Bar>
            <DateLabel className="ParagraphSmallRegular">
                {/* shortens the year to 2 digits to fit better on mobile */}
                {new Date(test.statusDate).toLocaleDateString('en-US', {timeZone: 'UTC'}).slice(0, -4) +
                    new Date(test.statusDate).toLocaleDateString('en-US', {timeZone: 'UTC'}).slice(-2)}
            </DateLabel>
        </BarContainer>
    );
};

export default HistogramBar;
