import React from 'react';
import 'rc-calendar/assets/index.css';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';

import moment from 'moment';
import 'moment/locale/en-gb';

const format = 'MM/DD/YYYY';
const now = moment();

now.locale('en-us').utcOffset(0);

function disabledDate(current) {
    if (!current) {
        return false;
    }
    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);
    return current.valueOf() >= date.valueOf();
}

const DatePickerCalender = ({onChange: handleUdpate, value, name, className}) => {
    const [state, setState] = React.useState({
        showDateInput: true,
        disabled: false,
        open: false,
    });
    const calendarContainerRef = React.useRef(null);
    const onChange = (newValue) => {
        if (!value) return;
        handleUdpate({
            target: {name, value: newValue},
        });
    };

    const onOpenChange = (open) => {
        setState({...state, open});
    };

    const onFocus = () => {
        if (!state.open && state.isMouseDown) {
            // focus from a "click" event, let the picker trigger automatically open the calendar
            setState({...state, isMouseDown: false});
        } else {
            // focus not caused by "click" (such as programmatic or via keyboard)
            setState({...state, open: true});
        }
    };

    const onMouseDown = () => {
        setState({...state, isMouseDown: true});
    };

    const getCalendarContainer = () => calendarContainerRef.current;

    const calendar = (
        <Calendar
            locale={enUS}
            style={{zIndex: 1001}}
            dateInputPlaceholder="please input"
            format={format}
            showDateInput={state.showDateInput}
            disabledDate={disabledDate}
            focusablePanel={false}
        />
    );
    return (
        <DatePicker
            animation="slide-up"
            calendar={calendar}
            value={value}
            onChange={onChange}
            getCalendarContainer={getCalendarContainer}
            onOpenChange={onOpenChange}
            open={state.open}
            style={{zIndex: 1001}}>
            {({value}) => {
                return (
                    <div tabIndex="0" onMouseDown={onMouseDown} onFocus={onFocus} className="is-fullwidth">
                        <input
                            placeholder="please select"
                            disabled={state.disabled}
                            readOnly
                            tabIndex="-1"
                            className={`${className}`}
                            value={(value && value.format(format)) || ''}
                        />
                        <div ref={calendarContainerRef} />
                    </div>
                );
            }}
        </DatePicker>
    );
};

export default DatePickerCalender;
