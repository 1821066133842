import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep9.png';

const AntibodyStep9 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 9</h2>

            <h3 className="antibody-step-description">Results</h3>

            <p className="has-text-centered">
                HIPAA compliant results will be emailed in approximately 24-48 hours from receipt of sample at our lab.
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep9;
