import React from 'react';
import {CardContainer, CardIcon, CardLink, Subtitle, Separator} from './styles/EmptyKitHistoryItem';

const EmptyKitHistoryItem: React.FC = () => {
    return (
        <CardContainer>
            <CardIcon className="fa-light fa-box-open" />
            <p className="Heading05Bold">You have no activated kits</p>
            <Subtitle className="ParagraphSmallRegular">
                Your latest and historical kits will be shown here after you take a activate a kit
            </Subtitle>
            <Separator />
            <p className="ParagraphNormalRegular">
                To get started,{' '}
                <CardLink href="https://mylabsdirect.com" target="_blank">
                    {' '}
                    shop for a kit
                </CardLink>
            </p>
        </CardContainer>
    );
};

export default EmptyKitHistoryItem;
