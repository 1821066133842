import {Device} from '../../services/constants';
import styled from 'styled-components';

export const PageContainer = styled.div`
    width: calc(100vw - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${Device.mobile} {
        width: 100%;
        margin-top: 24px;
    }
`;
export const HealthSummaryPage = styled.div`
    max-width: 820px;
    width: calc(100% - 32px);
    margin: 32px 0 0 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
`;
export const HealthSummaryHeader = styled.div`
    width: calc(100% - 16px);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
`;
export const TabBar = styled.div`
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 8px;
    border-radius: 28px;
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: var(--neutral-50);
`;
export const TabBarButton = styled.button<{isActive: number}>`
    height: fit-content;
    min-height: 40px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    border: none;
    border-radius: 24px;
    box-shadow: ${(props) => (props.isActive ? '0 3px 8px 0 rgba(0, 0, 0, 0.15)' : '')};
    color: ${(props) => (props.isActive ? 'var(--text-0)' : 'var(--text-600)')};
    background-color: ${(props) => (props.isActive ? 'var(--secondary-500)' : 'var(--neutral-50)')};
    font-size: 1rem;
    letter-spacing: normal;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        color: ${(props) => (props.isActive ? 'var(--neutral-50)' : 'var(--secondary-500)')};
    }
`;
export const HealthSummaryResults = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
