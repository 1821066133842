import {Device} from '../../services/constants';
import styled, {css} from 'styled-components';
import CreateAccountBackground from '../../assets/images/Create Account Background.jpeg';
import {Link} from 'react-router-dom';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url('${CreateAccountBackground}');
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
`;
export const FormContainer = styled.div`
    width: calc(100% - 64px);
    max-width: 343px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    margin: 0 16px;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--text-0);

    @media ${Device.mobile} {
        margin: 32px 16px 0 16px;
    }
`;
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 433px;

    @media ${Device.mobile} {
        max-width: 100%;
    }
`;
export const FormBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    padding: 16px;
`;
export const Header = styled.div`
    height: 28px;
    font-size: min(9.3vw, 1.44rem);
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: left;
    text-wrap: nowrap;
    align-self: flex-start;
`;
export const Label = styled.label<{error: boolean}>`
    font-size: 1.2rem;
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 16px;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const PasswordLabel = styled(Label)`
    margin-bottom: 0;
`;
export const LabelText = styled.p`
    padding: 0 12px;
`;
export const FormInput = styled.input<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const LeftIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 52px;
    color: var(--neutral-300);
    z-index: 1;
`;
export const RightIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 52px;
    color: var(--neutral-900);

    :hover {
        cursor: pointer;
        color: var(--neutral-700);
    }
`;
export const PasswordErrors = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 4px 0 0 0;
    padding-left: 12px;
`;
export const Footer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
export const SubmitContainer = styled.div`
    position: relative;
`;
export const ConfirmIcon = styled.i`
    margin: 20px 0;
`;
export const LoginLink = styled(Link)`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;
export const Caption = styled.div`
    letter-spacing: 1px;
    margin: 20px auto;
    text-align: center;
`;
