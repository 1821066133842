import React, {useState, useEffect, ChangeEvent} from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import PrimaryButton from '../../components/PrimaryButton';
import Spinner from '../../components/Spinner';
import settings from '../../settings';
import {useAppSelector} from '../../hooks';
// @ts-ignore
import BloodConsentPDF from '../../assets/documents/BLOOD-cashpay-consent.pdf';
// @ts-ignore
import CovidConsentPDF from '../../assets/documents/COVID-insurance-consent&CoA.pdf';
// @ts-ignore
import STDConsentPDF from '../../assets/documents/STD-cashpay-consent.pdf';
import {
    Form,
    FormSection,
    Header,
    Image,
    ImageContainer,
    Error,
    KitContainer,
    KitIcon,
    Paragraph,
    KitItem,
    KitText,
    LabelText,
    BackButton,
    StripeText,
    LeftIcon,
    SubmitContainer,
    ConsentBox,
    InternalLink,
    ConfidentialityLabel,
    ConfidentialityContainer
} from './styles/Payment';

export const Payment: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector(state => state.kit);
    const {user, token} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const [test, setTest] = useState({
        testID: '',
        affiliateID: '',
        isBlood: false,
        text: 'You’re almost done! Time to pay for your kit'
    });
    const [confidentiality, setConfidentiality] = useState(false);

    useEffect(() => {
        const regex = /^([a-zA-Z]{2}\-)?(0[1-6])\-\d{4}\-\d{4}$/;
        const result = regex.exec(kitInfo.kitId1);
        // Second capture group ie BW-(01)-0000-0000 or (01)-0000-0000, representing our test ID
        let testID = result?.[2] ?? '';
        // First, optional capture group ie (BW)-01-0000-0000, our affiliate ID
        let affiliateID = result?.[1] ?? '';
        // If we have an affiliate prefix, this is a blood test
        let isBlood = affiliateID ? affiliateID.length > 0 : false;

        let text = 'You’re almost done! Time to pay for your kit';
        if (isBlood) {
            text = 'You’re almost done! Time to pay for your kit.';
        } else {
            text =
                testID === '01' || testID === '04'
                    ? 'Please click checkout to pay for your Covid-19 RT-PCR Test kit'
                    : testID === '02' || testID === '05'
                    ? 'Please click checkout to pay for your Covid-19 Total Antibody Test kit'
                    : testID === '03' || testID === '06'
                    ? 'Please click checkout to pay for your Covid-19 Antibody IgG Test kit'
                    : 'You’re almost done! Time to pay for your kit';
        }

        setTest({
            testID,
            affiliateID,
            isBlood,
            text
        });
    }, [kitInfo.kitId1]);

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: ''
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        const submitter = event.nativeEvent.submitter.value;
        if (submitter === 'Proceed to Checkout') {
            let url = '';
            if (test.isBlood) {
                url = `${settings.apiURL}/api/session/createTestActivation`;
            } else if (test.testID === '04') {
                url = `${settings.apiURL}/api/session/createCheckoutSessionRTPCRHomeKitActivation`;
            } else if (test.testID === '05') {
                url = `${settings.apiURL}/api/session/createCheckoutSessionTotalAntibodyHomeKitActivation`;
            } else if (test.testID === '06') {
                url = `${settings.apiURL}/api/session/createCheckoutSessionIgGHomeKitActivation`;
            }

            if (url !== null) {
                setSubmitting(true);

                if (!confidentiality) {
                    setSubmitting(false);
                    setError(prevError => ({
                        ...prevError,
                        message: 'Please read and agree to the consent form.'
                    }));
                    return;
                }

                const checkoutParameters = {
                    user_email: user.userInfo.email,
                    kit_id: kitInfo.kitId1,
                    test_type: kitInfo.testType
                };

                const checkoutRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify(checkoutParameters)
                };

                const res = await fetch(url, checkoutRequestOptions);
                setSubmitting(false);
                if (!res.ok) {
                    setError(prevError => ({
                        ...prevError,
                        message: 'Failed to create checkout. Please try again.'
                    }));
                    return;
                }

                const checkoutRes = await res.json();

                if (checkoutRes.checkout_url === null) {
                    setSubmitting(false);
                    setError(prevError => ({
                        ...prevError,
                        message: 'Failed to retrieve checkout. Please try again.'
                    }));
                    return;
                }
                console.log(checkoutRes.checkout_url);
                window.location.href = checkoutRes.checkout_url;
            } else {
                setError(prevError => ({...prevError, message: 'Failed to create checkout. Please try again.'}));
                return;
            }
        }
        return;
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <Header className='Heading05Bold'>Checkout</Header>
            </FormSection>
            <Error className='ParagraphSmallRegular' hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                <ImageContainer>
                    <Image className='fa-light fa-cart-plus' />
                </ImageContainer>
                <Paragraph className='ParagraphNormalRegular'>
                    Confirm your kit details and continue to checkout
                </Paragraph>
                <KitContainer>
                    <KitText className='ParagraphNormalRegular'>KIT DETAILS</KitText>
                    <KitItem className='ParagraphNormalRegular'>
                        <KitIcon className='fa-light fa-folder-plus fa-fw' error={undefined} />
                        <LabelText>{`Test Type: ${kitInfo.kitName}`}</LabelText>
                    </KitItem>
                    <KitItem className='ParagraphNormalRegular'>
                        <KitIcon className='fa-light fa-box-open fa-fw' error={undefined} />
                        <LabelText>Kit ID: {kitInfo.kitId1}</LabelText>
                    </KitItem>
                </KitContainer>
            </FormSection>
            <ConfidentialityContainer>
                <ConsentBox
                    id='confidentiality'
                    checked={confidentiality}
                    type='checkbox'
                    onChange={() => {
                        setConfidentiality(!confidentiality);
                    }}
                />
                <ConfidentialityLabel
                    error={error.confidentiality}
                    htmlFor='confidentiality'
                    className='ParagraphSmallRegular'>
                    I have read the{' '}
                    <InternalLink //improve the parsing of what test is what, possibly add a "Category"
                        //tag to all tests, saying allergy, std, blood wellness, covid, etc
                        href={
                            (
                                kitInfo.kitId1.length === 15
                                    ? kitInfo.kitId1.substring(3, 5) === '04'
                                    : kitInfo.kitId1.substring(0, 2) === '04'
                                    ? kitInfo.kitId1.substring(3, 5) === '05'
                                    : kitInfo.kitId1.substring(0, 2) === '05'
                                    ? kitInfo.kitId1.substring(3, 5) === '06'
                                    : kitInfo.kitId1.substring(0, 2) === '06'
                            )
                                ? CovidConsentPDF
                                : kitInfo.testType === 'advanced-std-panel' ||
                                  kitInfo.testType === 'herpes-simplex-1-and-2+'
                                ? STDConsentPDF
                                : BloodConsentPDF
                        }
                        target='_blank'>
                        Informed Consent and Testing Agreement
                    </InternalLink>{' '}
                    and I give permission to MyLabsDirect to perform testing as described.
                </ConfidentialityLabel>
            </ConfidentialityContainer>
            <FormSection>
                <LeftIcon className='fa-light fa-credit-card' error={undefined}></LeftIcon>
                <StripeText className='ParagraphSmallRegular'>
                    Your payment information will be securely processed by Stripe
                </StripeText>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    {submitting && <Spinner left='30px' />}
                    <PrimaryButton value='Proceed to Checkout' width='100%' radius='24px' />
                </SubmitContainer>
                <BackButton
                    className='ParagraphNormalRegular'
                    onClick={() => props.goToNamedStep && props.goToNamedStep('step0')}>
                    Go back
                </BackButton>
            </FormSection>
        </Form>
    );
};
