import styled from 'styled-components';

export const SkeletonContainer = styled.div`
    max-width: 676px;
    width: calc(100% - 32px);
    margin: 0 8px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--neutral-0);

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 24px;
    }
`;

export const SkeletonContent = styled.div`
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    padding: 16px;
`;

export const SkeletonHeader = styled.div`
    height: 40px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;

export const SkeletonBody = styled.div`
    height: 104px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;

export const SkeletonItem = styled.div<{width: number; height: number}>`
    width: ${(props) => (props.width >= 0 ? `${props.width}px` : '100%')};
    height: ${(props) => (props.height >= 0 ? `${props.height}px` : '100%')};
    border-radius: 8px;

    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: skeletonShimmer;
    -webkit-animation-timing-function: linear;

    @keyframes skeletonShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-top: 16px;
        margin-bottom: 16px;
    }
`;
