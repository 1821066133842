import React from 'react';
import {Alert, ArrowIcon} from './styles/SupportAlert';

interface Props {
    value?: string;
    visible: boolean;
}

const PrimaryButton: React.FC<Props> = (props) => {
    const {value, visible} = props;

    let message = value;
    if (!value) {
        message = 'Need Help? Contact MyLabsDirect support';
    }

    return (
        <Alert to="/support" className="ParagraphSmallRegular" $visible={visible}>
            {message}
            <ArrowIcon className="fa-light fa-arrow-right" />
        </Alert>
    );
};

export default PrimaryButton;
