import {Device} from '../../services/constants';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import SignInBackground from '../../assets/images/Sign In Background.jpeg';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% + 16px);
    min-height: 100vh;
    background-image: url('${SignInBackground}');
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
`;
export const Form = styled.form`
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 311px;
`;
export const FormContainer = styled.div`
    width: calc(100% - 64px);
    max-width: 311px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    gap: 16px;
    margin: 0 16px;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: #ffffff;
    transition: 0.2s;

    @media ${Device.mobile} {
        margin: 32px 16px 0 16px;
    }
`;
export const Header = styled.div`
    height: 40px;
    font-size: min(1.728rem, 9.8vw);
    letter-spacing: 1.5px;
    text-align: left;
    color: var(--text-600);
    align-self: flex-start;
`;
export const Label = styled.label<{error: string | undefined}>`
    font-size: 1rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    height: 75px;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const LabelText = styled.p`
    padding-left: 12px;
    white-space: nowrap;
`;
export const FormInput = styled.input<{error: string | undefined}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const Caption = styled.div`
    color: var(--text-600);
    letter-spacing: normal;
    margin-bottom: 16px;
`;
export const LeftIcon = styled.i<{error: string | undefined}>`
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 43px;
    color: ${(props) => (props.error ? 'var(--error-500)' : 'var(--neutral-300)')};
    transition: 0.2s;
    line-height: 20px;
`;
export const RightIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 43px;
    color: var(--neutral-900);
    line-height: 20px;

    :hover {
        cursor: pointer;
        color: var(--neutral-700);
    }
`;
export const ChangePasswordContainer = styled.div`
    margin-bottom: 16px;
`;
export const PasswordErrors = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 8px 0 0 0;
    padding-left: 12px;
`;
export const SignInLink = styled(Link)`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;
    margin-top: 16px;
    align-self: center;

    &:hover {
        color: var(--link-300);
    }
`;
export const SuccessImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 120px;
    height: 120px;
    margin-bottom: 24px;
    border-radius: 32px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;
export const SuccessImage = styled.i`
    font-size: 72px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
export const SuccessParagraph = styled.p`
    margin-bottom: 16px;
    text-align: center;
`;
export const SuccessSignInLink = styled(SignInLink)`
    margin-top: 0;
`;
export const SubmitContainer = styled.div`
    position: relative;
`;
