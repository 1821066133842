import React from 'react';
import {PassError, PasswordErrorIcon, PasswordErrorText} from './styles/PasswordError';

interface Props {
    passes: boolean;
    value: string;
}

const PasswordError: React.FC<Props> = (props) => {
    const {passes, value} = props;

    return (
        <PassError>
            <PasswordErrorIcon
                className={passes ? 'fa-light fa-circle-xmark' : 'fa-light fa-circle-check'}
                color={passes ? 'var(--error-900)' : 'var(--mld-scheme-lab-results)'}
            />
            <PasswordErrorText className="ParagraphSmallRegular">{value}</PasswordErrorText>
        </PassError>
    );
};

export default PasswordError;
