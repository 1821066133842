import React from 'react';
import {Layout} from '../Components';
import FillOutSticker from '../Resources/img/Step4.png';

const Step4 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={FillOutSticker} alt="Fill Out Sticker" />
            </div>

            <h2 className="antibody-step-number">Step 3</h2>

            <h3 className="antibody-step-description">Fill Out Unique Test Kit ID Sticker</h3>

            <p className="has-text-centered">
                Clearly write your first and last name, date of birth, and the date of sample collection on the kit ID
                label. Personal information must be the same as the information used when you registered.
            </p>

            <p className="has-text-centered">
                <small>
                    <strong>Attach kit ID label on the blank side of tube.</strong>
                </small>
            </p>
        </section>
    </Layout>
);
export default Step4;
