import React from 'react';
import {CardContainer, Header, Image, Footer, Heading, Message, Button} from './styles/DownloadFailureCard';
interface Props {
    setDownloadFailed: Function;
}

const DownloadFailureCard: React.FC<Props> = ({setDownloadFailed}) => {
    return (
        <CardContainer>
            <Header>
                <Image className="fa-light fa-face-sad-sweat" />
            </Header>
            <Footer>
                <Heading className="Heading05Bold">Aw, Snap!</Heading>
                <Message className="ParagraphNormalRegular">
                    There was an issue downloading the requested PDF. Please contact MyLabsDirect support for
                    assistance.
                </Message>
                <Button className="ParagraphLargeRegular" onClick={() => setDownloadFailed(false)}>
                    Close
                </Button>
            </Footer>
        </CardContainer>
    );
};

export default DownloadFailureCard;
