import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep5.png';

const AntibodyStep5 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 5</h2>

            <h3 className="antibody-step-description">Pierce Finger with Lancet</h3>

            <p className="has-text-centered">
                Twist cap off of lancet. Cleanse top of finger with alcohol pad. Let air dry. Place top of lancet
                securely down on pad of fingertip, press lancet top down firmly until it makes a 'click' sound.
                <br />
                <br />
                <em>
                    *Note, you may not feel the lancet's price. If not, repeat this step (Step 5), applying more
                    pressure on the second attempt.
                </em>
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep5;
