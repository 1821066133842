import styled from 'styled-components';
import {Device} from '../../services/constants';

export const PageContainer = styled.div`
    width: calc(100vw - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    height: fit-content;
    margin: 24px 0 0 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        height: 100%;
    }

    @media ${Device.mobile} {
        width: 100%;
        margin-top: 48px;
    }
`;

export const ServiceModalOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
`;
