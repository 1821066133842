import React from 'react';
import {ErrorComponent} from './styles/Error';

interface Props {
    message?: string;
    width?: string;
}

const Error: React.FC<Props> = (props) => {
    const {message, width} = props;

    return (
        <ErrorComponent
            className="ParagraphSmallRegular"
            hasSubmitted={!!message}
            message={message || ''}
            width={width || ''}>
            {message}
        </ErrorComponent>
    );
};

export default Error;
