import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
// import ProtectedRoute from './ProtectedRoute';
// import AuthRoute from './AuthRoute';
import {useAuth} from '../hooks/useAuth';
import RegisterKit from '../RegisterKit/Router';
import Home from '../containers/Home';
import PatientSwitcher from '../containers/PatientSwitcher';
import Login from '../containers/Login';
import Register from '../containers/Register';
import Results from '../containers/Results';
import HealthSummary from '../containers/HealthSummary';
import Support from '../containers/Support';
import Settings from '../containers/Settings';
import SingleResult from '../containers/SingleResult';
import Reset from '../containers/Reset';
import Verify from '../containers/Verify';
import Create from '../containers/Create';
import Auth from '../containers/Auth';
import Referral from '../containers/Referral';
import KitLanding from '../containers/KitLanding';
import Insurance from '../containers/Insurance';
import Order from '../containers/Order';
import Admin from '../containers/Admin';
import TestKits from '../containers/TestKits';
// import NewRegisterKit from '../containers/RegisterKit';
import Layout from '../views/Layout';

interface Props {
    backgrounds: any;
    setAdminBanner: Function;
    pinnedMetrics: any;
    setPinnedMetrics: Function;
    hasViewedSwitcher: boolean;
    setHasViewedSwitcher: Function;
    hasViewedServiceModal: boolean;
    setHasViewedServiceModal: Function;
}

const Switcher: React.FC<Props> = ({
    backgrounds,
    setAdminBanner,
    pinnedMetrics,
    setPinnedMetrics,
    hasViewedSwitcher,
    setHasViewedSwitcher,
    hasViewedServiceModal,
    setHasViewedServiceModal,
}) => {
    const {isLoggedIn} = useAuth();

    function RequireAuth({children, redirectTo}: any) {
        let {isLoggedIn} = useAuth();
        return isLoggedIn ? children : <Navigate to={redirectTo} />;
    }

    // TODO Added a new default route path='*' at the end since <Redirect> has been deprecated in v6, we may
    // not need that and could just set the first <Home> route's path to '*'. Needs testing.
    //
    // ^^^ can't do this currently because it will not redirect to '/', and the background image will also not load.
    return (
        <Routes>
            <Route element={<Layout showNav={true} />}>
                <Route
                    path="/"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Home
                                pinnedMetrics={pinnedMetrics}
                                hasViewedSwitcher={hasViewedSwitcher}
                                setHasViewedSwitcher={setHasViewedSwitcher}
                                hasViewedServiceModal={hasViewedServiceModal}
                                setHasViewedServiceModal={setHasViewedServiceModal}
                            />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/results"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Results />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/health-summary"
                    element={
                        <RequireAuth redirectTo="/login">
                            <HealthSummary pinnedMetrics={pinnedMetrics} setPinnedMetrics={setPinnedMetrics} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/support"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Support />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Settings />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Admin setAdminBanner={setAdminBanner} setPinnedMetrics={setPinnedMetrics} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/result/:id"
                    element={
                        <RequireAuth redirectTo="/login">
                            <SingleResult setPinnedMetrics={setPinnedMetrics} />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/test-kits/activate/*"
                    element={
                        <RequireAuth redirectTo="/login">
                            <TestKits menu="activate" />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/test-kits"
                    element={
                        <RequireAuth redirectTo="/login">
                            <TestKits />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/test-kits/return"
                    element={
                        <RequireAuth redirectTo="/login">
                            <TestKits menu="return" />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/registerkit/*"
                    element={
                        <RequireAuth redirectTo="/login">
                            <RegisterKit />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/upload-kit-video"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Navigate to="/registerkit/step/12" />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/registerkit"
                    element={
                        <RequireAuth redirectTo="/login">
                            <Navigate to="/test-kits/activate" />
                        </RequireAuth>
                    }
                />
            </Route>
            <Route element={<Layout showNav={false} />}>
                <Route
                    path="/patients"
                    element={
                        <RequireAuth redirectTo="/login">
                            <PatientSwitcher />
                        </RequireAuth>
                    }
                />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/reset">
                    <Route path="" element={<Reset />} />
                    <Route path=":token" element={<Reset />} />
                </Route>
                <Route path="/verify/:token" element={<Verify />} />
                <Route path="/create/:token" element={<Create />} />
                <Route path="/auth/:token" element={<Auth />} />
                <Route path="/insurance" element={<Insurance hasScheduler={true} />} />
                <Route path="/acuity" element={<Referral />} />
                <Route path="/kits" element={<KitLanding />} />
                {/* <AuthRoute isAuth={isLoggedIn} path="/kits">
                <KitLanding background={backgrounds.referralBackground.src} />
            </AuthRoute> */}
                <Route path="/order">
                    <Route path="" element={<Order hasInsurance={false} />} />
                    <Route path=":company" element={<Order hasInsurance={false} />} />
                </Route>
                <Route path="/insurance-order" element={<Insurance hasScheduler={false} />} />
            </Route>

            {/* a recreation of the old redirect */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default Switcher;
