import React, {useState} from 'react';
import EmptyHealthSummaryCard from '../components/EmptyHealthSummaryCard';
import HealthSummaryItem from '../components/HealthSummaryItem';
import {useAppSelector} from '../hooks';
import {selectTestsByName} from '../slices/testSlice';
import {selectCurrentPatient} from '../slices/patientSlice';
import {
    PageContainer,
    HealthSummaryHeader,
    HealthSummaryPage,
    HealthSummaryResults,
    TabBar,
    TabBarButton,
} from './styles/HealthSummary';

interface Props {
    pinnedMetrics: any;
    setPinnedMetrics: Function;
}

const HealthSummary: React.FC<Props> = ({pinnedMetrics, setPinnedMetrics}) => {
    // Valid tab options: all, abnormal, pinned
    const [currentTab, setCurrentTab] = useState('all');

    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector((state) => selectTestsByName(state, currentPatient.fullName));
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    // const currentPatientData = JSON.parse(localStorage.getItem('tests')!!).filter(
    //     (test: any) =>
    //         currentPatient.fullName &&
    //         currentPatient.fullName.toLowerCase() === `${test.firstName} ${test.lastName}`.toLowerCase()
    // );

    const healthHistory = currentPatientData.filter(
        (test: any) => test.oid.substring(test.oid.length - 10, test.oid.length - 8) === 'BW'
    );

    const uniqueTests: any = {};
    const allResults = [];
    const abnormalResults = [];
    const pinnedResults = [];

    // Filter results for tabs to easily switch between
    // TO-DO: There must be a better way than a triple for loop here?
    for (let i = 0; i < healthHistory.length; i++) {
        const panel = healthHistory[i].results;
        for (let j = 0; j < panel.length; j++) {
            const test = panel[j];
            if (!uniqueTests[test.test_name]) {
                uniqueTests[test.test_name] = true;

                if (test.abnormal === 'Low' || test.abnormal === 'High') {
                    abnormalResults.push(test);
                }

                if (
                    pinnedMetrics.findIndex(
                        (i: any) =>
                            i.metric === test.test_name &&
                            i.firstName &&
                            currentPatient.firstName &&
                            i.firstName.toLowerCase() === currentPatient.firstName.toLowerCase() &&
                            i.lastName &&
                            currentPatient.lastName &&
                            i.lastName.toLowerCase() === currentPatient.lastName.toLowerCase()
                    ) >= 0
                ) {
                    pinnedResults.push(test);
                }

                allResults.push(test);
            }
        }
    }

    return (
        <PageContainer>
            <HealthSummaryPage>
                <HealthSummaryHeader>
                    <TabBar>
                        <TabBarButton
                            className="ParagraphNormalRegular"
                            isActive={currentTab === 'all' ? 1 : 0}
                            onClick={() => setCurrentTab('all')}>
                            All Health Metrics
                        </TabBarButton>
                        <TabBarButton
                            className="ParagraphNormalRegular"
                            isActive={currentTab === 'abnormal' ? 1 : 0}
                            onClick={() => setCurrentTab('abnormal')}>
                            Abnormal Health Metrics
                        </TabBarButton>
                        <TabBarButton
                            className="ParagraphNormalRegular"
                            isActive={currentTab === 'pinned' ? 1 : 0}
                            onClick={() => setCurrentTab('pinned')}>
                            Pinned to Dashboard
                        </TabBarButton>
                    </TabBar>
                </HealthSummaryHeader>
                {allResults.length === 0 && <EmptyHealthSummaryCard />}
                {allResults.length > 0 && (
                    <HealthSummaryResults>
                        {currentTab === 'all' && (
                            <>
                                {allResults.map((result) => (
                                    <HealthSummaryItem
                                        key={result.test_name}
                                        isGrouped={false}
                                        result={result}
                                        setPinnedMetrics={setPinnedMetrics}
                                    />
                                ))}
                            </>
                        )}
                        {currentTab === 'abnormal' && (
                            <>
                                {abnormalResults.map((result) => (
                                    <HealthSummaryItem
                                        key={result.test_name}
                                        isGrouped={false}
                                        result={result}
                                        setPinnedMetrics={setPinnedMetrics}
                                    />
                                ))}
                            </>
                        )}
                        {currentTab === 'pinned' && (
                            <>
                                {pinnedResults.map((result) => (
                                    <HealthSummaryItem
                                        key={result.test_name}
                                        isGrouped={false}
                                        result={result}
                                        setPinnedMetrics={setPinnedMetrics}
                                    />
                                ))}
                            </>
                        )}
                    </HealthSummaryResults>
                )}
            </HealthSummaryPage>
        </PageContainer>
    );
};

export default HealthSummary;
