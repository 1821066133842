import {Link, useParams} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import React, {useState, useEffect, useRef} from 'react';
import DownloadFailureCard from '../components/DownloadFailureCard';
import AboutTestCard from '../components/AboutTestCard';
import BinaryResult from '../components/BinaryResult';
import PanelResult from '../components/PanelResult';
import settings from '../settings';
import {useAppSelector} from '../hooks';
import {selectCurrentPatient} from '../slices/patientSlice';
import {selectTestsByName} from '../slices/testSlice';
import axios, {AxiosRequestConfig} from 'axios';
// import {selectPatient} from '../slices/patientSlice';
// import {useSelector} from 'react-redux';
import {
    PageContainer,
    SingleResultPage,
    ContextMenu,
    ContextMenuItem,
    ContextMenuOverlay,
    ContextMenuText,
    HeaderButton,
    HeaderButtonContainer,
    HeaderContainer,
    HeaderInfo,
    HeadingText,
    SubHeaderContainer,
    SubHeading,
    SubHeadingText,
    OuterContainer,
    TestInfoContainer,
    MenuIcon,
    DownloadIcon,
    ClipboardIcon,
    MenuButton,
    DownloadMenuItem
} from './styles/SingleResult';
import {getNameString} from '../utils/Test';

const SingleResult = (props: any) => {
    const {setPinnedMetrics} = props;
    const [menuVisible, setMenuVisible] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [downloadFailed, setDownloadFailed] = useState(false);
    const [aboutTestVisible, setAboutTestVisible] = useState(false);
    const {user, token} = useAuth();
    const menuRef = useRef(null);
    const overlayRef = useRef(null);
    const {id} = useParams<{id: string}>();

    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector(state => selectTestsByName(state, currentPatient.fullName));
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    // const currentPatientData = JSON.parse(localStorage.getItem('tests')!!).filter(
    //     (test: any) =>
    //         currentPatient.fullName &&
    //         currentPatient.fullName.toLowerCase() === `${test.firstName} ${test.lastName}`.toLowerCase()
    // );

    let result: any = {};

    for (let i = 0; i < currentPatientData.length; i++) {
        if (currentPatientData[i].id.toString() === id) {
            result = currentPatientData[i];
            break;
        }
    }

    const createdDateString = result.createdDate
        ? new Date(result.createdDate).toLocaleDateString('en-US', {timeZone: 'UTC'})
        : 'N/A';
    const statusDateString = result.statusDate
        ? new Date(result.statusDate).toLocaleDateString('en-US', {timeZone: 'UTC'})
        : 'N/A';

    const createdDateISOString = result.createdDate ? new Date(result.createdDate).toISOString() : 'N/A';
    const amendedDateISOString = result.amendedDate ? new Date(result.createdDate).toISOString() : 'N/A';
    const statusDateISOString = result.statusDate ? new Date(result.statusDate).toISOString() : 'N/A';

    const oidPrefix = result.oid.substring(result.oid.length - 10, result.oid.length - 8);
    const hasMultipleResults =
        oidPrefix === 'BW' || oidPrefix === 'CF' || oidPrefix === 'BT' || oidPrefix === 'AL' || oidPrefix === 'ST';
    const isPanel = oidPrefix === 'BW' || oidPrefix === 'AL';
    const hasAboutTest =
        oidPrefix === 'AL' || //Allergy
        oidPrefix === 'AB' || //Antibody Covid
        oidPrefix === 'CV' || //Covid RT-PCR
        oidPrefix === 'RA' || //Covid Rapid Antigen
        oidPrefix === 'ST' || //STD
        oidPrefix === 'RP'; //Covid Rapid RT-PCR

    // Desired Test Names for events:
    // covid_rt-pcr
    // covid_rapid-rt-pcr
    // covid_rapid-antigen
    // covid_igg-antibody
    // covid_total-antibody
    // covid_virtual-proctor
    // blood_womens-complete-wellness
    // blood_womens-hormone-health
    // blood_mens-complete-wellness
    // blood_mens-hormone-health
    // blood_testosterone-level
    // blood_heart-health
    // blood_thyroid-health
    // blood_basic-nutrition-lifestyle-risk
    // blood_immune-health

    const testNameString = getNameString(result);

    useEffect(() => {
        (window as any).hj('identify', null, {
            email: user.userInfo?.email,
            testType: oidPrefix === 'BW' || oidPrefix === 'BT' ? 'blood' : 'covid',
            testName: testNameString,
            testDate_collected: amendedDateISOString,
            testDate_received: statusDateISOString,
            testDate_generated: createdDateISOString
        });

        // Bind menu close event (to close menu on a click outside of it)
        document.addEventListener('mousedown', ev => {
            if (!menuRef) return;
            // @ts-ignore
            if (ev.target.id === 'context-menu-overlay' || ev.target.id === 'context-menu-close') {
                setMenuVisible(false);
            }
            if (
                // @ts-ignore
                (overlayRef.current && overlayRef.current.contains(ev.target)) ||
                // @ts-ignore
                (menuRef.current && menuRef.current.contains(ev.target)) ||
                // @ts-ignore
                (ev.target && ev.target.id === 'menu-button-image')
            )
                return;

            setMenuVisible(false);
        });
    }, [menuRef, overlayRef]);

    const handleDownload = async () => {
        if (downloading) {
            return;
        }

        setDownloading(true);

        // Request PDF data
        const requestOptions: AxiosRequestConfig = {
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            responseType: 'blob'
            //data: JSON.stringify({id: result.elis_id}),
        };

        const res = await axios.post(
            `${settings.apiURL}/api/order/pdf`,
            JSON.stringify({id: result.elis_id}),
            requestOptions
        );
        setDownloading(false);
        if (res.status !== 200) {
            setDownloadFailed(true);
            const body = res.data;
            console.error(body.errorMsg);
            return;
        }

        // Download retrieved PDF
        const filename = res.headers['content-disposition']?.split('=')[1];
        const blob = res.data;
        const newBlob = new Blob([blob], {type: 'application/pdf'});

        // TODO we probably don't need the msSaveOrOpenBlob bit anymore. This is a proprietary
        // IE extension, so we only need it for old versions of IE.
        if (window.navigator && (window.navigator as any)?.msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
        } else {
            const objUrl = window.URL.createObjectURL(newBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = filename || 'Report';
            link.click();

            setTimeout(() => {
                window.URL.revokeObjectURL(objUrl);
            }, 250);
        }
    };

    return (
        <PageContainer>
            {/* shows popup for failed download or 'about test' info */}
            {(downloadFailed || aboutTestVisible) && (
                <ContextMenuOverlay
                    ref={overlayRef}
                    id='context-menu-overlay'
                    onClick={ev => {
                        // @ts-ignore
                        if (ev.target.id === 'context-menu-overlay') {
                            setDownloadFailed(false);
                            setAboutTestVisible(false);
                        }
                    }}>
                    {downloadFailed && <DownloadFailureCard setDownloadFailed={setDownloadFailed} />}
                    {aboutTestVisible && <AboutTestCard setAboutTestVisible={setAboutTestVisible} test={result} />}
                </ContextMenuOverlay>
            )}
            <SingleResultPage>
                {/* Heading */}
                <OuterContainer>
                    <HeaderContainer>
                        <HeaderInfo>
                            <Link to='/results'>
                                <HeaderButtonContainer>
                                    <HeaderButton className='fa-light fa-circle-arrow-left' />
                                </HeaderButtonContainer>
                            </Link>
                            <TestInfoContainer>
                                {/* TODO Prefix */}
                                <HeadingText className='Heading05Bold'>{testNameString}</HeadingText>
                                <SubHeading>
                                    <ClipboardIcon className='fa-light fa-clipboard-user' />
                                    <SubHeadingText className='ParagraphSmallRegular'>{`${result.firstName} ${
                                        result.lastName
                                    } (${new Date(result.birthdate).toLocaleDateString('en-US', {
                                        timeZone: 'UTC'
                                    })})`}</SubHeadingText>
                                </SubHeading>
                            </TestInfoContainer>
                            <MenuButton
                                onClick={() => {
                                    if (!menuVisible) {
                                        (window as any).hj('event', 'v2_labResults_reportView_headerBar_contextMenu');
                                    }

                                    setMenuVisible(!menuVisible);
                                }}>
                                <HeaderButton id='menu-button-image' className='fa-light fa-circle-ellipsis' />
                            </MenuButton>
                        </HeaderInfo>
                        <ContextMenu ref={menuRef} visible={menuVisible} missingOption={!hasAboutTest}>
                            <DownloadMenuItem
                                isVisible={menuVisible}
                                isDownloading={downloading}
                                onClick={() => {
                                    (window as any).hj(
                                        'event',
                                        'v2_labResults_reportView_headerBar_contextMenu_downloadPdf'
                                    );
                                    handleDownload();
                                }}>
                                <ContextMenuText className='ParagraphNormalRegular'>
                                    {downloading ? 'Downloading Report PDF...' : 'Download Report PDF'}
                                </ContextMenuText>
                                <DownloadIcon
                                    className={downloading ? 'fa-light fa-spinner-third' : 'fa-light fa-download'}
                                    isspinning={downloading ? 1 : 0}
                                />
                            </DownloadMenuItem>
                            {hasAboutTest && (
                                <ContextMenuItem
                                    isVisible={menuVisible}
                                    onClick={() => {
                                        if (!aboutTestVisible) {
                                            (window as any).hj(
                                                'event',
                                                'v2_labResults_reportView_headerBar_contextMenu_aboutTest'
                                            );
                                        }

                                        setAboutTestVisible(!aboutTestVisible);
                                    }}>
                                    <ContextMenuText className='ParagraphNormalRegular'>
                                        About this test
                                    </ContextMenuText>
                                    <MenuIcon className='fa-light fa-circle-info' />
                                </ContextMenuItem>
                            )}
                        </ContextMenu>
                    </HeaderContainer>
                    {(result.firstName || result.lastName) && (
                        <SubHeaderContainer>
                            <SubHeadingText className='ParagraphSmallRegular'>{`Collected on: ${
                                createdDateString === '1/1/2001' ? 'N/A' : createdDateString
                            } - Received on: ${
                                createdDateString === '1/1/2001' ? 'N/A' : createdDateString
                            } - Results generated on: ${
                                statusDateString === '1/1/2001' ? 'N/A' : statusDateString
                            }`}</SubHeadingText>
                        </SubHeaderContainer>
                    )}
                </OuterContainer>

                {/* Displays Results */}
                <>
                    {result.resultType && (result.resultType === 'allergy' || result.resultType === 'blood') ? (
                        <PanelResult
                            setPinnedMetrics={setPinnedMetrics}
                            result={result}
                            test_type={result.resultType}
                        />
                    ) : result.resultType && result.resultType === 'binary' ? (
                        <BinaryResult result={result} hasMultipleResults={hasMultipleResults} />
                    ) : !isPanel ? (
                        <BinaryResult result={result} hasMultipleResults={hasMultipleResults} />
                    ) : (
                        <PanelResult
                            setPinnedMetrics={setPinnedMetrics}
                            result={result}
                            test_type={oidPrefix === 'BW' ? 'blood' : oidPrefix === 'AL' ? 'allergy' : ''}
                        />
                    )}
                </>
            </SingleResultPage>
        </PageContainer>
    );
};

export default SingleResult;
