import styled from 'styled-components';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;
export const SupportPage = styled.div`
    margin: 32px 16px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 0;
`;

export const BannerText = styled.div`
    min-height: 45px;
    flex-grow: 0;
    text-align: left;
`;

export const SupportOptions = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 0;
`;

export const SubText = styled.div`
    height: 48px;
    flex-grow: 0;
    text-align: left;
`;
export const Frame = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--neutral-0);
    box-shadow: var(--card-shadow);
`;

export const Box = styled.div`
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
`;

export const SupportItem = styled.div`
    display: flex;
    align-items: center;
`;

export const SupportItemPhoto = styled.i`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    font-size: 24px;
`;
