import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../hooks';
import {setCurrentPatient} from '../slices/patientSlice';
import {PatientBirthday, PatientContainer, PatientImage, PatientName, CurrentCaption} from './styles/PatientSelection';

interface Props {
    patient: any;
    isCurrent: boolean;
}

const PatientSelection: React.FC<Props> = ({patient, isCurrent}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <PatientContainer
            isCurrent={isCurrent ? 1 : 0}
            onClick={() => {
                dispatch(setCurrentPatient(patient));
                //localStorage.setItem('patient', JSON.stringify(patient));
                navigate('/');
            }}>
            <PatientImage className="fa-light fa-user" />
            <PatientName className="Heading06Bold">{`${
                Array.isArray(patient.firstName) ? patient.firstName[0] : patient.firstName
            } ${Array.isArray(patient.lastName) ? patient.lastName[0] : patient.lastName}`}</PatientName>
            <PatientBirthday className="ParagraphSmallRegular">
                {typeof patient.birthdate !== 'object'
                    ? new Date(patient.birthdate).toLocaleDateString('en-US', {timeZone: 'UTC'})
                    : patient.birthdate.toLocaleDateString('en-US', {timeZone: 'UTC'})}
            </PatientBirthday>
            {isCurrent && <CurrentCaption className="CaptionRegular">CURRENTLY VIEWING</CurrentCaption>}
        </PatientContainer>
    );
};

export default PatientSelection;
