import React, {useState, ChangeEvent, FormEvent} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import TopBar from '../components/TopBar';
import PasswordError from '../components/PasswordError';
import bcrypt from 'bcryptjs';
import PrimaryButton from '../components/PrimaryButton';
import Spinner from '../components/Spinner';
import Error from '../components/Error';
import settings from '../settings';
import axios from 'axios';
import {
    PageContainer,
    Form,
    FormContainer,
    FormInput,
    Header,
    Caption,
    Label,
    LabelText,
    LeftIcon,
    SignInLink,
    SubmitContainer,
    SuccessImage,
    SuccessImageContainer,
    SuccessParagraph,
    SuccessSignInLink,
    ChangePasswordContainer,
    RightIcon,
    PasswordErrors,
} from './styles/Reset';

const Reset: React.FC = () => {
    const [state, setState] = useState<{[x: string]: string}>();
    const [sent, setSent] = useState(false);
    const [expired, setExpired] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState<{[x: string]: string}>();
    const [passwordError, setPasswordError] = useState<{[x: string]: boolean}>({});
    const {token} = useParams<{token: string}>();
    const [show1, setShow1] = useState('password');
    const [show2, setShow2] = useState('password');
    const [sending, setSending] = useState(false);
    const [resetting, setResetting] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref');

    const togglePassword1 = () => {
        setShow1(show1 === 'password' ? 'text' : 'password');
    };

    const togglePassword2 = () => {
        setShow2(show2 === 'password' ? 'text' : 'password');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        const value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'password1') {
            const passwordUppercaseReg = /[A-Z]/;
            const passwordLowercaseReg = /[a-z]/;
            const passwordNumberReg = /[\d]/;

            setPasswordError({
                ...passwordError,
                passwordLetters: !passwordUppercaseReg.test(value) || !passwordLowercaseReg.test(value),
                passwordNumber: !passwordNumberReg.test(value),
                passwordLength: value.length <= 8,
            });
        }

        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: ''}));
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!state?.username) {
            setError((prevError) => ({...prevError, message: 'Please enter your email', username: 'true'}));
            return;
        }

        setSending(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify({username: state.username, ref: ref}),
        };

        const res = await axios(`${settings.apiURL}/api/reset`, requestOptions);
        setSending(false);
        if (res.status !== 200) {
            const message = res.data.error;
            setError((prevError) => ({...prevError, message: message}));
            return;
        }
        setError({
            message: '',
            password1: '',
            password2: '',
        });
        setSent(true);
    };

    const handleReset = async (event: FormEvent) => {
        event.preventDefault();
        if (!state?.password1) {
            setError((prevError) => ({...prevError, message: 'Please enter your new password', password1: 'true'}));
            return;
        }
        if (!state?.password2) {
            setError((prevError) => ({
                ...prevError,
                message: 'Please confirm your new password',
                password1: '',
                password2: 'true',
            }));
            return;
        }
        for (const key in passwordError) {
            if (passwordError[key]) {
                setError((prevError) => ({
                    ...prevError,
                    message: 'Please ensure password meets requirements',
                    password1: 'true',
                    password2: '',
                }));
                return;
            }
        }
        if (state.password1 !== state.password2) {
            setError((prevError) => ({
                ...prevError,
                message: 'Please ensure passwords match',
                password1: 'true',
                password2: 'true',
            }));
            return;
        }
        if (!token) {
            return;
        }

        const hash = await bcrypt.hash(state.password1, 10);
        setResetting(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify({password: hash, token: token}),
        };

        const res = await axios(`${settings.apiURL}/api/updatePass`, requestOptions);
        setResetting(false);
        if (res.status !== 200) {
            const message = res.data.error;

            if (message === 'This link has expired') {
                setExpired(true);
                return;
            }

            setError((prevError) => ({...prevError, message: message}));
            return;
        }
        setError({
            message: '',
            password1: '',
            password2: '',
        });
        setUpdated(true);
    };

    return (
        <PageContainer>
            <TopBar location={ref ? `/login?ref=${ref}` : '/login'} />
            <FormContainer>
                {!sent && !token && (
                    <>
                        <Header className="Heading04Bold">Forgot Password</Header>
                        <Error message={error?.message} />
                        <Form onSubmit={handleSubmit}>
                            <Caption className="ParagraphNormalRegular">
                                Enter the email address associated with your account and we'll send you an email with a
                                password reset link.
                            </Caption>
                            <Label error={error?.username} className="Heading05Regular" style={{marginBottom: '16px'}}>
                                <LabelText>Email</LabelText>
                                <LeftIcon className="fa-solid fa-envelope" error={error?.username} />
                                <FormInput
                                    error={error?.username}
                                    type="text"
                                    name="username"
                                    value={state?.username || ''}
                                    onChange={handleChange}
                                />
                            </Label>
                            <SubmitContainer>
                                {sending && <Spinner left="12%" />}
                                <PrimaryButton value="Reset Password" width="100%" />
                            </SubmitContainer>
                            <SignInLink to={ref ? `/login?ref=${ref}` : '/login'}>Back to sign in</SignInLink>
                        </Form>
                    </>
                )}
                {!!sent && !token && (
                    <>
                        <SuccessImageContainer>
                            <SuccessImage className="fa-light fa-envelope-dot" />
                        </SuccessImageContainer>
                        <SuccessParagraph className="Heading05Bold">Check Your Email</SuccessParagraph>
                        <SuccessParagraph className="ParagraphNormalRegular">
                            We've sent an email with instructions and a link to reset your password
                        </SuccessParagraph>
                        <SuccessSignInLink to={ref ? `/login?ref=${ref}` : '/login'}>Back to sign in</SuccessSignInLink>
                    </>
                )}

                {!!token && !updated && !expired && (
                    <>
                        <Header className="Heading04Bold">Create New Password</Header>
                        <Error message={error?.message} />
                        <Form onSubmit={handleReset}>
                            <ChangePasswordContainer>
                                <Label error={error?.password1} className="Heading05Regular">
                                    <LabelText>New Password</LabelText>
                                    <RightIcon
                                        className={show1 === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                        onClick={togglePassword1}
                                    />
                                    <FormInput
                                        error={error?.password1}
                                        type={show1}
                                        name="password1"
                                        value={state?.password1 || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <PasswordErrors>
                                    <PasswordError
                                        passes={passwordError?.passwordLength || !state?.password1}
                                        value="At least 9 characters long"
                                    />
                                    <PasswordError
                                        passes={passwordError?.passwordLetters || !state?.password1}
                                        value="Include at least 1 uppercase and lowercase letter"
                                    />
                                    <PasswordError
                                        passes={passwordError?.passwordNumber || !state?.password1}
                                        value="Include at least 1 number"
                                    />
                                </PasswordErrors>
                            </ChangePasswordContainer>
                            <ChangePasswordContainer>
                                <Label error={error?.password2} className="Heading05Regular">
                                    <LabelText>Confirm New Password</LabelText>
                                    <RightIcon
                                        className={show2 === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                        onClick={togglePassword2}
                                    />
                                    <FormInput
                                        error={error?.password2}
                                        type={show2}
                                        name="password2"
                                        value={state?.password2 || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <PasswordErrors>
                                    <PasswordError
                                        passes={!(state?.password1 === state?.password2 && !!state?.password1)}
                                        value="Passwords match"
                                    />
                                </PasswordErrors>
                            </ChangePasswordContainer>
                            <SubmitContainer>
                                {resetting && <Spinner left="12%" />}
                                <PrimaryButton value="Reset Password" width="100%" />
                            </SubmitContainer>
                        </Form>
                    </>
                )}

                {!!expired && (
                    <>
                        <SuccessImageContainer>
                            <SuccessImage className="fa-light fa-face-thermometer" />
                        </SuccessImageContainer>
                        <SuccessParagraph className="Heading05Bold">Sorry!</SuccessParagraph>
                        <SuccessParagraph className="ParagraphNormalRegular">
                            This link has expired. If you still want to reset your password, please make another another
                            request.
                        </SuccessParagraph>
                        <SuccessSignInLink to={ref ? `/login?ref=${ref}` : '/login'}>Back to sign in</SuccessSignInLink>
                    </>
                )}

                {!!updated && (
                    <>
                        <SuccessImageContainer>
                            <SuccessImage className="fa-light fa-key" />
                        </SuccessImageContainer>
                        <SuccessParagraph className="Heading05Bold">All Done!</SuccessParagraph>
                        <SuccessParagraph className="ParagraphNormalRegular">
                            Your password has been successfully reset, please sign in using your new password.
                        </SuccessParagraph>
                        <SuccessSignInLink to={ref ? `/login?ref=${ref}` : '/login'}>Back to sign in</SuccessSignInLink>
                    </>
                )}
            </FormContainer>
        </PageContainer>
    );
};

export default Reset;
