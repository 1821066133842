import React from 'react';
import {Header, HeaderButton, HeaderButtonImage, HeaderText} from './styles/SettingsHeader';

interface Props {
    onClick?: Function;
    value: string;
    className?: string;
}

const SettingsHeader: React.FC<Props> = (props) => {
    let {onClick, value, className} = props;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    if (!className) {
        className = 'Heading06Bold';
    }

    return (
        <Header>
            {onClick && ( //if theres no function given, dont show the back button
                <HeaderButton onClick={handleClick}>
                    <HeaderButtonImage className="fa-light fa-circle-arrow-left" />
                </HeaderButton>
            )}
            <HeaderText className={className}>{value}</HeaderText>
        </Header>
    );
};

export default SettingsHeader;
