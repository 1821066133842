import styled from 'styled-components';

export const SkeletonContainer = styled.div`
    width: 280px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
`;

export const SkeletonItem = styled.div<{width: number; height: number; isCircle: number}>`
    width: ${(props) => (props.width >= 0 ? `${props.width}px` : '100%')};
    height: ${(props) => (props.height >= 0 ? `${props.height}px` : '100%')};
    border-radius: ${(props) => (props.isCircle ? '50%' : '8px')};

    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: skeletonShimmer;
    -webkit-animation-timing-function: linear;

    @keyframes skeletonShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-top: 16px;
        margin-bottom: 16px;
    }
`;
