import React from 'react';
import HealthSummaryItem from '../components/HealthSummaryItem';
import AllergyItem from '../components/AllergyItem';
import {
    PanelContainer,
    PanelHeader,
    PanelName,
    BiomarkerImage,
    Biomarkers,
    Paragraph,
    MetricList,
    Separator,
} from './styles/PanelDisplay';

interface Props {
    setPinnedMetrics: Function;
    panel: string;
    results: any;
    test_type: string;
}

const PanelDisplay: React.FC<Props> = ({setPinnedMetrics, panel, results, test_type}) => {
    const metrics: {[index: string]: any} = {};

    if (results.length === 0) {
        return null;
    }

    return (
        <PanelContainer>
            <PanelHeader>
                <PanelName className="ParagraphLargeBold">{panel}</PanelName>
                <Biomarkers>
                    <BiomarkerImage className="fa-regular fa-circle" />
                    <Paragraph className="ParagraphNormalRegular">{`${results.length} ${
                        test_type === 'blood' && results.length === 1
                            ? 'biomarker'
                            : test_type === 'allergy' && results.length === 1
                            ? 'allergen'
                            : test_type === 'blood'
                            ? 'biomarkers'
                            : test_type === 'allergy'
                            ? 'allergens'
                            : 'biomarkers'
                    }`}</Paragraph>
                </Biomarkers>
            </PanelHeader>
            <MetricList>
                {results
                    .filter((r: any) => r.test_name !== '01; Total IgE')
                    .map((r: any, index: any) => {
                        let displayMetric = false;

                        if (!metrics[r.test_name]) {
                            displayMetric = true;
                            metrics[r.test_name] = true;
                        }

                        return (
                            <React.Fragment key={index}>
                                {index !== 0 && <Separator />}
                                {displayMetric && test_type === 'blood' && (
                                    <HealthSummaryItem
                                        isGrouped={true}
                                        result={r}
                                        setPinnedMetrics={setPinnedMetrics}
                                        isLabResult={true}
                                    />
                                )}
                                {displayMetric && test_type === 'allergy' && r.result !== null && <AllergyItem result={r} />}
                            </React.Fragment>
                        );
                    })}
            </MetricList>
        </PanelContainer>
    );
};

export default PanelDisplay;
