import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../store';

// Define a type for the slice state
interface Patient {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    birthdate: string;
}

interface PatientState {
    currentPatient: Patient;
    subPatients: Patient[];
}

// Define the initial state using that type
const initialState: PatientState = {
    currentPatient: {id: 0, firstName: '', lastName: '', fullName: '', birthdate: ''},
    subPatients: [],
};

export const patientSlice = createSlice({
    name: 'patient',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setCurrentPatient: (state, action: PayloadAction<Patient>) => {
            Object.assign(state.currentPatient, action.payload);
        },
        setSubPatients: (state, action: PayloadAction<Patient[]>) => {
            //Object.assign(state.subPatients, action.payload);
            state.subPatients = action.payload;
        },
    },
});

export const {setCurrentPatient, setSubPatients} = patientSlice.actions;

export const selectCurrentPatient = (state: RootState) => state.patient.currentPatient;
export const selectFullname = (state: RootState) => state.patient.currentPatient?.fullName;
export const selectSubPatients = (state: RootState) => state.patient.subPatients;

export default patientSlice.reducer;
