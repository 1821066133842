import styled from 'styled-components';

export const FastFactsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
`;
export const FastFact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 139px;
    padding: 8px;
    border-radius: 8px;
    border: solid 1px var(--neutral-100);
`;
export const FastFactIcon = styled.img`
    width: 48px;
    height: 48px;
    margin: 0 auto;
`;
