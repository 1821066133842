import styled from 'styled-components';

export const ErrorComponent = styled.p<{hasSubmitted: boolean; message: string; width: string}>`
    align-self: center;
    width: ${(props) => (props.width ? props.width : 'calc(100% - 24px)')};
    color: var(--text-0);
    text-align: center;
    overflow: hidden;
    margin: ${(props) => (props.hasSubmitted ? '16px 32px' : '0 32px')};
    padding: ${(props) => (props.hasSubmitted ? '12px' : '0')};
    border-radius: 8px;
    background-color: ${(props) =>
        props.hasSubmitted
            ? props.message === 'Success!'
                ? 'rgba(0, 59, 77, 1)'
                : 'rgba(165, 20, 0, 1)'
            : 'rgba(165, 20, 0, 0)'};
    transition: height 0.2s, margin 0.2s, padding 0.2s, background-color 0.2s 0.2s;
`;
