import React, {useState, ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import PrimaryButton from '../../components/PrimaryButton';
import Spinner from '../../components/Spinner';
import settings from '../../settings';
import {useAppSelector, useAppDispatch} from '../../hooks';
import {setKitParam} from '../../slices/kitSlice';
import {States, Ethnicities} from '../../services/constants';
import {
    Form,
    FormSection,
    Error,
    SubmitContainer,
    BackButton,
    Header,
    Label,
    Select,
    Image,
    ImageContainer,
    Paragraph,
    SurveyQuestion,
    RadioInput,
    SymptomInputContainer,
    SymptomOptionLabel,
    FormInput,
    StyledDatePicker
} from './styles/Step3';

export const Step3: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector(state => state.kit);
    const dispatch = useAppDispatch();
    const {user, token} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: ''
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const hasNoFirstName = !user.userInfo.firstName;
    const hasNoLastName = !user.userInfo.lastName;
    const hasNoBirthday = !user.userInfo.birthday;
    const hasNoAddress = !user.userInfo.address1;
    const hasNoCity = !user.userInfo.city;
    const hasNoState = !user.userInfo.state;
    const hasNoZip = !user.userInfo.zip;
    const hasNoPhone = !user.userInfo.phone;
    const hasNoGender = !user.userInfo.gender;
    const hasNoEthnicity = !user.userInfo.ethnicity;

    const hasMissingInfo =
        hasNoFirstName ||
        hasNoLastName ||
        hasNoBirthday ||
        hasNoAddress ||
        hasNoCity ||
        hasNoState ||
        hasNoZip ||
        hasNoPhone ||
        hasNoGender ||
        hasNoEthnicity;

    const covidIds: {[index: string]: boolean} = {
        '01': true,
        '02': true,
        '03': true,
        '04': true,
        '05': true,
        '06': true
    };

    const companies: any = {
        'baylor-student-on-campus': 1,
        'baylor-student-off-campus': 2,
        'baylor-faculty': 3,
        'baylor-staff': 4,
        'varsity-spirit': 110
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        // const affiliate = state.kitId1.length === 15 ? state.kitId1.substring(0, 2) : '';
        // const test = state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2);
        // const isBlood = bloodIds[test];
        // ([a-zA-Z]{2}\-)? Optionally matches a two letter prefix and hyphen
        // (0[1-6]) Matches 01-06, our valid test identifiers
        // \-\d{4} Matches a hyphen then four digits, this match is then repeated once more
        const regex = /^([a-zA-Z]{2}\-)?(0[1-6])\-\d{4}\-\d{4}$/;
        const result = regex.exec(kitInfo.kitId1);

        // Second capture group ie BW-(01)-0000-0000 or (01)-0000-0000, representing our test ID
        const testID = result?.[2];
        // First, optional capture group ie (BW)-01-0000-0000, our affiliate ID
        const affiliateID = result?.[1];
        // If we have an affiliate prefix, this is a blood test
        const isBlood = affiliateID && affiliateID?.length > 0;
        // If this is not a blood test, and the test ID is one of our promo IDs, this test is promotional
        //const isPromo = !isBlood && promoIds?.[testID];

        if (submitting) {
            return;
        } else {
            setError(prevError => ({
                ...prevError,
                contact: !isBlood && kitInfo.contact === '',
                symptoms: !isBlood && kitInfo.symptoms === '',
                vaccinated: !isBlood && kitInfo.company === 'varsity-spirit' && kitInfo.vaccinated === '',
                firstName: hasNoFirstName && kitInfo.firstName === '',
                lastName: hasNoLastName && kitInfo.lastName === '',
                birthday: hasNoBirthday && kitInfo.birthday === '',
                address1: hasNoAddress && kitInfo.address1 === '',
                city: hasNoCity && kitInfo.city === '',
                state: hasNoState && kitInfo.state === '',
                zip: hasNoZip && kitInfo.zip === '',
                phone: hasNoPhone && kitInfo.phone === '',
                gender: hasNoGender && kitInfo.gender === '',
                ethnicity: hasNoEthnicity && kitInfo.ethnicity === ''
            }));

            if (
                (!isBlood && kitInfo.contact === '') ||
                (!isBlood && kitInfo.symptoms === '') ||
                (!isBlood && kitInfo.vaccinated === '' && kitInfo.company === 'varsity-spirit') ||
                (hasNoFirstName && kitInfo.firstName === '') ||
                (hasNoLastName && kitInfo.lastName === '') ||
                (hasNoBirthday && kitInfo.birthday === '') ||
                (hasNoAddress && kitInfo.address1 === '') ||
                (hasNoCity && kitInfo.city === '') ||
                (hasNoState && kitInfo.state === '') ||
                (hasNoZip && kitInfo.zip === '') ||
                (hasNoPhone && kitInfo.phone === '') ||
                (hasNoGender && kitInfo.gender === '') ||
                (hasNoEthnicity && kitInfo.ethnicity === '')
            ) {
                return;
            }

            const error = await handleRegistration();

            if (error) {
                return;
            }

            if (hasMissingInfo) {
                const newInfo: any = {
                    firstName: kitInfo.firstName || user.userInfo.firstName,
                    lastName: kitInfo.lastName || user.userInfo.lastName,
                    birthday: kitInfo.birthday || user.userInfo.birthday,
                    address1: kitInfo.address1 || user.userInfo.address1,
                    city: kitInfo.city || user.userInfo.city,
                    state: kitInfo.state || user.userInfo.state,
                    zip: kitInfo.zip || user.userInfo.zip,
                    phone: kitInfo.phone || user.userInfo.phone,
                    gender: kitInfo.gender || user.userInfo.gender,
                    ethnicity: kitInfo.ethnicity || user.userInfo.ethnicity
                };

                const existingInfo = Object.assign(user.userInfo);

                for (const prop in newInfo) {
                    delete existingInfo[prop];
                }

                const update = {
                    ...existingInfo,
                    ...newInfo
                };

                setSubmitting(true);

                const updateUserOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify(update)
                };

                const updateUserRes = await fetch(`${settings.apiURL}/api/user`, updateUserOptions);
                if (!updateUserRes.ok) {
                    console.log('BAD CREDS');
                }

                for (const prop in newInfo) {
                    user.userInfo[prop] = update[prop];
                }

                setSubmitting(false);
            }

            if (!isBlood) {
                let url =
                    testID === '01'
                        ? `/registerkit/step/1?id=${kitInfo.kitId1}`
                        : testID === '02'
                        ? `/registerkit/antibody/step/1`
                        : '/';

                if (url === '/') {
                    props.goToNamedStep?.('success');
                }

                navigate(url);
                return;
            }

            props.goToNamedStep?.('step4');
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'kitId1' || name === 'kitId2') {
            const digitReg = /^[0-9]$/;
            const lowerReg = /^[a-z]$/;
            const letterReg = /^[A-Z]$/;

            if (lowerReg.test(value[value.length - 1])) {
                value = value.toUpperCase();
            }

            if (
                value.length === 3 ||
                (letterReg.test(value[0]) && value.length === 6) ||
                (digitReg.test(value[0]) && value.length === 8) ||
                (letterReg.test(value[0]) && value.length === 11)
            ) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (
                (value !== '' && !digitReg.test(value[value.length - 1]) && !letterReg.test(value[value.length - 1])) ||
                (letterReg.test(value[value.length - 1]) && value.length > 3) ||
                (digitReg.test(value[0]) && value.length > 12) ||
                value.length > 15
            ) {
                return;
            }
        }

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }
        }

        if (name === 'zip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        dispatch(setKitParam({[name]: value}));
        setError(prevError => ({...prevError, [name]: false}));
    };

    const handleRegistration = async () => {
        // const affiliate = state.kitId1.length === 15 ? state.kitId1.substring(0, 2) : '';
        // const test = state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2);
        // const isBlood = bloodIds[test];
        // ([a-zA-Z]{2}\-)? Optionally matches a two letter prefix and hyphen
        // (0[1-6]) Matches 01-06, our valid test identifiers
        // \-\d{4} Matches a hyphen then four digits, this match is then repeated once more
        const regex = /^([a-zA-Z]{2}\-)?(0[1-6])\-\d{4}\-\d{4}$/;
        const result = regex.exec(kitInfo.kitId1);

        // Second capture group ie BW-(01)-0000-0000 or (01)-0000-0000, representing our test ID
        const testID = result?.[2];
        // First, optional capture group ie (BW)-01-0000-0000, our affiliate ID
        const affiliateID = result?.[1];
        // If we have an affiliate prefix, this is a blood test
        const isBlood = affiliateID && affiliateID?.length > 0;
        // If this is not a blood test, and the test ID is one of our promo IDs, this test is promotional
        //const isPromo = !isBlood && promoIds?.[testID];

        let productLine = '';
        if (isBlood) {
            productLine = 'Blood Wellness';
        } else {
            switch (testID) {
                case '01':
                case '04':
                    productLine = 'COVID-19 PCR';
                    break;
                case '02':
                case '03':
                case '05':
                case '06':
                    productLine = 'COVID-19 Antibody';
                    break;
            }
        }

        let specimenType = '';
        if (isBlood) {
            specimenType = 'Whole Blood';
        } else {
            switch (testID) {
                case '01':
                case '04':
                    specimenType = 'Nasal Swab';
                    break;
                case '02':
                case '03':
                case '05':
                case '06':
                    specimenType = 'Whole Blood';
                    break;
            }
        }

        let panels = [kitInfo.testType];

        if (!isBlood) {
            panels =
                testID === '01' || testID === '04' // PCR
                    ? ['001']
                    : testID === '02' || testID === '05' // Antibody
                    ? ['003']
                    : testID === '03' || testID === '06' // Igg
                    ? ['004']
                    : ['001'];
        }

        const diagCodes = testID === '01' || testID === '03' ? ['Z1152'] : [];

        const order = {
            patient: {
                firstName: hasNoFirstName ? kitInfo.firstName : user.userInfo.firstName,
                lastName: hasNoLastName ? kitInfo.lastName : user.userInfo.lastName,
                gender: hasNoGender ? kitInfo.gender : user.userInfo.gender[0].toUpperCase(),
                categoryId: companies[kitInfo.company] || '',
                birthDate: hasNoBirthday ? new Date(kitInfo.birthday).toISOString() : user.userInfo.birthday,
                email: user.userInfo.email,
                race: hasNoEthnicity ? kitInfo.ethnicity : user.userInfo.ethnicity,
                address: {
                    phone: hasNoPhone ? kitInfo.phone : user.userInfo.phone,
                    address1: hasNoAddress ? kitInfo.address1 : user.userInfo.address1,
                    address2: user.userInfo.address2,
                    city: hasNoCity ? kitInfo.city : user.userInfo.city,
                    state: hasNoState ? kitInfo.state : user.userInfo.state,
                    zipCode: hasNoZip ? kitInfo.zip : user.userInfo.zip,
                    country: user.userInfo.country
                }
            },
            specimenId: kitInfo.kitId1,
            providerNPI: '1346818218',
            lab: settings.env === 'production' ? 'Default' : 'Test',
            productLine: productLine,
            specimenType: specimenType,
            profiles: [],
            panels: panels,
            diagCodes: diagCodes,
            sendEmail: false,
            doNotCollect: true,
            createNoUser: true,
            surveyAnswers: [
                {
                    questionId: 1,
                    answerText: kitInfo.symptoms
                },
                {
                    questionId: 2,
                    answerText: kitInfo.contact
                },
                {
                    questionId: 3,
                    answerText: kitInfo.vaccinated
                },
                {
                    questionId: 4,
                    answerText: kitInfo.vaccinated
                }
            ]
        };

        setSubmitting(true);

        const orderRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify(order)
        };

        const res = await fetch(`${settings.apiURL}/api/order/secureCreate`, orderRequestOptions);
        if (!res.ok) {
            setSubmitting(false);
            const message = (await res.json()).error;
            const registered = message === 'Specimen ID already exists in another order.';
            setError(prevError => ({
                ...prevError,
                message: registered ? 'This kit ID has already been registered.' : 'Could not create order'
            }));
            return message;
        }

        const orderRes = await res.json();

        const kit = {
            kit_id: kitInfo.kitId1,
            customer_id: user.userInfo.id,
            order_id: orderRes.id,
            category: companies[kitInfo.company] || '',
            createdDate: new Date(),
            firstName: user.userInfo.firstName,
            email: user.userInfo.email
        };

        const kitRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify(kit)
        };

        const kitRes = await fetch(`${settings.apiURL}/api/kit/createKitRegistration`, kitRequestOptions);
        setSubmitting(false);
        if (!kitRes.ok) {
            // const message = (await res.json()).error;
            setError(prevError => ({...prevError, message: 'Could not submit kit'}));
            return 'Could not submit kit';
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            {covidIds[
                kitInfo.kitId1.length === 15 ? kitInfo.kitId1.substring(3, 5) : kitInfo.kitId1.substring(0, 2)
            ] && (
                <>
                    <FormSection>
                        <Header className='Heading05Bold'>How are you feeling?</Header>
                    </FormSection>
                </>
            )}
            <Error className='ParagraphSmallRegular' hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                {covidIds[
                    kitInfo.kitId1.length === 15 ? kitInfo.kitId1.substring(3, 5) : kitInfo.kitId1.substring(0, 2)
                ] && (
                    <>
                        <ImageContainer>
                            <Image className='fa-light fa-face-thermometer' />
                        </ImageContainer>
                        <Paragraph>Please complete this COVID-19 screening form</Paragraph>
                        <SurveyQuestion error={error.contact}>
                            In the past 14 days, have you been in close contact with anyone who tested positive for
                            COVID-19?
                            <SymptomInputContainer>
                                <RadioInput
                                    error={error.contact}
                                    id='yes1'
                                    type='radio'
                                    name='contact'
                                    value='Yes'
                                    checked={kitInfo.contact === 'Yes'}
                                    onChange={handleChange}
                                />
                                <SymptomOptionLabel htmlFor='yes1'>Yes</SymptomOptionLabel>
                                <RadioInput
                                    error={error.contact}
                                    id='no1'
                                    type='radio'
                                    name='contact'
                                    value='No'
                                    checked={kitInfo.contact === 'No'}
                                    onChange={handleChange}
                                />
                                <SymptomOptionLabel htmlFor='no1'>No</SymptomOptionLabel>
                            </SymptomInputContainer>
                        </SurveyQuestion>
                        <SurveyQuestion error={error.symptoms}>
                            <p>
                                Are you currently experiencing <strong>any</strong> symptoms?
                            </p>
                            <SymptomInputContainer>
                                <RadioInput
                                    error={error.symptoms}
                                    id='yes2'
                                    type='radio'
                                    name='symptoms'
                                    value='Yes'
                                    checked={kitInfo.symptoms === 'Yes'}
                                    onChange={handleChange}
                                />
                                <SymptomOptionLabel htmlFor='yes2'>Yes</SymptomOptionLabel>
                                <RadioInput
                                    error={error.symptoms}
                                    id='no2'
                                    type='radio'
                                    name='symptoms'
                                    value='No'
                                    checked={kitInfo.symptoms === 'No'}
                                    onChange={handleChange}
                                />
                                <SymptomOptionLabel htmlFor='no2'>No</SymptomOptionLabel>
                            </SymptomInputContainer>
                        </SurveyQuestion>
                        {kitInfo.company === 'varsity-spirit' && (
                            <SurveyQuestion error={error.vaccinated}>
                                Are you fully vaccinated?
                                <SymptomInputContainer>
                                    <RadioInput
                                        error={error.vaccinated}
                                        id='yes3'
                                        type='radio'
                                        name='vaccinated'
                                        value='Yes'
                                        checked={kitInfo.vaccinated === 'Yes'}
                                        onChange={handleChange}
                                    />
                                    <SymptomOptionLabel htmlFor='yes3'>Yes</SymptomOptionLabel>
                                    <RadioInput
                                        error={error.vaccinated}
                                        id='no3'
                                        type='radio'
                                        name='vaccinated'
                                        value='No'
                                        checked={kitInfo.vaccinated === 'No'}
                                        onChange={handleChange}
                                    />
                                    <SymptomOptionLabel htmlFor='no3'>No</SymptomOptionLabel>
                                </SymptomInputContainer>
                            </SurveyQuestion>
                        )}
                    </>
                )}
                {hasMissingInfo && !submitting && (
                    <>
                        <Header className='Heading05Bold' style={{alignSelf: 'center', marginTop: '16px'}}>
                            Please confirm the following:
                        </Header>
                        {hasNoFirstName && (
                            <Label error={error.firstName} style={{fontWeight: 'normal'}}>
                                First Name
                                <FormInput
                                    error={error.firstName}
                                    type='text'
                                    name='firstName'
                                    value={kitInfo.firstName || ''}
                                    placeholder='John'
                                    onChange={handleChange}
                                />
                            </Label>
                        )}
                        {hasNoLastName && (
                            <Label error={error.lastName} style={{fontWeight: 'normal'}}>
                                Last Name
                                <FormInput
                                    error={error.lastName}
                                    type='text'
                                    name='lastName'
                                    value={kitInfo.lastName || ''}
                                    placeholder='Doe'
                                    onChange={handleChange}
                                />
                            </Label>
                        )}
                        {hasNoBirthday && (
                            <Label
                                error={error.birthday}
                                style={{fontWeight: 'normal'}}
                                onClick={e => e.preventDefault()}>
                                Date of Birth
                                <StyledDatePicker
                                    error={error.birthday}
                                    selected={new Date(kitInfo.birthday)}
                                    minDate={new Date('1/1/1870')}
                                    maxDate={new Date()}
                                    placeholderText='MM/DD/YYYY'
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dropdownMode='select'
                                    onChange={date => {
                                        dispatch(setKitParam({birthday: date}));
                                        setError({...error, birthday: false});
                                    }}
                                />
                            </Label>
                        )}
                        {hasNoAddress && (
                            <Label error={error.address1} style={{fontWeight: 'normal'}}>
                                Street Address
                                <FormInput
                                    error={error.address1}
                                    placeholder='123 Apple Street'
                                    type='text'
                                    name='address1'
                                    value={kitInfo?.address1 || ''}
                                    onChange={handleChange}
                                />
                            </Label>
                        )}
                        {hasNoCity && (
                            <Label error={error.city} style={{fontWeight: 'normal'}}>
                                City
                                <FormInput
                                    error={error.city}
                                    placeholder='Dallas'
                                    type='text'
                                    name='city'
                                    value={kitInfo?.city || ''}
                                    onChange={handleChange}
                                />
                            </Label>
                        )}
                        {hasNoState && (
                            <Label error={error.state} style={{fontWeight: 'normal'}}>
                                State
                                <Select
                                    error={error.state}
                                    name='state'
                                    value={kitInfo?.state || ''}
                                    onChange={handleChange}>
                                    <option disabled value=''>
                                        --
                                    </option>
                                    {States.map((usstate, ids) => (
                                        <option key={ids} value={usstate.code}>
                                            {usstate.name}
                                        </option>
                                    ))}
                                </Select>
                            </Label>
                        )}
                        {hasNoZip && (
                            <Label error={error.zip} style={{fontWeight: 'normal'}}>
                                ZIP Code
                                <FormInput
                                    error={error.zip}
                                    placeholder='10001'
                                    type='text'
                                    name='zip'
                                    value={kitInfo?.zip || ''}
                                    onChange={handleChange}
                                />
                            </Label>
                        )}
                        {hasNoPhone && (
                            <Label error={error.phone} style={{fontWeight: 'normal'}}>
                                Phone
                                <FormInput
                                    error={error.phone}
                                    placeholder='555-555-5555'
                                    type='tel'
                                    name='phone'
                                    value={kitInfo?.phone || ''}
                                    onChange={handleChange}
                                />
                            </Label>
                        )}
                        {hasNoGender && (
                            <SurveyQuestion error={error.gender}>
                                Biological Sex
                                <SymptomInputContainer>
                                    <RadioInput
                                        error={error.gender}
                                        id='male'
                                        type='radio'
                                        name='gender'
                                        value='M'
                                        checked={kitInfo.gender === 'M'}
                                        onChange={handleChange}
                                    />
                                    <SymptomOptionLabel htmlFor='male'>Male</SymptomOptionLabel>
                                    <RadioInput
                                        error={error.contact}
                                        id='female'
                                        type='radio'
                                        name='gender'
                                        value='F'
                                        checked={kitInfo.gender === 'F'}
                                        onChange={handleChange}
                                    />
                                    <SymptomOptionLabel htmlFor='female'>Female</SymptomOptionLabel>
                                </SymptomInputContainer>
                            </SurveyQuestion>
                        )}
                        {hasNoEthnicity && (
                            <Label error={error.ethnicity} className='Heading05Regular' style={{fontWeight: 'normal'}}>
                                Ethnicity
                                <Select
                                    error={error.ethnicity}
                                    name='ethnicity'
                                    value={kitInfo.ethnicity || ''}
                                    onChange={handleChange}
                                    style={{fontSize: '11px'}}>
                                    <option disabled value=''>
                                        --
                                    </option>
                                    {Ethnicities.map((ethnicity, ide) => (
                                        <option key={ide} value={ethnicity}>
                                            {ethnicity}
                                        </option>
                                    ))}
                                </Select>
                            </Label>
                        )}
                    </>
                )}
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    {submitting && <Spinner left='30px' />}
                    <PrimaryButton value='Continue' width='100%' radius='24px' />
                </SubmitContainer>
                <BackButton
                    className='ParagraphNormalRegular'
                    onClick={() => {
                        setError(initialError);
                        if (kitInfo.company === null) {
                            if (
                                covidIds[
                                    kitInfo.kitId1.length === 15
                                        ? kitInfo.kitId1.substring(3, 5)
                                        : kitInfo.kitId1.substring(0, 2)
                                ]
                            ) {
                                props.goToNamedStep && props.goToNamedStep('step1');
                            } else {
                                props.goToNamedStep && props.goToNamedStep('step0');
                            }
                        } else {
                            props.goToNamedStep && props.goToNamedStep('step2');
                        }
                    }}>
                    Go back
                </BackButton>
            </FormSection>
        </Form>
    );
};
