import React from 'react';
import {FieldContainer, FieldDetails, ChevronPhoto} from './styles/SettingsField';

interface FieldProps {
    isActive?: boolean; //whether the div is shaded
    title?: string; //the bold text above the value
    value?: string; //Label paragraph text
    onClick?: Function; //function(s) the chevron uses, also determines if the value should be changeable
    padding?: string;
}

const SettingsField: React.FC<FieldProps> = (props) => {
    const {isActive, title, value, onClick, padding} = props;

    let canHover = true; //whether hovering shades the div
    if (onClick === undefined) {
        //if theres no function, the div shouldnt be hoverable
        canHover = false;
    }

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <FieldContainer isActive={isActive} onClick={handleClick} canHover={canHover} padding={padding}>
            <FieldDetails>
                {title && <p className="ParagraphSmallBold">{title}</p>}
                {value && <p className="ParagraphNormalRegular">{value}</p>}
            </FieldDetails>
            {onClick !== undefined && <ChevronPhoto className="fa-light fa-chevron-right fa-fw" />}
        </FieldContainer>
    );
};

export default SettingsField;
