import DatePicker from 'react-datepicker';
import styled, {css} from 'styled-components';
import CreateAccountBackground from '../../assets/images/Create Account Background.jpeg';
import {Device} from '../../services/constants';
import {Link} from 'react-router-dom';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% + 16px);
    min-height: 100vh;
    background-image: url('${CreateAccountBackground}');
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
`;
export const FormContainer = styled.div`
    width: calc(100% - 64px);
    max-width: 293px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // gap: 32px;
    margin: 0 16px 16px 16px;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--text-0);

    @media ${Device.mobile} {
        width: 100%;
        max-width: 100%;
        height: calc(100vh + 100px);
        margin: 0;
        padding: 8px 0 0 0;
        border-radius: 0;
    }
`;
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 20px auto 0 auto;
`;
export const FormBody = styled.div`
    padding: '0';

    @media ${Device.mobile} {
        padding: 0 16px 16px 16px;
    }
`;
export const FormFooter = styled.div`
    width: 100%;
    height: auto;
    margin-top: auto;
    padding: 0;
    background-color: '';

    @media ${Device.mobile} {
        width: calc(100% - 32px);
        height: 47px;
        padding: 24.5px 16px;
        background-color: var(--neutral-800);
    }
`;
export const Header = styled.p`
    height: 40px;
    padding: 0;
    text-align: left;
    align-self: flex-start;
    font-size: min(1.44rem, 10.5vw);

    @media ${Device.mobile} {
        padding: 16px 16px 0 16px;
    }
`;
export const Label = styled.label<{error: boolean}>`
    font-size: 1.2rem;
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 16px;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const ZipLabel = styled(Label)`
    width: 100%;
    max-width: 180px;
    min-width: 0;

    @media ${Device.mobile} {
        max-width: 100%;
    }
`;
export const LabelText = styled.p`
    padding-left: 12px;
`;
export const PassportText = styled(LabelText)`
    font-size: 1.1rem;
`;
export const FormInput = styled.input<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;
    flex-basis: auto;
    padding-left: 40px;

    :hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const StyledDatePicker = styled(DatePicker)<{error: boolean | undefined}>`
    width: calc(100% - 56px);
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    padding-left: 12px;
    align-items: center;
`;
export const RadioOption = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;
export const RadioInput = styled(FormInput)`
    width: 20px;
    margin: 0;
`;
export const RadioLabel = styled.label``;
export const Select = styled.select<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;

    :hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const StateZipContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
`;
export const Submit = styled.input`
    width: 100%;
    height: 47px;
    border-radius: 8px;
    background-color: var(--primary-300);
    color: var(--text-0);
    font-size: 1.2rem;
    padding: 0 !important;
    border-width: 0;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--primary-700);
    }

    &:active {
        background-color: var(--primary-50);
    }
`;
export const Caption = styled.div`
    letter-spacing: 1px;
    margin: 20px auto;
`;
export const LeftIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 52px;
    color: var(--neutral-300);
    z-index: 1;
`;
// TODO Passport icon gets pushed down another line at the current width. If we fix width this needs to be adjusted
export const PassportIcon = styled(LeftIcon)`
    //top: 70px;
`;
export const Info = styled.div`
    margin-bottom: 20px;
    font-size: 0.9rem;
    margin-top: 16px;
    color: var(--text-300);
`;
export const Optional = styled.div`
    padding-left: 12px;
    font-size: 0.8rem;
`;
export const ConfirmIcon = styled.i`
    margin: 20px 0;
`;
export const SubmitContainer = styled.div`
    position: relative;
`;
export const LoginLink = styled(Link)`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;
