import styled from 'styled-components';
import {Device} from '../../services/constants';
import {Link} from 'react-router-dom';

export const ItemContainer = styled.div<{isGrouped: number; isActive: number}>`
    max-height: ${(props) => (props.isActive ? '1000px' : '60px')};
    overflow: hidden;
    width: ${(props) => (props.isGrouped ? '100%' : 'calc(100% - 32px)')};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: ${(props) => (props.isGrouped ? '' : '16px')};
    border-radius: 8px;
    box-shadow: ${(props) => (props.isGrouped ? '' : '0 1px 4px 0 rgba(0, 0, 0, 0.15)')};
    background-color: var(--neutral-0);
    transition: 0.2s;
`;
export const ItemHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
`;
export const ItemHeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
export const ItemHeaderName = styled.div`
    display: flex;
    gap: 8px;
`;
export const TestName = styled.p<{isActive: number}>`
    font-weight: ${(props) => (props.isActive ? '600' : '400')};
    letter-spacing: normal;
`;
export const PinnedImage = styled.i`
    font-size: 20px;
    color: var(--text-400);
`;
export const ItemHeaderUnits = styled.p`
    color: var(--text-400);
    letter-spacing: normal;
`;
export const ItemHeaderStatus = styled.div`
    height: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const ItemNormality = styled.p<{normality: string}>`
    color: ${(props) => {
        return props.normality === 'Low' ? '#fbcc62' : props.normality === 'High' ? '#f18385' : '#6ce559';
    }};
`;
export const ExpandButton = styled.button`
    width: 16px;
    height: 16px;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
        cursor: pointer;
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;
export const ExpandButtonImage = styled.i`
    font-size: 16px;
    color: var(--text-600);
`;
export const ExpandedContainer = styled.div<{isActive: number}>`
    width: 100%;
    max-height: ${(props) => (props.isActive ? '940px' : '0')};
    display: flex;
    flex-direction: column;
    gap: 16px;
    opacity: ${(props) => (props.isActive ? '100%' : '0')};
    transition: max-height 0.2s, opacity 0.2s 0.2s;
`;
export const HealthHistoryContainer = styled.div`
    padding: 16px 16px 40px 16px;
    height: 200px;
`;
export const MiddleData = styled.div`
    width: calc(100% - 32px);
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;

    @media ${Device.mobile} {
        padding: 8px;
    }
`;
export const MiddleDataFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
export const MiddleDataField = styled.p`
    letter-spacing: normal;
`;
export const ReportLink = styled(Link)`
    width: 78px;
    height: 16px;
    padding: 5px 24px 6px 22px;
    border-radius: 4px;
    color: var(--text-600);
    background-color: #ebebeb;
    text-decoration: none;
    font-size: 0.833rem;
    line-height: 1.2;
    letter-spacing: 0.5px;
    white-space: nowrap;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
        cursor: pointer;
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;
export const FinalMiddleDataField = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;
export const PinToggle = styled.label`
    height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        cursor: pointer;
    }
`;
export const PinCheckbox = styled.input`
    min-width: 24px;
    min-height: 24px;
    margin: 0;

    &:hover {
        cursor: pointer;
    }
`;
