import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const ResultContainer = styled.div`
    max-width: 676px;
    width: calc(100% - 32px);
    height: 290px;
    margin: 0 8px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--neutral-0);

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 24px;
    }
`;
export const ResultAccent = styled.div`
    width: 8px;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px;
    background-color: var(--secondary-500);
`;

export const ResultContent = styled.div`
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 32px;
    height: 240px;
    padding: 16px;
`;

export const ResultHeader = styled.div`
    height: 40px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;

export const CornerPhoto = styled.div`
    width: 26.4px;
    height: 26.4px;
    padding: 6.7px;
    border-radius: 50%;
    background-color: var(--secondary-500);
`;
export const CornerIcon = styled.i`
    font-size: 21px;
    font-weight: 100;
    line-height: 26.4px;
    color: white;
`;

export const ResultBody = styled.div`
    height: 104px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;

export const ResultItem = styled.div`
    height: 24px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 0;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-top: 16px;
        margin-bottom: 16px;
    }
`;

export const Icon = styled.i`
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #00ba8d;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-right: 16px;
    }
`;

export const Button = styled(Link)`
    height: 32px;
    width: 232px;
    margin: 0 auto;
    font-size: 0.833rem;
    font-weight: normal;
`;
