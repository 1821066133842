import React from 'react';
import {useState} from 'react';
import {useAppSelector} from '../hooks';
import {selectCurrentPatient} from '../slices/patientSlice';
import {selectTests, selectTestsByName} from '../slices/testSlice';
import {useAuth} from '../hooks/useAuth';
import {useNavigate} from 'react-router-dom';
import RegisterKit from '../containers/RegisterKit2';
import KitHistoryItem from '../components/KitHistoryItem';
import Skeleton from '../components/Skeleton';
import EmptyKitHistoryItem from '../components/EmptyKitHistoryItem';
import QuickDraw from '../assets/mld-quickdraw.svg';
import UrineCollect from '../assets/mld-blood-collection.svg';
import FingerPrick from '../assets/mld-finger.svg';
import {
    PageContainer,
    PageHeader,
    PageSubHeader,
    CardContainer,
    SelectionCard,
    CardIcon,
    CardText,
    BackButton,
    BackIcon,
    HeaderContainer,
    InfoCard,
    LargeCardIcon,
    KitListContainer,
} from './styles/TestKits';

interface Props {
    menu?: string;
}

const TestKits: React.FC<Props> = ({menu}) => {
    const [currentMenu, setCurrentMenu] = useState(menu ? menu : 'main');
    const {loading} = useAppSelector(selectTests);
    const {user} = useAuth();
    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector((state) => selectTestsByName(state, currentPatient?.fullName));
    const navigate = useNavigate();

    return (
        <PageContainer>
            {loading && (
                <>
                    <Skeleton variant={2} />
                    <Skeleton variant={2} />
                </>
            )}
            {!loading && (
                <>
                    {currentMenu === 'main' && (
                        <>
                            <PageHeader className="Heading03Bold">How Can We Help?</PageHeader>
                            <PageSubHeader className="Heading06Regular">Select an option to get started</PageSubHeader>
                            <CardContainer>
                                {/* this redirects to allow the key hashing */}
                                <SelectionCard onClick={() => navigate('/test-kits/activate')}>
                                    <CardIcon className="fa-light fa-box-open fa-fw" />
                                    <CardText>Activate a kit</CardText>
                                </SelectionCard>
                                <SelectionCard onClick={() => setCurrentMenu('return')}>
                                    <CardIcon className="fa-light fa-truck fa-fw" />
                                    <CardText>Return your kit</CardText>
                                </SelectionCard>
                                <SelectionCard onClick={() => setCurrentMenu('instructions')}>
                                    <CardIcon className="fa-light fa-file-lines fa-fw" />
                                    <CardText>Kit instructions</CardText>
                                </SelectionCard>
                                <SelectionCard onClick={() => setCurrentMenu('history')}>
                                    <CardIcon className="fa-light fa-clock-rotate-left fa-fw" />
                                    <CardText>Kit activation history</CardText>
                                </SelectionCard>
                                <SelectionCard onClick={() => setCurrentMenu('support')}>
                                    <CardIcon className="fa-light fa-phone fa-fw" />
                                    <CardText>Support</CardText>
                                </SelectionCard>
                            </CardContainer>
                        </>
                    )}
                    {currentMenu === 'activate' && (
                        <>
                            <HeaderContainer>
                                <BackButton
                                    onClick={() => {
                                        navigate('/test-kits');
                                    }}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Activate a kit</PageHeader>
                            </HeaderContainer>
                            <RegisterKit />
                        </>
                    )}
                    {currentMenu === 'return' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('main')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Return your kit</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">
                                How would you like to return your kit to us?
                            </PageSubHeader>
                            <CardContainer>
                                <SelectionCard onClick={() => setCurrentMenu('ship')}>
                                    <LargeCardIcon className="fa-light fa-truck" />
                                    <CardText className="ParagraphLargeRegular">Ship it</CardText>
                                    <CardText>Available nationwide</CardText>
                                </SelectionCard>
                                <SelectionCard onClick={() => setCurrentMenu('dropoff')}>
                                    <LargeCardIcon className="fa-light fa-hospital-user" />
                                    <CardText className="ParagraphLargeRegular">Local drop off</CardText>
                                    <CardText>Available in Dallas-Fort Worth</CardText>
                                </SelectionCard>
                                <SelectionCard onClick={() => setCurrentMenu('pickup')}>
                                    <LargeCardIcon className="fa-light fa-hand-holding-box" />
                                    <CardText className="ParagraphLargeRegular">Local pick up</CardText>
                                    <CardText>Available in Dallas-Fort Worth</CardText>
                                </SelectionCard>
                            </CardContainer>
                        </>
                    )}
                    {currentMenu === 'instructions' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('main')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Kit Instructions</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">Collection Method Instructions</PageSubHeader>
                            <CardContainer>
                                <SelectionCard
                                    onClick={() => {
                                        window.open(
                                            'https://mldmedia.blob.core.windows.net/public/Quickdraw-instructions-2022.pdf'
                                        );
                                    }}>
                                    {/* make this webfont? can load slowly currently*/}
                                    <img src={QuickDraw} alt="" />
                                    <CardText className="ParagraphLargeRegular">Quick Draw</CardText>
                                </SelectionCard>
                                <SelectionCard
                                    onClick={() => {
                                        window.open(
                                            'https://mldmedia.blob.core.windows.net/public/Final Finger Prick Instructions.pdf'
                                        );
                                    }}>
                                    {/* make this webfont? can load slowly currently*/}
                                    <img src={FingerPrick} alt="" />
                                    <CardText className="ParagraphLargeRegular">Finger Prick</CardText>
                                </SelectionCard>
                                <SelectionCard
                                    onClick={() => {
                                        window.open(
                                            'https://mldmedia.blob.core.windows.net/public/mld-urine-instructions-2022.pdf'
                                        );
                                    }}>
                                    {/* make this webfont? can load slowly currently*/}
                                    <img src={UrineCollect} alt="" />
                                    <CardText className="ParagraphLargeRegular">Urine Sample</CardText>
                                </SelectionCard>
                            </CardContainer>
                            <PageSubHeader className="Heading06Regular">Your recently activated kits</PageSubHeader>
                            {currentPatientData.length === 0 && <EmptyKitHistoryItem />}
                            {currentPatientData.length > 0 && (
                                <KitListContainer>
                                    {currentPatientData?.map((d: any) => {
                                        if (d.status?.toLowerCase() === 'created')
                                            if (d.kit_id !== null)
                                                return <KitHistoryItem key={d.id} kit={d} hasStatus={false} />;
                                    })}
                                </KitListContainer>
                            )}
                        </>
                    )}
                    {currentMenu === 'history' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('main')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Kit Activation History</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">
                                Your activated and completed kits
                            </PageSubHeader>
                            {currentPatientData.length === 0 && <EmptyKitHistoryItem />}
                            {currentPatientData.length > 0 && (
                                <KitListContainer>
                                    {currentPatientData?.map((d: any) => {
                                        if (d.kit_id !== null)
                                            return <KitHistoryItem key={d.id} kit={d} hasStatus={true} />;
                                    })}
                                </KitListContainer>
                            )}
                        </>
                    )}
                    {currentMenu === 'support' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('main')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Support</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">
                                Have questions about your kit? Reach out to us!
                            </PageSubHeader>
                            <CardContainer>
                                <InfoCard>
                                    <LargeCardIcon className="fa-light fa-phone" />
                                    <CardText className="ParagraphLargeRegular">Call or text</CardText>
                                    <CardText>(877) 355 - 7978</CardText>
                                </InfoCard>
                                <InfoCard>
                                    <LargeCardIcon className="fa-light fa-envelope-dot" />
                                    <CardText className="ParagraphLargeRegular">Email</CardText>
                                    <CardText>info@mylabsdirect.com</CardText>
                                </InfoCard>
                            </CardContainer>
                        </>
                    )}
                    {currentMenu === 'ship' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('return')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Return your kit</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">
                                Use the shipping label included in your test kit to ship the sample back to us!
                            </PageSubHeader>
                        </>
                    )}
                    {currentMenu === 'dropoff' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('return')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Return your kit</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">
                                Out and about? Drop your sample off at one of our convenient locations and we'll handle
                                things from there. Currently available in DFW only.
                            </PageSubHeader>
                            <CardContainer>
                                <InfoCard>
                                    <PageSubHeader className="Heading06Regular">Plano, TX</PageSubHeader>
                                    <CardText>610 Coit Rd, Ste 200, Plano, TX 75075</CardText>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1672.9185986601044!2d-96.7663925543167!3d33.00806741218437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c27d88f893443%3A0x12527c25944776dd!2sMy%20Labs%20Direct%20-%20Plano!5e0!3m2!1sen!2sus!4v1660769366452!5m2!1sen!2sus"
                                        width="100%"
                                        height="400"
                                        title="plano"
                                        style={{border: '0'}}
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    <PageSubHeader className="Heading06Regular">Available anytime</PageSubHeader>
                                </InfoCard>
                                <InfoCard>
                                    <PageSubHeader className="Heading06Regular">Farmers Branch, TX</PageSubHeader>
                                    <CardText>4099 McEwen Rd #160 Farmers Branch, TX 75244</CardText>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.7727895819216!2d-96.84450078450008!3d32.93060078344497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c272ecfa9830b%3A0x9fc547f3f3359d0f!2sMyLabsDirect%20-%20Farmers%20Branch!5e0!3m2!1sen!2sus!4v1660769535977!5m2!1sen!2sus"
                                        width="100%"
                                        height="400"
                                        style={{border: '0'}}
                                        title="farmersbranch"
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    <PageSubHeader className="Heading06Regular">Monday-Friday 9-5pm CST</PageSubHeader>
                                </InfoCard>
                            </CardContainer>
                        </>
                    )}
                    {currentMenu === 'pickup' && (
                        <>
                            <HeaderContainer>
                                <BackButton onClick={() => setCurrentMenu('return')}>
                                    <BackIcon className="fa-light fa-circle-arrow-left" />
                                </BackButton>
                                <PageHeader className="Heading03Bold">Return your kit</PageHeader>
                            </HeaderContainer>
                            <PageSubHeader className="Heading06Regular">
                                Want us to pick your sample up? Currently available in DFW only.
                            </PageSubHeader>
                            <CardContainer>
                                <InfoCard>
                                    <iframe
                                        src={`https://mylabsdirect.as.me/schedule.php?appointmentType=34976104&firstName=${
                                            currentPatient.firstName || user.userInfo.firstName
                                        }&lastName=${currentPatient.lastName || user.userInfo.lastName}&phone=${
                                            user.userInfo.phone
                                        }&email=${user.userInfo.email}&field:10422557=${
                                            currentPatient.birthdate
                                                ? new Date(currentPatient.birthdate).toLocaleDateString()
                                                : new Date(user.userInfo.birthday).toLocaleDateString()
                                        }`}
                                        title="pickupappt"
                                        style={{
                                            width: '100%',
                                            overflow: 'visible',
                                            border: 'none',
                                            height: '600px',
                                        }}
                                    />
                                </InfoCard>
                            </CardContainer>
                        </>
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default TestKits;
