import styled from 'styled-components';

export const CardContainer = styled.div<{isActive: boolean}>`
    max-height: ${(props) => (props.isActive ? 'none' : '92px')};
    overflow: hidden;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    transition: 0.2s;
    padding: 16px;
`;

export const TopContainer = styled.div`
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &:hover {
        cursor: pointer;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
`;

export const BlackIcon = styled.i`
    font-size: 22px;
    color: black;
`;

export const TestNameText = styled.p``;
TestNameText.defaultProps = {
    className: 'ParagraphNormalRegular',
};

export const StatusIcon = styled.i<{color: string}>`
    font-size: 20px;
    color: ${(props) => (props.color ? props.color : 'black')};
`;

export const StatusText = styled.p<{color: string}>`
    color: ${(props) => (props.color ? props.color : 'black')};
`;
StatusText.defaultProps = {
    className: 'ParagraphSmallRegular',
};

export const ExpandButton = styled.button`
    width: 16px;
    height: 16px;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
        cursor: pointer;
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;

export const ExpandButtonImage = styled.i`
    font-size: 16px;
    color: var(--text-600);
`;

export const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    background-color: var(--neutral-50);
`;

export const SeparatorLine = styled.div`
    height: 1px;
    background-color: var(--neutral-100);
`;

export const TitleText = styled.p``;
TitleText.defaultProps = {
    className: 'CaptionRegular',
};

export const InstructionsButton = styled.button`
    padding: 8px 24px;
    background-color: var(--secondary-500);
    border: none;
    border-radius: 24px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: min-content;

    transition: 0.2s;

    &:hover {
        background-color: #29c9a1;
        cursor: pointer;
    }
`;

export const InstructionsButtonIcon = styled.i`
    font-size: 24px;
    color: white;
`;
