import styled from 'styled-components';
import Logo from '../../assets/MLD Logo@2x.png';
import {Device} from '../../services/constants';
import {Link} from 'react-router-dom';

export const Bar = styled.div`
    width: 100%;
    object-fit: contain;
    background: none;
    height: 67px;
    display: flex;
    justify-content: center;
    padding: 32px 0;
    transition: 0.2s;

    @media ${Device.mobile} {
        height: 0;
        padding: 0;
    }
`;
export const BackButtonBar = styled.div`
    width: 100%;
    background: rgba(34, 34, 34, 0.5);
    height: 67px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    @media ${Device.mobile} {
        background: var(--neutral-800);
        margin-bottom: 0;
    }
`;
export const MLDLogo = styled.img`
    width: 100%;
    object-fit: contain;
`;
MLDLogo.defaultProps = {
    //sets the logo by default if nothing is passed in
    src: Logo,
};
export const LogoLink = styled(Link)`
    width: calc(100% - 32px);
    max-width: 300px;

    @media ${Device.mobile} {
        display: none;
    }
`;
export const BackButton = styled.button`
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    margin: 16px;
    padding: 0;
    border: none;
    background: none;
    color: var(--text-0);
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        color: var(--neutral-200);
    }

    &:active {
        color: var(--neutral-100);
    }
`;
export const BackIcon = styled.i`
    font-size: 32px;
`;
