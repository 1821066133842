import React, {useState, ChangeEvent} from 'react';
import {useLocation} from 'react-router-dom';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import settings from '../../settings';
import {useAppSelector} from '../../hooks';
import {Form, FormSection, Error, AcuityFormSection, BackButton, Header} from './styles/Step5';

export const Step5: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector((state) => state.kit);

    const {user, token} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const idParam = query.get('id');
    const [state, setState] = useState<{[x: string]: any}>({
        kitId1: '',
        kitId2: '',
        promoCode1: '',
        promoCode2: '',
        company: null,
        contact: '',
        symptoms: '',
        vaccinated: '',
        firstName: '',
        lastName: '',
        birthday: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        gender: '',
        ethnicity: '',
        location: '',
    });

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: '',
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const states: any = {
        AL: 'Alabama',
        AK: 'Alaska',
        AX: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        if (submitting) {
            return;
        } else {
            setSubmitting(true);

            const req = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: state.email}),
            };

            const res = await fetch(`${settings.apiURL}/api/appointments/recent`, req);
            setSubmitting(false);

            if (!res.ok) {
                const message = `You have not yet finished scheduling your appointment. To finalize your appointment you must scroll down and click "Complete Appointment" after you choose a location and time.`;
                setError((prevError) => ({...prevError, message: message}));
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                return;
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <AcuityFormSection position="top">
                <Header className="Heading05Bold">Schedule Appointment</Header>
            </AcuityFormSection>
            <Error className="ParagraphSmallRegular" hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                <iframe
                    src={`https://mylabsdirect.as.me/schedule.php?firstName=${
                        kitInfo.firstName || user.userInfo.firstName
                    }&lastName=${kitInfo.lastName || user.userInfo.lastName}&phone=${
                        kitInfo.phone || user.userInfo.phone
                    }&email=${kitInfo.email || user.userInfo.email}&field:10422557=${
                        kitInfo.birthday
                            ? new Date(kitInfo.birthday).toLocaleDateString()
                            : new Date(user.userInfo.birthday).toLocaleDateString()
                    }&field:10422558=${
                        kitInfo.gender
                            ? kitInfo.gender === 'M'
                                ? 'Male'
                                : 'Female'
                            : user.userInfo.gender && user.userInfo.gender?.[0].toUpperCase() === 'M'
                            ? 'Male'
                            : 'Female'
                    }&field:10468942=${
                        kitInfo.ethnicity ||
                        `${user.userInfo.ethnicity?.[0].toUpperCase()}${user.userInfo.ethnicity
                            ?.substring(1)
                            .toLowerCase()}`
                    }&field:11272312=${idParam ? idParam : kitInfo.kitId1}&field:9864758=${
                        kitInfo.address1
                            ? kitInfo.address2
                                ? kitInfo.address1 + `, ${kitInfo.address2}`
                                : kitInfo.address1
                            : user.userInfo.address2
                            ? user.userInfo.address1 + `, ${user.userInfo.address2}`
                            : user.userInfo.address1
                    }&field:9864759=${kitInfo.city || user.userInfo.city}&field:9864760=${
                        states[kitInfo.state]
                            ? states[kitInfo.state]
                            : states[user.userInfo.state]
                            ? states[user.userInfo.state]
                            : user.userInfo.state
                            ? user.userInfo.state
                            : ''
                    }&field:9864761=${state.zip || user.userInfo.zip}&appointmentType=${
                        kitInfo.location === 'mld' ? '31138680' : kitInfo.location === 'mobile' ? '31139910' : ''
                    }`}
                    width="100%"
                    height="650"
                    title="acuity"
                />
            </FormSection>
            <AcuityFormSection position="bottom">
                {/* <SubmitContainer>
                                {submitting && <SubmitSpinner src={SpinnerIcon} alt="" />}
                                <PrimaryButton value="Submit" width="100%" />
                            </SubmitContainer> */}
                <BackButton
                    className="ParagraphNormalRegular"
                    style={{backgroundColor: 'var(--neutral-50)'}}
                    onClick={props.previousStep}>
                    Go back
                </BackButton>
            </AcuityFormSection>
        </Form>
    );
};
