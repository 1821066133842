import React, {ChangeEvent, FormEvent, useState} from 'react';
import {useLocation, Link} from 'react-router-dom';
import TopBar from '../components/TopBar';
import PrimaryButton from '../components/PrimaryButton';
import Error from '../components/Error';
import Spinner from '../components/Spinner';
import ReactTooltip from 'react-tooltip';
import settings from '../settings';
import 'react-datepicker/dist/react-datepicker.css';
import {Countries, States, Ethnicities} from '../services/constants';
import axios from 'axios';
import {
    PageContainer,
    FormContainer,
    Form,
    FormBody,
    FormInput,
    TopForm,
    TopLabel,
    Label,
    LabelText,
    RadioLabel,
    Header,
    LeftIcon,
    StyledDatePicker,
    RadioContainer,
    RadioInput,
    RadioOption,
    Select,
    Info,
    Optional,
    PassportText,
    Footer,
    Caption,
    FooterLink,
    SubmitContainer,
    Resend,
    Notification,
} from './styles/Register';

const Register: React.FC = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref');
    const [state, setState] = useState<{[index: string]: any}>({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        birthday: '',
        gender: '',
        ethnicity: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States',
        license: '',
        ref: ref || '',
    });
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [sent, setSent] = useState(false);
    // const [created, setCreated] = useState(false);
    const [resent, setResent] = useState(false);
    const [creating, setCreating] = useState(false);
    const [resentNotification, setResentNotification] = useState('Sending...');
    const [step, setStep] = useState(0);
    const [error, setError] = useState<{[index: string]: boolean}>({});
    const [errorMsg, setErrorMsg] = useState('');
    // const [show, setShow] =  useState('password');

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    // const togglePassword = () => {
    //     setShow(show === 'password' ? 'text' : 'password');
    // }

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // event.preventDefault();
        // const { name, value } = event.target;
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }
        }

        if (name === 'zip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        if (name === 'password') {
            const passwordUppercaseReg = /[A-Z]/;
            const passwordLowercaseReg = /[a-z]/;
            const passwordNumberReg = /[\d]/;

            setError({
                ...error,
                passwordLetters: !passwordUppercaseReg.test(value) || !passwordLowercaseReg.test(value),
                passwordNumber: !passwordNumberReg.test(value),
                passwordLength: value.length <= 8,
            });
        }

        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setHasSubmitted(true);
        setError({});

        const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
        const emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // const passwordUppercaseReg = /[A-Z]/;
        // const passwordLowercaseReg = /[a-z]/;
        // const passwordNumberReg = /[\d]/;

        const error: {[key: string]: any} = {
            firstName: step === 0 && state.firstName === '',
            lastName: step === 0 && state.lastName === '',
            phone: step === 0 && !phoneReg.test(state.phone),
            email: step === 0 && !emailReg.test(state.email),
            birthday: step === 1 && state.birthday === '',
            gender: step === 1 && state.gender === '',
            ethnicity: step === 1 && state.ethnicity === '',
            address1: step === 2 && state.address1 === '',
            city: step === 2 && state.city === '',
            state: step === 2 && state.state === '',
            zip: step === 2 && state.zip === '',
            country: step === 2 && state.country === '',
            // passwordLetters: !passwordUppercaseReg.test(state.password) || !passwordLowercaseReg.test(state.password),
            // passwordNumber: !passwordNumberReg.test(state.password),
            // passwordLength: state.password.length <= 8
        };

        setError(error);

        for (const key in error) {
            if (error[key]) {
                setErrorMsg('Please fill out all required fields');
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                return;
            }
        }

        if (step === 3) {
            // setCreated(true);
            setCreating(true);

            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: JSON.stringify(state),
            };

            const res = await axios(`${settings.apiURL}/api/register`, requestOptions);
            setCreating(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });

            if (res.status !== 200) {
                if (res.status === 409) {
                    setError({accountExists: true});
                    setErrorMsg('An account with that email already exists. Please reset your password.');
                    return;
                }
                console.error('Error communicating with the server');
                return;
            }

            setSent(true);
        } else {
            nextStep();
        }
    };

    const resend = async () => {
        setResent(true);
        setResentNotification('Resending...');

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify(state),
        };

        const res = await axios(`${settings.apiURL}/api/resend`, requestOptions);
        if (res.status !== 200) {
            setResentNotification('Could not resend email.');
            console.error('Error communicating with the server');
            return;
        }

        setResentNotification('Email successfully resent!');
    };

    return (
        <PageContainer sent={sent}>
            <TopBar
                location={ref ? `/login?ref=${ref}` : '/login'}
                isBackBar={true}
                buttonvisible={step > 0 && !sent ? true : false}
                onClick={() => {
                    setError({});
                    prevStep();
                }}
            />
            <FormContainer>
                {!sent && (
                    <Form onSubmit={handleSubmit}>
                        <FormBody>
                            {!sent && <Header className="Heading05Regular">Create your account</Header>}
                            <Error message={errorMsg} />
                            {step === 0 && (
                                <>
                                    <TopForm>
                                        <TopLabel error={error.firstName}>
                                            <LabelText>First Name</LabelText>
                                            <FormInput
                                                error={error.firstName}
                                                placeholder="John"
                                                type="text"
                                                name="firstName"
                                                value={state?.firstName || ''}
                                                onChange={handleChange}
                                            />
                                        </TopLabel>
                                        <TopLabel error={error.lastName}>
                                            <LabelText>Last Name</LabelText>
                                            <FormInput
                                                error={error.lastName}
                                                placeholder="Doe"
                                                type="text"
                                                name="lastName"
                                                value={state?.lastName || ''}
                                                onChange={handleChange}
                                            />
                                        </TopLabel>
                                    </TopForm>
                                    <Label error={error.phone}>
                                        <LabelText>Phone</LabelText>
                                        <LeftIcon className="fa-solid fa-phone-flip" />
                                        <FormInput
                                            error={error.phone}
                                            placeholder="555-555-5555"
                                            type="tel"
                                            name="phone"
                                            value={state?.phone || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <Label error={error.email}>
                                        <LabelText>Email</LabelText>
                                        <LeftIcon className="fa-solid fa-envelope" />
                                        <FormInput
                                            error={error.email}
                                            placeholder="example@example.com"
                                            type="email"
                                            name="email"
                                            value={state?.email || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                </>
                            )}

                            {step === 1 && (
                                <>
                                    <ReactTooltip />
                                    <Label error={error.birthday} onClick={(e) => e.preventDefault()}>
                                        <LabelText>Date of Birth</LabelText>
                                        <LeftIcon className="fa-solid fa-calendar-days" />
                                        {/* <FormInput error={error.birthday} placeholder="MM/DD/YYYY" type="date" name="birthday" value={state?.birthday || ''} onChange={handleChange} /> */}
                                        <StyledDatePicker
                                            error={error.birthday}
                                            selected={state.birthday}
                                            minDate={new Date('1/1/1870')}
                                            maxDate={new Date()}
                                            placeholderText="MM/DD/YYYY"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode="select"
                                            onChange={(date) => {
                                                setState({...state, birthday: date});
                                                setError({...error, birthday: false});
                                            }}
                                        />
                                    </Label>
                                    <Label error={error.gender}>
                                        <LabelText>Biological Sex</LabelText>
                                        <RadioContainer>
                                            <RadioOption>
                                                <RadioInput
                                                    error={error.gender}
                                                    id="male"
                                                    type="radio"
                                                    name="gender"
                                                    value="Male"
                                                    onChange={handleChange}
                                                    checked={state.gender === 'Male'}
                                                />
                                                <RadioLabel htmlFor="male">Male</RadioLabel>
                                            </RadioOption>
                                            <RadioOption>
                                                <RadioInput
                                                    error={error.gender}
                                                    id="female"
                                                    type="radio"
                                                    name="gender"
                                                    value="Female"
                                                    onChange={handleChange}
                                                    checked={state.gender === 'Female'}
                                                />
                                                <RadioLabel htmlFor="female">Female</RadioLabel>
                                            </RadioOption>
                                        </RadioContainer>
                                    </Label>
                                    <Label error={error.ethnicity}>
                                        <LabelText>Ethnicity</LabelText>
                                        <Select
                                            error={error.ethnicity}
                                            name="ethnicity"
                                            value={state?.ethnicity || ''}
                                            onChange={handleChange}>
                                            <option disabled value="">
                                                --
                                            </option>
                                            {Ethnicities.map((ethnicity, ide) => (
                                                <option key={ide} value={ethnicity}>
                                                    {ethnicity}
                                                </option>
                                            ))}
                                        </Select>
                                    </Label>
                                    <Info
                                        className="ParagraphSmallRegular"
                                        data-tip="This data helps us better deliver accurate results">
                                        <i className="fa-solid fa-circle-question" /> How do you use this information?
                                    </Info>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <Label error={error.address1}>
                                        <LabelText>Street Address</LabelText>
                                        <FormInput
                                            error={error.address1}
                                            placeholder="123 Apple Street"
                                            type="text"
                                            name="address1"
                                            value={state?.address1 || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <Label error={error.address2}>
                                        <LabelText>Address Cont. - Apt/Suite/Etc</LabelText>
                                        <FormInput
                                            error={error.address2}
                                            placeholder="Apt 100"
                                            type="text"
                                            name="address2"
                                            value={state?.address2 || ''}
                                            onChange={handleChange}
                                        />
                                        <Optional>Optional</Optional>
                                    </Label>
                                    <Label error={error.city}>
                                        <LabelText>City</LabelText>
                                        <FormInput
                                            error={error.city}
                                            placeholder="Dallas"
                                            type="text"
                                            name="city"
                                            value={state?.city || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <TopForm>
                                        <TopLabel error={error.state}>
                                            <LabelText>State</LabelText>
                                            <Select
                                                error={error.state}
                                                name="state"
                                                value={state?.state || ''}
                                                onChange={handleChange}
                                                style={{fontSize: '11px'}}>
                                                <option disabled value="">
                                                    --
                                                </option>
                                                {States.map((usstate, ids) => (
                                                    <option key={ids} value={usstate.code}>
                                                        {usstate.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </TopLabel>
                                        <TopLabel error={error.zip}>
                                            <LabelText>ZIP Code</LabelText>
                                            <FormInput
                                                error={error.zip}
                                                placeholder="10001"
                                                type="text"
                                                name="zip"
                                                value={state?.zip || ''}
                                                onChange={handleChange}
                                            />
                                        </TopLabel>
                                    </TopForm>
                                    <Label error={error.country}>
                                        <LabelText>Country</LabelText>
                                        <Select
                                            error={error.country}
                                            name="country"
                                            value={state?.country || ''}
                                            onChange={handleChange}>
                                            <option disabled value="0">
                                                -- Select Country --
                                            </option>
                                            {Countries.map((country, idc) => (
                                                <option key={idc} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </Select>
                                    </Label>
                                </>
                            )}

                            {step === 3 && (
                                <Label error={error.license}>
                                    <PassportText>Passport/Driver's License Number</PassportText>
                                    <LeftIcon className="fa-solid fa-id-card" />
                                    <FormInput
                                        error={error.license}
                                        type="text"
                                        name="license"
                                        value={state?.license || ''}
                                        onChange={handleChange}
                                    />
                                    <Optional>Optional, required for travel</Optional>
                                    {/* <Info><FontAwesomeIcon icon={faQuestionCircle} />How do you use this information?</Info> */}
                                </Label>
                            )}
                            {/* <PasswordLabel error={hasSubmitted && (error.passwordLetters || error.passwordNumber || error.passwordLength)}>
                            <LabelText>Password</LabelText>
                            <LeftIcon icon={faKey} />
                            <RightIcon icon={show === 'text' ? faEyeSlash : faEye} onClick={togglePassword} />
                            <FormInput error={hasSubmitted && (error.passwordLetters || error.passwordNumber || error.passwordLength)} type={show} name="password" value={state?.password || ''} onChange={handleChange} />
                        </PasswordLabel>
                        <PasswordErrors>
                            <PasswordError>
                                <PasswordErrorIcon src={error.passwordLength || state.password === '' ? ErrorIcon : CheckIcon} />
                                <PasswordErrorText className="ParagraphSmallRegular">At least 9 characters long</PasswordErrorText>
                            </PasswordError>
                            <PasswordError>
                                <PasswordErrorIcon src={error.passwordLetters || state.password === '' ? ErrorIcon : CheckIcon} />
                                <PasswordErrorText className="ParagraphSmallRegular">Include at least 1 uppercase and lowercase letter</PasswordErrorText>
                            </PasswordError>
                            <PasswordError>
                                <PasswordErrorIcon src={error.passwordNumber || state.password === '' ? ErrorIcon : CheckIcon} />
                                <PasswordErrorText className="ParagraphSmallRegular">Include at least 1 number</PasswordErrorText>
                            </PasswordError>
                        </PasswordErrors> */}
                        </FormBody>
                        <Footer>
                            {step === 3 && (
                                <Caption className="ParagraphSmallRegular">
                                    By continuing to sign up, you're agreeing to our{' '}
                                    <FooterLink href="https://mylabsdirect.com/terms-of-service/" target="_blank">
                                        Terms of Service
                                    </FooterLink>{' '}
                                    and{' '}
                                    <FooterLink href="https://mylabsdirect.com/privacy-policy/" target="_blank">
                                        Privacy Policy.
                                    </FooterLink>
                                </Caption>
                            )}
                            <SubmitContainer>
                                {creating && <Spinner />}
                                <PrimaryButton value={step === 3 ? 'Sign Up' : 'Continue'} width="100%" />
                            </SubmitContainer>
                        </Footer>
                    </Form>
                )}
                {!!sent && (
                    <>
                        <i className="fa-solid fa-envelope fa-10x" />
                        <Caption>{state.email}</Caption>
                        <Caption className="ParagraphNormalBold">
                            Please check your email and click on the provided link to activate your account. Make sure
                            to check your Spam folder.
                        </Caption>
                        <Resend href="#" onClick={resend}>
                            Resend verification email
                        </Resend>
                        <Notification submitted={resent} message={resentNotification}>
                            {resentNotification}
                        </Notification>
                    </>
                )}
            </FormContainer>
        </PageContainer>
    );
};

export default Register;
