import React from 'react';
import {Button} from './styles/PrimaryButton';

// TODO Should we have a better default for width? Our buttons are usually 100% but right now
// that has to be provided in the JSX.
interface Props {
    value: string;
    width?: string;
    disabled?: boolean;
    onClick?: Function;
    radius?: string;
    invert?: boolean;
    margin?: string;
}

const PrimaryButton: React.FC<Props> = (props) => {
    const {value, width, disabled, onClick, radius, invert, margin} = props;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button
            className="ParagraphLargeRegular"
            type="submit"
            value={value}
            width={width}
            disabled={disabled}
            onClick={handleClick}
            radius={radius}
            invert={invert}
            margin={margin}
        />
    );
};

export default PrimaryButton;
