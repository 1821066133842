import React from 'react';
import {Layout} from '../Components';
import TestPrepUncapTube from '../Resources/img/AntibodyStep3.png';

const Step5 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={TestPrepUncapTube} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 4</h2>

            <h3 className="antibody-step-description">Prep Space and Wash Hands</h3>

            <p className="has-text-centered">
                Disinfect surfaces where you will be testing. Gently blow your nose. Wash your hands with soap and water
                for 30 seconds prior to performing your test.
            </p>
        </section>
    </Layout>
);
export default Step5;
