import styled from 'styled-components';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 32px 16px;
    margin: auto;
    width: 100%;
    max-width: 820px;
`;

export const PageHeader = styled.p`
    text-align: center;
`;

export const PageSubHeader = styled.p`
    text-align: center;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-grow: 0;
    flex-wrap: wrap;
    width: 100%;
`;

export const InfoCard = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px 0px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
`;

export const SelectionCard = styled(InfoCard)`
    width: 144px;
    min-width: 144px;
    &:hover {
        cursor: pointer;
    }
`;

export const CardIcon = styled.i`
    font-size: 32px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const LargeCardIcon = styled.i`
    font-size: 48px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const CardText = styled.p`
    white-space: nowrap;
`;
CardText.defaultProps = {
    className: 'ParagraphSmallRegular',
};

export const BackButton = styled.button`
    position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    margin: auto 0;
    padding: 0;
    border: none;
    background: none;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
    }
`;

export const BackIcon = styled.i`
    font-size: 32px;
    color: black;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const KitListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: auto;
    width: 100%;
`;
