import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep4.png';

const AntibodyStep4 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 4</h2>

            <h3 className="antibody-step-description">Prepare To Test</h3>

            <p className="has-text-centered">
                Uncap blood collection tube. Choose third or fourth finger on non-dominant hand for testing. Massage
                hand from palm to tip of finger to increase blood flow.
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep4;
