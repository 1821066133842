import styled from 'styled-components';

export const ResultContainer = styled.div<{isExpanded: number}>`
    // max-height: ${(props) => (props.isExpanded ? '10000px' : '320px')};
    overflow: hidden;
    max-width: 660px;
    width: calc(100% - 48px);
    margin: 0 8px;
    // flex-grow: 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    padding: 16px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--neutral-0);
    transition: 0.2s;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 16px;
    }
`;
export const Header = styled.div`
    margin: 0 auto;
`;
export const HealthHistoryContainer = styled.div`
    margin: 16px 0;
`;
export const InnerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;
export const HeaderButton = styled.button`
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
        cursor: pointer;
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;
export const HeaderButtonImage = styled.i`
    font-size: 24px;
`;
export const ExpandedContainer = styled.div<{isExpanded: number}>`
    width: 100%;
    max-height: ${(props) => (props.isExpanded ? 'none' : '0')};
    display: ${(props) => (props.isExpanded ? 'block' : 'none')};
    transition: max-height 0.2s, opacity 0.2s 0.2s;
`;
export const ButtonContainer = styled.div`
    width: 100%;
    background-color: var(--neutral-0);
    z-index: 1;
`;
export const Button = styled.button<{color: string}>`
    width: fit-content;
    padding: 8px 24px;
    margin: 0 auto;
    border-radius: 24px;
    font-size: 0.833rem;
    font-weight: normal;
    background-color: ${(props) => props.color};

    &:hover {
        cursor: pointer;
    }
`;
export const Stretch = styled.div`
    flex-grow: 1;
`;
