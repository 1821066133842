import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Device} from '../../services/constants';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: calc(100% + 16px);
    min-height: 100vh;

    @media ${Device.mobile} {
        height: auto;
        min-height: calc(100vh - 64px);
    }
`;

export const FormContainer = styled.div`
    width: calc(100% - 32px);
    max-width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 0;
    margin: 0px 16px 32px 16px;

    background-color: #ffffff;
    transition: 0.2s;

    @media ${Device.mobile} {
        margin: 32px 16px;
    }
`;

export const TopBar = styled.div`
    width: 100%;
    object-fit: contain;
    background: none;
    height: 67px;
    display: flex;
    justify-content: center;
    padding: 32px 0;
    transition: 0.2s;
    display: none;

    @media ${Device.mobile} {
        height: 0;
        padding: 0;
        display: flex;
    }
`;

export const LogoLink = styled(Link)`
    width: calc(100% - 32px);
    max-width: 300px;
`;

export const MLDLogo = styled.img`
    width: 100%;
    object-fit: contain;
`;
