import styled from 'styled-components';

export const ItemContainer = styled.div`
    max-height: none;
    overflow: hidden;
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 0px 16px;
    transition: 0.2s;
`;

// @media query fixes a scaling issue at ~500 px
export const ChartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    padding: 0;

    @media (max-width: 500px) {
        width: 80%;
    }
`;

export const ValueText = styled.p`
    color: var(--primary-500);
    border: solid var(--primary-500) 2px;
    border-radius: 5px;
    padding: 8px;
`;

export const TitleContainer = styled.div<{canExpand?: boolean}>`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
    padding: 0;

    &:hover {
        cursor: ${(props) => (props.canExpand ? 'pointer' : 'default')};
    }
`;

export const TitleRightContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
`;

export const IconContainer = styled.div`
    width: 20px;
    height: 20px;
`;

export const IndicatorIcon = styled.i<{section: number}>`
    width: 100%;
    color: ${(props) =>
        props.section === 1
            ? '#36cf00'
            : props.section === 2
            ? '#f6ca2c'
            : props.section === 3
            ? '#f39b17'
            : props.section === 4
            ? '#ed3b23'
            : props.section === 5
            ? '#8f21e7'
            : 'var(--neutral-300)'};
    font-size: 20px;
`;

export const IconMask = styled.i`
    color: white;
    position: absolute;
    font-size: 6px;
    padding-left: 7px;
    padding-top: 8px;
`;

export const ExpandButton = styled.button`
    width: 18px;
    height: 18px;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
        cursor: pointer;
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;

export const ExpandButtonImage = styled.i`
    font-size: 18px;
    color: var(--text-600);
`;

export const ExpandedContainer = styled.div<{isActive: number}>`
    width: 100%;
    max-height: ${(props) => (props.isActive ? 'none' : '0')};
    display: flex;
    flex-direction: column;
    gap: 24px;
    opacity: ${(props) => (props.isActive ? '100%' : '0')};
    //transition: max-height 0.2s, opacity 0.2s 0.2s;
`;

export const SelectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
`;

export const SelectionButton = styled.button<{isActive: boolean}>`
    flex-grow: 1;
    width: 20%;
    border-radius: 8px;
    padding: 8px;
    background-color: ${(props) => (props.isActive ? 'var(--primary-500)' : 'var(--neutral-50)')};
    border: none;
    transition: 0.2s;
    color: ${(props) => (props.isActive ? 'white' : 'black')};

    &:hover {
        background-color: ${(props) => (props.isActive ? 'var(--primary-500)' : 'var(--neutral-200)')};
        cursor: pointer;
    }
    &:active {
        background-color: var(--primary-500);
        color: white;
    }
`;

export const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--neutral-50);
    border-radius: 8px;
    padding: 8px 16px 16px 16px;
    gap: 12px;
`;

export const CategoryContainer = styled.div`
    border-radius: 8px;
    background-color: var(--neutral-400);
    padding: 4px;
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

export const CategoryIcon = styled.i`
    font-size: 24px;
    color: white;
`;

export const Logo = styled.img`
    width: 112px;
`;

export const FactSheetButton = styled.button`
    width: 50%;
    padding: 8px 16px;
    background-color: var(--secondary-500);
    border: none;
    border-radius: 24px;
    margin: 16px auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: min-content;

    transition: 0.2s;

    &:hover {
        background-color: #29c9a1;
        cursor: pointer;
    }
`;

export const InfoText = styled.p`
    white-space: pre-line;
`;
InfoText.defaultProps = {
    className: 'ParagraphNormalRegular',
};
