import React from 'react';
import ResultItem from '../components/ResultItem';
import EmptyLabResultsCard from '../components/EmptyLabResultsCard';
import {useAppSelector} from '../hooks';
import {selectTests, selectTestsByName} from '../slices/testSlice';
import {selectCurrentPatient} from '../slices/patientSlice';
import {useAuth} from '../hooks/useAuth';
import {ResultContainer, ResultLink, ResultsContainer, Title} from './styles/Results';

const Results: React.FC = () => {
    const {user} = useAuth();
    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector((state) => selectTestsByName(state, currentPatient.fullName));
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    // const currentPatientData = JSON.parse(localStorage.getItem('tests')!!).filter(
    //     (test: any) =>
    //         currentPatient.fullName &&
    //         currentPatient.fullName.toLowerCase() === `${test.firstName} ${test.lastName}`.toLowerCase()
    // );

    const results = currentPatientData.filter((r: any) => {
        const status = r.status?.toLowerCase();
        return status === 'collected' || status === 'received' || status === 'resulted' || status === 'processing';
    });
    // .reverse();
    let index = 0;

    return (
        <ResultsContainer isEmpty={results.length === 0}>
            {results.length > 0 && (
                <>
                    <Title className="Heading03Bold">My Results</Title>
                    {results.map((r: any) => {
                        const status = r.status?.toLowerCase();

                        return status === 'collected' ||
                            status === 'received' ||
                            status === 'processing' ||
                            r.unreleased ? (
                            <ResultContainer key={r.id}>
                                <ResultItem result={r} index={r.id} />
                            </ResultContainer>
                        ) : status === 'resulted' ? (
                            <ResultLink
                                key={r.id}
                                to={{
                                    pathname: `/result/${r.id}`,
                                }}>
                                <ResultItem result={r} index={index++} />
                            </ResultLink>
                        ) : null;
                    })}
                </>
            )}
            {results.length === 0 && <EmptyLabResultsCard />}
        </ResultsContainer>
    );
};

export default Results;
