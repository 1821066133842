import styled from 'styled-components';

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    padding: 24px;
    gap: 24px;
`;

export const Header = styled.div`
    height: 35px;
    text-align: left;
    color: var(--text-600);
    align-self: flex-start;
`;

export const BackButton = styled.button`
    color: var(--link-600);
    background-color: var(--neutral-0);
    border: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;

export const Error = styled.p<{hasSubmitted: boolean; message: string}>`
    width: calc(100% - 64px);
    height: ${(props) => (props.hasSubmitted ? (props.message.length > 45 ? '42px' : '21px') : '0')};
    color: var(--text-0);
    text-align: center;
    overflow: hidden;
    line-height: 1.5;
    margin: ${(props) => (props.hasSubmitted ? '16px 24px' : '0 24px')};
    padding: ${(props) => (props.hasSubmitted ? '8px' : '0')};
    border-radius: 8px;
    background-color: ${(props) => (props.hasSubmitted ? 'rgba(165, 20, 0, 1)' : 'rgba(165, 20, 0, 0)')};
    transition: height 0.2s, margin 0.2s, padding 0.2s, background-color 0.2s 0.2s;
`;

export const AcuityFormSection = styled(FormSection)<{position: string}>`
    border-radius: ${(props) => (props.position === 'top' ? '8px 8px 0 0' : '0 0 8px 8px')};
    background-color: var(--neutral-50);
`;
