import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import styled, {css} from 'styled-components';
import InsuranceBackground from '../../assets/images/Insurance Background.jpeg';
import {Device} from '../../services/constants';

export const Background = styled.div`
    position: fixed;
    width: 100%;
    height: calc(100% + 200px);
    z-index: -5;
    background-image: url('${InsuranceBackground}');
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;

    @media ${Device.mobile} {
        height: calc(100% - 64px);
    }
`;
export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% + 16px);
    min-height: 100vh;

    @media ${Device.mobile} {
        height: auto;
        min-height: calc(100vh - 64px);
    }
`;
export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
export const FormContainer = styled.div<{isSurvey: boolean; isAcuity: boolean}>`
    width: calc(100% - 32px);
    max-width: ${(props) => (props.isAcuity ? '752px' : '420px')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0 16px 32px 16px;
    border-radius: 8px;
    box-shadow: ${(props) => (props.isSurvey ? '' : '0 8px 16px 0 rgba(0, 0, 0, 0.25)')};
    border: ${(props) => (props.isSurvey ? '' : 'solid 0 rgba(255, 255, 255, 0.5)')};
    background-color: ${(props) => (props.isSurvey ? '' : 'var(--neutral-0)')};
    transition: max-width 0.2s, margin 0.2s;

    @media ${Device.mobile} {
        margin: 32px 16px;
    }
`;
// export const SurveyHeader = styled.p`
//     width: 100%;
//     color: var(--text-0);
//     text-align: center;
// `
// export const Acknowledgement = styled(SurveyHeader)`
//     margin-top: 24px;
// `
export const SurveyContainer = styled.div`
    width: calc(100% - 48px);
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: var(--neutral-0);
`;
export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    padding: 24px;
    gap: 24px;
`;
export const AcuityFormSection = styled(FormSection)<{position: string}>`
    border-radius: ${(props) => (props.position === 'top' ? '8px 8px 0 0' : '0 0 8px 8px')};
    background-color: var(--neutral-50);
`;
export const Header = styled.div`
    text-align: left;
    color: var(--text-600);
    align-self: flex-start;
`;
export const CenterHeader = styled(Header)`
    width: 100%;
    text-align: center;
`;
export const Label = styled.label<{error: string | undefined}>`
    font-size: 1rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 83px;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const LabelText = styled.p`
    padding-left: 12px;
    white-space: nowrap;
`;
export const FormInput = styled.input<{error: string | undefined}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const StyledDatePicker = styled(DatePicker)<{error: string | undefined}>`
    width: calc(100% - 56px);
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const FullInput = styled(FormInput)`
    padding: 0 0 0 12px;
`;
export const LeftIcon = styled.i<{error: string | undefined}>`
    font-size: 20px;
    position: absolute;
    left: 12px;
    top: 43px;
    color: ${(props) => (props.error ? 'var(--error-500)' : 'var(--neutral-300)')};
    transition: 0.2s;
    z-index: 1;
`;
export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 120px;
    height: 120px;
    border-radius: 32px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;
export const Image = styled.i`
    font-size: 58px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
export const Paragraph = styled.p`
    white-space: pre-line;
    text-align: center;
`;
export const IconParagraph = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    color: var(--text-300);
`;
export const Icon = styled.i`
    font-size: 22px;
`;
export const IdIcon = styled.i`
    font-size: 32px;
`;
export const SurveyQuestion = styled.div<{error: boolean | undefined}>`
    width: 100%;
    font-size: 1rem;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    gap: 8px;
    position: relative;
    transition: 0.2s;
    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const RadioInput = styled(FormInput)`
    width: 20px;
    height: 20px;
    margin: 0;
    &:hover {
        cursor: pointer;
    }
`;
export const SymptomInputContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const SymptomCheckLabel = styled.label`
    padding-left: 12px;
    margin-right: 32px;
    &:hover {
        cursor: pointer;
    }
`;
export const Symptoms = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`;
export const Symptom = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const SquareInput = styled(RadioInput)``;
export const SymptomLabel = styled.label<{error: string | undefined}>`
    &:hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const Advertisement = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #3ae7ff, #f328f8);
    border-image-slice: 1;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, var(--neutral-0), var(--neutral-0)),
        linear-gradient(to right, #3ae7ff, #f328f8);
    background-origin: border-box;
    background-clip: content-box, border-box;
`;
export const AdIcon = styled.i`
    font-size: 28px;
    margin: 16px 0 16px 16px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
export const ShopLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border-width: 0;
    background-color: var(--primary-700);
    color: var(--text-0);
    text-decoration: none;
    font-size: 1.2rem;
    padding: 0 !important;
    margin: 0 auto;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--primary-300);
    }

    &:active {
        background-color: var(--primary-50);
    }
`;
export const DualContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`;
export const DualLabel = styled(Label)`
    width: calc(50% - 4px);
    max-width: 182px;
`;
export const Select = styled.select<{error: boolean}>`
    width: 100%;
    height: 52px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;

    :hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const OptionalContainer = styled.div`
    width: 100%;
`;
export const Optional = styled.div`
    padding-left: 12px;
    margin-top: 8px;
    font-size: 0.8rem;
`;
export const AttestationName = styled.p`
    width: calc(100% - 32px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    text-align: center;
`;
export const ExternalLink = styled.a`
    margin-top: 32px;
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;
export const InternalLink = styled(ExternalLink)`
    margin-top: 0;
`;
export const SubmitContainer = styled.div`
    width: 100%;
    position: relative;
`;
export const BackButton = styled.button`
    color: var(--link-600);
    background-color: var(--neutral-0);
    border: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;
export const PhotoUploadContainer = styled.div<{spacing: string}>`
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: ${(props) => (props.spacing === 'single' ? 'center' : 'space-between')};
`;
export const PhotoSubmitLabel = styled.label<{background: string}>`
    width: 124px;
    height: 60px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: ${(props) => (props.background ? '2px solid var(--success-500)' : '2px dashed var(--neutral-400)')};
    border-radius: 8px;
    background-color: var(--neutral-0);
    background: ${(props) => (props.background ? `url(${props.background})` : '')};
    background-size: cover;
    background-position: center;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--neutral-200);
    }
    &:active {
        background-color: var(--neutral-300);
    }
`;
export const PhotoSubmit = styled.input`
    display: none;
`;
export const Spacer = styled.div`
    width: 32px;
    height: 32px;
`;
export const ConfidentialityContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const ConfidentialityLabel = styled.label<{error: string | undefined}>`
    width: calc(100% - 32px);
    transition: 0.2s;

    &:hover {
        cursor: pointer;
    }

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
export const PriorityBanner = styled.div`
    width: calc(100% - 16px);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--neutral-100);
`;
