import React from 'react';
import {Navigate} from 'react-router-dom';
import TopBar from '../components/TopBar';
import {useAuth} from '../hooks/useAuth';
import {PageContainer, FormContainer, Header, Caption, LinkFrame, LinkText, LeftIcon} from './styles/KitLanding';

const KitLanding: React.FC = () => {
    const {isLoggedIn} = useAuth();

    return (
        <PageContainer>
            {isLoggedIn && <Navigate to="/newregisterkit" replace={true} />}
            <TopBar />
            <FormContainer>
                <Header className="Heading05Bold">Let's Get Your Kit Activated</Header>
                <Caption style={{color: 'white'}}>Choose an option to get started</Caption>
                <LinkFrame to="/login?ref=kit">
                    <Caption>I already have a MyLabsDirect account</Caption>
                    <LinkText className="ParagraphLargeBold">
                        <LeftIcon className="fa-solid fa-right-to-bracket" />
                        Sign In
                    </LinkText>
                </LinkFrame>
                <LinkFrame to="/register?ref=kit">
                    <Caption>I need a MyLabsDirect account</Caption>
                    <LinkText className="ParagraphLargeBold">
                        <LeftIcon className="fa-solid fa-user-plus" />
                        Create an account
                    </LinkText>
                </LinkFrame>
                {/* <div>
                <InfoIcon icon={faInfoCircle} />
                <Caption style={{color: 'white', fontWeight: 'normal', fontSize: '.8rem'}}>If you've activated a kit with us before, you already have an account</Caption>
                </div> */}
            </FormContainer>
        </PageContainer>
    );
};

export default KitLanding;
