import React, {useContext, useState, createContext} from 'react';
import {bannerState} from '../slices/bannerSlice';
import {useAppDispatch, useAppSelector} from '../hooks';
import PrimaryButton from './PrimaryButton';
import {hideBanner} from '../slices/bannerSlice';
import {CookieBanner, BannerContent, ExitButton, AdminBanner} from './styles/Banner';
// import {showBanner, setMessage} from '../slices/bannerSlice';

// export function ShowBanner(message: string) {
//     const dispatch = useAppDispatch();
//     return () => {
//         dispatch(showBanner());
//         dispatch(setMessage(message));
//     };
// }
export const BannerContext = createContext<{state: any; setState: Function}>({state: {}, setState: () => {}});

interface Props {
    admin?: boolean;
    cookie?: boolean;
}

const Banner: React.FC<Props> = (props) => {
    let {visible, message} = useAppSelector(bannerState);
    const {admin, cookie} = props;
    //const message = useAppSelector(bannerMessage);

    const dispatch = useAppDispatch();
    let cookiesEnabled = navigator.cookieEnabled;

    return (
        <>
            {visible && (
                <CookieBanner>
                    <BannerContent>
                        {message}
                        {/* You are currently viewing the dashboard as user: {user.userInfo.fullName}{' '}
                <Link to="/admin" onClick={exitImpersonation}>
                    Close
                </Link> */}
                    </BannerContent>
                </CookieBanner>
            )}
            {visible && !cookiesEnabled && cookie && (
                <CookieBanner>
                    <ExitButton className="fa-solid fa-x" onClick={() => dispatch(hideBanner())} />
                    <BannerContent className="ParagraphSmallRegular">
                        Cookies are required for this site to function. We use cookies to improve your experience and
                        keep you signed in.
                        <br />
                        By clicking "Accept", you consent to our use of cookies.
                    </BannerContent>
                    <PrimaryButton
                        value="Accept"
                        width="100px"
                        onClick={() => dispatch(hideBanner())}
                        invert
                        margin="0"
                        radius="0"
                    />
                </CookieBanner>
            )}
        </>
    );
};

export default Banner;
