import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Device} from '../../services/constants';

export const ResultsContainer = styled.div<{isEmpty: boolean}>`
    max-width: 820px;
    margin: auto;
    margin-top: 32px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.isEmpty ? 'center' : 'flex-start')};
    align-items: ${(props) => (props.isEmpty ? 'center' : 'flex-start')};
    gap: 16px;
    padding: 0 8px;

    @media ${Device.mobile} {
        width: 100%;
        margin: 56px 8px 0 8px;
    }
`;

export const ResultContainer = styled.div`
    max-width: 820px;
    width: 100%;
`;

export const ResultLink = styled(Link)`
    max-width: 820px;
    width: 100%;
    text-decoration: none;
`;

export const Title = styled.p``;
