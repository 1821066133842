import styled from 'styled-components';

export const PanelContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
export const PanelHeader = styled.div`
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px 16px 16px;
`;
export const PanelName = styled.p`
    letter-spacing: normal;
`;
export const Biomarkers = styled.div`
    display: flex;
    align-items: center;
`;
export const BiomarkerImage = styled.i`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    font-size: 16px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
export const Paragraph = styled.p`
    letter-spacing: normal;
`;
export const MetricList = styled.div`
    width: calc(100% - 32px);
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 24px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: var(--neutral-0);
`;
export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--neutral-100);
`;
