import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;
export const PatientSwitcherPage = styled.div`
    margin: 32px 16px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 82px;
    padding: 0;
`;

export const LogoLink = styled(Link)`
    margin: 0 auto;
`;

export const Logo = styled.img`
    width: 232px;
    height: 52px;
    cursor: pointer;
`;

export const Heading = styled.h2`
    margin: 0 auto;
    text-align: center;
`;

export const Patients = styled.div`
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
`;

export const CloseLink = styled(Link)`
    margin: 0 auto;
    width: 90px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    text-decoration: none;
    color: var(--text-0);
    background-color: var(--neutral-500);
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--neutral-400);
    }
    &:active {
        background-color: var(--neutral-200);
    }
`;
