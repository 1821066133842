import React, {useState, ChangeEvent} from 'react';
import {useNavigate, useLocation, Link, useParams} from 'react-router-dom';
import {useAuth} from '../../hooks/useAuth';
import DatePicker from 'react-datepicker';
import styled, {css} from 'styled-components';
import TopBar from '../../components/TopBar';
import PrimaryButton from '../../components/PrimaryButton';
import Spinner from '../../components/Spinner';
import Error from '../../components/Error';
import settings from '../../settings';
import {States, Ethnicities, Device} from '../../services/constants';

const PageContainer = styled.div<{background: any}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% + 16px);
    min-height: 100vh;
    background: url('${(props) => props.background}'), rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;

    @media ${Device.mobile} {
        height: auto;
        min-height: calc(100vh - 64px);
    }
`;
const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;
const FormContainer = styled.div<{isAcuity: boolean}>`
    width: calc(100% - 32px);
    max-width: ${(props) => (props.isAcuity ? '752px' : '420px')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0 16px 32px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: #ffffff;
    transition: 0.2s;

    @media ${Device.mobile} {
        margin: 32px 16px;
    }
`;
const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    padding: 24px;
    gap: 24px;
`;
const AcuityFormSection = styled(FormSection)<{position: string}>`
    border-radius: ${(props) => (props.position === 'top' ? '8px 8px 0 0' : '0 0 8px 8px')};
    background-color: var(--neutral-50);
`;
const Header = styled.div`
    height: 35px;
    text-align: left;
    color: var(--text-600);
    align-self: flex-start;
`;
const Label = styled.label<{error: string | undefined}>`
    font-size: 1rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 99px;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
const LabelText = styled.p`
    padding-left: 12px;
    white-space: nowrap;
`;
const FormInput = styled.input<{error: string | undefined}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin: 8px 0;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
const Select = styled.select<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    padding-left: 40px;

    :hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
const LeftIcon = styled.i<{error: number | undefined}>`
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 43px;
    color: ${(props) => (props.error ? 'var(--error-500)' : 'var(--neutral-300)')};
    transition: 0.2s;
`;
const BackButton = styled.button`
    color: var(--link-600);
    background-color: var(--neutral-0);
    border: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 120px;
    height: 120px;
    border-radius: 32px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;
const Image = styled.i`
    font-size: 72px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const Paragraph = styled.p`
    white-space: pre-line;
    text-align: center;
`;
const SurveyQuestion = styled.div<{error: boolean | undefined}>`
    width: 100%;
    margin-top: 8px;
    font-size: 1rem;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    gap: 8px;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
const RadioInput = styled(FormInput)`
    width: 20px;
    height: 20px;
    margin: 0;

    &:hover {
        cursor: pointer;
    }
`;
const SymptomInputContainer = styled.div`
    display: flex;
    align-items: center;
`;
const SymptomOptionLabel = styled.label`
    padding-left: 12px;
    margin-right: 32px;

    &:hover {
        cursor: pointer;
    }
`;
const StyledDatePicker = styled(DatePicker)<{error: boolean | undefined}>`
    width: calc(100% - 56px);
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
const SubmitContainer = styled.div`
    width: 100%;
    position: relative;
`;

interface Props {
    background: any;
}

const Step0: React.FC<Props> = ({background}) => {
    const {user, token} = useAuth();
    const hasNoFirstName = !user.userInfo.firstName;
    const hasNoLastName = !user.userInfo.lastName;
    const hasNoBirthday = !user.userInfo.birthday;
    const hasNoAddress = !user.userInfo.address1;
    const hasNoCity = !user.userInfo.city;
    const hasNoState = !user.userInfo.state;
    const hasNoZip = !user.userInfo.zip;
    const hasNoPhone = !user.userInfo.phone;
    const hasNoGender = !user.userInfo.gender;
    const hasNoEthnicity = !user.userInfo.ethnicity;
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const idParam = query.get('id');
    const companies: any = {
        'baylor-student-on-campus': 1,
        'baylor-student-off-campus': 2,
        'baylor-faculty': 3,
        'baylor-staff': 4,
        'varsity-spirit': 110,
    };
    const [state, setState] = useState<{[x: string]: any}>({
        kitId1: '',
        kitId2: '',
        promoCode1: '',
        promoCode2: '',
        company: null,
        contact: '',
        symptoms: '',
        vaccinated: '',
        firstName: '',
        lastName: '',
        birthday: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        gender: '',
        ethnicity: '',
        location: '',
    });
    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: '',
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);
    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(idParam ? 4 : 0);

    const nextStep = () => setStep(step + 1);
    const prevStep = () => {
        setError(initialError);
        setStep(step - 1);
    };
    const goStepZero = () => {
        setError(initialError);
        setStep(0);
    };
    const simpleRefresh = () => {
        setError(initialError);
        navigate('/registerkit/step/0');
    };

    const {chk_id} = useParams<{chk_id: string}>();

    const hasMissingInfo =
        hasNoFirstName ||
        hasNoLastName ||
        hasNoBirthday ||
        hasNoAddress ||
        hasNoCity ||
        hasNoState ||
        hasNoZip ||
        hasNoPhone ||
        hasNoGender ||
        hasNoEthnicity;

    const states: any = {
        AL: 'Alabama',
        AK: 'Alaska',
        AX: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
    };

    // ID indicators:
    // 01: Covid RT-PCR
    // 02: Covid Total Antibody
    // 03: Covid IgG Antibody
    // 04: Covid RT-PCR with promo code
    // 05: Covid Total Antibody with promo code
    // 06: Covid IgG Antibody with promo code
    // 07: Women's Complete Wellness
    // 08: Women's Hormone Health
    // 09: Men's Complete Wellness
    // 10: Men's Hormone Health
    // 11: Testosterone Level
    // 12: Thyroid Health
    // 13: Basic Nutrition & Lifestyle Risk
    // 14: Heart Health
    // 15: Immune Health

    const covidIds: {[index: string]: boolean} = {
        '01': true,
        '02': true,
        '03': true,
        '04': true,
        '05': true,
        '06': true,
    };

    const promoIds: {[index: string]: boolean} = {
        '04': true,
        '05': true,
        '06': true,
    };

    const bloodIds: {[index: string]: boolean} = {
        '07': true,
        '08': true,
        '09': true,
        '10': true,
        '11': true,
        '12': true,
        '13': true,
        '14': true,
        '15': true,
    };

    const validIds: {[index: string]: boolean} = {
        ...covidIds,
        ...bloodIds,
    };

    //We check for valid returning from checkout, then setting whether success or try again screen shows.
    if (chk_id && state.kitId1 === '' && state.kitId2 === '') {
        navigate({search: chk_id.toString()}, {replace: true});
        console.log('chk_id: ' + chk_id);
        const kit_id = chk_id.substring(chk_id.indexOf('-') + 1);
        console.log('kit_id: ' + kit_id);
        const idReg = /^[+]?[(]?[0-9]{2}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{4}$/;
        if (idReg.test(kit_id)) {
            state.kitId1 = kit_id;
            state.kitId2 = kit_id;

            if (chk_id.startsWith('99ax-')) {
                setStep(999);
            } else if (chk_id.startsWith('01fb-')) {
                setStep(1000);
            }
        }
    }

    const handleRegistration = async () => {
        // const affiliate = state.kitId1.length === 15 ? state.kitId1.substring(0, 2) : '';
        const test = state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2);
        const isBlood = bloodIds[test];

        const productLine =
            test === '01' || test === '04'
                ? 'COVID-19 PCR'
                : test === '02' || test === '03' || test === '05' || test === '06'
                ? 'COVID-19 Antibody'
                : isBlood
                ? 'Blood Wellness'
                : 'COVID-19 PCR';

        const specimenType =
            test === '01' || test === '04'
                ? 'Nasal Swab'
                : test === '02' || test === '03' || test === '05' || test === '06' || isBlood
                ? 'Whole Blood'
                : 'Nasal Swab';

        const profiles =
            test === '07'
                ? ['WHH']
                : test === '08'
                ? ['002']
                : test === '09'
                ? ['MHH']
                : test === '10'
                ? ['001']
                : test === '11'
                ? ['TotT']
                : test === '12'
                ? ['HH']
                : test === '13'
                ? ['TH']
                : test === '14'
                ? ['003']
                : test === '15'
                ? ['BNLR']
                : [];

        const panels =
            test === '01' || test === '04'
                ? ['001']
                : test === '02' || test === '05'
                ? ['003']
                : test === '03' || test === '06'
                ? ['004']
                : isBlood
                ? []
                : ['001'];

        const diagCodes = test === '01' || test === '03' ? ['Z20822'] : [];

        const order = {
            patient: {
                firstName: hasNoFirstName ? state.firstName : user.userInfo.firstName,
                lastName: hasNoLastName ? state.lastName : user.userInfo.lastName,
                gender: hasNoGender ? state.gender : user.userInfo.gender[0].toUpperCase(),
                categoryId: companies[state.company] || '',
                birthDate: hasNoBirthday ? new Date(state.birthday).toISOString() : user.userInfo.birthday,
                email: user.userInfo.email,
                race: hasNoEthnicity ? state.ethnicity : user.userInfo.ethnicity,
                address: {
                    phone: hasNoPhone ? state.phone : user.userInfo.phone,
                    address1: hasNoAddress ? state.address1 : user.userInfo.address1,
                    address2: user.userInfo.address2,
                    city: hasNoCity ? state.city : user.userInfo.city,
                    state: hasNoState ? state.state : user.userInfo.state,
                    zipCode: hasNoZip ? state.zip : user.userInfo.zip,
                    country: user.userInfo.country,
                },
            },
            specimenId: state.kitId1,
            providerNPI: '1346818218',
            lab: settings.env === 'production' ? 'Default' : 'Test',
            productLine: productLine,
            specimenType: specimenType,
            profiles: profiles,
            panels: panels,
            diagCodes: diagCodes,
            sendEmail: false,
            doNotCollect: true,
            createNoUser: true,
            surveyAnswers: [
                {
                    questionId: 1,
                    answerText: state.symptoms,
                },
                {
                    questionId: 2,
                    answerText: state.contact,
                },
                {
                    questionId: 3,
                    answerText: state.vaccinated,
                },
                {
                    questionId: 4,
                    answerText: state.vaccinated,
                },
            ],
        };

        setSubmitting(true);

        const orderRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify(order),
        };

        const res = await fetch(`${settings.apiURL}/api/order/secureCreate`, orderRequestOptions);
        if (!res.ok) {
            setSubmitting(false);
            const message = (await res.json()).error;
            const registered = message === 'Specimen ID already exists in another order.';
            setError((prevError) => ({
                ...prevError,
                message: registered ? 'This kit ID has already been registered.' : 'Could not create order',
            }));
            return message;
        }

        const orderRes = await res.json();

        const kit = {
            kit_id: state.kitId1,
            customer_id: user.userInfo.id,
            order_id: orderRes.id,
            category: companies[state.company] || '',
            createdDate: new Date(),
            firstName: user.userInfo.firstName,
            email: user.userInfo.email,
        };

        const kitRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify(kit),
        };

        const kitRes = await fetch(`${settings.apiURL}/api/kit/createKitRegistration`, kitRequestOptions);
        setSubmitting(false);
        if (!kitRes.ok) {
            // const message = (await res.json()).error;
            setError((prevError) => ({...prevError, message: 'Could not submit kit'}));
            return 'Could not submit kit';
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'kitId1' || name === 'kitId2') {
            const digitReg = /^[0-9]$/;
            const lowerReg = /^[a-z]$/;
            const letterReg = /^[A-Z]$/;

            if (lowerReg.test(value[value.length - 1])) {
                value = value.toUpperCase();
            }

            if (
                value.length === 3 ||
                (letterReg.test(value[0]) && value.length === 6) ||
                (digitReg.test(value[0]) && value.length === 8) ||
                (letterReg.test(value[0]) && value.length === 11)
            ) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (
                (value !== '' && !digitReg.test(value[value.length - 1]) && !letterReg.test(value[value.length - 1])) ||
                (letterReg.test(value[value.length - 1]) && value.length > 3) ||
                (digitReg.test(value[0]) && value.length > 12) ||
                value.length > 15
            ) {
                return;
            }
        }

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }
        }

        if (name === 'zip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handlePromoChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (step === 0) {
            const id = state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2);
            const isPromo = promoIds[id];
            const isBlood = bloodIds[id];

            const idReg =
                state.kitId1.length === 15
                    ? /[A-Z]{2}?[-\s.]?[0-9]{2}?[-\s.]?[0-9]{4}[-\s.]?[0-9]{4}$/
                    : state.kitId1.length === 12
                    ? /[0-9]{2}?[-\s.]?[0-9]{4}[-\s.]?[0-9]{4}$/
                    : null;

            if (!validIds[id] || !idReg || !idReg.test(state.kitId1)) {
                setError((prevError) => ({
                    ...prevError,
                    kitId1: true,
                    kitId2: false,
                    message: 'Please enter a valid Kit ID',
                }));
                return;
            }

            if (state.kitId1 !== state.kitId2) {
                setError((prevError) => ({
                    ...prevError,
                    kitId1: true,
                    kitId2: true,
                    message: 'Please ensure Kit IDs match',
                }));
                return;
            }

            setSubmitting(true);

            const kitIdRequestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({kit_id: state.kitId1}),
            };

            const kitIdRes = await fetch(`${settings.apiURL}/api/kit/getKit`, kitIdRequestOptions);

            if (!isBlood) {
                setSubmitting(false);
            }

            if (!kitIdRes.ok) {
                // const message = (await res.json()).error;
                setError((prevError) => ({...prevError, message: 'Could not check kit ID'}));
                setSubmitting(false);
                return;
            }

            const kitIdResponse = await kitIdRes.json();
            if (kitIdResponse.kit_id) {
                setError((prevError) => ({...prevError, message: 'This kit ID has already been registered'}));
                setSubmitting(false);
                return;
            }

            if (isPromo) {
                setSubmitting(true);
                const purchasedKitIdRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify({kit_id: state.kitId1}),
                };

                const purchasedKitIdRes = await fetch(
                    `${settings.apiURL}/api/purchasedKits/enabled`,
                    purchasedKitIdRequestOptions
                );
                setSubmitting(false);
                if (!purchasedKitIdRes.ok) {
                    setError((prevError) => ({...prevError, message: 'Could not check kit ID'}));
                    return;
                }

                setSubmitting(false);

                const purchasedKitIsEnabled = await purchasedKitIdRes.text();

                if (purchasedKitIsEnabled === 'true') {
                    nextStep();
                    return;
                } else if (purchasedKitIsEnabled === 'false') {
                    setStep(99);
                    return;
                } else {
                    setError((prevError) => ({...prevError, message: 'Could not check kit ID'}));
                    return;
                }
            }

            if (isBlood) {
                setError(initialError);

                if (hasMissingInfo) {
                    setStep(3);
                } else {
                    const error = await handleRegistration();

                    if (error) {
                        return;
                    }

                    setStep(4);
                }

                return;
            }
        }

        if (step === 1) {
            const submitter = event.nativeEvent.submitter.value;
            if (submitter === 'Yes, select my company') {
                nextStep();
            } else if (submitter === 'No, this is a personal kit') {
                setState((prevState) => ({...prevState, company: null, vaccinated: ''}));
                setStep(3);
            }
            return;
        }

        if (step === 2) {
            if (state.company === null) {
                setError((prevError) => ({...prevError, company: true, message: 'Please select a company'}));
                return;
            } else if (state.company !== 'varsity-spirit') {
                setState((prevState) => ({...prevState, vaccinated: ''}));
            }
        }

        if (step === 3) {
            // const affiliate = state.kitId1.length === 15 ? state.kitId1.substring(0, 2) : '';
            const test = state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2);
            const isBlood = bloodIds[test];

            if (submitting) {
                return;
            } else {
                setError((prevError) => ({
                    ...prevError,
                    contact: !isBlood && state.contact === '',
                    symptoms: !isBlood && state.symptoms === '',
                    vaccinated: !isBlood && state.company === 'varsity-spirit' && state.vaccinated === '',
                    firstName: hasNoFirstName && state.firstName === '',
                    lastName: hasNoLastName && state.lastName === '',
                    birthday: hasNoBirthday && state.birthday === '',
                    address1: hasNoAddress && state.address1 === '',
                    city: hasNoCity && state.city === '',
                    state: hasNoState && state.state === '',
                    zip: hasNoZip && state.zip === '',
                    phone: hasNoPhone && state.phone === '',
                    gender: hasNoGender && state.gender === '',
                    ethnicity: hasNoEthnicity && state.ethnicity === '',
                }));

                if (
                    (!isBlood && state.contact === '') ||
                    (!isBlood && state.symptoms === '') ||
                    (!isBlood && state.vaccinated === '' && state.company === 'varsity-spirit') ||
                    (hasNoFirstName && state.firstName === '') ||
                    (hasNoLastName && state.lastName === '') ||
                    (hasNoBirthday && state.birthday === '') ||
                    (hasNoAddress && state.address1 === '') ||
                    (hasNoCity && state.city === '') ||
                    (hasNoState && state.state === '') ||
                    (hasNoZip && state.zip === '') ||
                    (hasNoPhone && state.phone === '') ||
                    (hasNoGender && state.gender === '') ||
                    (hasNoEthnicity && state.ethnicity === '')
                ) {
                    return;
                }

                const error = await handleRegistration();

                if (error) {
                    return;
                }

                if (hasMissingInfo) {
                    const newInfo: any = {
                        firstName: state.firstName || user.userInfo.firstName,
                        lastName: state.lastName || user.userInfo.lastName,
                        birthday: state.birthday || user.userInfo.birthday,
                        address1: state.address1 || user.userInfo.address1,
                        city: state.city || user.userInfo.city,
                        state: state.state || user.userInfo.state,
                        zip: state.zip || user.userInfo.zip,
                        phone: state.phone || user.userInfo.phone,
                        gender: state.gender || user.userInfo.gender,
                        ethnicity: state.ethnicity || user.userInfo.ethnicity,
                    };

                    const existingInfo = Object.assign(user.userInfo);

                    for (const prop in newInfo) {
                        delete existingInfo[prop];
                    }

                    const update = {
                        ...existingInfo,
                        ...newInfo,
                    };

                    setSubmitting(true);

                    const updateUserOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                        body: JSON.stringify(update),
                    };

                    const updateUserRes = await fetch(`${settings.apiURL}/api/user`, updateUserOptions);
                    if (!updateUserRes.ok) {
                        console.log('BAD CREDS');
                    }

                    for (const prop in newInfo) {
                        user.userInfo[prop] = update[prop];
                    }

                    setSubmitting(false);
                }

                if (!isBlood) {
                    navigate(
                        test === '01'
                            ? `/registerkit/step/1?id=${state.kitId1}`
                            : test === '02'
                            ? `/registerkit/antibody/step/1`
                            : '/'
                    );
                    return;
                }
            }
        }

        if (step === 4) {
            const submitter = event.nativeEvent.submitter.value;

            if (submitter === 'At an MLD location') {
                setState((prevState) => ({
                    ...prevState,
                    location: 'mld',
                }));
            } else if (submitter === 'At my location') {
                setState((prevState) => ({
                    ...prevState,
                    location: 'mobile',
                }));
            }
        }

        if (step === 5) {
            if (submitting) {
                return;
            } else {
                setSubmitting(true);

                const req = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({email: state.email}),
                };

                const res = await fetch(`${settings.apiURL}/api/appointments/recent`, req);
                setSubmitting(false);

                if (!res.ok) {
                    const message = `You have not yet finished scheduling your appointment. To finalize your appointment you must scroll down and click "Complete Appointment" after you choose a location and time.`;
                    setError((prevError) => ({...prevError, message: message}));
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    return;
                }
            }
        }

        if (step === 99) {
            const submitter = event.nativeEvent.submitter.value;
            if (submitter === 'Proceed to Checkout') {
                const id = state.kitId1.substring(0, 2);
                let url = null;

                if (id === '04') {
                    url = `${settings.apiURL}/api/session/createCheckoutSessionRTPCRHomeKitActivation`;
                } else if (id === '05') {
                    url = `${settings.apiURL}/api/session/createCheckoutSessionTotalAntibodyHomeKitActivation`;
                } else if (id === '06') {
                    url = `${settings.apiURL}/api/session/createCheckoutSessionIgGHomeKitActivation`;
                }

                if (url !== null) {
                    setSubmitting(true);

                    const checkoutParameters = {
                        user_email: user.userInfo.email,
                        kit_id: state.kitId1,
                    };

                    const checkoutRequestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                        body: JSON.stringify(checkoutParameters),
                    };

                    const res = await fetch(url, checkoutRequestOptions);
                    setSubmitting(false);
                    if (!res.ok) {
                        setError((prevError) => ({
                            ...prevError,
                            message: 'Failed to create checkout. Please try again.',
                        }));
                        return;
                    }

                    const checkoutRes = await res.json();

                    if (checkoutRes.checkout_url === null) {
                        setSubmitting(false);
                        setError((prevError) => ({
                            ...prevError,
                            message: 'Failed to retrieve checkout. Please try again.',
                        }));
                        return;
                    }
                    console.log(checkoutRes.checkout_url);
                    window.location.href = checkoutRes.checkout_url;
                } else {
                    setError((prevError) => ({...prevError, message: 'Failed to create checkout. Please try again.'}));
                    return;
                }
            }
            return;
        }

        if (step === 100) {
            if (state.promoCode1 !== state.promoCode2) {
                setError((prevError) => ({
                    ...prevError,
                    promoCode1: true,
                    promoCode2: true,
                    message: 'Please ensure Promo Codes match',
                }));
                return;
            }

            setSubmitting(true);
            const couponRequestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({coupon_name: state.promoCode1}),
            };

            const validateCouponRes = await fetch(`${settings.apiURL}/api/coupon/validate`, couponRequestOptions);
            setSubmitting(false);

            if (!validateCouponRes.ok) {
                setError((prevError) => ({...prevError, message: 'Problem checking coupon. Please try again.'}));
                return;
            }

            const couponIsEnabled = await validateCouponRes.text();

            if (couponIsEnabled === 'true') {
                nextStep();
                return;
            } else if (couponIsEnabled === 'false') {
                setError((prevError) => ({...prevError, message: 'Invalid Coupon.'}));
                return;
            } else {
                setError((prevError) => ({...prevError, message: 'Problem checking coupon. Please try again.'}));
                return;
            }
        }

        if (step === 101) {
            const submitter = event.nativeEvent.submitter.value;
            if (submitter === 'Activate This Kit') {
                setSubmitting(true);
                console.log(state.kitId1);
                console.log(state.promotCode1);
                const enableKitRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify({
                        kit_id: state.kitId1,
                        coupon_name: state.promoCode1,
                        user_email: user.userInfo.email,
                    }),
                };

                const enableKitRes = await fetch(
                    `${settings.apiURL}/api/purchasedKits/enableKitWithPromoCode`,
                    enableKitRequestOptions
                );
                console.log(enableKitRes);
                setSubmitting(false);

                if (!enableKitRes.ok) {
                    setError((prevError) => ({...prevError, message: 'Problem activating Kit. Please try again.'}));
                    return;
                }

                const kitIsActivated = await enableKitRes.text();

                if (kitIsActivated === 'true') {
                    setStep(1);
                    return;
                } else if (kitIsActivated === 'false') {
                    setError((prevError) => ({...prevError, message: 'Problem activating Kit. Please try again.'}));
                    return;
                } else {
                    setError((prevError) => ({...prevError, message: 'Problem activating Kit. Please try again.'}));
                    return;
                }
            }
        }

        if (step === 999) {
            const submitter = event.nativeEvent.submitter.value;
            if (submitter === 'Complete Registration') {
                setSubmitting(true);

                const kitIdRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify({kit_id: state.kitId1}),
                };

                const kitIdRes = await fetch(`${settings.apiURL}/api/kit/getKit`, kitIdRequestOptions);
                setSubmitting(false);
                if (!kitIdRes.ok) {
                    // const message = (await res.json()).error;
                    setError((prevError) => ({...prevError, message: 'Could not check kit ID. Please try again.'}));
                    return;
                }

                const kitIdResponse = await kitIdRes.json();
                if (kitIdResponse.kit_id) {
                    setError((prevError) => ({
                        ...prevError,
                        message: 'This kit ID has already been registered. ' + '\nPlease contact support.',
                    }));
                    return;
                }

                const purchasedKitIdRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify({kit_id: state.kitId1}),
                };

                const purchasedKitIdRes = await fetch(
                    `${settings.apiURL}/api/purchasedKits/enabled`,
                    purchasedKitIdRequestOptions
                );
                setSubmitting(false);
                if (!purchasedKitIdRes.ok) {
                    setError((prevError) => ({...prevError, message: 'Could not check kit ID. Please try again.'}));
                    return;
                }

                const purchasedKitIsEnabled = await purchasedKitIdRes.text();

                if (purchasedKitIsEnabled === 'true') {
                    setStep(1);
                    return;
                } else if (purchasedKitIsEnabled === 'false') {
                    setError((prevError) => ({...prevError, message: 'There was a problem. Please try again.'}));
                    return;
                } else {
                    setError((prevError) => ({...prevError, message: 'Could not check kit ID. Please try again.'}));
                    return;
                }
            }
            return;
        }

        if (step === 1000) {
            const submitter = event.nativeEvent.submitter.value;
            if (submitter === 'Return to Registration') {
                navigate('/registerkit/step/0');
            }
            return;
        }

        setError(initialError);
        nextStep();
    };

    return (
        <PageContainer background={background}>
            <TopBar location="/" />
            <FormContainer isAcuity={step === 5}>
                {step === 0 && (
                    <Form onSubmit={handleSubmit}>
                        <FormSection>
                            <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                Register Kit
                            </Header>
                        </FormSection>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <ImageContainer>
                                <Image className="fa-light fa-box-open" />
                            </ImageContainer>
                            <Paragraph className="ParagraphNormalRegular">
                                Let's get started! Enter the Unique Test Kit ID found inside of your kit.
                            </Paragraph>
                            <Paragraph>This ID will be either 10 or 12 characters long.</Paragraph>
                            <Label error={error.kitId1} className="Heading05Regular">
                                <LabelText>Kit ID</LabelText>
                                <LeftIcon className="fa-light fa-box" error={error.kitId1 ? 1 : 0} />
                                <FormInput
                                    error={error.kitId1}
                                    type="text"
                                    name="kitId1"
                                    value={state.kitId1 || ''}
                                    placeholder="XX-00-0000-0000"
                                    onChange={handleChange}
                                />
                            </Label>
                            <Label error={error.kitId2} className="Heading05Regular">
                                <LabelText>Confirm Kit ID</LabelText>
                                <LeftIcon className="fa-light fa-box" error={error.kitId2 ? 1 : 0} />
                                <FormInput
                                    error={error.kitId2}
                                    type="text"
                                    name="kitId2"
                                    value={state.kitId2 || ''}
                                    placeholder="XX-00-0000-0000"
                                    onChange={handleChange}
                                />
                            </Label>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                {submitting && <Spinner left="28%" />}
                                <PrimaryButton value="Continue" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={() => navigate('/')}>
                                Back to dashboard
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 1 && (
                    <Form onSubmit={handleSubmit}>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <ImageContainer>
                                <Image className="fa-light fa-box-open" />
                            </ImageContainer>
                            <Paragraph className="Heading06Bold">
                                {`Are you registering this ${
                                    (
                                        state.kitId1.length === 15
                                            ? state.kitId1.substring(3, 5) === '01'
                                            : state.kitId1.substring(0, 2) === '01'
                                    )
                                        ? 'COVID-19\nRT-PCR '
                                        : (
                                              state.kitId1.length === 15
                                                  ? state.kitId1.substring(3, 5) === '02'
                                                  : state.kitId1.substring(0, 2) === '02'
                                          )
                                        ? 'COVID-19 Total Antibody '
                                        : (
                                              state.kitId1.length === 15
                                                  ? state.kitId1.substring(3, 5) === '03'
                                                  : state.kitId1.substring(0, 2) === '03'
                                          )
                                        ? 'COVID-19 IgG Antibody '
                                        : ''
                                }test kit as part of your workplace or company requirements?`}
                            </Paragraph>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="Yes, select my company" width="100%" />
                            </SubmitContainer>
                            <SubmitContainer>
                                <PrimaryButton value="No, this is a personal kit" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                Go back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 2 && (
                    <Form onSubmit={handleSubmit}>
                        <FormSection>
                            <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                Select your company
                            </Header>
                        </FormSection>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <Label error={error.company} className="Heading05Regular">
                                <LabelText>Company Name</LabelText>
                                <LeftIcon className="fa-light fa-briefcase" error={error.company ? 1 : 0} />
                                <Select
                                    error={error.company}
                                    name="company"
                                    value={state.company || ''}
                                    onChange={handleChange}>
                                    <option disabled value="">
                                        {' '}
                                        -- select an option --{' '}
                                    </option>
                                    <option value="baylor-student-on-campus">Baylor Student On-Campus</option>
                                    <option value="baylor-student-off-campus">Baylor Student Off-Campus</option>
                                    <option value="baylor-faculty">Baylor Faculty</option>
                                    <option value="baylor-staff">Baylor Staff</option>
                                    <option value="varsity-spirit">Varsity Spirit</option>
                                    <option value="other">Other</option>
                                </Select>
                            </Label>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="Continue" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                Go back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 3 && (
                    <Form onSubmit={handleSubmit}>
                        {covidIds[
                            state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2)
                        ] && (
                            <>
                                <FormSection>
                                    <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                        How are you feeling?
                                    </Header>
                                </FormSection>
                            </>
                        )}
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            {covidIds[
                                state.kitId1.length === 15 ? state.kitId1.substring(3, 5) : state.kitId1.substring(0, 2)
                            ] && (
                                <>
                                    <ImageContainer>
                                        <Image className="fa-light fa-face-thermometer" />
                                    </ImageContainer>
                                    <SurveyQuestion error={error.contact}>
                                        In the past 14 days, have you been in close contact with anyone who tested
                                        positive for COVID-19?
                                        <SymptomInputContainer>
                                            <RadioInput
                                                error={error.contact}
                                                id="yes1"
                                                type="radio"
                                                name="contact"
                                                value="Yes"
                                                checked={state.contact === 'Yes'}
                                                onChange={handleChange}
                                            />
                                            <SymptomOptionLabel htmlFor="yes1">Yes</SymptomOptionLabel>
                                            <RadioInput
                                                error={error.contact}
                                                id="no1"
                                                type="radio"
                                                name="contact"
                                                value="No"
                                                checked={state.contact === 'No'}
                                                onChange={handleChange}
                                            />
                                            <SymptomOptionLabel htmlFor="no1">No</SymptomOptionLabel>
                                        </SymptomInputContainer>
                                    </SurveyQuestion>
                                    <SurveyQuestion error={error.symptoms}>
                                        <p>
                                            Are you currently experiencing <strong>any</strong> symptoms?
                                        </p>
                                        <SymptomInputContainer>
                                            <RadioInput
                                                error={error.symptoms}
                                                id="yes2"
                                                type="radio"
                                                name="symptoms"
                                                value="Yes"
                                                checked={state.symptoms === 'Yes'}
                                                onChange={handleChange}
                                            />
                                            <SymptomOptionLabel htmlFor="yes2">Yes</SymptomOptionLabel>
                                            <RadioInput
                                                error={error.symptoms}
                                                id="no2"
                                                type="radio"
                                                name="symptoms"
                                                value="No"
                                                checked={state.symptoms === 'No'}
                                                onChange={handleChange}
                                            />
                                            <SymptomOptionLabel htmlFor="no2">No</SymptomOptionLabel>
                                        </SymptomInputContainer>
                                    </SurveyQuestion>
                                    {state.company === 'varsity-spirit' && (
                                        <SurveyQuestion error={error.vaccinated}>
                                            Vaccinated?
                                            <SymptomInputContainer>
                                                <RadioInput
                                                    error={error.vaccinated}
                                                    id="yes3"
                                                    type="radio"
                                                    name="vaccinated"
                                                    value="Yes"
                                                    checked={state.vaccinated === 'Yes'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomOptionLabel htmlFor="yes3">Yes</SymptomOptionLabel>
                                                <RadioInput
                                                    error={error.vaccinated}
                                                    id="no3"
                                                    type="radio"
                                                    name="vaccinated"
                                                    value="No"
                                                    checked={state.vaccinated === 'No'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomOptionLabel htmlFor="no3">No</SymptomOptionLabel>
                                            </SymptomInputContainer>
                                        </SurveyQuestion>
                                    )}
                                </>
                            )}
                            {hasMissingInfo && !submitting && (
                                <>
                                    <Header className="Heading05Bold" style={{alignSelf: 'center', marginTop: '16px'}}>
                                        Please confirm the following:
                                    </Header>
                                    {hasNoFirstName && (
                                        <Label error={error.firstName} style={{fontWeight: 'normal'}}>
                                            First Name
                                            <FormInput
                                                error={error.firstName}
                                                type="text"
                                                name="firstName"
                                                value={state.firstName || ''}
                                                placeholder="John"
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasNoLastName && (
                                        <Label error={error.lastName} style={{fontWeight: 'normal'}}>
                                            Last Name
                                            <FormInput
                                                error={error.lastName}
                                                type="text"
                                                name="lastName"
                                                value={state.lastName || ''}
                                                placeholder="Doe"
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasNoBirthday && (
                                        <Label
                                            error={error.birthday}
                                            style={{fontWeight: 'normal'}}
                                            onClick={(e) => e.preventDefault()}>
                                            Date of Birth
                                            <StyledDatePicker
                                                error={error.birthday}
                                                selected={state.birthday}
                                                minDate={new Date('1/1/1870')}
                                                maxDate={new Date()}
                                                placeholderText="MM/DD/YYYY"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dropdownMode="select"
                                                onChange={(date) => {
                                                    setState({...state, birthday: date});
                                                    setError({...error, birthday: false});
                                                }}
                                            />
                                        </Label>
                                    )}
                                    {hasNoAddress && (
                                        <Label error={error.address1} style={{fontWeight: 'normal'}}>
                                            Street Address
                                            <FormInput
                                                error={error.address1}
                                                placeholder="123 Apple Street"
                                                type="text"
                                                name="address1"
                                                value={state?.address1 || ''}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasNoCity && (
                                        <Label error={error.city} style={{fontWeight: 'normal'}}>
                                            City
                                            <FormInput
                                                error={error.city}
                                                placeholder="Dallas"
                                                type="text"
                                                name="city"
                                                value={state?.city || ''}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasNoState && (
                                        <Label error={error.state} style={{fontWeight: 'normal'}}>
                                            State
                                            <Select
                                                error={error.state}
                                                name="state"
                                                value={state?.state || ''}
                                                onChange={handleChange}>
                                                <option disabled value="">
                                                    --
                                                </option>
                                                {States.map((usstate, ids) => (
                                                    <option key={ids} value={usstate.code}>
                                                        {usstate.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Label>
                                    )}
                                    {hasNoZip && (
                                        <Label error={error.zip} style={{fontWeight: 'normal'}}>
                                            ZIP Code
                                            <FormInput
                                                error={error.zip}
                                                placeholder="10001"
                                                type="text"
                                                name="zip"
                                                value={state?.zip || ''}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasNoPhone && (
                                        <Label error={error.phone} style={{fontWeight: 'normal'}}>
                                            Phone
                                            <FormInput
                                                error={error.phone}
                                                placeholder="555-555-5555"
                                                type="tel"
                                                name="phone"
                                                value={state?.phone || ''}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasNoGender && (
                                        <SurveyQuestion error={error.gender}>
                                            Biological Sex
                                            <SymptomInputContainer>
                                                <RadioInput
                                                    error={error.gender}
                                                    id="male"
                                                    type="radio"
                                                    name="gender"
                                                    value="M"
                                                    checked={state.gender === 'M'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomOptionLabel htmlFor="male">Male</SymptomOptionLabel>
                                                <RadioInput
                                                    error={error.contact}
                                                    id="female"
                                                    type="radio"
                                                    name="gender"
                                                    value="F"
                                                    checked={state.gender === 'F'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomOptionLabel htmlFor="female">Female</SymptomOptionLabel>
                                            </SymptomInputContainer>
                                        </SurveyQuestion>
                                    )}
                                    {hasNoEthnicity && (
                                        <Label
                                            error={error.ethnicity}
                                            className="Heading05Regular"
                                            style={{fontWeight: 'normal'}}>
                                            Ethnicity
                                            <Select
                                                error={error.ethnicity}
                                                name="ethnicity"
                                                value={state.ethnicity || ''}
                                                onChange={handleChange}
                                                style={{fontSize: '11px'}}>
                                                <option disabled value="">
                                                    --
                                                </option>
                                                {Ethnicities.map((ethnicity, ide) => (
                                                    <option key={ide} value={ethnicity}>
                                                        {ethnicity}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Label>
                                    )}
                                </>
                            )}
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                {submitting && <Spinner left="28%" />}
                                <PrimaryButton value="Register Kit" width="100%" />
                            </SubmitContainer>
                            <BackButton
                                className="ParagraphNormalRegular"
                                onClick={() => {
                                    setError(initialError);
                                    if (state.company === null) {
                                        if (
                                            covidIds[
                                                state.kitId1.length === 15
                                                    ? state.kitId1.substring(3, 5)
                                                    : state.kitId1.substring(0, 2)
                                            ]
                                        ) {
                                            setStep(1);
                                        } else {
                                            setStep(0);
                                        }
                                    } else {
                                        setStep(2);
                                    }
                                }}>
                                Go back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 4 && (
                    <Form onSubmit={handleSubmit}>
                        <FormSection>
                            <ImageContainer>
                                <Image className="fa-light fa-box-open" />
                            </ImageContainer>
                        </FormSection>
                        <FormSection>
                            <Paragraph className="Heading05Bold">Thank you!</Paragraph>
                            <Paragraph>
                                Your kit has been registered! Next, you'll need to make an appointment with us to have
                                your blood drawn. Where would you like to be tested?
                            </Paragraph>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="At an MLD location" width="100%" />
                            </SubmitContainer>
                            <SubmitContainer>
                                <PrimaryButton value="At my location" width="100%" />
                            </SubmitContainer>
                            <Paragraph className="ParagraphSmallRegular">
                                Our mobile concierge services start at just $99
                            </Paragraph>
                            {/* <BackButton className="ParagraphNormalRegular" onClick={() => setStep(0)}>
                                Go back
                            </BackButton> */}
                        </FormSection>
                    </Form>
                )}

                {step === 5 && (
                    <Form onSubmit={handleSubmit}>
                        <AcuityFormSection position="top">
                            <Header className="Heading05Bold">Schedule Appointment</Header>
                        </AcuityFormSection>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <iframe
                                src={`https://mylabsdirect.as.me/schedule.php?firstName=${
                                    state.firstName || user.userInfo.firstName
                                }&lastName=${state.lastName || user.userInfo.lastName}&phone=${
                                    state.phone || user.userInfo.phone
                                }&email=${state.email || user.userInfo.email}&field:10422557=${
                                    state.birthday
                                        ? new Date(state.birthday).toLocaleDateString()
                                        : new Date(user.userInfo.birthday).toLocaleDateString()
                                }&field:10422558=${
                                    state.gender
                                        ? state.gender === 'M'
                                            ? 'Male'
                                            : 'Female'
                                        : user.userInfo.gender && user.userInfo.gender[0].toUpperCase() === 'M'
                                        ? 'Male'
                                        : 'Female'
                                }&field:10468942=${
                                    state.ethnicity ||
                                    `${user.userInfo.ethnicity[0].toUpperCase()}${user.userInfo.ethnicity
                                        .substring(1)
                                        .toLowerCase()}`
                                }&field:11272312=${idParam ? idParam : state.kitId1}&field:9864758=${
                                    state.address1
                                        ? state.address2
                                            ? state.address1 + `, ${state.address2}`
                                            : state.address1
                                        : user.userInfo.address2
                                        ? user.userInfo.address1 + `, ${user.userInfo.address2}`
                                        : user.userInfo.address1
                                }&field:9864759=${state.city || user.userInfo.city}&field:9864760=${
                                    states[state.state]
                                        ? states[state.state]
                                        : states[user.userInfo.state]
                                        ? states[user.userInfo.state]
                                        : user.userInfo.state
                                        ? user.userInfo.state
                                        : ''
                                }&field:9864761=${state.zip || user.userInfo.zip}&appointmentType=${
                                    state.location === 'mld'
                                        ? '31138680'
                                        : state.location === 'mobile'
                                        ? '31139910'
                                        : ''
                                }`}
                                width="100%"
                                height="650"
                                title="acuity"
                            />
                        </FormSection>
                        <AcuityFormSection position="bottom">
                            {/* <SubmitContainer>
                                {submitting && <SubmitSpinner src={SpinnerIcon} alt="" />}
                                <PrimaryButton value="Submit" width="100%" />
                            </SubmitContainer> */}
                            <BackButton
                                className="ParagraphNormalRegular"
                                style={{backgroundColor: 'var(--neutral-50)'}}
                                onClick={prevStep}>
                                Go back
                            </BackButton>
                        </AcuityFormSection>
                    </Form>
                )}

                {step === 99 && (
                    <Form onSubmit={handleSubmit}>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <ImageContainer>
                                <Image className="fa-light fa-box-open" />
                            </ImageContainer>
                            <Paragraph className="Heading06Bold">
                                {`The following kit: ${state.kitId1} \n ${
                                    (
                                        state.kitId1.length === 15
                                            ? state.kitId1.substring(3, 5) === '04'
                                            : state.kitId1.substring(0, 2) === '04'
                                    )
                                        ? 'COVID-19 RT-PCR\n'
                                        : (
                                              state.kitId1.length === 15
                                                  ? state.kitId1.substring(3, 5) === '05'
                                                  : state.kitId1.substring(0, 2) === '05'
                                          )
                                        ? 'Total Antibody\n'
                                        : (
                                              state.kitId1.length === 15
                                                  ? state.kitId1.substring(3, 5) === '06'
                                                  : state.kitId1.substring(0, 2) === '06'
                                          )
                                        ? 'IgG\n'
                                        : ''
                                }must be activated before registration.\n`}
                            </Paragraph>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="Proceed to Checkout" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={goStepZero}>
                                Go back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 100 && (
                    <Form onSubmit={handleSubmit}>
                        <FormSection>
                            <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                Free Trial Promotion Code
                            </Header>
                        </FormSection>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <ImageContainer>
                                <Image className="fa-light fa-box-open" />
                            </ImageContainer>
                            <Paragraph className="ParagraphNormalRegular">
                                Please enter your Free Trial Promotional Code
                            </Paragraph>
                            <Label error={error.promoCode1} className="Heading05Regular">
                                <LabelText>Promo Code</LabelText>
                                <LeftIcon className="fa-light fa-box" error={error.promoCode1 ? 1 : 0} />
                                <FormInput
                                    error={error.promoCode1}
                                    type="text"
                                    name="promoCode1"
                                    value={state.promoCode1 || ''}
                                    placeholder=""
                                    onChange={handlePromoChange}
                                />
                            </Label>
                            <Label error={error.promoCode2} className="Heading05Regular">
                                <LabelText>Confirm Promo Code</LabelText>
                                <LeftIcon className="fa-light fa-box" error={error.promoCode2 ? 1 : 0} />
                                <FormInput
                                    error={error.promoCode2}
                                    type="text"
                                    name="promoCode2"
                                    value={state.promoCode2 || ''}
                                    placeholder=""
                                    onChange={handlePromoChange}
                                />
                            </Label>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                {submitting && <Spinner left="28%" />}
                                <PrimaryButton value="Continue" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                Go back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 101 && (
                    <Form onSubmit={handleSubmit}>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <FormSection>
                                <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                    Kit Activation Confirmation
                                </Header>
                            </FormSection>
                            <Paragraph className="Heading06Bold">
                                {`Use Free Trial Promotion Code on Kit\n ${state.kitId1}?`}
                            </Paragraph>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="Activate This Kit" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                Go Back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 999 && (
                    <Form onSubmit={handleSubmit}>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <FormSection>
                                <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                    Payment Successful
                                </Header>
                            </FormSection>
                            <Paragraph className="Heading06Bold">
                                {`You have successfully activated kit\n ${state.kitId1}`}
                            </Paragraph>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="Complete Registration" width="100%" />
                            </SubmitContainer>
                            <BackButton className="ParagraphNormalRegular" onClick={simpleRefresh}>
                                Go Back
                            </BackButton>
                        </FormSection>
                    </Form>
                )}

                {step === 1000 && (
                    <Form onSubmit={handleSubmit}>
                        <Error message={error.message} width="calc(100% - 88px)" />
                        <FormSection>
                            <FormSection>
                                <Header className="Heading05Bold" style={{alignSelf: 'center'}}>
                                    Payment Cancelled
                                </Header>
                            </FormSection>
                        </FormSection>
                        <FormSection>
                            <SubmitContainer>
                                <PrimaryButton value="Return to Registration" width="100%" />
                            </SubmitContainer>
                        </FormSection>
                    </Form>
                )}
            </FormContainer>
        </PageContainer>
    );
};

export default Step0;
