import React, {useState} from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import PrimaryButton from '../../components/PrimaryButton';
import settings from '../../settings';
import {useAppSelector} from '../../hooks';
import {
    Form,
    FormSection,
    Error,
    BackButton,
    Header,
    Image,
    ImageContainer,
    Paragraph,
    KitContainer,
    KitIcon,
    KitItem,
    KitText,
    LabelText,
    LeftIcon,
    StripeText,
    SubmitContainer
} from './styles/Step99';

export const Step99: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector(state => state.kit);
    const {user, token} = useAuth();
    const [submitting, setSubmitting] = useState(false);

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: ''
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        const submitter = event.nativeEvent.submitter.value;
        if (submitter === 'Proceed to Checkout') {
            const id = kitInfo.kitId1.substring(0, 2);
            let url = null;

            if (id === '04') {
                url = `${settings.apiURL}/api/session/createCheckoutSessionRTPCRHomeKitActivation`;
            } else if (id === '05') {
                url = `${settings.apiURL}/api/session/createCheckoutSessionTotalAntibodyHomeKitActivation`;
            } else if (id === '06') {
                url = `${settings.apiURL}/api/session/createCheckoutSessionIgGHomeKitActivation`;
            }

            if (url !== null) {
                setSubmitting(true);

                const checkoutParameters = {
                    user_email: user.userInfo.email,
                    kit_id: kitInfo.kitId1
                };

                const checkoutRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                    body: JSON.stringify(checkoutParameters)
                };

                const res = await fetch(url, checkoutRequestOptions);
                setSubmitting(false);
                if (!res.ok) {
                    setError(prevError => ({
                        ...prevError,
                        message: 'Failed to create checkout. Please try again.'
                    }));
                    return;
                }

                const checkoutRes = await res.json();

                if (checkoutRes.checkout_url === null) {
                    setSubmitting(false);
                    setError(prevError => ({
                        ...prevError,
                        message: 'Failed to retrieve checkout. Please try again.'
                    }));
                    return;
                }
                console.log(checkoutRes.checkout_url);
                window.location.href = checkoutRes.checkout_url;
            } else {
                setError(prevError => ({...prevError, message: 'Failed to create checkout. Please try again.'}));
                return;
            }
        }
        return;
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <Header className='Heading05Bold'>Checkout</Header>
            </FormSection>
            <Error className='ParagraphSmallRegular' hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                <ImageContainer>
                    <Image className='fa-light fa-cart-plus' />
                </ImageContainer>
                <Paragraph className='Heading06Bold'>
                    {`The following kit: ${kitInfo.kitId1} \n ${
                        (
                            kitInfo.kitId1.length === 15
                                ? kitInfo.kitId1.substring(3, 5) === '04'
                                : kitInfo.kitId1.substring(0, 2) === '04'
                        )
                            ? 'COVID-19 RT-PCR\n'
                            : (
                                  kitInfo.kitId1.length === 15
                                      ? kitInfo.kitId1.substring(3, 5) === '05'
                                      : kitInfo.kitId1.substring(0, 2) === '05'
                              )
                            ? 'Total Antibody\n'
                            : (
                                  kitInfo.kitId1.length === 15
                                      ? kitInfo.kitId1.substring(3, 5) === '06'
                                      : kitInfo.kitId1.substring(0, 2) === '06'
                              )
                            ? 'IgG\n'
                            : ''
                    }must be activated before registration.\n`}
                </Paragraph>
                <Paragraph className='ParagraphNormalRegular'>
                    Confirm your kit details and continue to checkout
                </Paragraph>
                <KitContainer>
                    <KitText className='ParagraphNormalRegular'>KIT DETAILS</KitText>
                    <KitItem className='ParagraphNormalRegular'>
                        <KitIcon className='fa-light fa-folder-plus fa-fw' error={undefined} />
                        <LabelText>{`Test Type: ${kitInfo.kitName}`}</LabelText>
                    </KitItem>
                    <KitItem className='ParagraphNormalRegular'>
                        <KitIcon className='fa-light fa-box-open fa-fw' error={undefined} />
                        <LabelText>Kit ID: {kitInfo.kitId1}</LabelText>
                    </KitItem>
                </KitContainer>
            </FormSection>
            <FormSection>
                <LeftIcon className='fa-light fa-credit-card' error={undefined}></LeftIcon>
                <StripeText className='ParagraphSmallRegular'>
                    Your payment information will be securely processed by Stripe
                </StripeText>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    {/* {submitting && <Spinner left="30px" />} */}
                    <PrimaryButton value='Proceed to Checkout' width='100%' radius='24px' />
                </SubmitContainer>
                <BackButton
                    className='ParagraphNormalRegular'
                    onClick={() => props.goToNamedStep && props.goToNamedStep('step0')}>
                    Go back
                </BackButton>
            </FormSection>
        </Form>
    );
};
