import React from 'react';
// import ProtectedRoute from '../components/ProtectedRoute';
import {Route, Routes, Navigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import {
    Step0,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
    Step11,
    Step12,
    Step13,
    Step14,
    Done,
    AntibodyStep1,
    AntibodyStep2,
    AntibodyStep3,
    AntibodyStep4,
    AntibodyStep5,
    AntibodyStep6,
    AntibodyStep7,
    AntibodyStep8,
    AntibodyStep9,
    AntibodyStep10,
    AntibodyStepVideo,
} from './Pages';

export default function RouterComponent(props) {
    const {isAuth} = props;

    function RequireAuth({children, redirectTo}) {
        let {isLoggedIn} = useAuth();
        return isLoggedIn ? children : <Navigate to={redirectTo} />;
    }

    return (
        <Routes>
            {/* <ProtectedRoute isAuth={isAuth} path="/registerkit/step/0" exact>
                <Step0 background={backgrounds.registerKitBackground.src} />
            </ProtectedRoute>
            <ProtectedRoute isAuth={isAuth} path="/registerkit/step/0/:chk_id" exact>
                <Step0 background={backgrounds.registerKitBackground.src} />
            </ProtectedRoute>
            <ProtectedRoute isAuth={isAuth} path="/registerkit/antibody/step/0" exact>
                <Step0 background={backgrounds.registerKitBackground.src} />
            </ProtectedRoute> */}

            <Route
                path="step/1"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step1 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/2"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step2 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/3"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step3 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/4"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step4 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/5"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step5 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/6"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step6 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/7"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step7 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/8"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step8 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/9"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step9 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/10"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step10 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/11"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step11 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/12"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step12 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/13"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step13 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/14"
                element={
                    <RequireAuth redirectTo="/login">
                        <Step14 />
                    </RequireAuth>
                }
            />
            <Route
                path="step/15"
                element={
                    <RequireAuth redirectTo="/login">
                        <Done />
                    </RequireAuth>
                }
            />

            <Route
                path="antibody/step/1"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStepVideo />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/2"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep1 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/3"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep2 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/4"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep3 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/5"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep4 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/6"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep5 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/7"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep6 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/8"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep7 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/9"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep8 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/10"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep9 />
                    </RequireAuth>
                }
            />
            <Route
                path="antibody/step/11"
                element={
                    <RequireAuth redirectTo="/login">
                        <AntibodyStep10 />
                    </RequireAuth>
                }
            />
        </Routes>
    );
}
