import React, {useState} from 'react';
import {useAuth} from '../hooks/useAuth';
import HealthHistory from './HealthHistory';
import MetricDescription from './MetricDescription';
import settings from '../settings';
import {useAppSelector} from '../hooks';
import {selectTestsByName} from '../slices/testSlice';
import {selectCurrentPatient} from '../slices/patientSlice';
import axios from 'axios';
import {
    ItemContainer,
    ItemHeader,
    ItemHeaderInfo,
    ItemHeaderName,
    ItemHeaderStatus,
    ItemHeaderUnits,
    ItemNormality,
    TestName,
    PinnedImage,
    ExpandButton,
    ExpandButtonImage,
    ExpandedContainer,
    HealthHistoryContainer,
    MiddleData,
    MiddleDataField,
    MiddleDataFields,
    FinalMiddleDataField,
    PinToggle,
    PinCheckbox,
    ReportLink,
} from './styles/HealthSummaryItem';

interface Props {
    isGrouped: boolean;
    result: any;
    setPinnedMetrics: Function;
    isLabResult?: boolean;
}

const HealthSummaryItem: React.FC<Props> = ({isGrouped, result, setPinnedMetrics, isLabResult}) => {
    const existingPinnedMetrics = localStorage.getItem('pinnedMetrics');
    const newPinnedMetrics = existingPinnedMetrics ? JSON.parse(existingPinnedMetrics) : [];
    const {token} = useAuth();

    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector((state) => selectTestsByName(state, currentPatient.fullName));
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    // const currentPatientData = JSON.parse(localStorage.getItem('tests')!!).filter(
    //     (test: any) =>
    //         currentPatient.fullName &&
    //         currentPatient.fullName.toLowerCase() === `${test.firstName} ${test.lastName}`.toLowerCase()
    // );

    const fullName = currentPatient.fullName ? currentPatient.fullName : '';
    const healthHistory = currentPatientData.filter(
        (test: any) => test.oid.substring(test.oid.length - 10, test.oid.length - 8) === 'BW'
    );

    let isPinned = false;

    for (let i = 0; i < newPinnedMetrics.length; i++) {
        if (
            newPinnedMetrics[i].metric === result.test_name &&
            fullName.toLowerCase() === `${newPinnedMetrics[i].firstName} ${newPinnedMetrics[i].lastName}`.toLowerCase()
        ) {
            isPinned = true;
            break;
        }
    }

    const [pinned, setPinned] = useState(isPinned);
    const [expanded, setExpanded] = useState(false);
    let index = 0;
    const toggleId = `toggle-${result.test_name ? result.test_name.split(' ').join('-') : index++}`;
    const testDisplayName =
        result.test_name && result.test_name.includes('; ') ? result.test_name.split('; ')[1] : result.test_name;
    let foundLatest = false;
    let order = {
        id: 0,
    };

    if (!isGrouped) {
        for (let i = 0; i < healthHistory.length; i++) {
            for (let j = 0; j < healthHistory[i].results.length; j++) {
                if (healthHistory[i].results[j].test_name === result.test_name) {
                    order = healthHistory[i];
                    foundLatest = true;
                }
            }
            if (foundLatest) {
                break;
            }
        }
    }

    // Set the pinned status of this metric across the app
    const handleChange = async () => {
        if (pinned) {
            for (let i = 0; i < newPinnedMetrics.length; i++) {
                if (
                    newPinnedMetrics[i].metric === result.test_name &&
                    fullName === `${newPinnedMetrics[i].firstName} ${newPinnedMetrics[i].lastName}`
                ) {
                    newPinnedMetrics.splice(i, 1);
                    break;
                }
            }
        } else {
            newPinnedMetrics.push({
                metric: result.test_name,
                firstName: currentPatient.firstName,
                lastName: currentPatient.lastName,
            });
        }

        setPinned(!pinned);
        setPinnedMetrics(newPinnedMetrics);
        localStorage.setItem('pinnedMetrics', JSON.stringify(newPinnedMetrics));

        const pinOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: JSON.stringify({
                metric: result.test_name,
                status: !pinned ? 1 : 0,
                firstName: currentPatient.firstName,
                lastName: currentPatient.lastName,
            }),
        };

        const pinRes = await axios(`${settings.apiURL}/api/pin`, pinOptions);
        if (pinRes.status !== 200) {
            console.log('Failed to update pin');
        }
    };

    return (
        <ItemContainer isGrouped={isGrouped ? 1 : 0} isActive={expanded ? 1 : 0}>
            <ItemHeader onClick={() => setExpanded(!expanded)}>
                <ItemHeaderInfo>
                    <ItemHeaderName>
                        <TestName className="ParagraphNormalRegular" isActive={expanded ? 1 : 0}>
                            {testDisplayName}
                        </TestName>
                        {pinned && <PinnedImage className="fa-light fa-house-user" />}
                    </ItemHeaderName>
                    <ItemHeaderUnits className="ParagraphNormalRegular">
                        {`${result.result}${result.units ? ` ${result.units}` : ''}`}
                    </ItemHeaderUnits>
                </ItemHeaderInfo>
                <ItemHeaderStatus>
                    <ItemNormality normality={result.abnormal}>{result.abnormal || 'Normal'}</ItemNormality>
                    <ExpandButton onClick={() => setExpanded(!expanded)}>
                        <ExpandButtonImage
                            className={expanded ? 'fa-light fa-circle-chevron-up' : 'fa-light fa-circle-chevron-down'}
                        />
                    </ExpandButton>
                </ItemHeaderStatus>
            </ItemHeader>
            <ExpandedContainer isActive={expanded ? 1 : 0}>
                <HealthHistoryContainer>
                    <HealthHistory metric={result.test_name} isLabResult={isLabResult} metricdate={result.statusDate} />
                </HealthHistoryContainer>
                {!isGrouped && (
                    <MiddleData>
                        <MiddleDataFields>
                            <MiddleDataField className="ParagraphNormalRegular">
                                {`Latest Result: ${result.result}${result.units ? ` ${result.units}` : ''}`}
                            </MiddleDataField>
                            {result.panel_name && (
                                <MiddleDataField className="ParagraphNormalRegular">{`Source: ${result.panel_name}`}</MiddleDataField>
                            )}
                            <FinalMiddleDataField>
                                <MiddleDataField className="ParagraphNormalRegular">{`Result Date: ${new Date(
                                    result.statusDate
                                ).toLocaleDateString('en-US', {
                                    timeZone: 'UTC',
                                })}`}</MiddleDataField>
                                <ReportLink to={`/result/${order.id}`}>View Report</ReportLink>
                            </FinalMiddleDataField>
                        </MiddleDataFields>
                        <PinToggle htmlFor={toggleId}>
                            <PinCheckbox id={toggleId} type="checkbox" checked={pinned} onChange={handleChange} />
                            <MiddleDataField className="ParagraphNormalRegular">{`Pin ${testDisplayName} to your dashboard`}</MiddleDataField>
                        </PinToggle>
                    </MiddleData>
                )}
                {result.notes && (
                    <div>
                        <i className="fa-light fa-note-sticky fa-lg" style={{marginRight: '5px'}} />
                        {result.notes}
                    </div>
                )}

                <MetricDescription test={result.test_name} testDisplayName={testDisplayName} />
            </ExpandedContainer>
        </ItemContainer>
    );
};

export default HealthSummaryItem;
