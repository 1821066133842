import React, {useState} from 'react';
import FastFacts from './FastFacts';
import {getCategory, getNameString} from '../utils/Test';
import {
    CardContainer,
    Header,
    HeaderText,
    Footer,
    Heading,
    InfoMenu,
    Image,
    Back,
    InfoMenuItem,
    Chevron,
    FooterButton,
    FooterButtonContainer,
} from './styles/AboutTestCard';
interface Props {
    setAboutTestVisible: Function;
    test: any;
}

const AboutTestCard: React.FC<Props> = ({setAboutTestVisible, test}) => {
    const [currentView, setCurrentView] = useState('menu');

    const testName = test.results[0].test_name;
    const testType = test.oid.substring(test.oid.length - 10, test.oid.length - 8);

    //tests that have a second menu item
    const hasExtraInfo = getCategory(test) === 'covid' || getCategory(test) === 'allergy';

    return (
        <CardContainer>
            <Header isDetailed={currentView !== 'menu'}>
                {currentView !== 'menu' && (
                    <Back className="fa-light fa-circle-arrow-left" onClick={() => setCurrentView('menu')} />
                )}
                {currentView === 'menu' ? (
                    <Image className="fa-light fa-circle-info" />
                ) : currentView === 'summary' ? (
                    <HeaderText>Summary</HeaderText>
                ) : currentView === 'details' ? (
                    <HeaderText>
                        {testName === 'Covid-19 RT-PCR Test'
                            ? 'What is real-time PCR?'
                            : testName === 'Covid-19 Total Antibody Test' || testName === 'Covid-19 Antibody IgG Test'
                            ? 'Antibody fast facts'
                            : testName === 'COVID-19 Rapid RT-PCR'
                            ? 'How does this rapid test differ from other tests?'
                            : testName === 'Rapid COVID-19 Antigen'
                            ? 'How does this rapid test differ from other tests?'
                            : testType === 'AL'
                            ? 'What are component tests?'
                            : testType === 'ST'
                            ? ''
                            : ''}
                    </HeaderText>
                ) : (
                    ''
                )}
            </Header>
            <Footer>
                {currentView === 'menu' && (
                    <>
                        <Heading className="Heading05Bold">About This Test</Heading>
                        <InfoMenu>
                            <InfoMenuItem onClick={() => setCurrentView('summary')}>
                                <p>Summary</p>
                                <Chevron className="fa-light fa-chevron-right" />
                            </InfoMenuItem>
                            {hasExtraInfo && (
                                <InfoMenuItem onClick={() => setCurrentView('details')}>
                                    <p>
                                        {testName === 'Covid-19 RT-PCR Test'
                                            ? 'What is real-time PCR?'
                                            : testName === 'Covid-19 Total Antibody Test' ||
                                              testName === 'Covid-19 Antibody IgG Test'
                                            ? 'What are antibodies?'
                                            : testName === 'COVID-19 Rapid RT-PCR'
                                            ? 'How does this rapid test differ from other tests?'
                                            : testName === 'Rapid COVID-19 Antigen'
                                            ? 'How does this rapid test differ from other tests?'
                                            : testType === 'AL'
                                            ? 'What are component tests?'
                                            : testType === 'ST'
                                            ? ''
                                            : ''}
                                    </p>
                                    <Chevron className="fa-light fa-chevron-right" />
                                </InfoMenuItem>
                            )}
                        </InfoMenu>
                    </>
                )}

                {currentView === 'summary' && (
                    <>
                        {testName === 'Covid-19 RT-PCR Test' ? (
                            <p>
                                Developed by ThermoFisher Scientific and Applied BioSystems, the FDA-EUA TaqPath RT-PCR
                                Covid-19 assay offers 100% specificity of complete genomes for SARS-CoV-2 through viral
                                RNA extraction and genetic analysis using real-time PCR (polymerase chain reaction).
                            </p>
                        ) : testName === 'Covid-19 Total Antibody Test' ? (
                            <>
                                <p>
                                    This test received a CE-Mark by the FDA for having both 100% sensitivity and
                                    specificity for the detection of Covid-19 IgM, IgA and IgG antibodies.
                                </p>
                                <p>
                                    Developed by Ortho-Clinical Diagnostics, this total antibody test indicates if you
                                    are positive or negative for total antibodies. A positive reaction indicates that
                                    you had or currently have COVID-19 and developed an antibody response to the virus.
                                    A negative reaction indicates that you have either not been infected or are in the
                                    very early stages of infection before antibodies or symptoms have developed. This
                                    test will also detect antibodies that have developed from a Covid-19 vaccination.
                                </p>
                                <p>
                                    While this test cannot tell you the specific antibody you may be positive for (IgM,
                                    IgA or IgG), the benefit of a total antibody test is that it can detect antibodies,
                                    such as IgM, that develop earlier in the infection process before symptoms of
                                    Covid-19 may have developed.
                                </p>
                            </>
                        ) : testName === 'Covid-19 Antibody IgG Test' ? (
                            <>
                                <p>
                                    Manufactured by Ortho Clinical Diagnostics, this was the first quantitative COVID-19
                                    IgG antibody test to receive FDA Authorization. This test targets the S1 spike
                                    protein of SARS-CoV-2 IgG antibodies and was calibrated to the World Health
                                    Organization’s International Standards. With 100% specificity, this test is designed
                                    to give clinicians and public health leaders a standard tool to measure antibody
                                    response to SARS-CoV-2. The My Labs Direct COVID-19 IgG quantitative antibody report
                                    presents a reactive (positive) or non-reactive (negative) analysis for the presence
                                    of antibodies found in a specific patient sample. The report also presents the
                                    manufacturer’s reference range data for the assay. The reference range for this
                                    assay is 17.8 to 200 Units per mL. A patient sample with a reported value that falls
                                    between 17.8 and 200 or is greater than 200 U/mL is determined reactive (positive)
                                    for the detection of the S1 spike protein of SARS-CoV-2 IgG antibodies. A patient
                                    sample with a reported value below 17.8 is determined non-reactive (negative) for
                                    the detection of the S1 spike protein of SARS-CoV-2 IgG antibodies.
                                </p>
                            </>
                        ) : testName === 'COVID-19 Rapid RT-PCR' ? (
                            <>
                                <p>
                                    RT-PCR testing for SARS-CoV-2 is the gold-standard for detecting the presence of
                                    viral RNA in a specimen. This extremely sensitive and accurate method of detection
                                    tells us if someone has any trace of the virus in their system during the
                                    pre-symptomatic phase (right after exposure) and during the acute infection period
                                    and well after symptoms have subsided.
                                </p>
                                <p>
                                    This test has been authorized by the FDA under an Emergency Use Authorization (EUA)
                                    for use by authorized laboratories for the detection of SARS-CoV-2.
                                </p>
                            </>
                        ) : testName === 'Rapid COVID-19 Antigen' ? (
                            <>
                                <p>
                                    The SARS Antigen Test detects SARS-CoV-2 antigens directly from gentle nasal swab
                                    specimens. This test provides accurate and reliable results in 10 minutes, allowing
                                    for COVID-19 testing of symptomatic patients within the first five days of symptoms
                                    and asymptomatic patients when tested serially.
                                </p>
                                <p>
                                    This test has been authorized by the FDA under an Emergency Use Authorization (EUA)
                                    for use by authorized laboratories for the detection of proteins from SARS-CoV-2,
                                    not for any other viruses or pathogens.
                                </p>
                            </>
                        ) : testType === 'AL' ? (
                            <>
                                <p>
                                    A blood test, also called a specific IgE test, is a powerful allergy diagnostic tool
                                    that measures the concentration of specific IgE antibodies in the blood. It can test
                                    for hundreds of allergic triggers, such as pollen, mold, food, and animal dander. 
                                </p>
                                <p>
                                    Any detectable level of specific IgE indicates a sensitization to a specific
                                    allergen. Even a low level of sensitization may be significant if it is associated
                                    with symptoms when you are exposed to the specific allergen. The reverse may also be
                                    true. You may be sensitized to an allergen at any level. But if you don’t have
                                    symptoms when exposed to the allergen, you are not clinically allergic. The results
                                    of an allergy blood test, together with a detailed medical history and a physical
                                    examination, will help a healthcare provider develop a customized treatment plan for
                                    allergic symptoms.
                                </p>
                            </>
                        ) : testType === 'ST' ? (
                            <p>
                                For real-time PCR (RT-PCR) detection of bacterial and parasitic pathogens associated
                                with sexually transmitted infections and lesions. The STI Panels contain the assays and
                                controls for a real-time polymerase chain reaction (PCR) test intended for the
                                qualitative detection of nucleic acids from specific infectious agents in urine
                                specimens from individuals suspected of sexually transmitted infections.
                            </p>
                        ) : (
                            <p>{''}</p>
                        )}
                    </>
                )}

                {currentView === 'details' && (
                    <>
                        {testName === 'Covid-19 RT-PCR Test' ? (
                            <p>
                                RT-PCR is a laboratory technique used in molecular biology to detect the presence of DNA
                                or RNA of a specific virus or pathogen. In the case of Covid-19, we use this method to
                                detect the RNA of three viral proteins that are specific to the virus. This is
                                accomplished through a multi-step process that first involves adding chemicals to your
                                sample. These chemicals are designed to bind to targeted areas that are specific to the
                                RNA of the Covid-19 virus. Your sample is then placed on an RT-PCR laboratory instrument
                                that is designed to heat and cool your specimen for several hours. This heating and
                                cooling process creates a chemical reaction that makes millions of copies of your sample
                                so that the virus can be detected if it is present.
                            </p>
                        ) : testName === 'Covid-19 Total Antibody Test' || testName === 'Covid-19 Antibody IgG Test' ? (
                            <FastFacts />
                        ) : testName === 'COVID-19 Rapid RT-PCR' ? (
                            <p>“Rapid” PCR tests are two-times less sensitive than "Lab-processed" RT-PCR tests.</p>
                        ) : testName === 'Rapid COVID-19 Antigen' ? (
                            <p>
                                Rapid antigen tests are less sensitive than PCR tests. This means that a sample must
                                contain a high viral load before it is detectable through a rapid test. For this reason,
                                rapid tests are most accurate in highly contagious, symptomatic patients.
                            </p>
                        ) : testType === 'AL' ? (
                            <p>
                                Allergen component tests can help pinpoint the allergenic proteins that may be causing
                                symptoms. Specific IgE testing for allergen components helps to identify the speciﬁc
                                proteins that may cause reactions. So instead of just knowing that an individual is
                                sensitized to peanuts, for example, a blood test can help pinpoint exactly which protein
                                within the peanut may trigger a reaction. And knowing the speciﬁc protein can help a
                                healthcare provider determine if there may be a risk for a more severe systemic reaction
                                or a mild, localized reaction.
                            </p>
                        ) : testType === 'ST' ? (
                            <p></p>
                        ) : (
                            <p>''</p>
                        )}
                    </>
                )}
            </Footer>
            <FooterButtonContainer>
                <FooterButton
                    className="ParagraphLargeRegular"
                    id="context-menu-close"
                    onClick={() => setAboutTestVisible(false)}>
                    Close
                </FooterButton>
            </FooterButtonContainer>
        </CardContainer>
    );
};

export default AboutTestCard;
