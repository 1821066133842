import React from 'react';
import {Layout} from '../Components';
import SampleCollection from '../Resources/img/Step8.png';

const Step9 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={SampleCollection} alt="Insert Sample" />
            </div>

            <h6 className="required-record">
                this step <strong>must</strong> be recorded{' '}
                <span className="record-icon">
                    <span></span>
                </span>
            </h6>

            <h2 className="antibody-step-number">Step 7</h2>

            <h3 className="antibody-step-description">Place the Swab in Sample Tube</h3>

            <p className="has-text-centered">
                Insert swab sample (cotton tip down) into the sample tube. Do not spill liquid. Cotton swab should be
                placed inside the liquid. Snap the swab stick so that it fits inside of the sample tube (refer to video
                for clarification).
            </p>

            <p className="has-text-centered">Discard broken piece.</p>

            <p className="has-text-centered">Tightly seal cap.</p>
        </section>
    </Layout>
);
export default Step9;
