import React from 'react';
import {
    MetricInfoContainer,
    MetricInfoHeader,
    MetricInfoImage,
    MetricInfoText,
    MetricList,
    TestName,
} from './styles/MetricDescription';

interface Props {
    test: string;
    testDisplayName: string;
}

const MetricDescription: React.FC<Props> = ({test, testDisplayName}) => {
    const name = test.toLowerCase().trim();

    return (
        <>
            {name === 'magnesium' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Magnesium is a mineral stored in your bones, tissues and cells that is important in producing
                        energy, muscle and nerve function, heart health and bone strength. You absorb magnesium into
                        your system through your small intestines when you eat foods such as spinach, whole grains,
                        nuts, beans, bananas, milk, and salmon.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Magnesium Level</strong> - Low magnesium levels can indicate inefficient mineral
                        absorption often caused by excessive alcohol consumption, underlying kidney problems, certain
                        medications, celiac disease or chronic digestive issues. Over time, magnesium deficiency can
                        lead to decreased calcium and potassium levels, weak bones and negative effects on your heart.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of long-term magnesium deficiency can include:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Nausea and vomiting</li>
                        <li>Sleepiness</li>
                        <li>Headaches</li>
                        <li>Weakness and fatigue</li>
                        <li>Loss of appetite</li>
                        <li>Muscle spasms</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>High Magnesium Level</strong> - While not as common as low levels, high magnesium levels
                        are typical in those with kidney damage or those who take certain drugs. Excessive levels of
                        magnesium can cause serious complications, including cardiac arrest.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'folate' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Folate (vitamin B-9) is important in red blood cell formation for healthy cell growth and
                        function. This nutrient is crucial during early pregnancy to reduce the risk of birth defects of
                        the brain and spine. There is evidence folate can reduce the risk of various cancers and is
                        helpful in treating depression.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Folate Levels</strong> - While excess folate levels aren't usually dangerous, they
                        are linked to some conditions, including certain types of cancers.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low folate levels</strong> could be caused by the following: high alcohol consumption,
                        diet low in folate, genetic factors and certain medications.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of low folate:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fatigue</li>
                        <li>Gray hair</li>
                        <li>Mouth sores</li>
                        <li>Tongue swelling</li>
                        <li>Growth problems</li>
                    </MetricList>
                    <MetricInfoText>
                        For most, a balanced diet usually provides all the folate you need. Folate is found in dark
                        green leafy vegetables, beans, peas, nuts, oranges, lemons, bananas, melons, and strawberries.
                        The synthetic form of folate is folic acid and is in many fortified foods, such as cereals and
                        pastas.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'ferritin' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Ferritin is a blood protein that stores iron. Testing your ferritin levels can indicate if your
                        body's iron levels are high or low.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High ferritin Levels</strong> indicate that your body is storing too much iron. This
                        could point to liver disease, rheumatoid arthritis, and other inflammatory conditions or
                        hyperthyroidism. Some types of cancer also cause your blood ferritin level to be high.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low ferritin levels</strong> in your blood indicate your body's iron stores are low and
                        you have iron deficiency which could mean you are anemic.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Anemia Symptoms can include:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weakness</li>
                        <li>Dizziness</li>
                        <li>Pale skin</li>
                        <li>Headache</li>
                        <li>Numbness or coldness in hands and feet</li>
                        <li>Shortness of breath on exertion</li>
                        <li>Low body temperature</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'uric acid' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Uric acid is a normal body waste product formed when chemicals called purines break down.
                        Purines are a natural substance found in the body. When purines are broken down to uric acid in
                        the blood, the body rids it through urination or bowel movements.
                    </MetricInfoText>
                    <MetricInfoText>
                        If your body makes too much uric acid, it can build up in the blood. Uric acid levels can
                        increase when you eat too many high-purine foods such as liver, shellfish and alcohol or if you
                        take medicines like diuretics, aspirin, and niacin. Crystals of uric acid can form and collect
                        in the joints, causing painful inflammation such as gout and can also lead to kidney stones.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of uric acid symptoms could include:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Severe pain in your joints</li>
                        <li>Joint stiffness</li>
                        <li>Difficulty moving affected joints</li>
                        <li>Redness and swelling</li>
                        <li>Misshapen joints</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'vitamin b12' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Vitamin B-12 (cobalamin) is an essential vitamin and plays an important role in blood cell
                        formation, cell metabolism, nerve function and the production of DNA. It is found in foods such
                        as meat, fish, dairy, and fortified foods such as breakfast cereal and is widely available as an
                        oral supplement.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Vitamin B12 Levels</strong> - Abnormally high B12 levels may suggest liver or
                        kidney problems, diabetes, or certain forms of leukemia. Megadoses of the vitamin can lead to
                        acne and rosacea.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Vitamin B12 Levels </strong> - While vitamin B-12 deficiency is not common in the
                        US, people who follow a vegetarian or vegan diet might be prone to this deficiency because plant
                        foods don't contain vitamin B-12. Older adults and people with digestive tract conditions that
                        affect absorption of nutrients may also be susceptible to vitamin B-12 deficiency.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of low B12 levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Anemia</li>
                        <li>Fatigue</li>
                        <li>Muscle weakness</li>
                        <li>Intestinal problems</li>
                        <li>Nerve damage</li>
                        <li>Mood disturbances</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'vitamin d (oh-25)' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Vitamin D is an essential vitamin that helps regulate calcium and phosphorus in the body and is
                        needed for building and maintaining healthy bones. Your body can only absorb calcium, the
                        primary component of bone, when vitamin D is present. It is known as the “sunshine vitamin”
                        because it can be made in the skin with exposure to UV light.
                    </MetricInfoText>
                    <MetricInfoText>
                        Vitamin D also regulates many other cellular functions in your body. Its anti-inflammatory,
                        antioxidant and neuroprotective properties support immune health, muscle function and brain cell
                        activity.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Vitamin D Levels</strong> - While rare, high levels of vitamin D are usually caused
                        by large doses of vitamin D supplements - not by diet or sun exposure. The buildup of vitamin D
                        in your blood can cause nausea, vomiting, weakness and frequent urination.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Vitamin D Levels </strong> - Deficiencies in vitamin D leads to low calcium
                        absorption which causes weakened bones that become soft and brittle and prone to fractures as
                        well as osteoporosis - a loss of total bone mass. Deficiency in vitamin D may contribute to the
                        development of cancer, cardiovascular disease, type 2 diabetes, and autoimmune disorders.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of low vitamin D:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fatigue</li>
                        <li>Bone pain</li>
                        <li>Muscle weakness, muscle aches or muscle cramps</li>
                        <li>Mood changes like depression</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'cholesterol' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Total cholesterol is the total amount of cholesterol in your blood. It includes low-density
                        lipoprotein (LDL, or “bad”) cholesterol and high-density lipoprotein (HDL, or “good”)
                        cholesterol. Cholesterol is a waxy, fat-like substance found in every cell in your body.
                    </MetricInfoText>
                    <MetricInfoText>
                        Your total cholesterol level reflects your risk for heart disease. The higher the level, the
                        higher your risk. If your total cholesterol is too high, your healthcare provider may recommend
                        lifestyle changes and/or medications to lower it.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong> By itself, high blood cholesterol doesn't cause symptoms, which is
                        why it's important to have your cholesterol tested.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'ldl' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Low-density-lipoprotein (LDL) Is a lipoprotein that transports cholesterol to the cells. High
                        levels of LDLs in the blood have been associated with an increased risk for heart disease. For
                        this reason, they are referred to as “bad cholesterol”. LDL cholesterol needs to be kept at the
                        lowest level you and your healthcare provider can achieve. LDL cholesterol goes up if your diet
                        is high in saturated and “trans” fat.
                    </MetricInfoText>
                    <MetricInfoText>
                        Your total cholesterol level reflects your risk for heart disease. The higher the level, the
                        higher your risk. If your total cholesterol is too high, your healthcare provider may recommend
                        lifestyle changes and/or medications to lower it.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High LDL Levels</strong> - Increased risk for heart disease. High LDL is primarily
                        caused by poor diet, obesity, lack of exercise, smoking, alcohol and age.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low LDL Levels</strong> - Decreased risk of heart disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong> High LDL has no symptoms, which is why it's important to have your
                        LDL levels checked.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'direct hdl' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        High-density lipoprotein (HDL) is a lipoprotein that picks up cholesterol from cells and
                        transports it to the liver so that it can be eliminated from the body. It essentially clears
                        fats from your blood stream.
                    </MetricInfoText>
                    <MetricInfoText>
                        HDL cholesterol is called the “good cholesterol” because high levels of HDL in the blood are
                        associated with a reduction in the risk of heart disease. The higher the HDL the better.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High HDL Levels</strong> - Reduced risk of heart disease. Physical activity, losing
                        extra weight, quitting smoking, using alcohol in moderation and choosing better fats all help
                        increase your HDL levels.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low HDL Levels</strong> - Associated with an increased risk of cardiovascular disease.
                        Type 2 diabetes, being overweight, not getting enough exercise, smoking, unhealthy diet,
                        metabolic syndrome, and genetic factors play a role in low HDL levels.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong> Low HDL levels rarely present symptoms until after there's been a
                        medical emergency, which is why it's important to have your HDL levels checked.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'cholesterol/hdl ratio' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Cholesterol/HDL Ratio</strong>
                    </MetricInfoText>
                    <MetricInfoText>This is the ratio of cholesterol to direct HDL.</MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'triglycerides' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Triglycerides are the main types of lipid (fat) in food and the body. The structure of
                        triglycerides includes lipid molecules called fatty acids. Fatty acids are what we are really
                        talking about when we refer to trans fat or saturated fat. You have a higher risk of high
                        triglycerides if you have diabetes, chronic kidney disease, metabolic syndrome, inflammatory
                        diseases, obesity or are overweight, consume excess: alcohol, saturated fats, and sugars, or if
                        you maintain a sedentary lifestyle, or have a family history of high triglycerides.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Triglyceride Levels</strong> - High concentrations of triglycerides in the blood
                        may contribute to hardening of the arteries or thickening of the artery walls which increases
                        the risk of stroke, heart attack and heart disease. Extremely high triglycerides can also cause
                        acute inflammation of the pancreas (pancreatitis).
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high triglycerides:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>
                            In rare cases, some people may develop inflammation of the pancreas which can cause sudden
                            severe abdominal pain, loss of appetite, nausea, vomiting and fever.
                        </li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low Triglyceride Levels</strong> - In general, having low triglyceride levels is not a
                        problem. If your levels are less than 150mg/dL, you have a lower risk of cardiovascular diseases
                        such as heart attack or stroke.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'vldl-c' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        VLDL-C stands for very-low-density lipoprotein cholesterol. Your liver makes VLDL and releases
                        it into your bloodstream. The VLDL particles mainly carry triglycerides (a type of fat) to your
                        tissues. It's similar to LDL cholesterol, but LDL mainly carries cholesterol to your tissues
                        whereas VLDL-C carries triglycerides to your tissues. You can lower your VLDL level by losing
                        weight, dieting, and exercising. It's important to switch to healthy fats and cut back on sugar
                        and alcohol. Some people may need to take medication.
                    </MetricInfoText>
                    <MetricInfoText>
                        VLDL is another “bad cholesterol” because it contributes to the buildup of plaque in your
                        arteries. Over time the plaque can harden and narrow your arteries and contribute to a type of
                        blood vessel disease called atherosclerosis, which reduces blood flow.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High VLDL-C Levels</strong> - Increased risk for heart disease and stroke. High VLDL is
                        primarily caused by poor diet, obesity, lack of exercise, smoking, alcohol and age.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low VLDL-C Levels</strong> - Decreased risk of heart disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high VLDL-C:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Tired</li>
                        <li>Shortness of breath</li>
                        <li>Possible chest pain</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'homocysteine' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Homocysteine is an amino acid produced when proteins are broken down. Normally, vitamin B12,
                        vitamin B6 and folic acid break down homocysteine and change it into other substances your body
                        needs. After this break down, there should be very little homocysteine left in the bloodstream.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Homocysteine Levels</strong> - Also called hyperhomocysteinemia, can contribute to
                        arterial damage and blood clots in your blood vessels. High homocysteine levels usually indicate
                        a deficiency in vitamin B-12 or folate.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high homocysteine levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Pale skin</li>
                        <li>Weakness</li>
                        <li>Fatigue</li>
                        <li>Tingling sensations in hands, arms, legs, or feet</li>
                        <li>Dizziness</li>
                        <li>Mouth sores</li>
                        <li>Mood changes</li>
                    </MetricList>
                    <MetricInfoText>
                        Increasing folate, vitamin B6 or B12 in your diet can help lower homocysteine levels. Foods such
                        as beans, leafy green vegetables, citrus fruits, beets, and wheat germ which are high in folic
                        acid, beta-carotene and vitamin C, effectively lower homocysteine levels.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'cortisol' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Cortisol is produced by the adrenal glands and is the primary stress hormone. It enhances your
                        brain's use of glucose (sugar) and increases the availably of substances that repair tissues.
                        Its release is increased in response to stress and low blood-glucose levels. It helps regulate
                        metabolism, affects blood pressure, and serves as an anti- inflammatory.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Cortisol Levels</strong> - High cortisol levels can be caused by many underlying
                        issues such as overactivity or cancer of the pituitary or adrenal glands, chronic stress and
                        medication side effects. Estrogen can also increase cortisol levels in your blood.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Cortisol Levels</strong> - Low cortisol levels mean the adrenal glands aren't making
                        enough cortisol. This could be caused by Addison's disease which could be caused by cancerous
                        cells, removal of the adrenal glands, certain genetic disorders, and certain antifungal
                        medications.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high cortisol:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weight gain around midsection and upper back</li>
                        <li>Weight gain and rounding of the face</li>
                        <li>Acne</li>
                        <li>Thinning skin</li>
                        <li>Easy bruising</li>
                        <li>Flushed face</li>
                        <li>Slowed healing</li>
                        <li>Muscle weakness</li>
                        <li>Severe fatigue</li>
                        <li>Irritability</li>
                        <li>Difficulty concentrating</li>
                        <li>High blood pressure</li>
                        <li>Headache</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low cortisol:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Extreme fatigue</li>
                        <li>Weight loss</li>
                        <li>Dehydration</li>
                        <li>Decreased appetite</li>
                        <li>Low blood pressure</li>
                        <li>Depression</li>
                        <li>Irritability</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'fsh' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        FSH stands for follicle stimulating hormone. This hormone is secreted by the anterior pituitary
                        gland which helps control a woman's menstrual cycle and egg production and a man's sperm
                        production. Too much or too little FSH can cause a variety of problems in men and women.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of FSH in men</strong> can be caused by damage due to chemotherapy,
                        radiation, infection, or alcohol abuse.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low levels of FSH in men</strong> is commonly caused by use of testosterone or steroids.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of FSH in women</strong> can be caused by menopause and polycystic ovarian
                        syndrome in which a women's hormones are out of balance.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low levels of FSH in women</strong> is commonly caused by being very underweight or
                        having had recent rapid weight loss.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high FSH in men:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Headaches</li>
                        <li>Visual impairment</li>
                        <li>Hormonal deficiencies</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of high FSH in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Irregular period</li>
                        <li>Hot flashes</li>
                        <li>Disrupted sleep</li>
                        <li>Skin and hair changes</li>
                        <li>Difficulty getting pregnant</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low FSH in men:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Loss of body and facial hair</li>
                        <li>Weakness</li>
                        <li>Lack of interest in sexual activity</li>
                        <li>Erectile dysfunction</li>
                        <li>Infertility</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low FSH in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Irregular or stopped menstrual cycle</li>
                        <li>Infertility</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'lh' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Luteinizing hormone is produced and released in the anterior pituitary gland. This hormone plays
                        a significant role in controlling the production of estrogen in the ovaries in females and
                        testosterone in the testes in males. LH plays an important role in sexual development and
                        functioning. Too much or too little LH can cause a variety of problems including delayed puberty
                        in children, infertility, menstrual difficulties in women, and low sex drive in men.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of LH in men</strong> can be caused by damage due to chemotherapy,
                        radiation, infection, or alcohol abuse. It may be an indicator of testicular failure and
                        infertility.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low levels of LH in men</strong> could mean you have a disorder of the pituitary gland
                        or the hypothalamus.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of LH in women</strong> can be caused by menopause and polycystic ovarian
                        syndrome in which a woman's hormones are out of balance.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low levels of LH in women</strong> are commonly caused by being very underweight,
                        malnutrition, recent rapid weight loss, or the pituitary gland is not working correctly.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high LH in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Infertility</li>
                        <li>Unwanted hair</li>
                        <li>Loss of scalp hair</li>
                        <li>No period</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low LH in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Irregular or stopped menstrual periods</li>
                        <li>Infertility</li>
                        <li>Hot flashes</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low LH in men:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Loss of body and facial hair</li>
                        <li>Weakness</li>
                        <li>Lack of interest in sexual activity</li>
                        <li>Erectile dysfunction</li>
                        <li>Infertility</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'progesterone' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Progesterone is a female sex hormone that is mainly produced in the ovaries following ovulation
                        each month. It helps regulate the menstrual cycle and maintenance of pregnancy. Without this
                        hormone, a body cannot prepare the right environment for the egg and developing fetus. If
                        pregnant, low levels of progesterone can increase risk of pregnancy loss.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High progesterone levels</strong> may mean you are pregnant, have a cyst on your ovaries
                        or a molar pregnancy - an abdominal growth that can cause symptoms of pregnancy.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low progesterone levels</strong> may be caused by menopause, problems with the ovaries
                        or miscarriage.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high progesterone:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Breast swelling</li>
                        <li>Breast tenderness</li>
                        <li>Bloating</li>
                        <li>Anxiety or agitation</li>
                        <li>Fatigue</li>
                        <li>Low sex drive</li>
                        <li>Weight gain</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low progesterone:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Abnormal uterine bleeding</li>
                        <li>Irregular or missed periods</li>
                        <li>Spotting and abdominal pain during pregnancy</li>
                        <li>Frequent miscarriages</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'estradiol' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Estradiol is the strongest of the three estrogens in the body and is an important factor in the
                        female reproductive system. While men and women have estradiol, women have much higher levels of
                        the hormone than men.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>In women:</strong> Estradiol's main function is to mature and then maintain the
                        reproductive system. Estradiol levels cause the maturation and release of the egg as well as the
                        thickening of the uterus lining to allow a fertilized egg to implant. As women age, levels of
                        this hormone decrease significantly.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>In men:</strong> Proper estradiol levels help with bone maintenance, nitric oxide
                        production, and brain function.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high estradiol in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Acne</li>
                        <li>Constipation</li>
                        <li>Loss of sex drive</li>
                        <li>Depression</li>
                        <li>Increased risk of uterine and breast cancer</li>
                        <li>Increased risk of cardiovascular disease</li>
                        <li>Weight gain</li>
                        <li>Menstrual problems</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of high estradiol in men:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Development of female characteristics</li>
                        <li>Mood swings</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low estradiol in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Absence of menstrual cycle</li>
                        <li>Mood swings</li>
                        <li>Vaginal dryness</li>
                        <li>Hot flashes</li>
                        <li>Night sweats</li>
                        <li>Osteoporosis</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low estradiol in men:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Decreased sex drive</li>
                        <li>Excess fat around the belly</li>
                        <li>Bone loss</li>
                        <li>Osteoporosis</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'tsh' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Thyroid stimulating hormone is made by the thyroid gland and is released into the blood where it
                        is delivered to tissues in the body. It helps the body stay warm, use energy, and keeps the
                        brain, heart, muscles, and other organs working as they should.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High TSH Levels</strong> - The pituitary gland produces more TSH when the thyroid gland
                        does not produce enough hormones. High levels are an indication that the thyroid gland is not
                        making enough thyroid hormone (primary hypothyroidism).
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low TSH Levels</strong> - Low TSH levels are an indication that the thyroid is producing
                        too much thyroid hormone (hyperthyroidism or overactive thyroid). Low TSH may result from an
                        abnormality in the pituitary gland. This abnormality prevents it from making enough TSH to
                        stimulate the thyroid.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high TSH levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Rapid heartbeat</li>
                        <li>Muscle weakness</li>
                        <li>Nervousness or irritability</li>
                        <li>Insomnia</li>
                        <li>Frequent bowel movements or diarrhea</li>
                        <li>Weight loss</li>
                        <li>Mood changes</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low TSH levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weight gain</li>
                        <li>Fatigue</li>
                        <li>Swelling of face</li>
                        <li>Sensitivity to cold temperatures</li>
                        <li>Slow heart rate</li>
                        <li>Dry skin</li>
                        <li>Depression</li>
                        <li>Constipation</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'free t3' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        The thyroid produces a hormone called triiodothyronine, known as T3. Most of the T3 in your body
                        binds to protein. The T3 that doesn't bind to protein is called T3 free. It helps regulate the
                        speed with which the cells/metabolism work. It also regulates your heart rate and how fast your
                        intestines process food. Measuring T3 in the blood is a way to determine if there is a thyroid
                        problem.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High T3 free levels</strong> causes an excessively high metabolic rate and increased
                        risk of hyperthyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low T3 free levels</strong> causes slowed metabolic rate and may be a sign of
                        hypothyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high T3 free levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Rapid heartbeat</li>
                        <li>Elevated blood pressure</li>
                        <li>Anxiety</li>
                        <li>Increased sweating</li>
                        <li>Insomnia</li>
                        <li>Weight loss</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low T3 free levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fatigue</li>
                        <li>Weight gain</li>
                        <li>Puffy face</li>
                        <li>Trouble tolerating cold</li>
                        <li>Constipation</li>
                        <li>Dry skin</li>
                        <li>Thinning hair</li>
                        <li>Depression</li>
                        <li>Slowed heart rate</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 't3 uptake' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        The T3 uptake is part of a thyroid test that measures the binding capacity of the protein TBG
                        (thyroxine-binding globulin) to T3, a thyroid hormone. This test, in combination with total T4
                        test, helps identify thyroid dysfunction.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High T3 uptake</strong> may be caused by low TBG levels, elevating the T3 uptake
                        results. Possible causes of low TBG levels include diabetes, alcoholism, genetic disorders,
                        endurance exercise, and certain medications. Hyperthyroidism (high levels of T3) is also a cause
                        of high T3 uptake.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low T3 uptake</strong> may be caused by a high amount of TBG in the blood, or low levels
                        of T3 which means there is less available to bind to TBG. Low T3 levels may be due to pregnancy,
                        iodine deficiency, surgery, or radiation of the thyroid or pituitary gland, or an autoimmune
                        disorder. Hypothyroidism (low levels of T3) is also a cause of low T3 uptake.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high T3 uptake:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Rapid heartbeat</li>
                        <li>Elevated blood pressure</li>
                        <li>Anxiety</li>
                        <li>Increased sweating</li>
                        <li>Insomnia</li>
                        <li>Weight loss</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low T3 uptake:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fatigue</li>
                        <li>Weight gain</li>
                        <li>Puffy face</li>
                        <li>Trouble tolerating cold</li>
                        <li>Constipation</li>
                        <li>Dry skin</li>
                        <li>Thinning hair</li>
                        <li>Depression</li>
                        <li>Slowed heart rate</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'free t4' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        T4, also called thyroxine, is the main form of thyroid hormone made by the thyroid gland. T4 is
                        mostly bound to proteins with a small amount unbound, or free. This test measures the amount of
                        free/or unbound T4 circulating through the blood and indicates how well the thyroid gland is
                        functioning.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of T4</strong> may be caused by certain thyroid problems such as
                        thyroiditis, a goiter, high levels of protein in the blood, and hyperthyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low levels of T4</strong> may be caused by fasting, malnutrition, iodine deficiency,
                        medications that affect protein levels, illness, pituitary problems, and hypothyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high T4:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Anxiety</li>
                        <li>Unplanned weight loss</li>
                        <li>Tremors</li>
                        <li>Diarrhea</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low T4:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weight gain</li>
                        <li>Fatigue</li>
                        <li>Hair loss</li>
                        <li>Low tolerance for cold temperatures</li>
                        <li>Irregular period</li>
                        <li>Constipation</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'insulin' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Insulin is a hormone that lowers the level of glucose (sugar) in the blood. It's made by the
                        pancreas and released into the blood when the glucose level goes up, such as after eating.
                        Insulin helps glucose enter the body's cells where it is used for energy or stored for future
                        use.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High insulin levels</strong> (hyperinsulinemia) are often caused by insulin resistance.
                        When the body does not use insulin correctly it causes the pancreas to produce more insulin to
                        try to compensate for the rise in blood sugar levels. This insulin resistance can lead to type 2
                        diabetes.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low insulin levels</strong> (hypoglycemia) mean there is not enough insulin in your
                        body, and as a result, glucose can't get into your cells and stays in the bloodstream instead.
                        It is often related to diabetes treatments, but other drugs and a variety of conditions can
                        cause low blood sugar in people who don't have diabetes.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high insulin levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Frequent and intensive hunger</li>
                        <li>Excess cravings for sugar</li>
                        <li>Weight gain, especially around the waist</li>
                        <li>Fatigue</li>
                        <li>Anxiety and panic</li>
                        <li>Lack of motivation</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low insulin levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Irregular or fast heartbeat</li>
                        <li>Fatigue</li>
                        <li>Pale skin</li>
                        <li>Shakiness</li>
                        <li>Anxiety</li>
                        <li>Sweating</li>
                        <li>Hunger</li>
                        <li>Irritability</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'hba1c' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        This blood test measures your average blood sugar levels. It shows the amount of glucose
                        attached to hemoglobin (which is the part of your red blood cells that carries oxygen from your
                        lungs to the rest of your body). It's used to help detect and diagnose prediabetes and diabetes.
                        If you have diabetes, this test helps you and your doctor manage it.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High HbA1c levels</strong> may be a sign of diabetes, or means you are at risk for
                        getting diabetes.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low HbA1c levels</strong> can be caused by conditions that decrease red blood cell
                        levels, such as alcohol, liver disease, genetic disorders and some drugs and supplements.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high HbA1c levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Increased thirst</li>
                        <li>Increased urination</li>
                        <li>Blurred vision</li>
                        <li>Fatigue</li>
                        <li>Anxiety and panic</li>
                        <li>Lack of motivation</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low HbA1c levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Nervousness</li>
                        <li>Dizziness</li>
                        <li>Trembling</li>
                        <li>Sweating</li>
                        <li>Hunger</li>
                        <li>Weakness</li>
                        <li>Severe cases may lead to seizures and loss of consciousness</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'ckmb' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Creatine kinase - MB (CK-MB) is a form of an enzyme found primary in heart muscle cells. This
                        test measures CK-MB in the blood and helps to distinguish between skeletal muscle and heart
                        muscle damage. It is sometimes used to determine if you have had a heart attack and to monitor
                        for additional heart damage.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High CK MB levels</strong> are typically caused by any heart muscle damage, including
                        damage from surgery, trauma, inflammation, kidney failure and decreased oxygen.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low CK MB levels</strong> are commonly caused by muscle wasting (muscle atrophy) due to
                        physical inactivity, illnesses, or old age.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high CK MB levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Confusion or loss of consciousness</li>
                        <li>Slurred speech</li>
                        <li>Loss of vision or vision changes</li>
                        <li>Muscle aches and pains</li>
                        <li>Paralysis</li>
                        <li>Sudden weakness or numbness on one side of the body</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low CK MB levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Low muscle mass</li>
                        <li>Lack of strength</li>
                        <li>Thin or frail body</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 't3, total' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        T3, also called triiodothyronine, is a hormone produced by the thyroid. Most of the T3 in your
                        body binds to protein. The T3 that doesn't bind to protein is called T3 free. The T3 total test
                        measures both bound and free T3 and indicates how well the thyroid is functioning.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High T3 free levels</strong> causes an excessively high metabolic rate and increased
                        risk of hyperthyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low T3 free levels</strong> causes slowed metabolic rate and may be a sign of
                        hypothyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high T3 free levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Rapid heartbeat</li>
                        <li>Elevated blood pressure</li>
                        <li>Anxiety</li>
                        <li>Increased sweating</li>
                        <li>Insomnia</li>
                        <li>Weight loss</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low T3 free levels:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fatigue</li>
                        <li>Weight gain</li>
                        <li>Puffy face</li>
                        <li>Trouble tolerating cold</li>
                        <li>Constipation</li>
                        <li>Dry skin</li>
                        <li>Thinning hair</li>
                        <li>Depression</li>
                        <li>Slowed heart rate</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 't4, total' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        T4, also called thyroxine, is the main form of thyroid hormone made by the thyroid gland. T4 is
                        mostly bound to proteins with a small amount unbound, or free. This test measures the total
                        amount of both free and bound T4 and helps identify thyroid dysfunction.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of T4</strong> may be caused by certain thyroid problems such as
                        thyroiditis, a goiter, high levels of protein in the blood, and hyperthyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low levels of T4</strong> may be caused by fasting, malnutrition, iodine deficiency,
                        medications that affect protein levels, illness, pituitary problems, and hypothyroidism.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high T4:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Anxiety</li>
                        <li>Unplanned weight loss</li>
                        <li>Tremors</li>
                        <li>Diarrhea</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of low T4:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weight gain</li>
                        <li>Fatigue</li>
                        <li>Hair loss</li>
                        <li>Low tolerance for cold temperatures</li>
                        <li>Irregular period</li>
                        <li>Constipation</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'ck' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        CK or creatine kinase is and enzyme found in heart tissue, skeletal muscles and a small amount
                        in the brain. The CK test is most often used to diagnose and monitor muscular injures and
                        diseases.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of CK</strong> may mean you have an injury or disease of the muscles, heart,
                        or brain.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high CK:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Confusion or loss of consciousness</li>
                        <li>Slurred speech</li>
                        <li>Changes or loss in vision</li>
                        <li>Muscle aches and pain</li>
                        <li>Muscle stiffness</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low levels of CK</strong> may mean you have chronic kidney disease or serious kidney
                        damage.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of low CK:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Low muscle mass</li>
                        <li>Lack of strength</li>
                        <li>Thin or frail body</li>
                        <li>Feeling faint or dizzy</li>
                        <li>Fatigue or nausea</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'psa, total' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        PSA or prostate-specific antigen test is used primarily to screen for prostate cancer. It
                        measures the PSA in the blood, which is a protein produced by both cancerous and noncancerous
                        tissue in the prostate.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High levels of PSA</strong> could be a sign of prostate cancer of a different condition
                        like prostatitis or an enlarged prostate.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high PSA:</strong> an elevated PSA does not cause symptoms, but your doctor
                        may want to do a PSA test if you have:
                    </MetricInfoText>
                    <MetricList>
                        <li>Difficulty urinating</li>
                        <li>Frequent urination, including during the night</li>
                        <li>Slow urine stream</li>
                        <li>Urinary incontinence</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low levels of PSA</strong> is a sign of good prostate and overall health.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'iron' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Iron is an essential nutrient that is required for the production of healthy red blood cells
                        (RBC) which binds oxygen in the lungs and releases it as blood circulates to other parts of the
                        body.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High iron levels:</strong> iron overload, or hemochromatosis is a condition in which
                        your body stores too much iron. Excess iron can build up in the heart, liver, joints, pancreas,
                        and pituitary glands. Untreated, it can cause organ damage, and lead to a heart attack.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high iron:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Joint pain</li>
                        <li>Abdominal pain</li>
                        <li>Fatigue</li>
                        <li>Weakness</li>
                        <li>Diabetes</li>
                        <li>Loss of sex drive</li>
                        <li>Heart failure</li>
                        <li>Impotence</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low iron levels,</strong> or anemia, is when your body doesn't have enough red blood
                        cells. The red blood cells carry oxygen from your lungs to the rest of your body. Every organ
                        and tissue in your body needs oxygen to work properly.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of low iron:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Tired</li>
                        <li>Weak</li>
                        <li>Short of breath</li>
                        <li>Dizziness or lightheadedness</li>
                        <li>Headaches</li>
                        <li>Cold feet and hands</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'shbg' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Sex hormone binding globulin, or SHBG is a protein made by the liver and attaches itself to sex
                        hormones, testosterone, dihydrotestosterone and estradiol, found in both men and women. It is
                        often used to help evaluate men for low testosterone and women for excess testosterone
                        production.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High SHBG levels</strong> means the protein is attaching itself to too much testosterone
                        so less of the hormone is available and your tissues may not be getting enough testosterone.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High SHBG symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Infertility</li>
                        <li>Decreased sperm concentration and motility</li>
                        <li>Decreased sex drive</li>
                        <li>Impotence</li>
                        <li>Reduced testicle size</li>
                        <li>Decreased body hair</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low SHBG levels</strong> means there are potentially more free sex hormones for your
                        body to use.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low SHBG symptoms in men:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fluid retention</li>
                        <li>Acne</li>
                        <li>Increased appetite and weight gain</li>
                        <li>Increased muscle mass</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low SHBG symptoms in women:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weight gain</li>
                        <li>Excess facial and body hair</li>
                        <li>Acne</li>
                        <li>Mood changes</li>
                        <li>Menstrual changes</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'dheas' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Dehydroepiandrosterone (DHEA) is a hormone that your body naturally produces in the adrenal
                        gland. It helps produce other hormones including testosterone and estrogen.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High DHEAs levels</strong> may be related to adrenal cancer, tumors, or excess growth of
                        adrenal hormone-producing tissue.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High DHEAs symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Acne</li>
                        <li>Hair loss</li>
                        <li>Irritability</li>
                        <li>Trouble sleeping</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low DHEAs levels</strong> may be due to damage to the adrenal gland or a diseased
                        pituitary gland.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low DHEAs symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Unexpected weight loss</li>
                        <li>Low sex drive</li>
                        <li>Infertility</li>
                        <li>Tiredness</li>
                        <li>Short height in children</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'rf' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Rheumatoid factors are proteins produced by the immune system that can attack healthy tissue in
                        your body.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High RF levels</strong> in the blood are most often associate with autoimmune diseases
                        such as rheumatoid arthritis and Sjogren's syndrome. However, RF may be detected in some healthy
                        people, and people with autoimmune diseases sometimes have normal levels of RF.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High RF symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Pain or aching in more than one joint</li>
                        <li>Tenderness and swelling in more than one joint</li>
                        <li>Fatigue</li>
                        <li>Low-grade fever</li>
                        <li>Loss of appetite</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Low RF levels</strong> or a negative RF test most often means you do not have rheumatoid
                        arthritis or Sjogren syndrome.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '12; lymp%' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Lymphocytes</strong> are one of the 5 types of white blood cells in your blood.
                        Lymphocytes such as B-cells and T-cells are found primarily in the lymph system and fight
                        bacteria and other pathogens in the blood.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>LYMP%</strong> is the percentage of Lymphocytes in your total white blood cell count.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '17; lymp#' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Lymphocytes</strong> are one of the 5 types of white blood cells in your blood.
                        Lymphocytes such as B-cells and T-cells are found primarily in the lymph system and fight
                        bacteria and other pathogens in the blood.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>LYMP#</strong> is the measurement of lymphocytes found in a complete blood count
                        presented as an absolute number, rather than a percentage.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '13; mono%' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Monocytes</strong> are a type of white blood cell that comes from bone marrow. Monocytes
                        work in conjunction with neutrophils to combat infections and other illnesses while removing
                        damaged or dead cells.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>MONO%</strong> is the percentage of monocytes in your total white blood cell count.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '18; mono#' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Monocytes</strong> are a type of white blood cell that comes from bone marrow. Monocytes
                        work in conjunction with neutrophils to combat infections and other illnesses while removing
                        damaged or dead cells.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>MONO#</strong> is the measurement of monocytes found in a complete blood count presented
                        as an absolute number, rather than a percentage.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '14; eos%' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Eosinophils</strong> are a type of white blood cell that is activated in response to
                        allergies and some types of infections.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>EOS%</strong> is the percentage of eosinophils in your total white blood cell count.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '19; eos#' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Eosinophils</strong> are a type of white blood cell that is activated in response to
                        allergies and some types of infections.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>EOS#</strong> is the measurement of eosinophils found in a complete blood count
                        presented as an absolute number rather than a percentage.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '15; baso%' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Basophils</strong> are a type of white blood cell and are involved in early
                        identification of infections as well as wound repair and allergic reactions.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>BASO%</strong> is the percentage of basophils in your total white blood cell count.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '20; baso#' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Basophils</strong> are a type of white blood cell and are involved in early
                        identification of infections as well as wound repair and allergic reactions.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>BASO#</strong> is the measurement of basophils found in a complete blood count presented
                        as an absolute number rather than a percentage.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'tg-ab' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Thyroglobulin is a protein produced in the thyroid gland. Thyroglobulin helps your thyroid make
                        active thyroid hormones. When a person has an autoimmune condition, it can disrupt your
                        production of thyroglobulin and your immune system creates antibodies that attack the body's
                        healthy cells. A thyroglobulin antibody test can check your bloodstream for levels of these
                        antibodies. A high level may indicate an autoimmune condition.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'thyroid ab/ag' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        A thyroid antibody test provides an overall picture of how your thyroid is performing by
                        measuring key hormones and antibodies to identify thyroid dysfunction or damage.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'free testosterone' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        98% of the testosterone found in the body is bound to either sex-hormone binding globulin (SHBG)
                        or albumin. This is referred to as “bound testosterone”. The other 2% that is left is known as
                        “free testosterone”. This free testosterone is what connects with testosterone receptors within
                        the body's cells. Free testosterone enables cell replication in the bones and muscles and is
                        responsible for secondary sexual characteristics in men such as facial hair and a deeper voice.
                        Low levels of free testosterone can lead to poor muscle development, irritability, low sex drive
                        and other issues.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'tpoab' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Thyroid peroxidase (TPO) is an enzyme found in the thyroid gland. It plays an important role in
                        production of thyroid hormones. A Thyroid Peroxidase Antibodies test detects antibodies against
                        TPO in the blood. If there is a presence of TPO antibodies in your blood it could be a sign of
                        an autoimmune disorder or may increase the risk of future thyroid disorders.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'ua complete' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        A Urinalysis (UA) is a test that checks several components of a urine sample for abnormalities.
                        This test is frequently used to screen for urinary tract infections, kidney and liver issues and
                        diabetes.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'high sensitivity crp' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        High Sensitivity C Reactive Protein usually measures overall levels of inflammation in your
                        body. Can be used to find high risk for stroke or heart disease in people who have not been
                        diagnosed.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low hs-CRP:</strong> Low hs-CRP indicates you have a lower risk for heart disease in
                        your future and shouldn't need any follow up.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High hs-CRP:</strong> High hs-CRP could indicate you will have an increase risk of heart
                        disease in your future. It could mean inflammation that is not causing any issues to the heart.
                        Following up with a doctor to discuss this would be best practice.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Unexplained exhaustion</li>
                        <li>Pain</li>
                        <li>Headache</li>
                        <li>Nausea, loss of appetite, indigestion</li>
                        <li>Chills</li>
                        <li>Fever</li>
                        <li>Insomnia</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {
                // Total Testosterone
                name === 'total testosterone' && (
                    <MetricInfoContainer>
                        <MetricInfoHeader>
                            <MetricInfoImage className="fa-regular fa-circle-info" />
                            <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                        </MetricInfoHeader>
                        <MetricInfoText>
                            Total Testosterone measures the level of the hormone testosterone in your blood.
                            Testosterone is a male sex hormone that helps male features develop over time. Women also
                            have this hormone as it is made in their ovaries and adrenal glands. Testosterone cause
                            physical changes in boys during pubecent years. Men continue to make testosterone. It helps
                            make sperm and helps to boost sex drive.
                        </MetricInfoText>
                        <MetricInfoText>
                            <strong>Symptoms of high testosterone in women:</strong>
                        </MetricInfoText>
                        <MetricList>
                            <li>Acne or oily skin</li>
                            <li>Worsened sleep apnea</li>
                            <li>Increase in muscle mass</li>
                            <li>Excess hair growth on face and body</li>
                            <li>Anxiety and depression</li>
                            <li>Deepening of the voice</li>
                            <li>Enlarged clitoris</li>
                            <li>Infertility</li>
                            <li>Thinning of hair</li>
                            <li>Loss of libido</li>
                            <li>Reduction in size of breasts</li>
                            <li>Weight gain</li>
                        </MetricList>
                        <MetricInfoText>
                            <strong>Symptoms of high testosterone in men:</strong>
                        </MetricInfoText>
                        <MetricList>
                            <li>Acne</li>
                            <li>Aggressive</li>
                            <li>High sex drive</li>
                            <li>Headaches</li>
                            <li>Increased appetite</li>
                            <li>High blood pressure</li>
                            <li>Excessive body hair</li>
                            <li>Heart or liver problems</li>
                        </MetricList>
                        <MetricInfoText>
                            <strong>Symptoms of low testosterone in men:</strong>
                        </MetricInfoText>
                        <MetricList>
                            <li>Large breasts</li>
                            <li>Low sex drive</li>
                            <li>Low sperm count</li>
                            <li>Changes in testicles</li>
                            <li>Weakening of bones</li>
                            <li>Trouble getting an erection</li>
                            <li>Loss of muscle</li>
                            <li>Hair loss</li>
                            <li>Anemia</li>
                            <li>Fatigue</li>
                            <li>Depression</li>
                        </MetricList>
                        <MetricInfoText>
                            <strong>Symptoms of low testosterone in women:</strong>
                        </MetricInfoText>
                        <MetricList>
                            <li>Fertility problems</li>
                            <li>Irregular periods</li>
                            <li>Low sex drive</li>
                            <li>Vaginal dryness</li>
                            <li>Changes in breast tissue</li>
                            <li>Osteoporosis</li>
                        </MetricList>
                        <MetricInfoText>
                            <strong>High Testosterone:</strong> If your levels are higher than normal, this could mean
                            testosterone supplements or anabolic steroid use. This could also mean you have a tumor on
                            the ovaries or testes.
                        </MetricInfoText>
                        <MetricInfoText>
                            <strong>Low Testosterone:</strong> This usually means the body does not have enough of the
                            needed substance. Testosterone Deficiency (TD) is more common in men who are overweight or
                            diabetic. Testosterone usually decreases with age so older men tend to have lower
                            testosterone.
                        </MetricInfoText>
                    </MetricInfoContainer>
                )
            }

            {name === 'calcium' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Calcium is a mineral that your body uses to build and maintain strong bones. It is used for many
                        functions in the body. Most calcium in the body is stored in the bones and teeth alone allowing
                        them to have structure and toughness. It is also the most abundant mineral your body has.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Calcium</strong> - Also known as hypocalcemia deficiency is when you have low
                        calcium in the body and an electrolyte imbalance. Long term low calcium can cause confusion,
                        memory loss, hallucinations, and depression.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Calcium</strong> - Also known as Hypercalcemia is when you have too much calcium in
                        the body. This can cause kidney stones, weaken your bones, and interrupt heart and brain
                        function. This is usually a result of an overactive parathyroid gland. Other causes can be due
                        to: cancer, medication reactions, taking too much of the supplement, and other medical
                        disorders.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Osteoporosis</li>
                        <li>Dental changes</li>
                        <li>Cataracts</li>
                        <li>Changes in the brain</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'chloride' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Chloride is a type of electrolyte. Electrolytes are electrically charged minerals that help to
                        control the number of fluids and balance of acids and bases in your body. Chloride imbalance is
                        often measured to diagnose or monitor other conditions like congestive heart failure, kidney
                        disease, high blood pressure, and liver disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Chloride</strong> - Low Chloride levels in your blood could mean congestive heart
                        failure, lung disease, Addison's disease. Many factors could affect your chloride levels if you
                        have lost fluid because of vomiting or diarrhea. Having abnormal levels of chloride doesn't
                        necessarily mean there is a medical issue.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Chloride</strong> - High Chloride levels in your blood could mean kidney disease,
                        acidosis, alkalosis. Many factors could affect your chloride levels including taking in too many
                        fluids. Certain medications, such as antacids could alter your results.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Dehydration</li>
                        <li>Diarrhea</li>
                        <li>Vomiting</li>
                        <li>Fatigue or weakness</li>
                        <li>Shortness of breath</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'enzymatic co2' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Carbon Dioxide in your blood should cause no issues to your body. If you have an abnormal
                        amount, you could have a disease or health emergency you don't know about. Most of the carbon
                        dioxide in your body is in the form of bicarbonate. This is used to keep the PH in your blood
                        balanced.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Carbon Dioxide</strong> - A low carbon dioxide level can occur with some conditions.
                        Some can be serious while other conditions can be harmless. Hyperventilating is the most common
                        cause of low CO2 in the body.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Carbon Dioxide</strong> - High carbon dioxide could mean that your body has an
                        electrolyte imbalance. It could also be a buildup of carbon dioxide in the blood. This occurs
                        when alveoli are unable to exchange carbon dioxide from the blood for oxygen. If you have
                        chronic respiratory disease this might be elevated. Also can be caused by pulmonary conditions.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Weakness or fatigue</li>
                        <li>Diziness</li>
                        <li>Confusion</li>
                        <li>Loss of consciousness</li>
                        <li>Shortness of breath or rapid breathing</li>
                        <li>Headache</li>
                        <li>Blurred vision</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'glucose' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Glucose is the main sugar in your blood. It comes from the foods you put in your body and your
                        body uses it for its main source of energy.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Glucose</strong> - Also known as hypoglycemia. When your blood sugar drops to below
                        70 mg/dl you would need to do something to bring it back up. Knowing if you have low blood sugar
                        is key because it can cause major problems if left untreated.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Glucose</strong> - Diabetes is the disease in which your blood sugar levels are
                        consistently high. If you continually have your blood sugar too high you can cause serious
                        damage to your organs and your body. If you blood sugar runs high you may have to take
                        medications to help manage it and/or follow a specific diet.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Confusion</li>
                        <li>Heart palpitations</li>
                        <li>Anxiety</li>
                        <li>Fatigue</li>
                        <li>Weight loss</li>
                        <li>Blurred vision</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'potassium' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Potassium is an essential nutrient you get out of the food you eat. This nutrient is known to
                        help your muscle and nerve function. Your body uses the potassium that it needs. The extra
                        potassium that your body does not need is removed by your kidneys.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Potassium</strong> - Also known as Hypokalemia. One can get this deficiency
                        condition by losing fluids. This includes: vomiting, adrenal gland disorders, diarrhea, or the
                        use of diuretics. Lower potassium levels can affect your muscles by making them cramp, twitch,
                        feel week, or even paralyze them. You could also feel abnormal heart rhythms. Medications can
                        also affect potassium levels.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Potassium</strong> - Hyperkalemia is the condition where you have too much
                        potassium in your body. Too much potassium in your body can cause damage to your body and
                        possibly even cause a heart attack.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Muscle pain</li>
                        <li>Weakness or fatigue</li>
                        <li>Digestive issues</li>
                        <li>Breathing issues</li>
                        <li>Nausea or vomiting</li>
                        <li>Chest pain</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'sodium' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Sodium is an electrolyte and is crucial to help send electrical signals between cells. It also
                        helps to control the fluid amounts in your body. Most foods you ingest have sodium in them. The
                        most common form would be salt. You body moves sodium out of the body through sweat and
                        urination.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Sodium</strong> - Also known as hypoatremia. This is where the sodium level in the
                        body is too low. This can be caused by consuming too many fluids, organ failure, cirrhosis, or
                        the use of diuretics.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Sodium</strong> - Too much sodium in the body can lead to major damage to the major
                        organs. This includes: high blood pressure, heart disease, and can even lead to stroke. Abnormal
                        levels could indicate a problem or disorder.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Nausea and vomiting</li>
                        <li>Headache</li>
                        <li>Confusion</li>
                        <li>Muscle spasms, cramps, or weakness</li>
                        <li>Seizures</li>
                        <li>Coma</li>
                        <li>Loss of energy</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'bun' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        This stands for blood urea Nitrogen. This test will measure the amount of nitrogen in your blood
                        that will come from the waste product urea. Urea is the main nitrogenous broken-down product of
                        protein. Urea is made in the liver and leaves your body through urine. This is an indicator or
                        liver and kidney function.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low BUN</strong> - Lower BUN levels can be caused by anabolic steroids, higher IGF-1,
                        and/or growth horome. Genetic deficiency, pregnancy, overhydration, low protein diet, and
                        impaired liver function can also be the cause of a lower BUN value.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High BUN</strong> - High BUN levels can be caused by kidney disease, a higher protein
                        diet, and infection or fever, thryroid issues. It can also be from: inflammation, dehydration,
                        stress, GI bleed, poor circulation, or severe burns. If you have high BUN, this could be an
                        indicator that there is an underlying condition.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Frequent urination</li>
                        <li>Discolored urine</li>
                        <li>Pain</li>
                        <li>Fatigue</li>
                        <li>Swelling</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'creatinine' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Creatinine is a chemical compound waste product that comes from the normal deterioration on
                        muscles in the body. The normal level of creatinine will depend on your gender, body size, age,
                        and race. This means there is no “normal” level. This test can measure how well your kidneys are
                        functioning and how well they are filtering waste from your blood. Creatinine leaves your body
                        through urine.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Creatinine</strong> - Having low creatinine levels could mean lower muscle mass that
                        could've been caused by aging, liver issues, pregnancy, or by a muscular disease. Lower levels
                        found in an older or underwieght person should not be a cause for concern.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Creatinine</strong> - High Creatinine levels usually means there is too much
                        creatine in the body. It is not being removed or filtered appropriately. It could mean that your
                        kidneys are not functioning as they should. A high level can be caused from ingesting large
                        amounts of protein or the supplement, creatine. It could also be from dehydration.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Low muscle mass</li>
                        <li>Liver disease</li>
                        <li>Faint or dizziness</li>
                        <li>Weightloss</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'alkp' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        This measures the amount of Alkaline Phosphatase enzyme in your blood. This enzyme is typically
                        found in the liver, bones, intestines, and kidneys. This is most often tested for liver disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low ALKP</strong> - Low levels of alkaline phosphatase could indicate hypophosphatasia
                        which is a rare genetic disease that will affect your teeth and bones. Could also be due to
                        malnutrition, Celiac's disease, or a protein or zinc deficiency.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High ALKP</strong> - High levels of alkaline phosphatase could indicate liver, bone, or
                        gallbladder issues. This could mean there is a block in your bile ducts, cirrhosis, liver
                        cancer, or some forms of hepatitis.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Nausea and vomiting</li>
                        <li>Jaundice</li>
                        <li>Fatigue</li>
                        <li>Weightloss</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'alt' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        ALT stands for alanine transaminase which is an enzyme found mainly in the liver. Based on the
                        level of ALT, this test is used to monitor liver function. ALT is excreted into the bloodstream
                        when there is damage done to the liver cells.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low ALT</strong> - Low levels of Alanine Transaminase is usually a good sign. However,
                        in some instances, low ALT can be a result of an undiagnosed medical condition.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High ALT</strong> - High Alanine transaminase could indicate liver problems. Abnormal
                        levels could be from hepatitis, infection, cirrhosis, liver cancer, or other liver disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Jaundice</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'ast' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Aspartate aminotransferase is an enzyme in your body. It is found mostly in the liver but also
                        found in other tissues in the body. When cells are damaged, AST can be released into the
                        bloodstream. So abnormal levels can be a sign of an underlying issue.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low AST</strong> - Low levels of Aspartate aminotransferase could indicate a vitamin B6
                        deficiency because this particular AST enzyme requires AST to function normally. Having a lower
                        level of this enzyme is usually a good sign unless you are diabetic.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High AST</strong> - High levels of Aspartate aminotransferase could indicate there is an
                        underlying health condition such as: cirrhosis, liver disease, or hepatitis. It could also be
                        related to heart issues or pancreatitis.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fatigue</li>
                        <li>Weakness</li>
                        <li>Loss of appetite</li>
                        <li>Nausea or vomiting</li>
                        <li>Swelling in abdomen</li>
                        <li>Jaundice</li>
                        <li>Dark urine</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'albumin' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Albumin is one of the most abundant proteins found in the bloodstream. It helps to prevent fluid
                        from exiting out of blood vessels. It is a protein made by your liver. If there are abnormal
                        levels of albumin it could signal issues with your liver or kidneys. It could also signal
                        nutrient deficiency.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Albumin</strong> - Hypoalbuminemia can be caused by multiple conditions such as:
                        nephrotic syndrome, malnutrition, heart failure, hepatic cirrhosis. Most cases of low albumin
                        are caused by sudden onset or chronic inflammatory responses.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Albumin</strong> - Higher than normal levels of Albumin could signal dehydration.
                        Certain medications can cause abnormal levels of albumin.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fluid retention</li>
                        <li>Appetite changes</li>
                        <li>Thinning hair</li>
                        <li>Rapid heartbeat</li>
                        <li>Weakness or exhaustion</li>
                        <li>Vomiting, diarrhea, nausea</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'total protein' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        This particular test measures the total amount of 2 classes of proteins found in the fluid of
                        your blood including albumin and globulin.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Total Protein</strong> - Having low protein could indicate health conditions such as
                        malnutrition or malabsorption disorders. These conditions could also include liver or kidney
                        disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Total Protein</strong> - If you have high protein levels this could mean you have
                        an infection such as HIV, blood cancer, or viral hepatitis.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Swelling</li>
                        <li>Mood changes</li>
                        <li>Weakness or fatigue</li>
                        <li>Slow healing injuries</li>
                        <li>Getting or staying sick</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'total bilirubin' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        This type of test refers to all of the bilirubin in your blood including unconjugated (indirect)
                        bilirubin and conjugated (direct) bilirubin.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Total Bilirubin</strong> - Low bilirubin levels mean you shouldn't have anything to
                        be concerned about. Consuming some substances like caffeine or NSAIDS can temporarily lower the
                        level.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Total Bilirubin</strong> - High bilirubin levels can indicate several health
                        issues. Bile duct problems or liver issues could be a result of higher levels. Also less severe
                        conditions could cause higher levels. This would include: Anemia, reactions to medications,
                        gallstones, Gilbert Syndrome.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Dark urine</li>
                        <li>Vomiting</li>
                        <li>Nausea</li>
                        <li>Weakness and lightheaded</li>
                        <li>Chest pain</li>
                        <li>Fever</li>
                        <li>Chills</li>
                        <li>Abdominal pain or swelling</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'phosphorus' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        The main function of phosphorus in the body is to work alongside calcium and vitamin D to help
                        in the development of bones and teeth. This mineral is significant in how the body uses fats and
                        carbohydrates. It is found in many foods you digest and you may also take it as an added
                        supplement. Your kidneys help to keep the right level of phosphorus in your body.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Phosphorus</strong> - Lower phosphorus levels can inhibit growth and decrease bone
                        and tooth development. A Phosphorus deficiency is uncommon. Poor diets or severe eating
                        disorders could be related to the deficiency. Diabetes, alcoholism, and genetic disorders could
                        also contribute to lower levels.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Phosphorus</strong> - Also known as hyperphosphatemia. Too much phosphorus in the
                        body can cause health issues including bone disease. When you have too much phosphorus it forces
                        your body to pull calcium from your bones to keep your blood good and balanced. This in return,
                        will cause your bones to become weak and unhealthy.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of Phosphorus Deficiency:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Loss of appetite</li>
                        <li>Anxiety</li>
                        <li>Bone pain/fragile bones</li>
                        <li>Fatigue</li>
                        <li>Irregular breathing</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'amylase' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Amylase is an enzyme which is produced by your salivary glands and pancreas. The pancreas is
                        responsible for creating different enzymes that help to break down the food in the intestines.
                        An abnormal level of amylase could indicate a pancreatic disorder.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Amylase</strong> - Lower levels of Amylase could mean chronic pancreatitis. This can
                        result in infalmmation of the pancreas that gets worse over time which will cause long term
                        damage. Medications can sometimes alter the levels of amylase in your body.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Amylase</strong> - Higher levels could be a sign of sudden onset of inflammation of
                        the pancreas, or pancreatitis. Higher levels could also be caused by in infection, cancer,
                        alcohol, or even medications you are taking. If you have a higher level avoid alcohol and seek
                        further treatment.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms for pancreatic diseases:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Fever</li>
                        <li>Loss of appetite</li>
                        <li>Upper abdominal pain</li>
                        <li>Nausea or vomiting</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'prolactin' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        A hormone that is released from the anterior pituitary gland that is in charge of stimulating
                        milk production usually after childbirth.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Prolactin</strong> - Lower levels of prolactin can lead to insufficient milk being
                        produced after giving birth. Most with lower prolactin levels don't usually have specific
                        medical issues but they could have reduced immune systems to some infections. Medications could
                        alter levels of prolactin.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High Prolactin</strong> - Excess of prolactin can possibly cause milk production of
                        breast milk in women who are not currently pregnant or breastfeeding. High prolactin could be
                        due to a tumor on the pituitary gland, and kidney or thyroid disease.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of high prolactin:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Infertility</li>
                        <li>Absent periods</li>
                        <li>Low libido</li>
                        <li>Painful or uncomfortable intercourse</li>
                        <li>Vaginal dryness</li>
                        <li>Acne</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'dtibc' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Total iron binding capacity is a test to monitor how much iron you have in your blood. Iron
                        moves through your bloodstream attached to a protein called transferrin. This determines who
                        well the protein is carrying iron through your body.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low TIBC</strong> - The most common cause of low TIBC is more than the normal level of
                        iron in the body. Inflammation can also alter the levels of TIBC in the blood. Liver disease can
                        also be a result of lower level TIBC as the liver helps keep iron levels in balance. If one
                        suffers from liver disease, the liver is not able to produce transferrin effectively which would
                        decrease the TIBC.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High TIBC</strong> - Higher levels of TIBC could be a sign of iron deficiency anemia. If
                        one has celiac disease this could also alter levels of TIBC.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'uibc' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Unsaturated iron binding capacity is used to determine the reserve capacity of transferrin,
                        which means the part of the transferrin that has not been saturated with iron yet.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low UIBC</strong> - Lower levels of UIBC could occur if someone is malnurished, has
                        liver disease, inflammation, or nephrotic syndrome.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>High UIBC</strong> - Higher levels of UIBC could be an indicator of iron deficiency.
                        Higher levels could also increase with pregnancy and birth control pills.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of anemia:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Chronic fatigue</li>
                        <li>Dizziness</li>
                        <li>Weakness</li>
                        <li>Headache</li>
                        <li>Pale skin</li>
                        <li>Acne</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>Symptoms of iron overload:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Joint pain</li>
                        <li>Fatigue/weakness</li>
                        <li>Lack of energy</li>
                        <li>Abdominal pain</li>
                        <li>Low libido</li>
                        <li>Organ damage</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'lipase' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Lipase is a protein that helps to absorb fat. It is released in the pancreas. These levels are
                        usually used to diagnose pancreatitis or other pancreas disorders.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Low Lipase</strong> - a low level of lipase in the bloodstream could mean there is
                        damage to the cells in the pancreas that make lipase. This can be an indication of a chronic
                        disease such as: cystic fibrosis or chronic pancreatitis.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Nausea and vomiting</li>
                        <li>Diarrhea</li>
                        <li>Back or abdominal pain</li>
                        <li>Fever</li>
                        <li>Loss of appetite</li>
                    </MetricList>
                    <MetricInfoText>
                        <strong>High Lipase</strong> - a high level of lipase could mean that your pancreas is injured
                        or inflamed. If this is the case, it typically releases more lipase than usual. Could also
                        indicate an ulcer, gastroenteritis, or a bowel obstruction.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Severe abdominal pain</li>
                        <li>Fever</li>
                        <li>Loss of appetite</li>
                        <li>Nausea</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === '03; hgb' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Hemoglobin measures the amount of this oxygen-carrying protein that is found inside RBCs.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '04; hct' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Hematocrit measures the proportion of your total blood volume that consists of red blood cells.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '05; mcv' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Mean corpuscular volume is a measurement of the average size of red blood cells.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '06; mch' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Mean corpuscular hemoglobin is the average amount of hemoglobin inside each red blood cell.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '07; mchc' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Mean corpuscular hemoglobin concentration is a calculated measurement of how concentrated
                        hemoglobin is within red blood cells.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '09; rdw' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Red cell distribution width (RDW) is a measurement of the variation in the size of your red
                        blood cells.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '10; mpv' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Mean platelet volume (MPV), which determines the average size of platelets.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '11; neut%' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Neutrophils</strong> make up the greatest percentage of WBCs and are produced by the
                        bone marrow to fight a diverse array of inflammatory and infectious diseases.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>NEUT%</strong> is the percentage of neutrophils in your total white blood cell count.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '16; neut#' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Neutrophils</strong> make up the greatest percentage of WBCs and are produced by the
                        bone marrow to fight a diverse array of inflammatory and infectious diseases.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>NEUT#</strong> is the measurement of neutrophils found in a complete blood count
                        presented as an absolute number, rather than a percentage.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'bioavailable testosterone' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Total testosterone circulates primarily as protein-bound (about 60% bound to sex hormone binding
                        globulin (SHBG) and 50% to albumin). Only 2-3% exists in free, biological active form.
                        Testosterone is weakly tied to albumin and can be reversed easily, therefore albumin bound and
                        free testosterone are deemed to be bioavailable testosterone.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'bun/creat ratio' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        This ratio is useful in the differential diagnosis of chronic or acute renal disease. Reduced
                        renal perfusion, congestive heart failure, or recent onset of urinary tract obstruction, could
                        likely show in an increase in BUN/Creatinine ratio. If there is liver disease present, there
                        could be a decrease in the BUN/Creatinine ratio.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === 'estrogen' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Estrogen is a hormone. Although present in the body in small amounts, hormones have important
                        roles in maintaining your total health. Estrogen is commonly associated with the female body.
                        Men also produce estrogen, but women produce it in higher levels. Estrogen is responsible for
                        sexual development of girls in puberty. It also controls the growth and stability of the uterine
                        lining during a woman's menstrual cycle and at the beginning of a pregnancy It can cause breast
                        changes in teenagers and women who are pregnant. It is involved in bone and cholesterol
                        metabolism, as well. Lastly it regulates food intake, body weight, glucose metabolism, and
                        insulin sensitivity.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Increase of UTIs from thinning of urethra</li>
                        <li>Shifts in mood</li>
                        <li>Hot flashes</li>
                        <li>Breast tenderness</li>
                        <li>Lack in vaginal lubrication</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'ggt' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Gamma-glutamyl transferase measures the levels of GGT in the blood. It is an enzyme found
                        throughout the body bu tmostly found in the liver. High levels of GGT in the blood could be an
                        indicator of damage to the liver or bile ducts. Normal ranges suggest that there is no liver
                        damage.
                    </MetricInfoText>
                    <MetricInfoText>
                        <strong>Symptoms of abnormal levels of GGT:</strong>
                    </MetricInfoText>
                    <MetricList>
                        <li>Nausea or vomiting</li>
                        <li>Abnormal pain or swelling</li>
                        <li>Jaundice</li>
                        <li>Weakness or fatigue</li>
                    </MetricList>
                </MetricInfoContainer>
            )}

            {name === 'reverse t3' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        Also known as reverse triiodothyronine. RT3 is a metabolite of T4. Typically, when T4 loses an
                        atom of iodine—a process known as monodeiodination—it becomes (T3), the active thyroid hormone.
                        It appears that rT3 can be elevated in times when the body is undergoing a crisis.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '02; rbc' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Red Blood Cell Count, Hemoglobin, and Hematocrit</strong>
                    </MetricInfoText>
                    <MetricInfoText>
                        Having a low red blood cell count could mean you are anemic. Anemia can cause weakness and/or
                        fatigue.
                    </MetricInfoText>
                    <MetricInfoText>
                        Having a high red blood cell could be an indication of an undiagnosed condition such as heart
                        disease or polycythemia vera
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '01; wbc' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>White Blood Cell Count</strong>
                    </MetricInfoText>
                    <MetricInfoText>
                        Having a low or high white blood cell count may indicate inflammation, infection, bone marrow
                        disease, an immune disorder or a reaction to a medication
                    </MetricInfoText>
                </MetricInfoContainer>
            )}

            {name === '08; plt' && (
                <MetricInfoContainer>
                    <MetricInfoHeader>
                        <MetricInfoImage className="fa-regular fa-circle-info" />
                        <TestName className="ParagraphNormalRegular">{`About ${testDisplayName}`}</TestName>
                    </MetricInfoHeader>
                    <MetricInfoText>
                        <strong>Platelet Count</strong>
                    </MetricInfoText>
                    <MetricInfoText>
                        A low platelet count could be a sign of an undiagnosed medical condition or could be a side
                        effect from a medication. If your platelet count is low, your blood may not clot easily, putting
                        you at risk for excessive bleeding
                    </MetricInfoText>
                    <MetricInfoText>
                        A high platelet count occurs when the bone marrow produces too many platelets, a condition
                        called thrombocytosis. Blood clots more quickly when you have too many platelets which can lead
                        to dangerous blood clots forming in the arms and legs. These blood clots may break off or travel
                        to another area of the body, causing a stroke or heart attack.
                    </MetricInfoText>
                </MetricInfoContainer>
            )}
        </>
    );
};

export default MetricDescription;
