import styled from 'styled-components';
import {Device} from '../../services/constants';

export const ItemContainer = styled.div`
    max-width: 100%;
    height: 77px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 8px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--neutral-0);

    @media ${Device.mobile} {
        height: 100px;
    }
`;

export const InfoContainer = styled.div`
    width: calc(100% - 26px);
    max-width: calc(100% - 26px);
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;

    @media ${Device.mobile} {
        max-width: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: var(--text-600);
        justify-content: default;
    }
`;

export const LeftContainer = styled.div`
    color: var(--text-600);
    max-width: 500px;
    align-self: stretch;
    flex-grow: 0;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px 16px;
    padding: 0;

    @media ${Device.mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const RightContainer = styled.div`
    color: var(--text-400);
    gap: 16px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 8px;

    @media ${Device.mobile} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const ColorBar = styled.div<{color: string}>`
    width: 10px;
    min-width: 10px;
    height: 100%;
    min-height: 61px;
    border-radius: 8px;
    background-color: ${(props) => props.color};
`;

export const TestName = styled.p`
    // width: 230px;
`;

export const LatestBadge = styled.div`
    width: 128px;
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background-color: var(--text-400);
    color: white;
`;

export const LatestText = styled.p`
    color: var(--text-0);
`;

export const PendingBadge = styled(LatestBadge)`
    width: 140px;
    background-color: var(--warning-50);
    color: white;
`;

export const PendingText = styled(LatestText)``;

export const Icon = styled.i`
    font-size: 24px;
    color: var(--text-600);
`;

export const SmallIcon = styled.i`
    font-size: 16px;
`;
