import React, {useState, useEffect, ChangeEvent, ReactNode} from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import PrimaryButton from '../../components/PrimaryButton';
import Spinner from '../../components/Spinner';
import settings from '../../settings';
import {useAppSelector, useAppDispatch} from '../../hooks';
import {setKitParam} from '../../slices/kitSlice';
import {fetchPanels} from '../../slices/panelSlice';
import {
    Form,
    FormSection,
    BackButton,
    Image,
    ImageContainer,
    Paragraph,
    LabelText,
    Header,
    Error,
    Label,
    Select,
    LeftIcon,
    SubmitContainer
} from './styles/TestType';

export const TestType = ({...props}) => {
    const kitInfo = useAppSelector(state => state.kit);
    const panels = useAppSelector(state => state.panels);

    const dispatch = useAppDispatch();

    const {user, token} = useAuth();

    useEffect(() => {
        dispatch(fetchPanels(token));
    }, []);

    // Dynamically build the list of options from panel tests
    const panelsJsx = panels.panels.map((panel: any) => {
        return <option value={panel.code}>{panel.name}</option>;
    });

    const [submitting, setSubmitting] = useState(false);
    const companies: any = {
        'baylor-student-on-campus': 1,
        'baylor-student-off-campus': 2,
        'baylor-faculty': 3,
        'baylor-staff': 4,
        'varsity-spirit': 110
    };

    const hasNoFirstName = !user.userInfo.firstName;
    const hasNoLastName = !user.userInfo.lastName;
    const hasNoBirthday = !user.userInfo.birthday;
    const hasNoAddress = !user.userInfo.address1;
    const hasNoCity = !user.userInfo.city;
    const hasNoState = !user.userInfo.state;
    const hasNoZip = !user.userInfo.zip;
    const hasNoPhone = !user.userInfo.phone;
    const hasNoGender = !user.userInfo.gender;
    const hasNoEthnicity = !user.userInfo.ethnicity;

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: ''
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        const test = kitInfo.kitId1.length === 15 ? kitInfo.kitId1.substring(3, 5) : kitInfo.kitId1.substring(0, 2);

        if (!kitInfo.testType) {
            setError(prevError => ({...prevError, company: true, message: 'Please select a test'}));
            return;
        }

        const error = await handleRegistration();

        if (error) {
            setError(prevError => ({...prevError, message: error}));
            return;
        }

        if (test === '01') props.goToNamedStep?.('success');
        else props.goToNamedStep?.('payment');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let {options, value} = event.target as HTMLSelectElement;
        let selectedKitName = options[options.selectedIndex].text;

        let name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let val =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        dispatch(setKitParam({[name]: val}));
        dispatch(setKitParam({kitName: selectedKitName}));
        setError(prevError => ({...prevError, [name]: false}));
    };

    const handleRegistration = async () => {
        const regex = /^([a-zA-Z]{2}\-)?(0[1-6])\-\d{4}\-\d{4}$/;
        const result = regex.exec(kitInfo.kitId1);
        const testID = result?.[2];
        // First, optional capture group ie (BW)-01-0000-0000, our affiliate ID
        const affiliateID = result?.[1];
        // If we have an affiliate prefix, this is a blood test
        const isBlood = affiliateID && affiliateID.length > 0;

        let productLine = '';
        if (isBlood) {
            productLine = 'Blood Wellness';
        } else {
            switch (testID) {
                case '01':
                case '04':
                    productLine = 'COVID-19 PCR';
                    break;
                case '02':
                case '03':
                case '05':
                case '06':
                    productLine = 'COVID-19 Antibody';
                    break;
            }
        }

        let specimenType = '';
        if (isBlood) {
            specimenType = 'Whole Blood';
        } else {
            switch (testID) {
                case '01':
                case '04':
                    specimenType = 'Nasal Swab';
                    break;
                case '02':
                case '03':
                case '05':
                case '06':
                    specimenType = 'Whole Blood';
                    break;
            }
        }

        //sets profiles to any type and an empty array, as it's required
        //only here for panel code fix, needs reactoring
        const profiles: any = [];

        let panels: string[] = [kitInfo.testType];

        if (!isBlood) {
            panels =
                testID === '01' || testID === '04' // PCR
                    ? ['001']
                    : testID === '02' || testID === '05' // Antibody
                    ? ['003']
                    : testID === '03' || testID === '06' // Igg
                    ? ['004']
                    : ['001'];
        }

        const diagCodes = testID === '01' || testID === '03' ? ['Z1152'] : [];

        const order = {
            patient: {
                firstName: hasNoFirstName ? kitInfo.firstName : user.userInfo.firstName,
                lastName: hasNoLastName ? kitInfo.lastName : user.userInfo.lastName,
                gender: hasNoGender ? kitInfo.gender : user.userInfo.gender[0].toUpperCase(),
                categoryId: companies[kitInfo.company] || '',
                birthDate: hasNoBirthday ? new Date(kitInfo.birthday).toISOString() : user.userInfo.birthday,
                email: user.userInfo.email,
                race: hasNoEthnicity ? kitInfo.ethnicity : user.userInfo.ethnicity,
                address: {
                    phone: hasNoPhone ? kitInfo.phone : user.userInfo.phone,
                    address1: hasNoAddress ? kitInfo.address1 : user.userInfo.address1,
                    address2: user.userInfo.address2,
                    city: hasNoCity ? kitInfo.city : user.userInfo.city,
                    state: hasNoState ? kitInfo.state : user.userInfo.state,
                    zipCode: hasNoZip ? kitInfo.zip : user.userInfo.zip,
                    country: user.userInfo.country
                }
            },
            specimenId: kitInfo.kitId1,
            providerNPI: '1346818218',
            lab: settings.env === 'production' ? 'Default' : 'Test',
            productLine: productLine,
            specimenType: specimenType,
            profiles: profiles,
            panels: panels,
            diagCodes: diagCodes,
            sendEmail: false,
            doNotCollect: true,
            createNoUser: true,
            surveyAnswers: [
                {
                    questionId: 1,
                    answerText: kitInfo.symptoms
                },
                {
                    questionId: 2,
                    answerText: kitInfo.contact
                },
                {
                    questionId: 3,
                    answerText: kitInfo.vaccinated
                },
                {
                    questionId: 4,
                    answerText: kitInfo.vaccinated
                }
            ]
        };

        setSubmitting(true);

        const orderRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify(order)
        };

        const res = await fetch(`${settings.apiURL}/api/order/secureCreate`, orderRequestOptions);
        if (!res.ok) {
            setSubmitting(false);
            const message = (await res.json()).error;
            const registered = message === 'Specimen ID already exists in another order.';
            setError(prevError => ({
                ...prevError,
                message: registered ? 'This kit ID has already been registered.' : 'Could not create order'
            }));
            return message;
        }

        const orderRes = await res.json();

        const kit = {
            kit_id: kitInfo.kitId1,
            customer_id: user.userInfo.id,
            order_id: orderRes.id,
            category: companies[kitInfo.company] || '',
            createdDate: new Date(),
            firstName: user.userInfo.firstName,
            email: user.userInfo.email,
            test_type: kitInfo.testType
        };

        const kitRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify(kit)
        };

        const kitRes = await fetch(`${settings.apiURL}/api/kit/createKitRegistration`, kitRequestOptions);
        setSubmitting(false);
        if (!kitRes.ok) {
            setError(prevError => ({...prevError, message: 'Could not submit kit'}));
            return 'Could not submit kit';
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <FormSection>
                    <Header className='Heading05Bold'>Select your test type</Header>
                </FormSection>
                <Error className='ParagraphSmallRegular' hasSubmitted={!!error.message} message={error.message || ''}>
                    {error.message}
                </Error>
                <FormSection>
                    <ImageContainer>
                        <Image className='fa-light fa-folder-plus' />
                    </ImageContainer>
                    <Paragraph className='ParagraphNormalBold' style={{color: 'var(--neutral-500)'}}>
                        Select the type of test you would like to take
                    </Paragraph>
                    <Label error={error.company} className='Heading05Regular'>
                        <LabelText>Test Type</LabelText>
                        <LeftIcon className='fa-light fa-briefcase' error={error.company ? 1 : 0} />
                        <Select
                            error={error.company}
                            name='testType'
                            value={kitInfo.testType || ''}
                            onChange={handleChange}
                            className='ParagraphNormalRegular'>
                            <option disabled value=''>
                                {' '}
                                -- select an option --{' '}
                            </option>
                            {panelsJsx}
                            {/* <option value='advanced-std-panel'>Advanced STD Panel</option>
                            <option value='advanced-testosterone'>Advanced Testosterone</option>
                            <option value='advanced-thyroid'>Advanced Thyroid</option>
                            <option value='cardiac-risk'>Cardiac Risk</option>
                            <option value='diabetic-risk'>Diabetic Risk</option>
                            <option value='herpes-simplex-1-and-2+'>Herpes Simplex 1 &amp; 2+</option>
                            <option value='inflammation'>Inflammation</option>
                            <option value='liver-and-kidney'>Liver &amp; Kidney</option>
                            <option value='mens-complete-hormone-health'>Men's Complete Hormone Health</option>
                            <option value='nutrient-health'>Nutrient Health</option>
                            <option value='postmenopause-test'>Postmenopause Test</option>
                            <option value='stress-test'>Stress Test</option>
                            <option value='thyroid-test'>Thyroid Test</option>
                            <option value='total-testosterone'>Total Testosterone</option>
                            <option value='vitamin-d'>Vitamin D</option>
                            <option value='weight-management'>Weight Management</option>
                            <option value='womens-complete-hormone-health'>Women's Complete Hormone Health</option>
                            <option value='womens-fertility'>Women's Fertility</option>
                            <option value='womens-perimenopause'>Women's Perimenopause</option> */}
                        </Select>
                    </Label>
                </FormSection>
                <FormSection>
                    <SubmitContainer>
                        {submitting && <Spinner left='30px' />}
                        <PrimaryButton value='Continue' width='100%' radius='24px' />
                    </SubmitContainer>
                    <BackButton className='ParagraphNormalRegular' onClick={() => props.goToNamedStep?.('step0')}>
                        Go back
                    </BackButton>
                </FormSection>
            </Form>
        </>
    );
};
