import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import settings from '../settings';
import axios from 'axios';
import type {RootState} from '../store';
import {Resend} from '../containers/styles/Register';

type Panel = {
    name: string;
    code: string;
};

interface PanelState {
    panels: Panel[];
}

const initialState = {
    panels: [] as Panel[]
};

export const fetchPanels = createAsyncThunk('panel/fetchPanels', async (token: string, api) => {
    try {
        const req = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        };
        const res = await axios.get(`${settings.apiURL}/api/kit/getPanels`, req);

        let panels: any = [];
        // We have decided to display the name of the panel with this data.
        // subbing at index 6 because that's where the space after "DTC - " is
        // Otherwise, display the regular panel name (e.g. Celiac)
        for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].name.includes('DTC - ')) {
                res.data[i].name = res.data[i].name.substring(6, res.data[i].name.length);
            }
            panels.push({
                code: res.data[i].code,
                name: res.data[i].name
            });
        }

        api.dispatch(setPanels(panels));

        return panels;
    } catch (err) {
        console.error(err);
        return null;
    }
});

export const panelSlice = createSlice({
    name: 'panels',
    initialState,
    reducers: {
        setPanels: (state, action: PayloadAction<PanelState>) => {
            Object.assign(state.panels, action.payload);
        }
    }
});

export const {setPanels} = panelSlice.actions;
export const selectPanel = (state: RootState) => state.panels;

export default panelSlice.reducer;
