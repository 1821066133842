import styled from 'styled-components';
import ReferralBackground from '../../assets/images/Referral Background.jpeg';
import {Link} from 'react-router-dom';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url('${ReferralBackground}');
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
`;
export const FormContainer = styled.div`
    width: calc(100% - 32px);
    max-width: 343px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin: 0 16px;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--text-0);
    transition: 0.2s;
`;
export const Header = styled.div`
    // font-size: min(2.074rem, 9.8vw);
    color: var(--text-0);
    align-self: flex-start;
    margin-bottom: 20px;
    transition: 0.2s;
`;

export const Caption = styled.div`
    color: var(--text-600);
    letter-spacing: 0.5px;
    font-size: 1rem;
    // margin: 20px auto;
    font-weight: bold;
    text-align: left;
    width: 100%;
`;
export const LeftIcon = styled.i`
    font-size: 32px;
    margin-right: 10px;
`;
// export const InfoIcon = styled(LeftIcon)`
//     float: left;
//     color: white;
//     margin-right: 10px;
// `
export const LinkText = styled.div`
    margin: 0 auto 0 0;
    display: flex;
    align-items: center;
    flex-grow: 0;
    text-align: left;
    color: var(--primary-700);
    transition: 0.2s;

    &:hover {
        color: var(--primary-300);
    }

    &:active {
        color: var(--primary-50);
    }
`;
export const LinkFrame = styled(Link)`
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: calc(100% - 32px);
    max-width: 343px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    border: solid 4px rgba(91, 91, 91, 0.5);
    background-color: var(--neutral-50);
    text-decoration: none;
`;
