import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const CardContainer = styled.div`
    max-width: 433px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    border-radius: 8px;
    background-color: var(--neutral-0);
    box-shadow: var(--card-shadow);
    padding: 24px;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 24px;
    }
`;
export const VialsImage = styled.i`
    font-size: 64px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-right: 16px;
    }
`;
export const Separator = styled.div`
    width: 100%;
    max-width: 420px;
    height: 1px;
    background-color: var(--neutral-100);
`;
export const KitLink = styled(Link)`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: var(--link-300);
    }
`;
export const CardLink = styled.a`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;
    text-align: center;

    &:hover {
        color: var(--link-300);
    }
`;
