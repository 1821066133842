import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep2.png';

const AntibodyStep2 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 2</h2>

            <h3 className="antibody-step-description">Fill out Unique Test Kit ID Sticker </h3>

            <p className="has-text-centered">
                Clearly print date of birth, sex, sample collection date and sample collection time on the unique test
                kit sticker located on the back of the biohazard bag.
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep2;
