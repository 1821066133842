import React from 'react';
import {Layout} from '../Components';

const Done = () => (
    <Layout>
        <section className="is-fullheight done">
            <div>
                <h1 className="final-page-top-text">you're all done!</h1>
                <div>
                    <h6 className="final-page-middle-text">Questions? We've got answers.</h6>
                    <h6 className="final-page-info-text">
                        Call or text 877-355-7978
                        <br />
                        Email info@mylabsdirect.com
                    </h6>
                </div>
            </div>
        </section>
    </Layout>
);
export default Done;
