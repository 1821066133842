import React from 'react';
import {getNameString} from '../utils/Test';
import {
    ResultAccent,
    ResultBody,
    ResultContainer,
    ResultContent,
    ResultHeader,
    ResultItem,
    CornerIcon,
    CornerPhoto,
    Icon,
    Button
} from './styles/KitCard';

interface Props {
    kit: any;
}

const KitCard: React.FC<Props> = ({kit}) => {
    const regex = /^([a-zA-Z]{2}\-)?(0[1-6])\-\d{4}\-\d{4}$/;
    const result = regex.exec(kit.kit_id);
    const testID = result?.[2];
    // First, optional capture group ie (BW)-01-0000-0000, our affiliate ID
    const affiliateID = result?.[1];
    // If we have an affiliate prefix, this is a blood test
    const isBlood = affiliateID && affiliateID.length > 0;

    const createdDate = new Date(kit.createdDate).toLocaleDateString('en-us', {timeZone: 'UTC'});

    let testType = getNameString(kit);
    let url = '';
    if (isBlood) {
        url = `/test-kits/return`;
    } else {
        //TODO fix the urls for covid tests
        url =
            testID === '01' || testID === '04'
                ? `/registerkit/step/1?id=${kit.kit_id}`
                : testID === '02' || testID === '03' || testID === '05' || testID === '06'
                ? `/registerkit/antibody/step/1`
                : '/';
    }

    return (
        <ResultContainer>
            <ResultAccent />
            <ResultContent>
                <ResultHeader>
                    <p className='Heading04ExtraBold'>Kit Registration</p>
                    <CornerPhoto>
                        <CornerIcon className='fa-light fa-box-open' />
                    </CornerPhoto>
                </ResultHeader>
                <ResultBody>
                    <p className='Heading05ExtraBold'>{testType}</p>
                    <ResultItem>
                        <Icon className='fa-light fa-box fa-fw' />
                        <p className='ParagraphNormalRegular'>{`Kit ID: ${kit.kit_id}`}</p>
                    </ResultItem>
                    <ResultItem>
                        {/* <Icon className='fa-light fa-calendar-day fa-fw' /> */}
                        {/*<p className='ParagraphNormalRegular'>{`Registered on ${createdDate}`}</p>*/}
                    </ResultItem>
                </ResultBody>
                <Button className='PrimaryLarge ViewKitInstructions' to={url}>
                    {isBlood ? 'Return Your Kit' : 'View Kit Instructions'}
                </Button>
            </ResultContent>
        </ResultContainer>
    );
};

export default KitCard;
