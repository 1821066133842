import React from 'react';
import {CardContainer, Image, Button, Message, Heading} from './styles/ServiceModalCard';

interface Props {
    setServiceModalVisible: Function;
    message: string;
}

const ServiceModalCard: React.FC<Props> = ({setServiceModalVisible, message}) => {
    return (
        <CardContainer>
            <Image className="fa-light fa-wrench" />
            <Heading className="ParagraphNormalRegular">Service Status</Heading>
            <Message className="ParagraphNormalRegular">{message}</Message>
            <Button className="ParagraphLargeRegular" onClick={() => setServiceModalVisible(false)}>
                Close
            </Button>
        </CardContainer>
    );
};

export default ServiceModalCard;
