import styled from 'styled-components';
import {Device} from '../../services/constants';

export const SubmitFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    margin-top: auto;

    @media ${Device.mobile} {
        background-color: var(--neutral-100);
        padding: 24px;
    }
`;

export const SubmitContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const Cancel = styled.button`
    color: var(--error-500);
    background-color: var(--neutral-0);
    border: none;
    transition: 0.2s;
    align-self: center;

    @media ${Device.mobile} {
        background-color: var(--neutral-100);
    }

    &:hover {
        cursor: pointer;
        color: var(--error-50);
    }
`;
