import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep6.png';

const AntibodyStep6 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 6</h2>

            <h3 className="antibody-step-description">Collect Blood</h3>

            <p className="has-text-centered">
                Do not squeeze fingertip! This may decrease blood flow. Wipe away the first drop of blood. Position
                testing finger above collection tube and firmly push on hand and testing finger, letting new drops of
                blood collect into tube. Fill to the top line. Do not over or under fill. If more blood is needed,
                repeat the previous step (Step 5), using a new lancet, on a different finger.
                <br />
                <br />
                Do not test on pinkie finger.
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep6;
