import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from './hooks/useAuth';
// import {LocalStorageProvider} from './hooks/useLocalStorage';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
// import {createStore} from 'redux';
import {Provider} from 'react-redux';
import {store} from './store';
// import rootReducer from './reducers';
// import {saveState} from './localStorage';

const appInsights = new ApplicationInsights({
    config: {
        connectionString:
            'InstrumentationKey=d0397b95-ccaf-4642-9de0-c1d844d81e08;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/',
        enableAutoRouteTracking: true,
    },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

// store.subscribe(() => {
//     saveState({
//         patient: store.getState().patient,
//         tests: store.getState().tests,
//         kit: store.getState().kit,
//     });
// });

// ReactDOM.render(
//     <React.StrictMode>
//         <LocalStorageProvider>
//             <AuthProvider initialCheckToken={true}>
//                 <Provider store={store}>
//                     <Router>
//                         <App />
//                     </Router>
//                 </Provider>
//             </AuthProvider>
//         </LocalStorageProvider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        {/* <LocalStorageProvider> */}
        <AuthProvider initialCheckToken={false}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
        </AuthProvider>
        {/* </LocalStorageProvider> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
