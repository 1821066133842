import styled from 'styled-components';

export const PassError = styled.div`
    display: flex;
    align-items: center;
`;

export const PasswordErrorIcon = styled.i<{color: string}>`
    width: 16px;
    height: 16px;
    margin-right: 10px;
    font-size: 16px;
    color: ${(props) => props.color};
`;

export const PasswordErrorText = styled.p`
    width: 75%;
    line-height: 1.5;
`;
