import React from 'react';
import {arc, DefaultArcObject} from 'd3-shape';
import {scaleLinear} from 'd3-scale';

interface Props {
    value: number;
    min: number;
    max: number;
    color: string;
    type: string;
}

const Gauge: React.FC<Props> = ({value, min, max, color, type}) => {
    const defaultArcObject = {} as DefaultArcObject;
    const innerRadius = 0.87;
    const outerRadius = 1;
    const startAngle = -Math.PI / 2;
    const endAngle = Math.PI / 2;
    const cornerRadius = 1;

    const backgroundArc = arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(startAngle)
        .endAngle(endAngle)
        .cornerRadius(cornerRadius)(defaultArcObject)!!;

    const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
    const percent = percentScale(value);

    const angleScale = scaleLinear()
        .domain([0, 1])
        .range([-Math.PI / 2, Math.PI / 2])
        .clamp(true);

    const angle = angleScale(percent);

    const filledArc = arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(startAngle)
        .endAngle(angle)
        .cornerRadius(cornerRadius)(defaultArcObject)!!;

    const getCoordsOnArc = (angle: any, offset = 10) => [
        Math.cos(angle - Math.PI / 2) * offset,
        Math.sin(angle - Math.PI / 2) * offset,
    ];

    const markerLocation = getCoordsOnArc(angle, 1 - (outerRadius - innerRadius) / 2);

    let percentageFull = (value - min) / (max - min);

    if (percentageFull < 0) {
        percentageFull = 0;
    } else if (percentageFull > 1) {
        percentageFull = 1;
    }

    const markerRotation = 90 - 180 * (1 - percentageFull);

    return (
        <svg style={{overflow: 'visible'}} width="182px" height="91px" viewBox={[-1, -1, 2, 1].join(' ')}>
            <path d={backgroundArc} fill="var(--neutral-200)" mask="url(#refs)" />
            <path d={filledArc} fill={color} mask="url(#refs)" />
            <defs>
                <mask id="refs">
                    <rect x="-1" y="-1" width="100%" height="100%" fill="white" />
                    {type === 'doubleRef' && (
                        <>
                            {min !== 0 && <line x1="-1" x2="0" y1="-1" y2="0" stroke="black" strokeWidth="0.027" />}
                            <line x1="1" x2="0" y1="-1" y2="0" stroke="black" strokeWidth="0.027" />
                        </>
                    )}
                    {type === 'singleRef' && <line x1="0" x2="0" y1="-1" y2="0" stroke="black" strokeWidth="0.027" />}
                </mask>
            </defs>

            <rect
                x={markerLocation[0] - 0.05}
                y={markerLocation[1] - 0.15}
                width="0.1"
                height="0.3"
                rx="3%"
                transform-origin={`${markerLocation[0]} ${markerLocation[1]}`}
                transform={`rotate(${markerRotation})`}
                fill={color}
            />
        </svg>
    );
};

export default Gauge;
