import React from 'react';
import image from '../Resources/img/AntibodyStep1.png';
import {Layout} from '../Components';
import shippingExclamation from '../Resources/img/double-shipping-exclamation.png';

const Step1 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 1</h2>

            <h3 className="antibody-step-description">Prepare To Ship Collection Sample Back To MyLabsDirect</h3>

            <p className="has-text-centered">
                Before testing, arrange to return your collection sample the same day you test. Your pre-paid FedEx
                return label is included. Contact FedEx to have your sample picked up or you may drop off at a FedEx
                location. Call 1-800-GoFedEx and tell the agent you are shipping an at-home UN3373 test kit, or visit{' '}
                <b>fedex.com/labreturns</b>
                <br />
                <br />
                <img
                    src={shippingExclamation}
                    alt="Testing on Friday? Please call or text 877-355-7978 for a Saturday delivery return label!"
                />
            </p>
        </section>
    </Layout>
);
export default Step1;
