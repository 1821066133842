import React from 'react';
import {
    PageContainer,
    SupportOptions,
    SupportItem,
    SupportItemPhoto,
    SupportPage,
    SubText,
    BannerText,
    Frame,
    Box,
} from './styles/Support';

const Support: React.FC = () => {
    return (
        <PageContainer>
            <SupportPage>
                <BannerText className="Heading03Bold">Got A Question? We're Here To Help!</BannerText>
                <SupportOptions>
                    <SubText className="Heading04Bold">Support Options</SubText>
                    <Box>
                        <Frame>
                            <p className="ParagraphLargeBold">Call or Text</p>
                            <a href="tel:+18773557978" style={{textDecoration: 'none', color: 'inherit'}}>
                                <SupportItem>
                                    <SupportItemPhoto className="fa-light fa-phone" />
                                    <p className="ParagraphLargeRegular">(877) 355 - 7978</p>
                                </SupportItem>
                            </a>
                        </Frame>
                        <Frame>
                            <p className="ParagraphLargeBold">Email</p>
                            <a href="mailto:info@mylabsdirect.com" style={{textDecoration: 'none', color: 'inherit'}}>
                                <SupportItem>
                                    <SupportItemPhoto className="fa-light fa-envelope" />
                                    <p className="ParagraphLargeRegular">info@mylabsdirect.com</p>
                                </SupportItem>
                            </a>
                        </Frame>
                    </Box>
                </SupportOptions>
            </SupportPage>
        </PageContainer>
    );
};

export default Support;
