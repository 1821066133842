import DatePicker from 'react-datepicker';
import styled, {css} from 'styled-components';
import CreateAccountBackground from '../../assets/images/Create Account Background.jpeg';
import {Device} from '../../services/constants';

export const PageContainer = styled.div<{sent: boolean}>`
    display: flex;
    position: ${(props) => (props.sent ? 'fixed' : '')};
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh + 333px);
    background-image: url('${CreateAccountBackground}');
    background-color: rgba(0, 0, 0, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;

    @media ${Device.mobile} {
        height: 100%;
    }
`;
export const FormContainer = styled.div`
    width: calc(100% - 64px);
    max-width: 433px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--text-0);

    @media ${Device.mobile} {
        width: 100%;
        max-width: 100%;
        height: 100vh;
        margin: 0;
        padding: 8px 0 0 0;
        border-radius: 0;
    }
`;
export const FormBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    padding: 16px;
`;
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 433px;

    @media ${Device.mobile} {
        max-width: 100%;
    }
`;
export const TopForm = styled.div`
    max-width: 433px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0 20px;

    @media ${Device.mobile} {
        max-width: 100%;
    }
`;
export const Header = styled.div`
    height: 28px;
    font-size: min(9.3vw, 1.44rem);
    font-weight: bold;
    letter-spacing: 1.5px;
    text-align: left;
    text-wrap: nowrap;
    align-self: flex-start;
`;
export const Label = styled.label<{error: boolean}>`
    font-size: 1.2rem;
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 16px;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
Label.defaultProps = {
    className: 'ParagraphNormalRegular',
};
export const TopLabel = styled(Label)`
    width: 100%;
    min-width: calc(50% - 10px);
`;
// export const PasswordLabel = styled(Label)`
//     margin-bottom: 0;
// `
export const LabelText = styled.p`
    padding: 0 12px;
`;
export const PassportText = styled(LabelText)`
    font-size: 1.1rem;
`;
export const FormInput = styled.input<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const StyledDatePicker = styled(DatePicker)<{error: boolean | undefined}>`
    width: calc(100% - 56px);
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const RadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    padding-left: 12px;
    align-items: center;
`;
export const RadioOption = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;
export const RadioInput = styled(FormInput)`
    width: 20px;
    margin: 0;
`;
export const RadioLabel = styled.label``;
export const Select = styled.select<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;

    :hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
export const Caption = styled.p`
    text-align: center;
    margin: 6.5px auto;
`;
export const LeftIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 52px;
    color: var(--neutral-300);
    z-index: 1;
    line-height: 20px;
`;
// export const RightIcon = styled(FontAwesomeIcon)`
//     width: 20px;
//     height: 20px;
//     position: absolute;
//     right: 10px;
//     top: 52px;
//     color: var(--neutral-900);

//     :hover {
//         cursor: pointer;
//         color: var(--neutral-700);
//     }
// `
export const Info = styled.div`
    margin-bottom: 20px;
    font-size: 0.9rem;
    margin-top: 16px;
    color: var(--text-300);
`;
export const Optional = styled.div`
    padding-left: 12px;
    font-size: 0.8rem;
`;
// export const PasswordErrors = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 4px;
//     margin: 4px 0 0 0;
//     padding-left: 12px;
// `
// export const PasswordError = styled.div`
//     display: flex;
//     align-items: center;
// `
// export const PasswordErrorIcon = styled.img`
//     width: 16px;
//     height: 16px;
//     margin-right: 10px;
// `
// export const PasswordErrorText = styled.p`
//     // font-size: min(0.833rem, 3.2vw);
// `
export const Footer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
export const FooterLink = styled.a`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;

    :hover {
        color: var(--link-300);
    }
`;
export const Resend = styled.a`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: var(--link-300);
    }
`;
export const Notification = styled.p<{submitted: boolean; message: string}>`
    height: ${(props) => (props.submitted ? '21.6px' : '0')};
    margin-top: ${(props) => (props.submitted ? '20px' : '0')};
    overflow: hidden;
    color: ${(props) =>
        !props.submitted
            ? 'rgba(256, 256, 256, 0)'
            : props.message.substring(0, 9) === 'Could not'
            ? 'var(--error-500)'
            : 'var(--text-0)'};
    transition: height 0.2s, color 0.2s 0.2s;
`;
export const SubmitContainer = styled.div`
    position: relative;
`;
