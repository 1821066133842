import styled, {css} from 'styled-components';
import DatePicker from 'react-datepicker';
import {Device} from '../../services/constants';

export const PageContainer = styled.div`
    width: calc(100% - 16px);
    height: calc(100vh - 16px);
    display: flex;
    margin: 8px;
    margin-bottom: 0px;

    @media ${Device.mobile} {
        width: 100%;
        height: calc(100vh - 64px);
        margin: 0;
        margin-top: 16px;
    }
`;
export const DesktopMainMenu = styled.div`
    width: calc(25% - 64px);
    background-color: var(--neutral-50);
    border-radius: 8px 0 0 8px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
    padding: 32px;
`;

export const Header3 = styled.h3`
    margin: 0;
    letter-spacing: normal;
`;

export const DesktopMainMenuOptions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const DesktopMainMenuOption = styled.button<{ishovered: number}>`
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: var(--text-400);
    color: ${(props) => (props.ishovered ? 'var(--link-300)' : 'var(--text-600)')};

    &:hover {
        cursor: pointer;
    }
`;

export const OptionPhoto = styled.i<{ishovered: number}>`
    font-size: 24px;
    transition: 0.2s;
    color: ${(props) => (props.ishovered ? 'var(--link-300)' : 'var(--text-600)')};
`;

export const OptionText = styled.p`
    margin-left: 12px;
    transition: 0.2s;
`;

export const Menu = styled.div`
    width: 37.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-right: 1px solid var(--neutral-200);
    background-color: var(--neutral-0);
    overflow: auto;

    @media ${Device.mobile} {
        width: 100%;
        border-right: none;
    }
`;

export const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const EditMenu = styled.form`
    display: flex;
    flex-direction: column;
    width: 37.5%;
    height: 100%;
    border-radius: 0 8px 8px 0;
    background-color: var(--neutral-0);
    overflow: auto;

    @media ${Device.mobile} {
        width: 100%;
        border-radius: 0;
    }
`;

export const EditFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;

    @media ${Device.mobile} {
        padding: 24px;
    }
`;

export const Label = styled.label`
    font-size: 1rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 83px;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
`;

export const VariableLabel = styled(Label)<{error: boolean}>`
    color: ${(props) => (props.error ? 'var(--error-500)' : 'var(--text-600)')};
`;

export const LabelText = styled.p`
    padding-left: 12px;
    white-space: nowrap;
`;

export const FormInput = styled.input`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 1px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;
`;

export const VariableFormInput = styled(FormInput)<{error: boolean}>`
    border: ${(props) => (props.error ? 'solid 1px var(--error-500)' : 'solid 1px var(--neutral-600)')};
`;

export const PasswordContainer = styled.div``;

export const PasswordErrors = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 8px 0 0 0;
    padding-left: 12px;
`;

export const LeftIcon = styled.i`
    font-size: 20px;
    position: absolute;
    left: 12px;
    top: 43px;
    color: var(--neutral-300);
    transition: 0.2s;
    z-index: 1;
    line-height: 20px;
`;

export const RightIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 43px;
    color: var(--neutral-900);
    line-height: 20px;

    :hover {
        cursor: pointer;
        color: var(--neutral-700);
    }
`;

export const FullInput = styled(FormInput)`
    padding: 0 0 0 12px;
`;

export const DualContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
`;

export const DualLabel = styled(Label)`
    width: calc(50% - 4px);
`;

export const Select = styled.select`
    width: 100%;
    height: 52px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 1px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;

    :hover {
        cursor: pointer;
    }
`;

export const OptionalContainer = styled.div`
    width: 100%;
`;

export const Optional = styled.div`
    padding-left: 12px;
    margin-top: 8px;
    font-size: 0.8rem;
`;

export const StyledDatePicker = styled(DatePicker)<{error: boolean | undefined}>`
    width: calc(100% - 56px);
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;

export const RadioInput = styled(FormInput)<{error: boolean}>`
    border: ${(props) => (props.error ? 'solid 1px var(--error-500)' : 'solid 1px var(--neutral-600)')};
    width: 20px;
    height: 20px;
    margin: 0;
    &:hover {
        cursor: pointer;
    }
`;

export const Checkbox = styled(RadioInput)``;

export const CheckboxInputContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const CheckboxLabel = styled.label<{error: boolean | undefined}>`
    padding-left: 12px;
    margin-right: 32px;
    &:hover {
        cursor: pointer;
    }
    ${(props) =>
        !!props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;
