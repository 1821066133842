import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import React from 'react';
import type {RootState} from '../store';

// Define a type for the slice state
interface BannerState {
    visible: boolean;
    message: React.ReactElement;
}

// Define the initial state using that type
const initialState: BannerState = {
    visible: false,
    message: React.createElement('p', null, 'This is a message'),
};

export const bannerSlice = createSlice({
    name: 'banner',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        showBanner: (state) => {
            state.visible = true;
        },
        hideBanner: (state) => {
            state.visible = false;
        },
        setMessage: (state, action: PayloadAction<React.ReactElement>) => {
            state.message = action.payload;
        },
        // state.id = action.payload.id;
        // state.firstName = action.payload.firstName;
        // state.lastName = action.payload.lastName;
        // state.fullName = action.payload.fullName;
        // state.birthdate = action.payload.birthdate;
        // increment: (state) => {
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        // // Use the PayloadAction type to declare the contents of `action.payload`
        // incrementByAmount: (state, action: PayloadAction<number>) => {
        //     state.value += action.payload;
        // },
    },
});

// export const {increment, decrement, incrementByAmount} = counterSlice.actions;
export const {showBanner, hideBanner, setMessage} = bannerSlice.actions;

export const bannerState = (state: RootState) => state.banner;

export default bannerSlice.reducer;
