import React, {useState} from 'react';
import {getNameString} from '../utils/Test';
import {
    CardContainer,
    TopContainer,
    BlackIcon,
    TestNameText,
    InfoContainer,
    StatusIcon,
    StatusText,
    ExpandButton,
    ExpandButtonImage,
    RowContainer,
    DetailsContainer,
    SeparatorLine,
    TitleText,
    InstructionsButton,
    InstructionsButtonIcon,
} from './styles/KitHistoryItem';

interface Props {
    kit: any;
    hasStatus: boolean;
}

const KitHistoryItem: React.FC<Props> = ({kit, hasStatus}) => {
    const [expanded, setExpanded] = useState(false);

    const oidPrefix = kit.oid.substring(kit.oid.length - 10, kit.oid.length - 8);

    //converts kit.test_type to a readable string, e.g. "thyroid-test" to "Thyroid Test"
    let test_type: string = '';
    if (kit.test_type.length > 1 && kit.test_type[1] !== null) {
        let test_arr = kit.test_type[1].split('-'); //makes array of the words
        for (let i = 0; i < test_arr.length; i++) {
            if (test_arr[i] === 'std') test_arr[i] = 'STD'; //makes all of std capital, not just first letter
            else test_arr[i] = test_arr[i][0].toUpperCase() + test_arr[i].substr(1); //makes first letter capital
        }
        test_type = test_arr.join(' '); //joins all the words together into a string, separated by spaces
    }

    //test_name to display what the kits test is
    const test_name = getNameString(kit);

    const statusText =
        kit.status?.toLowerCase() === 'resulted'
            ? 'Completed'
            : kit.status?.toLowerCase() === 'created'
            ? 'Activated: waiting for kit to be returned'
            : kit.status?.toLowerCase() === 'collected'
            ? 'Activated: kit has been collected'
            : kit.status?.toLowerCase() === 'received'
            ? 'Activated: kit has been received'
            : kit.status?.toLowerCase() === 'processing'
            ? 'Activated: waiting on results to process'
            : kit.status;

    return (
        <CardContainer isActive={expanded}>
            <TopContainer onClick={() => setExpanded(!expanded)}>
                <RowContainer>
                    <InfoContainer>
                        <BlackIcon className="fa-light fa-box-open" />
                        <TestNameText>{test_name}</TestNameText>
                    </InfoContainer>
                    <ExpandButton onClick={() => setExpanded(!expanded)}>
                        <ExpandButtonImage
                            className={expanded ? 'fa-light fa-circle-chevron-up' : 'fa-light fa-circle-chevron-down'}
                        />
                    </ExpandButton>
                </RowContainer>
                {hasStatus && (
                    <InfoContainer>
                        <StatusIcon
                            className={
                                kit.status?.toLowerCase() === 'resulted'
                                    ? 'fa-light fa-circle-check'
                                    : 'fa-light fa-clock'
                            }
                            color={
                                kit.status?.toLowerCase() === 'resulted' ? 'var(--success-500)' : 'var(--warning-500)'
                            }
                        />
                        <StatusText
                            color={
                                kit.status?.toLowerCase() === 'resulted' ? 'var(--success-500)' : 'var(--warning-500)'
                            }>
                            {statusText}
                        </StatusText>
                    </InfoContainer>
                )}
            </TopContainer>
            {expanded && (
                <>
                    <SeparatorLine />
                    <TitleText>KIT DETAILS</TitleText>
                    <DetailsContainer>
                        <InfoContainer>
                            <BlackIcon className="fa-light fa-calendar-day fa-fw" />
                            <TestNameText>
                                Activation Date:{' '}
                                {new Date(kit.createdDate).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                            </TestNameText>
                        </InfoContainer>
                        <InfoContainer>
                            <BlackIcon className="fa-light fa-box-open fa-fw" />
                            <TestNameText>Kit ID: {kit.kit_id}</TestNameText>
                        </InfoContainer>
                    </DetailsContainer>
                    <TitleText>INSTRUCTIONS AND MANUALS</TitleText>
                    <InfoContainer>
                        {(oidPrefix === 'ST' || //if the test is an STD test, show urine test info
                            test_type.toLowerCase().includes('herpes') ||
                            test_type.toLowerCase().includes('std')) && (
                            <InstructionsButton
                                className="ParagraphNormalRegular"
                                onClick={() => {
                                    window.open(
                                        'https://mldmedia.blob.core.windows.net/public/mld-urine-instructions-2022.pdf'
                                    );
                                }}>
                                <InstructionsButtonIcon className="fa-light fa-file-image" />
                                <div>Urine Sample Method Instructions</div>
                            </InstructionsButton>
                        )}
                        {oidPrefix !== 'ST' && //if the test isnt STD test, show blood test info
                            !test_type.toLowerCase().includes('herpes') &&
                            !test_type.toLowerCase().includes('std') && (
                                <>
                                    <InstructionsButton
                                        className="ParagraphNormalRegular"
                                        onClick={() => {
                                            window.open(
                                                'https://mldmedia.blob.core.windows.net/public/Final Finger Prick Instructions.pdf'
                                            );
                                        }}>
                                        <InstructionsButtonIcon className="fa-light fa-file-image" />
                                        <div>Finger Prick Method Instructions</div>
                                    </InstructionsButton>
                                    <InstructionsButton
                                        className="ParagraphNormalRegular"
                                        onClick={() => {
                                            window.open(
                                                'https://mldmedia.blob.core.windows.net/public/Quickdraw-instructions-2022.pdf'
                                            );
                                        }}>
                                        <InstructionsButtonIcon className="fa-light fa-file-image" />
                                        <div>Quick Draw Method Instructions</div>
                                    </InstructionsButton>
                                </>
                            )}
                    </InfoContainer>
                </>
            )}
        </CardContainer>
    );
};

export default KitHistoryItem;
