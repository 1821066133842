import styled from 'styled-components';

export const CardContainer = styled.div`
    max-width: 513px;
    width: calc(100% - 80px);
    margin: 16px auto;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    box-shadow: var(--card-shadow);
    background-color: var(--neutral-0);
    text-align: center;
`;
export const CardIcon = styled.i`
    font-size: 64px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
export const Subtitle = styled.p`
    letter-spacing: normal;
`;
export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--neutral-100);
`;
export const CardLink = styled.a`
    color: var(--link-600);
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: var(--link-300);
    }
`;
