import React from 'react';
import {CardContainer, DashboardIcon, Separator, KitLink, CardLink} from './styles/EmptyDashboardCard';

const EmptyDashboardCard: React.FC = () => {
    return (
        <CardContainer>
            <DashboardIcon className="fa-light fa-house-user" />
            <p className="Heading05Bold">Welcome To Your MyLabsDirect Dashboard</p>
            <p className="ParagraphSmallRegular">
                Your latest lab results and other important information will be shown here
            </p>
            <Separator />
            <p className="ParagraphMediumRegular">
                To get started, <KitLink to="/test-kits/activate/">register a kit</KitLink> or{' '}
                <CardLink href="https://mylabsdirect.com/at-home-test-kits/" target="_blank">
                    {' '}
                    shop for a test
                </CardLink>
            </p>
        </CardContainer>
    );
};

export default EmptyDashboardCard;
