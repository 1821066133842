import styled from 'styled-components';

export const PageContainer = styled.div`
    width: calc(100% - 600px); //this only affects mobile sizing
    margin: 32px 16px 0px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;
export const ContextMenuOverlay = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
`;
export const SingleResultPage = styled.div`
    max-width: 820px;
    width: 100%;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 0;
`;

export const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
`;

export const HeaderContainer = styled.div`
    position: relative;
    z-index: 0;
    width: calc(100% - 32px);
    height: 80px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 0 16px;
    border-radius: 8px;
    background-color: var(--neutral-0);
`;

export const SubHeaderContainer = styled(HeaderContainer)`
    height: 16px;
    padding: 16px;
    border-radius: 0 0 8px;
    align-items: center;
    z-index: -1;
    color: var(--text-400);
    background-color: var(--neutral-50);
`;

export const HeaderInfo = styled.div`
    height: 48px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
`;

export const HeaderButtonContainer = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;

export const MenuButton = styled.button`
    width: 32px;
    height: 32px;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--neutral-200);
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;

export const ContextMenu = styled.div<{visible: boolean; missingOption: boolean}>`
    position: absolute;
    top: 66px;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 228px;
    height: ${(props) => (props.visible ? (props.missingOption ? '36px' : '72px') : '0')};
    overflow: hidden;
    padding: ${(props) => (props.visible ? '8px' : '0')};
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    background-color: var(--neutral-500);
    transition: 0.2s;
`;

export const ContextMenuItem = styled.button<{isVisible: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${(props) => (props.isVisible ? '100%' : '0')};
    padding: ${(props) => (props.isVisible ? '8px' : '0')};
    border: none;
    border-radius: 8px;
    background-color: var(--neutral-500);
    color: var(--text-0);
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--neutral-400);
    }
    &:active {
        background-color: var(--neutral-300);
    }
`;

export const DownloadMenuItem = styled(ContextMenuItem)<{isVisible: boolean; isDownloading: boolean}>`
    background-color: ${(props) => (props.isDownloading ? 'var(--neutral-400) !important' : 'var(--neutral-500)')};
    color: ${(props) => (props.isDownloading ? 'var(--text-100)' : 'var(--text-0)')};

    &:hover {
        cursor: ${(props) => (props.isDownloading ? 'default' : 'pointer')};
    }
`;

export const ContextMenuText = styled.p`
    font-size: 0.833rem;
`;

export const MenuIcon = styled.i`
    width: 20px;
    height: 20px;
    font-size: 20px;
`;

export const DownloadIcon = styled(MenuIcon)<{isspinning: number}>`
    animation: ${(props) => (props.isspinning ? 'spin 3s linear infinite' : '')};

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const HeaderButton = styled.i`
    width: 32px;
    height: 32px;
    font-size: 32px;
    color: black;
`;

export const TestInfoContainer = styled.div`
    width: 100%;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0;
`;

export const HeadingText = styled.p`
    font-size: min(4vw, 1.44rem);
    text-align: center;
`;

export const SubHeading = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const ClipboardIcon = styled.i`
    font-size: 20px;
`;

export const SubHeadingText = styled.p`
    text-align: center;
`;
