import React from 'react';
import {useState} from 'react';
import HealthHistory from './HealthHistory';
import MetricDescription from './MetricDescription';
import {useAppSelector} from '../hooks';
import {selectCurrentPatient} from '../slices/patientSlice';
import {Mobile, Desktop} from './MediaQueries';
import {
    ResultContainer,
    Header,
    HeaderButton,
    HeaderButtonImage,
    HealthHistoryContainer,
    InnerHeader,
    Stretch,
    Button,
    ButtonContainer,
    ExpandedContainer,
} from './styles/HealthSummaryCard';

interface Props {
    pinnedMetrics: any;
}

const HealthSummaryCard: React.FC<Props> = ({pinnedMetrics}) => {
    const [expanded, setExpanded] = useState(false);
    const [currentMetricIndex, setCurrentMetricIndex] = useState(0);

    const currentPatient = useAppSelector(selectCurrentPatient);
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    let {firstName, lastName} = currentPatient;

    const currentPinnedMetrics = [];

    for (let i = 0; i < pinnedMetrics.length; i++) {
        if (
            firstName &&
            lastName &&
            pinnedMetrics[i].firstName &&
            pinnedMetrics[i].lastName &&
            pinnedMetrics[i].firstName.toLowerCase() === firstName.toLowerCase() &&
            pinnedMetrics[i].lastName.toLowerCase() === lastName.toLowerCase()
        ) {
            currentPinnedMetrics.push(pinnedMetrics[i].metric);
        }
    }

    const testDisplayName =
        currentPinnedMetrics[currentMetricIndex] && currentPinnedMetrics[currentMetricIndex].includes('; ')
            ? currentPinnedMetrics[currentMetricIndex].split('; ')[1]
            : currentPinnedMetrics[currentMetricIndex];

    const nextMetric = () => {
        if (currentMetricIndex + 1 === currentPinnedMetrics.length) {
            setCurrentMetricIndex(0);
        } else {
            setCurrentMetricIndex(currentMetricIndex + 1);
        }
    };

    const prevMetric = () => {
        if (currentMetricIndex === 0) {
            setCurrentMetricIndex(currentPinnedMetrics.length - 1);
        } else {
            setCurrentMetricIndex(currentMetricIndex - 1);
        }
    };

    return (
        <>
            {currentPinnedMetrics.length > 0 && (
                <ResultContainer isExpanded={expanded ? 1 : 0}>
                    <Header>
                        <InnerHeader>
                            <Desktop>
                                <p className="Heading06Bold">{testDisplayName}</p>
                                <Stretch />
                            </Desktop>
                            {currentPinnedMetrics.length > 1 && (
                                <HeaderButton onClick={nextMetric}>
                                    <HeaderButtonImage className="fa-solid fa-circle-chevron-left" />
                                </HeaderButton>
                            )}
                            <Mobile>
                                <p className="Heading06Bold">{testDisplayName}</p>
                            </Mobile>
                            {currentPinnedMetrics.length > 1 && (
                                <HeaderButton onClick={prevMetric}>
                                    <HeaderButtonImage className="fa-solid fa-circle-chevron-right" />
                                </HeaderButton>
                            )}
                        </InnerHeader>
                    </Header>
                    <HealthHistoryContainer>
                        <HealthHistory metric={currentPinnedMetrics[currentMetricIndex]} />
                    </HealthHistoryContainer>
                    <ExpandedContainer isExpanded={expanded ? 1 : 0}>
                        <MetricDescription
                            test={currentPinnedMetrics[currentMetricIndex]}
                            testDisplayName={testDisplayName}
                        />
                    </ExpandedContainer>
                    <ButtonContainer>
                        <Button
                            color="var(--mld-scheme-health-summary)"
                            className="PrimaryLarge ViewMyLabResults"
                            onClick={() => setExpanded(!expanded)}>
                            {expanded ? 'Hide Details' : 'View Details'}
                        </Button>
                    </ButtonContainer>
                </ResultContainer>
            )}
        </>
    );
};

export default HealthSummaryCard;
