import styled from 'styled-components';
import {Device} from '../../services/constants';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc(100% - 32px);
    max-width: 414px;
    height: auto;
    margin: 64px 16px 0 16px;
    border-radius: 8px;
    box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    z-index: 3;
    transition: 0.2s;

    @media ${Device.mobile} {
        width: 100%;
        max-width: 100%;
        height: calc(100% - 64px);
        margin: 64px 16px 0 16px;
        border-radius: '0';
    }
`;
export const Header = styled.div<{isDetailed: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${(props) => (props.isDetailed ? '64px' : '96px')};
    border-radius: 8px 8px 0 0;
    background-color: var(--primary-300);
    transition: 0.2s;

    @media ${Device.mobile} {
        border-radius: 0;
    }
`;
export const HeaderText = styled.p`
    color: var(--text-0);
    text-align: center;
`;
export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(100% - 48px);
    max-height: 392px;
    overflow: auto;
    padding: 24px;

    @media ${Device.mobile} {
        max-height: 100%;
    }
`;
export const Heading = styled.p`
    text-align: center;
`;
export const InfoMenu = styled.div`
    margin-bottom: 56px;
`;
export const InfoMenuItem = styled.div`
    display: flex;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--neutral-100);

    &:hover {
        cursor: pointer;
    }
`;
export const Chevron = styled.i`
    font-size: 16px;
`;
export const Back = styled.i`
    position: absolute;
    left: 0;
    font-size: 24px;
    margin: 0 24px;
    color: white;
    &:hover {
        cursor: pointer;
    }
`;
export const FooterButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    height: 48px;
    margin-top: '';
    padding: 24px;
    border-radius: 0 0 8px 8px;
    background-color: var(--neutral-0);

    @media ${Device.mobile} {
        margin-top: auto;
        border-radius: 0;
        background-color: var(--neutral-100);
    }
`;
export const FooterButton = styled.button`
    width: 100%;
    height: 48px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    color: var(--text-0);
    background-color: var(--neutral-600);
    line-height: 1.25;
    transition: 0.2s;
    &:hover {
        cursor: pointer;
        background-color: var(--neutral-800);
    }
    &:active {
        background-color: var(--neutral-500);
    }
`;
export const Image = styled.i`
    width: 48px;
    height: 48px;
    font-size: 48px;
    color: white;
`;
