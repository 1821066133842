import React from 'react';
import {AntibodyLayout} from '../../Components';

import image from '../../Resources/img/AntibodyStep7.png';

const AntibodyStep7 = () => (
    <AntibodyLayout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Step 7</h2>

            <h3 className="antibody-step-description">Prepare Sample For Return</h3>

            <p className="has-text-centered">
                Wash hands and apply bandage to testing finger(s). Firmly apply cap to collection tube. Rotate the
                collection tube up and down, 5 to 10 times, to properly mix the sample for accurate test results.
                <br />
                <br />
                Place collection tube into biohazard bag. Seal bag.
            </p>
        </section>
    </AntibodyLayout>
);
export default AntibodyStep7;
