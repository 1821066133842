import {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import logo from '../assets/theme-full-color.svg';
import MicroscopeIcon from '../assets/Lab Results.svg';
import {useAuth} from '../hooks/useAuth';
import {Desktop} from './MediaQueries';
import settings from '../settings';
import axios from 'axios';
import {useAppDispatch, useAppSelector} from '../hooks';
import {setCurrentPatient, selectCurrentPatient, setSubPatients, selectSubPatients} from '../slices/patientSlice';
import {
    NavBar,
    NavItem,
    NavItemImage,
    NavLinks,
    BottomLink,
    BottomLinks,
    LabResultsPhoto,
    LinkText,
    Logo,
    LogoLink,
    LogoutPhoto,
    UserFrame,
    UserName,
    UserPhoto,
    MenuButton,
    MenuIcon,
    MicroscopeImage,
    HeaderButton,
    HealthSummaryPhoto,
    ContextMenu,
    ContextMenuDivider,
    ContextMenuItem,
    ContextMenuText,
    DashboardPhoto,
    SmallNavItemImage,
    ExternalNavItem,
    ShopTestsPhoto,
    TestKitsPhoto,
    SupportPhoto,
    AdminPhoto,
} from './styles/Nav';

const Nav = (props: any) => {
    const dispatch = useAppDispatch();
    const {user, token, logout, setToken} = useAuth();
    const location = useLocation();
    const {navVisible, setNavVisible, adminBanner, setAdminBanner} = props;

    const [currentHover, setCurrentHover] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();

    const patients = useAppSelector(selectSubPatients);
    const currentPatient = useAppSelector(selectCurrentPatient);

    useEffect(() => {
        // Bind menu close event (to close menu on a click outside of it)
        document.addEventListener('mousedown', (ev) => {
            if (!menuRef) return;
            // @ts-ignore
            if ((menuRef.current && menuRef.current.contains(ev.target)) || ev.target.id === 'nav-menu-button-image')
                return;

            setMenuVisible(false);
        });
    }, [menuRef]);

    async function handleLogout() {
        (window as any).hj('event', 'v2_navbar_link_logout');
        setNavVisible(false);

        // We need to call the /logout endpoint to clear our httpOnly cookie
        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            withCredentials: true,
        };

        await axios(`${settings.apiURL}/api/logout`, resultsOptions);
        setAdminBanner(false);
        logout();
        dispatch({type: 'LOGOUT'});
        navigate('/login');
    }

    return (
        <>
            <NavBar navVisible={navVisible} adminBanner={adminBanner} setAdminBanner={setAdminBanner}>
                {/* @TODO Need to handle uploading user photos */}
                <Desktop>
                    <LogoLink
                        to="/"
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_mldLogo');
                        }}>
                        <Logo src={logo} />
                    </LogoLink>
                </Desktop>
                <UserFrame>
                    <UserPhoto className="fa-light fa-user" />
                    <UserName className="ParagraphNormalRegular">{currentPatient.fullName}</UserName>
                    <MenuButton
                        onClick={() => {
                            if (!menuVisible) {
                                (window as any).hj('event', 'v2_navbar_profileMenu');
                            }

                            setMenuVisible(!menuVisible);
                        }}>
                        <HeaderButton id="nav-menu-button-image" className="fa-light fa-circle-ellipsis" />
                    </MenuButton>
                    <ContextMenu ref={menuRef} visible={menuVisible}>
                        {patients.length > 1 && (
                            <>
                                <ContextMenuItem
                                    to="/patients"
                                    $visible={menuVisible}
                                    onClick={() => {
                                        (window as any).hj('event', 'v2_navbar_profileMenu_patientSwitcher');
                                        setMenuVisible(false);
                                        setNavVisible(false);
                                    }}>
                                    <ContextMenuText className="ParagraphNormalRegular">Switch Patient</ContextMenuText>
                                    <MenuIcon className="fa-light fa-users" />
                                </ContextMenuItem>
                                <ContextMenuDivider visible={menuVisible} />
                            </>
                        )}
                        <ContextMenuItem
                            to="/settings"
                            $visible={menuVisible}
                            onClick={() => {
                                (window as any).hj('event', 'v2_navbar_profileMenu_acccountSettings');
                                setMenuVisible(false);
                                setNavVisible(false);
                            }}>
                            <ContextMenuText className="ParagraphNormalRegular">Account Settings</ContextMenuText>
                            <MenuIcon className="fa-light fa-gear" />
                        </ContextMenuItem>
                    </ContextMenu>
                </UserFrame>
                <NavLinks>
                    {/* <ConnectingLine /> */}
                    <NavItem
                        to="/"
                        ishovered={currentHover === 'dashboard' ? 1 : 0}
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_link_dashboard');
                            setNavVisible(false);
                        }}
                        onMouseEnter={() => setCurrentHover('dashboard')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <DashboardPhoto
                            ishovered={currentHover === 'dashboard' ? 1 : 0}
                            isCurrentPage={location.pathname === '/'}>
                            <SmallNavItemImage
                                className="fa-light fa-house-user fa-fw"
                                style={{fontSize: '20px', marginTop: '1px'}}
                            />
                        </DashboardPhoto>
                        <LinkText className={`ParagraphNormal${location.pathname === '/' ? 'ExtraBold' : 'Regular'}`}>
                            Dashboard
                        </LinkText>
                    </NavItem>
                    <NavItem
                        to="/results"
                        ishovered={currentHover === 'lab results' ? 1 : 0}
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_link_labResults');
                            setNavVisible(false);
                        }}
                        onMouseEnter={() => setCurrentHover('lab results')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <LabResultsPhoto
                            ishovered={currentHover === 'lab results' ? 1 : 0}
                            isCurrentPage={
                                location.pathname === '/results' || location.pathname.split('/')[1] === 'result'
                            }>
                            <MicroscopeImage src={MicroscopeIcon} alt="" />
                        </LabResultsPhoto>
                        <LinkText
                            className={`ParagraphNormal${
                                location.pathname === '/results' || location.pathname.split('/')[1] === 'result'
                                    ? 'ExtraBold'
                                    : 'Regular'
                            }`}>
                            Lab Results
                        </LinkText>
                    </NavItem>
                    <NavItem
                        to="/health-summary"
                        ishovered={currentHover === 'health summary' ? 1 : 0}
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_link_healthProfile');
                            setNavVisible(false);
                        }}
                        onMouseEnter={() => setCurrentHover('health summary')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <HealthSummaryPhoto
                            ishovered={currentHover === 'health summary' ? 1 : 0}
                            isCurrentPage={location.pathname === '/health-summary'}>
                            <NavItemImage className="fa-light fa-heart-pulse fa-fw" style={{marginTop: '1px'}} />
                        </HealthSummaryPhoto>
                        <LinkText
                            className={`ParagraphNormal${
                                location.pathname === '/health-summary' ? 'ExtraBold' : 'Regular'
                            }`}>
                            Health Profile
                        </LinkText>
                    </NavItem>
                    <ExternalNavItem
                        href="https://mylabsdirect.com/at-home-test-kits/"
                        target="_blank"
                        rel="noreferrer"
                        ishovered={currentHover === 'shop tests' ? 1 : 0}
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_link_shopForTests');
                        }}
                        onMouseEnter={() => setCurrentHover('shop tests')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <ShopTestsPhoto
                            ishovered={currentHover === 'shop tests' ? 1 : 0}
                            isCurrentPage={location.pathname === '/shop'}>
                            <SmallNavItemImage className="fa-light fa-cart-plus fa-fw" />
                        </ShopTestsPhoto>
                        <LinkText className="ParagraphNormalRegular">Shop For Tests</LinkText>
                    </ExternalNavItem>
                    <NavItem
                        to="/test-kits/"
                        ishovered={currentHover === 'test kits' ? 1 : 0}
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_link_testKits');
                            setNavVisible(false);
                        }}
                        onMouseEnter={() => setCurrentHover('test kits')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <TestKitsPhoto
                            ishovered={currentHover === 'test kits' ? 1 : 0}
                            isCurrentPage={location.pathname === '/test-kits/'}>
                            <SmallNavItemImage className="fa-light fa-box-open fa-fw" />
                        </TestKitsPhoto>
                        <LinkText className="ParagraphNormalRegular">Test Kits</LinkText>
                    </NavItem>
                    <NavItem
                        to="/test-kits/activate"
                        ishovered={currentHover === 'register kit' ? 1 : 0}
                        onMouseEnter={() => setCurrentHover('register kit')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <TestKitsPhoto
                            ishovered={currentHover === 'register kit' ? 1 : 0}
                            isCurrentPage={location.pathname === '/activate-kit/'}>
                            <SmallNavItemImage className="fa-light fa-box fa-fw" />
                        </TestKitsPhoto>
                        <LinkText className="ParagraphNormalRegular">Register a Kit</LinkText>
                    </NavItem>
                    {/* <SelectionCard onClick={() => navigate('/test-kits/activate')}>
                                    <CardIcon className="fa-light fa-box-open fa-fw" />
                                    <CardText>Activate a kit</CardText>
                                </SelectionCard> */}
                    <NavItem
                        to="/support"
                        ishovered={currentHover === 'support' ? 1 : 0}
                        onClick={() => {
                            (window as any).hj('event', 'v2_navbar_link_support');
                            setNavVisible(false);
                        }}
                        onMouseEnter={() => setCurrentHover('support')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <SupportPhoto
                            ishovered={currentHover === 'support' ? 1 : 0}
                            isCurrentPage={location.pathname === '/support'}>
                            <NavItemImage className="fa-light fa-phone fa-fw" />
                        </SupportPhoto>
                        <LinkText
                            className={`ParagraphNormal${location.pathname === '/support' ? 'ExtraBold' : 'Regular'}`}>
                            Support
                        </LinkText>
                    </NavItem>
                    {user.userInfo?.admin && !user.userInfo?.impersonate_id && (
                        <NavItem
                            to="/admin"
                            ishovered={currentHover === 'admin' ? 1 : 0}
                            onClick={() => {
                                setNavVisible(false);
                            }}
                            onMouseEnter={() => setCurrentHover('admin')}
                            onMouseLeave={() => setCurrentHover('')}>
                            <AdminPhoto
                                ishovered={currentHover === 'admin' ? 1 : 0}
                                isCurrentPage={location.pathname === '/admin'}>
                                <SmallNavItemImage className="fa-light fa-lock fa-fw" />
                            </AdminPhoto>
                            <LinkText
                                className={`ParagraphNormal${
                                    location.pathname === '/admin' ? 'ExtraBold' : 'Regular'
                                }`}>
                                Admin
                            </LinkText>
                        </NavItem>
                    )}
                </NavLinks>
                <BottomLinks>
                    {/* <NavItem
                        to="/settings"
                        ishovered={currentHover === 'settings' ? 1 : 0}
                        onClick={() => {
                        setNavVisible(false);
                        }}
                        onMouseEnter={() => setCurrentHover('settings')}
                        onMouseLeave={() => setCurrentHover('')}
                    >
                        <BottomLinkPhoto icon={faCog} fixedWidth ishovered={currentHover === 'settings' ? 1 : 0} />
                        <LinkText className={`ParagraphNormal${location.pathname === '/settings' ? 'ExtraBold' : 'Regular'}`}>Settings</LinkText>
                    </NavItem>
                    <Separator /> */}
                    <BottomLink
                        to="/logout"
                        ishovered={currentHover === 'logout' ? 1 : 0}
                        onClick={handleLogout}
                        onMouseEnter={() => setCurrentHover('logout')}
                        onMouseLeave={() => setCurrentHover('')}>
                        <LogoutPhoto
                            className="fa-light fa-arrow-right-from-bracket fa-fw"
                            ishovered={currentHover === 'logout' ? 1 : 0}
                        />
                        <LinkText className="ParagraphNormalRegular">Logout</LinkText>
                    </BottomLink>
                </BottomLinks>
            </NavBar>
        </>
    );
};

export default Nav;
