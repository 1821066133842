import styled from 'styled-components';

export const AdminBanner = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 22px;
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 24px 16px;
    background-color: var(--error-50);
    z-index: 1000;
    margin: 0 auto;
    font-size: 0.833rem;
    text-align: center;
    white-space: nowrap;
    overflow: auto;
`;

export const CookieBanner = styled.div`
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px 16px;
    background-color: var(--neutral-100); //TODO find preferred color
    z-index: 1000;
    margin: 0 auto;
    font-size: 0.833rem;
    text-align: center;
    white-space: nowrap;
    overflow: auto;
`;

export const BannerContent = styled.div``;

export const ExitButton = styled.button`
    position: absolute;
    left: 10px;
    top: 10px;
    border: none;
    background: none;

    &:hover {
        cursor: pointer;
    }
`;
