// import axios from 'axios';
// import {useAuth} from '../hooks/useAuth';
// import settings from '../settings';

// const {user, token} = useAuth();

export const getTypeString = (test: any) => {
    //converts kit.test_type to a readable string, e.g. "thyroid-test" to "Thyroid Test"
    let test_type: string = '';
    if (test.test_type.length > 1 && test.test_type[1] !== null) {
        let test_arr = test.test_type[1].split('-'); //makes array of the words
        for (let i = 0; i < test_arr.length; i++) {
            if (test_arr[i] === 'std') test_arr[i] = 'STD'; //makes all of std capital, not just first letter
            else test_arr[i] = test_arr[i][0].toUpperCase() + test_arr[i].substr(1); //makes first letter capital
        }
        test_type = test_arr.join(' '); //joins all the words together into a string, separated by spaces
    }
    return test_type;
};

export const getNameString = (test: any) => {
    if (test.name) {
        if (test.name.includes('DTC - ')) {
            return test.name.substring(6, test.name.length);
        }
        return test.name;
    }

    //Keep this here for backwards compatibility
    const oidPrefix = test.oid.substring(test.oid.length - 10, test.oid.length - 8);
    let test_type: string = '';
    if (test.test_type.length > 1 && test.test_type[1] !== null) {
        let test_arr = test.test_type[1].split('-'); //makes array of the words
        for (let i = 0; i < test_arr.length; i++) {
            if (test_arr[i] === 'std') test_arr[i] = 'STD'; //makes all of std capital, not just first letter
            else test_arr[i] = test_arr[i][0].toUpperCase() + test_arr[i].substr(1); //makes first letter capital
        }
        test_type = test_arr.join(' '); //joins all the words together into a string, separated by spaces
    }

    //returns what the kits test is
    return oidPrefix === 'CV'
        ? 'Covid-19 RT-PCR Test' //covid tests first
        : oidPrefix === 'AB'
        ? 'Covid-19 Antibody IgG Test'
        : oidPrefix === 'RP'
        ? 'COVID-19 Rapid RT-PCR Test'
        : oidPrefix === 'RA'
        ? 'Rapid COVID-19 Antigen Test'
        : oidPrefix === 'BT'
        ? 'Blood Type Test'
        : oidPrefix === 'CF'
        ? 'Rapid Flu/COVID 19 Test'
        : test_type //if it has a test_type attribute, show it
        ? test_type
        : test.profile //if it has a profile, show it
        ? test.profile
        : oidPrefix === 'AL' //defaults if missing info
        ? 'Allergy Test'
        : oidPrefix === 'ST'
        ? 'STD Test'
        : test.results.length > 0 //if its resulted, show its panel name
        ? test.results[0].panel_name || test.results[0].test_name
        : oidPrefix === 'BW'
        ? 'Blood Wellness Test'
        : `Kit ${test.oid}`; //if all else fails, shows e.g. Kit AD0112320402
};

export const getCategory = (test: any) => {
    const oidPrefix = test.oid.substring(test.oid.length - 10, test.oid.length - 8);

    return oidPrefix === 'CV' || oidPrefix === 'RP' || oidPrefix === 'RA'
        ? 'covid'
        : oidPrefix === 'CF'
        ? 'covid-flu'
        : oidPrefix === 'AB'
        ? 'covid-antibodies'
        : oidPrefix === 'AL'
        ? 'allergy'
        : oidPrefix === 'ST'
        ? 'std'
        : oidPrefix === 'BW'
        ? 'blood-wellness'
        : //TODO: add different types of blood wellness test check
          '';
};

export const getCollectionType = (test: any) => {
    const oidPrefix = test.oid.substring(test.oid.length - 10, test.oid.length - 8);
    let test_type = test.getTypeString();

    return oidPrefix === 'ST' || //if the test is an STD test, show urine test info
        test_type.toLowerCase().includes('herpes') ||
        test_type.toLowerCase().includes('std')
        ? 'urine'
        : 'blood';

    //TODO: double check this being correct
};

export const displayBinaryResultText = (result: string, test_name: string) => {
    //result is 'Positive', 'Negative', 'Detected', etc..
    //This is only for binary results

    switch (test_name) {
        case 'Covid-19 RT-PCR Test':
        case 'Rapid COVID-19 Antigen':
        case 'COVID-19 Rapid RT-PCR':
        case 'COVID-19':
            return `${result} - You are ${result === 'Negative' ? 'not' : ''} actively infected with COVID-19`;
        case 'Flu A':
        case 'Flu B':
            return `${result} - You are ${result === 'Negative' ? 'not' : ''} actively infected with ${test_name}`;
        case 'Covid-19 Total Antibody Test':
        case 'Covid-19 Antibody IgG Test':
            return `${result} - You ${result === 'Negative' ? 'do not' : ''} have antibodies`;
        //if the test_name isn't parsed, just return result text as it came
        default:
            return result;
    }
};

export const getColor = (test: any, test_name: string) => {
    if (test.color) {
        //if the object has a color from db
        return test.color;
    } else {
        return test_name === 'Rapid Flu/COVID-19 Test'
            ? 'var(--mld-shop-tests-covid-flu)'
            : test_name === 'Blood Type Test'
            ? 'var(--mld-shop-tests-blood-type)'
            : test_name === 'Covid-19 RT-PCR Test'
            ? 'var(--mld-kits-covid-rt-pcr)'
            : test_name === 'Covid-19 Total Antibody Test'
            ? 'var(--mld-kits-covid-antibody)'
            : test_name === 'Covid-19 Antibody IgG Test'
            ? 'var(--mld-kits-covid-igg)'
            : test_name === 'COVID-19 Rapid RT-PCR Test'
            ? 'var(--mld-kits-covid-rapid-rt-pcr)'
            : test_name === 'Rapid COVID-19 Antigen Test'
            ? 'var(--mld-kits-covid-rapid-antigen)'
            : test_name === "Women's Complete Wellness"
            ? 'var(--mld-shop-tests-women-s-complete-wellness)'
            : test_name === "Women's Hormone Health"
            ? 'var(--mld-shop-tests-women-s-hormone-health)'
            : test_name === "Men's Complete Wellness"
            ? 'var(--mld-shop-tests-men-s-complete-wellness)'
            : test_name === "Men's Hormone Health"
            ? 'var(--mld-shop-tests-men-s-hormone-health)'
            : test_name === 'Testosterone Level'
            ? 'var(--mld-shop-tests-testosterone)'
            : test_name === 'Thyroid Health'
            ? 'var(--mld-shop-tests-thyroid-health)'
            : test_name === 'Basic Nutrition and Lifestyle Risk'
            ? 'var(--mld-shop-tests-basic-nutrition-and-lifestyle-risk)'
            : test_name === 'Heart Health'
            ? 'var(--mld-shop-tests-heart-health)'
            : test_name === 'Immune Health'
            ? 'var(--mld-shop-tests-immune-health)'
            : test_name === 'Diabetic Health'
            ? 'var(--mld-shop-tests-diabetic-health)'
            : test_name === 'General Health'
            ? 'var(--mld-shop-tests-general-health)'
            : test_name === 'Nutrient Health'
            ? 'var(--mld-shop-tests-nutrient-health)'
            : 'var(--mld-scheme-lab-results)';
    }
};
