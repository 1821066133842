import React, {useState, ChangeEvent, FormEvent, useRef, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import TopBar from '../components/TopBar';
import PrimaryButton from '../components/PrimaryButton';
import Error from '../components/Error';
import Spinner from '../components/Spinner';
import settings from '../settings';
import {VerificationMode} from './TwoStepVerificationSettings';
import axios from 'axios';
import {fetchTests} from '../slices/testSlice';
import {setCurrentPatient} from '../slices/patientSlice';
import {useAppDispatch} from '../hooks';
import {
    PageContainer,
    Form,
    FormContainer,
    FormInput,
    Forgot,
    ModalIconContainer,
    VerificationDetail,
    VerificationHeader,
    StyledIcon,
    OverlayingSpinner,
    DigitContainer,
    DigitInput,
    SubmitContainer,
    HorizontalLine,
    ResendCode,
    LinkButton,
    Header,
    FieldName,
    FieldNameText,
    LeftIcon,
    RightIcon,
    ColorBar,
    Caption,
    BottomLink,
} from './styles/Login';

interface FormFields {
    username: string;
    password: string;
    symptoms?: string;
    contact?: string;
}

const Login: React.FC = () => {
    const [state, setState] = useState<FormFields>({
        username: '',
        password: '',
    });
    const [error, setError] = useState<{[index: string]: boolean}>();
    const [show, setShow] = useState('password');
    const [signingIn, setSigningIn] = useState(false);
    const [userResResult, setUserResResult] = useState<{[index: string]: any}>({});
    const {login, token} = useAuth();
    const [step, setStep] = useState(0);
    const [appointmentID, setAppointmentID] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref');
    const codeLength = 6;
    const [verificationCode, setVerificationCode] = useState<string[]>(Array(codeLength).fill(''));
    const [check2FA, setCheck2FA] = useState<boolean>(false);
    const [verificationError, setVerificationError] = useState<{[index: string]: boolean}>();
    const [processing, setProcessing] = useState(false);
    const dispatch = useAppDispatch();

    const codeInputRefs = useRef(new Array(6));

    const nextStep = () => setStep(step + 1);

    const togglePassword = () => {
        setShow(show === 'password' ? 'text' : 'password');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        const value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;
        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (step === 0) {
            if (!state.username) {
                setError((prev) => ({
                    ...prev,
                    username: true,
                    password: false,
                    invalid: false,
                    failed: false,
                    unverified: false,
                }));
                return;
            }

            if (!state.password) {
                setError((prev) => ({
                    username: false,
                    password: true,
                    invalid: false,
                    failed: false,
                    unverified: false,
                }));
                return;
            }

            setSigningIn(true);

            // We need to send plaintext password so we can attempt ELIS account login
            // TODO Is there a better way we could handle this while still supporting
            // ELIS login?
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({username: state.username, password: state.password}),
                withCredentials: true,
            };

            const res = await axios(`${settings.apiURL}/api/login`, requestOptions).catch(() =>
                setError({username: false, password: false, invalid: false, failed: true, unverified: false})
            );
            setSigningIn(false);
            if (!res) {
                return;
            }

            const userRes = res.data;

            if (res.status !== 200) {
                setError((prev) => ({
                    username: false,
                    password: false,
                    invalid: userRes.error === 'Invalid credentials',
                    failed: false,
                    unverified: userRes.error === 'User not verified',
                }));
                if (userRes.error === 'Two factor authentication failure') {
                    setVerificationError((prev) => ({...prev, failed: true}));
                }
                return;
            }

            if (
                userRes.result.userInfo.admin &&
                userRes.result.userInfo.twoFAMode !== null &&
                userRes.result.userInfo.twoFAMode !== ''
            ) {
                setUserResResult(userRes.result);
                setCheck2FA(true);
                setVerificationError({});
                return;
            }

            login(userRes.result);
            dispatch(
                setCurrentPatient({
                    id: userRes.result.userInfo.id,
                    firstName: userRes.result.userInfo.firstName,
                    lastName: userRes.result.userInfo.lastName,
                    fullName: `${userRes.result.userInfo.firstName} ${userRes.result.userInfo.lastName}`,
                    birthdate: userRes.result.userInfo.birthday,
                })
            );
            dispatch(fetchTests(userRes.result.token));

            //better way to redirect after logging in? could not parse how it redirected before
            //never gets here if login info is wrong
            if (ref === 'kit') {
                navigate('/newregisterkit');
            } else {
                navigate('/');
            }

            // setAppointmentID(userRes.result.userInfo.appointmentID);
            setError({});
            setUserResResult(userRes.result);
            // nextStep();
        }
    };

    const onVerificationCodeKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        switch (e.key) {
            case 'ArrowLeft':
                if (index != 0) {
                    codeInputRefs.current[index - 1].focus();
                }
                break;
            case 'ArrowRight':
                if (index != 5) {
                    codeInputRefs.current[index + 1].focus();
                }
                break;
            case 'Backspace':
                if (index !== 0 && verificationCode[index] === '') {
                    codeInputRefs.current[index - 1].focus();
                }
                setVerificationCode((oldVerificationCode) => {
                    const newVerificationCode = [...oldVerificationCode];
                    newVerificationCode[index] = '';
                    return newVerificationCode;
                });
                e.preventDefault();
                break;
            case 'Delete':
                setVerificationCode((oldVerificationCode) => {
                    const newVerificationCode = [...oldVerificationCode];
                    newVerificationCode[index] = '';
                    return newVerificationCode;
                });
                e.preventDefault();
                break;

            default:
                if (e.key.length === 1) {
                    setVerificationCode((oldVerificationCode) => {
                        const newVerificationCode = [...oldVerificationCode];
                        newVerificationCode[index] = e.key;
                        return newVerificationCode;
                    });
                    if (index !== 5) {
                        codeInputRefs.current[index + 1].focus();
                    }
                    e.preventDefault();
                }
                break;
        }
    };

    const resendVerificationCode = async () => {
        setProcessing(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${userResResult.token}`},
            data: JSON.stringify({phone: userResResult.userInfo.phone}),
        };
        const res = await axios(`${settings.apiURL}/api/2FA/SMS/verificationCode`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, failed: true}))
        );
        setProcessing(false);

        if (!res) {
            return;
        }

        const dataRes = res.data;
        if (res.status !== 200) {
            if (dataRes.error === 'Two factor authentication failure') {
                setVerificationError((prev) => ({...prev, failed: true}));
            }
        }
    };

    const handleVerificationCodeSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const code = verificationCode.join('');
        if (code.length !== codeLength) {
            setVerificationError((prevState) => ({...verificationError, invalidLength: true}));
            return;
        }

        setProcessing(true);

        let res;

        const {twoFAMode} = userResResult.userInfo;

        if (twoFAMode === 'SMS') {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${userResResult.token}`},
                data: JSON.stringify({
                    phone: userResResult.userInfo.phone,
                    verificationCode: verificationCode.join(''),
                }),
            };

            res = await axios(`${settings.apiURL}/api/2FA/SMS/verify`, requestOptions).catch(() =>
                setVerificationError((prev) => ({...prev, failed: true}))
            );
        } else if (twoFAMode === 'EMAIL') {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${userResResult.token}`},
                data: JSON.stringify({
                    email: userResResult.userInfo.email,
                    verificationCode: verificationCode.join(''),
                }),
            };

            res = await axios(`${settings.apiURL}/api/2FA/email/verify`, requestOptions).catch(() =>
                setVerificationError((prev) => ({...prev, failed: true}))
            );
        } else {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${userResResult.token}`},
                data: JSON.stringify({verificationCode: verificationCode.join('')}),
            };

            res = await axios(`${settings.apiURL}/api/2FA/TOTP/verify`, requestOptions).catch(() =>
                setVerificationError((prev) => ({...prev, failed: true}))
            );
        }
        setProcessing(false);

        if (!res) {
            return;
        }

        const dataRes = res.data;
        if (res.status !== 200) {
            setVerificationError((prev) => ({
                ...prev,
                invalidCode: dataRes!!.error === 'Invalid code',
                failed: dataRes!!.error === 'Two factor authentication failure',
            }));
            return;
        }

        login(userResResult);
        if (ref === 'kit') {
            navigate('/registerkit/step/0');
        }
        setAppointmentID(userResResult.userInfo.appointmentID);
        setVerificationError({});
    };

    // Attempt login using refresh token and automatically redirect to dashboard if successful
    // useEffect(() => {
    //     axios(`${settings.apiURL}/api/refresh`, {method: 'POST', withCredentials: true})
    //         .then((tokenRefreshResponse) => {
    //             //localStorage.setItem('token', tokenRefreshResponse.data.token);
    //             setToken(tokenRefreshResponse.data.token);
    //             failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
    //             return Promise.resolve();
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // }, []);

    return (
        <PageContainer>
            <TopBar location={ref ? `/login?ref=${ref}` : '/login'} />
            {check2FA ? (
                <FormContainer>
                    <Error
                        message={
                            !!verificationError?.invalidLength
                                ? 'Please enter the verification code.'
                                : !!verificationError?.invalidCode
                                ? 'Invalid code.'
                                : !!verificationError?.failed
                                ? 'Failure in two factor authentication.'
                                : ''
                        }
                    />

                    <ModalIconContainer style={{width: '100px', height: '100px', marginBottom: '35px'}}>
                        <StyledIcon className="fa-light fa-shield-keyhole" />
                    </ModalIconContainer>
                    <VerificationHeader>Enter Verification Code</VerificationHeader>
                    <VerificationDetail>
                        {userResResult.userInfo.twoFAMode === VerificationMode.SMS
                            ? `Please enter the 6-digit code sent to your phone number ending in ${userResResult.userInfo.phone.substr(
                                  userResResult.userInfo.phone.length - 4,
                                  4
                              )}.`
                            : userResResult.userInfo.twoFAMode === VerificationMode.EMAIL
                            ? `Please enter the 6-digit code sent to your email address.`
                            : `Please enter the 6-digit code from your registered TOTP app.`}
                    </VerificationDetail>
                    <Form onSubmit={handleVerificationCodeSubmit} style={{position: 'relative'}}>
                        {processing && <OverlayingSpinner color="#3c99dc" size="50px" left="42%" />}
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {Array(codeLength)
                                .fill(null)
                                .map((_, index) => (
                                    <DigitContainer key={index}>
                                        <DigitInput
                                            type="text"
                                            name={`code-${index}`}
                                            value={verificationCode[index]}
                                            onKeyDown={(e) => onVerificationCodeKeyPress(e, index)}
                                            ref={(el) => (codeInputRefs.current[index] = el)}
                                        />
                                    </DigitContainer>
                                ))}
                        </div>
                        <SubmitContainer>
                            <PrimaryButton value="Verify" width="100%" />
                        </SubmitContainer>
                    </Form>
                    <HorizontalLine />
                    <ResendCode>
                        It may take a minute to receive your code. Haven't received it?
                        <LinkButton onClick={resendVerificationCode}>Resend a new code.</LinkButton>
                    </ResendCode>
                </FormContainer>
            ) : (
                <FormContainer>
                    {step === 0 && <Header className="Heading04Bold">Sign In</Header>}
                    <Error
                        message={
                            !!error?.username
                                ? 'Please enter your email.'
                                : !!error?.password
                                ? 'Please enter your password'
                                : !!error?.invalid
                                ? 'Invalid email or password'
                                : !!error?.failed
                                ? 'Could not log in. Please try again later.'
                                : !!error?.unverified
                                ? 'Check your email to verify your account.'
                                : !!verificationError?.failed
                                ? 'Failure in two factor authentication.'
                                : ''
                        }
                    />
                    <Form onSubmit={handleSubmit}>
                        {step === 0 && (
                            <>
                                <FieldName error={error?.username} className="Heading05Regular">
                                    <FieldNameText>Email</FieldNameText>
                                    <LeftIcon className="fa-solid fa-envelope" error={error?.username ? 1 : 0} />
                                    <FormInput
                                        error={error?.username}
                                        type="text"
                                        name="username"
                                        value={state?.username || ''}
                                        onChange={handleChange}
                                    />
                                </FieldName>
                                <FieldName error={error?.password} className="Heading05Regular">
                                    <FieldNameText>Password</FieldNameText>
                                    <LeftIcon className="fa-solid fa-key" error={error?.password ? 1 : 0} />
                                    <RightIcon
                                        className={show === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                        onClick={() => {
                                            if (show === 'password') {
                                                (window as any).hj(
                                                    'event',
                                                    'v2_auth_authBox_passwordInput_revealPassword'
                                                );
                                            }

                                            togglePassword();
                                        }}
                                    />
                                    <FormInput
                                        error={error?.password}
                                        type={show}
                                        name="password"
                                        value={state?.password || ''}
                                        onChange={handleChange}
                                    />
                                </FieldName>
                                <Forgot
                                    to={ref ? `/reset?ref=${ref}` : '/reset'}
                                    onClick={() => {
                                        (window as any).hj('event', 'v2_auth_authBox_forgotPassword');
                                    }}>
                                    Forgot your password?
                                </Forgot>
                                <SubmitContainer>
                                    {signingIn && <Spinner left="28%" />}
                                    <PrimaryButton
                                        value="Sign In"
                                        width="100%"
                                        onClick={() => {
                                            (window as any).hj('event', 'v2_auth_authBox_signIn');
                                        }}
                                    />
                                </SubmitContainer>
                                <ColorBar />
                                <Caption>New to MyLabsDirect?</Caption>
                                <BottomLink
                                    to={ref ? `/register?ref=${ref}` : '/register'}
                                    onClick={() => {
                                        (window as any).hj('event', 'v2_auth_authBox_createAccount');
                                    }}>
                                    Create an account
                                </BottomLink>
                            </>
                        )}
                    </Form>
                </FormContainer>
            )}
        </PageContainer>
    );
};

export default Login;
