import React from 'react';
import {Layout} from '../Components';
import Results from '../Resources/img/AntibodyStep9.png';

const Step14 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={Results} alt="Results" />
            </div>

            <h2 className="antibody-step-number">Step 12</h2>

            <h3 className="antibody-step-description">Results</h3>

            <p className="has-text-centered">
                HIPAA compliant results will be emailed in approximately 24-48 hours from receipt of sample at our lab.
            </p>
        </section>
    </Layout>
);
export default Step14;
