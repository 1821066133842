import React from 'react';
import {Layout} from '../Components';
import PackageItemsIcon from '../Resources/img/AntibodyStep8.png';

const Step11 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={PackageItemsIcon} alt="Package Items" />
            </div>

            <h6 className="required-record">
                this step <strong>must</strong> be recorded{' '}
                <span className="record-icon">
                    <span></span>
                </span>
            </h6>

            <h2 className="antibody-step-number">Step 9</h2>

            <h3 className="antibody-step-description">Package Items For Return </h3>

            <p className="has-text-centered">
                Place biohazard bag into return box and close box. Place box into UN 3373 return bag and apply the
                shipping label to the bag. Seal bag tightly. Wash hands then disinfect the exterior of the package using
                alcohol pad before shipping.
            </p>

            <p className="has-text-centered">
                <em>
                    <small>Do not cover the UN 3373 stamp</small>
                </em>
            </p>
        </section>
    </Layout>
);
export default Step11;
