import styled from 'styled-components';
import {Device} from '../../services/constants';

export const CardContainer = styled.div`
    position: absolute;
    width: calc(100% - 32px);
    max-width: 414px;
    margin: 64px 16px 0 16px;
    border-radius: 8px;
    box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    z-index: 3;
    transition: 0.2s;

    @media ${Device.mobile} {
        margin: 128px 16px 0 16px;
    }
`;
export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 96px;
    border-radius: 8px 8px 0 0;
    background-color: var(--error-50);
`;
export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(100% - 48px);
    padding: 24px;
`;
export const Heading = styled.p`
    text-align: center;
`;
export const Message = styled.p`
    margin-bottom: 64px;
`;
export const Button = styled.button`
    width: 100%;
    height: 48px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    margin-top: auto;
    border: none;
    border-radius: 8px;
    color: var(--text-0);
    background-color: var(--neutral-600);
    line-height: 1.25;
    transition: 0.2s;
    &:hover {
        cursor: pointer;
        background-color: var(--neutral-800);
    }
    &:active {
        background-color: var(--neutral-500);
    }
`;
export const Image = styled.i`
    font-size: 48px;
`;
