import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from '../store';

// Define a type for the slice state
interface KitState {
    kitId1: string;
    kitId2: string;
    promoCode1: string;
    promoCode2: string;
    company: string;
    contact: string;
    symptoms: string;
    vaccinated: string;
    firstName: string;
    lastName: string;
    birthday: string;
    address1: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
    gender: string;
    ethnicity: string;
    location: string;
    testType: string;
    [index: string]: string;
    kitName: string;
}

// Define the initial state using that type
const initialState: KitState = {
    kitId1: '',
    kitId2: '',
    promoCode1: '',
    promoCode2: '',
    company: '',
    contact: '',
    symptoms: '',
    vaccinated: '',
    firstName: '',
    lastName: '',
    birthday: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    gender: '',
    ethnicity: '',
    location: '',
    testType: '',
    kitName: ''
};

export const kitSlice = createSlice({
    name: 'kit',
    initialState,
    reducers: {
        setKit: (state, action: PayloadAction<KitState>) => {
            Object.assign(state, action.payload);
        },
        setKitParam: (state, action) => {
            Object.assign(state, action.payload);
        }
    }
});

// export const {increment, decrement, incrementByAmount} = counterSlice.actions;
export const {setKit, setKitParam} = kitSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;
export const selectKit = (state: RootState) => state.kit;

export default kitSlice.reducer;
