import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {useLocation, useNavigate} from 'react-router-dom';

const Layout = (props) => {
    const {children} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const kitId = query.get('id');
    const next = () => {
        let split = location.pathname.split('/');
        split[split.length - 1] = parseInt(getStep()) + 1;
        navigate(`${split.join('/')}?id=${kitId}`);
    };
    React.useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    const previous = () => {
        if (!isStepZero()) {
            const step = parseInt(getStep());
            if (step === 1) {
                navigate('/newregisterkit');
            } else {
                let split = location.pathname.split('/');
                split[split.length - 1] = parseInt(getStep()) - 1;
                navigate(`${split.join('/')}?id=${kitId}`);
            }
        } else if (isStepZero()) {
            navigate('/registerkit/select-kit');
        }
    };
    const getStep = () => {
        console.log(location.pathname.split('/'));
        return location.pathname.split('/')[3];
    };
    const isStepZero = () => {
        if (location.pathname === '/registerkit/step/0') {
            return true;
        }
        return false;
    };
    const isKitSelect = () => {
        if (location.pathname === '/registerkit/select-kit') {
            return true;
        }
        return false;
    };
    const isLastPage = () => {
        if (location.pathname === '/registerkit/step/15' || location.pathname === '/registerkit/step/0') {
            return true;
        }
        return false;
    };

    const showRestart = () => {
        if (location.pathname === '/registerkit/step/15') {
            return true;
        }
        return false;
    };
    const restart = () => {
        navigate(`/`);
    };

    return (
        <div id="guest-layout">
            <Header />
            {children}

            <Footer
                showPrev={!isKitSelect()}
                showNext={!isLastPage() && !isKitSelect()}
                showRestart={showRestart()}
                restart={restart}
                next={next}
                previous={previous}
            />
        </div>
    );
};

export default Layout;
