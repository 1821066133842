import styled from 'styled-components';
import {Device} from '../../services/constants';

export const HealthHistoryContainer = styled.div`
    @media ${Device.desktop} {
        position: relative;
        width: 100%;
        height: 224px;
        display: flex;
        gap: 24px;
        overflow: auto;
    }
`;
export const LatestResultContainer = styled.div`
    width: 182px;
    display: flex;
    padding-left: 8px;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    margin: 0 auto;

    @media ${Device.mobile} {
        width: 224px;
        padding: 5px 0px 23px 0px;
        height: 200px;
        gap: 0;
        display: block;
        margin: auto;
        border-radius: 8px;
        background-color: var(--neutral-50);
        text-align: center;
    }
`;
export const ResultHeader = styled.p`
    white-space: nowrap;

    @media ${Device.mobile} {
        text-align: center;
        margin-bottom: 12px;
    }
`;
export const GaugeDetails = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    transform: translateY(-78px);

    @media ${Device.mobile} {
        width: 182px;
        margin: auto;
        margin-top: 10px;
    }
`;
export const GaugeUnitsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const GaugeUnits = styled.p``;
export const GaugeFields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`;
export const GaugeField = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;
export const GraphLabel = styled.p`
    font-size: 13.3px;
    font-weight: 300;
`;
export const GraphLabelDetails = styled.p`
    font-size: 13.3px;
`;
export const Separator = styled.div`
    min-width: 1px;
    background-color: var(--neutral-400);
`;
export const AllResultsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media ${Device.mobile} {
        display: block;
        min-width: 224px;
        max-width: 309px;
        height: 200px;
        border-radius: 8px;
        background-color: var(--neutral-50);
        margin: 0 auto 40px auto;
        padding: 5px 0 23px 0;
        background-color: var(--neutral-50);
    }
`;
export const BoundaryLines = styled.div<{operator: string}>`
    position: absolute;
    left: 0;
    top: 37px;
    width: 100%;
    height: 65px;
    border-top: ${(props) => (props.operator !== '>' ? '1px dashed var(--neutral-600)' : '')};
    border-bottom: ${(props) => (props.operator !== '<' ? '1px dashed var(--neutral-600)' : '')};

    @media ${Device.mobile} {
        top: 66px;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        width: 279px;
        max-width: calc(100% - 20px);
        border-top: ${(props) => (props.operator !== '>' ? '2px dashed var(--neutral-200)' : '')};
        border-bottom: ${(props) => (props.operator !== '<' ? '2px dashed var(--neutral-200)' : '')};
    }
`;
export const Histogram = styled.div`
    display: flex;
    padding: 0;
    justify-content: center;
    position: relative;
    width: 100%;
    min-width: 0;
    gap: 18px;

    @media ${Device.mobile} {
        position: static;
        width: auto;
        padding-top: 20px;
    }
`;
export const HistogramLabels = styled.div`
    @media ${Device.desktop} {
        position: absolute;
        width: 82px;
        min-width: 82px;
        margin-top: 40px;
        white-space: nowrap;
        left: 0;
    }
`;
export const HistogramBars = styled.div`
    display: flex;
    justify-content: center;
    min-width: 0;
    width: fit-content;
    height: 178px;
    margin-left: 82px;
    gap: 24px;

    @media ${Device.mobile} {
        min-width: 224px;
        height: 140px;
        gap: 18px;
        width: auto;
        margin-left: 0;
    }
`;
