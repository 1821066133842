import React from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAppSelector} from '../../hooks';
import {
    Form,
    FormSection,
    BackButton,
    Image,
    ImageContainer,
    Paragraph,
    KitContainer,
    KitIcon,
    KitItem,
    KitText,
    LabelText
} from './styles/Success';

export const Success: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector(state => state.kit);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <ImageContainer>
                    <Image className='fa-light fa-circle-check' />
                </ImageContainer>
            </FormSection>
            <FormSection>
                <Paragraph className='Heading05Bold'>Thank you!</Paragraph>
                <KitContainer>
                    <KitText className='ParagraphNormalRegular'>KIT DETAILS</KitText>
                    <KitItem className='ParagraphNormalRegular'>
                        <KitIcon className='fa-light fa-folder-plus fa-fw' error={undefined} />
                        <LabelText>{`Test Type: ${kitInfo.kitName}`}</LabelText>
                    </KitItem>
                    <KitItem className='ParagraphNormalRegular'>
                        <KitIcon className='fa-light fa-box-open fa-fw' error={undefined} />
                        <LabelText>Kit ID: {kitInfo.kitId1}</LabelText>
                    </KitItem>
                </KitContainer>
                <Paragraph className='ParagraphNormalBold' style={{color: 'var(--neutral-600)'}}>
                    Your kit has been registered!
                </Paragraph>
                <Paragraph style={{color: 'var(--neutral-600)'}} className='ParagraphNormalRegular'>
                    {/* TODO: add kit specific instructions? */}
                    We will notify you once your results are ready to view.
                </Paragraph>
                <BackButton
                    className='ParagraphNormalRegular'
                    onClick={() => {
                        props.goToNamedStep?.('step0');
                    }}>
                    Register a new kit
                </BackButton>
            </FormSection>
        </Form>
    );
};
