import React from 'react';
import {useState, ChangeEvent} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import SettingsField from '../components/SettingsField';
import PasswordError from '../components/PasswordError';
import SettingsHeader from '../components/SettingsHeader';
import SettingsFooter from '../components/SettingsFooter';
import Error from '../components/Error';
import settings from '../settings';
import 'event-submitter-polyfill';
import TwoStepVerificationSettings from './TwoStepVerificationSettings';
import {Countries, States, Ethnicities} from '../services/constants';
import {Desktop, Mobile} from '../components/MediaQueries';
import axios from 'axios';
import {useAppDispatch, useAppSelector} from '../hooks';
import {selectCurrentPatient} from '../slices/patientSlice';
import {
    PageContainer,
    Select,
    StyledDatePicker,
    DesktopMainMenu,
    DesktopMainMenuOption,
    DesktopMainMenuOptions,
    DualContainer,
    DualLabel,
    Header3,
    OptionPhoto,
    OptionText,
    Optional,
    OptionalContainer,
    Menu,
    Fields,
    EditFields,
    EditMenu,
    Label,
    LabelText,
    CheckboxLabel,
    VariableLabel,
    LeftIcon,
    FormInput,
    VariableFormInput,
    FullInput,
    RightIcon,
    PasswordContainer,
    PasswordErrors,
    Checkbox,
    CheckboxInputContainer
} from './styles/Settings';

const Settings: React.FC = () => {
    const {user, token} = useAuth();
    const navigate = useNavigate();
    const currentPatient = useAppSelector(selectCurrentPatient);

    const initialState: any = {
        id: currentPatient.id,
        elis_id: user.userInfo.elis_id,
        firstName: currentPatient.firstName,
        lastName: currentPatient.lastName,
        birthday: currentPatient.birthdate,
        gender: user.userInfo.gender,
        ethnicity: user.userInfo.ethnicity,
        email: user.userInfo.email,
        phone: user.userInfo.phone,
        address1: user.userInfo.address1,
        address2: user.userInfo.address2,
        city: user.userInfo.city,
        state: user.userInfo.state,
        zip: user.userInfo.zip,
        country: user.userInfo.country,
        twoFAMode: user.userInfo.twoFAMode,
        smsEnabled: user.userInfo.smsEnabled
    };

    const initialError = {
        phone: false,
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
        message: '',
        ethnicity: false,
        gender: false,
        birthday: false,
        firstName: false,
        lastName: false,
        smsEnabled: false
    };

    const initialPasswordState = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    const initialPasswordError = {
        passwordLength: true,
        passwordLetters: true,
        passwordNumber: true,
        passwordMatch: true
    };

    const [state, setState] = useState(initialState);
    const [error, setError] = useState(initialError);
    const [menuState, setMenuState] = useState(initialState);
    const [passwordState, setPasswordState] = useState(initialPasswordState);
    const [passwordError, setPasswordError] = useState(initialPasswordError);

    // Valid menu options: 'Main', 'Personal Details', 'Password & Security, SMS Settings'
    const [currentMenu, setCurrentMenu] = useState('Main');
    const [currentHover, setCurrentHover] = useState('');
    // Valid submenu options: '', 'Email', 'Phone', 'Home Address', 'Password'
    const [currentEditMenu, setCurrentEditMenu] = useState('');

    const [saving, setSaving] = useState(false);
    const [showOld, setShowOld] = useState('password');
    const [showNew, setShowNew] = useState('password');
    const [showConfirm, setShowConfirm] = useState('password');

    const toggleOldPassword = () => {
        setShowOld(showOld === 'password' ? 'text' : 'password');
    };

    const toggleNewPassword = () => {
        setShowNew(showNew === 'password' ? 'text' : 'password');
    };

    const toggleConfirmPassword = () => {
        setShowConfirm(showConfirm === 'password' ? 'text' : 'password');
    };

    const reset = () => {
        setState(initialState);
        setPasswordState(initialPasswordState);
        setMenuState(initialState);
        setError(initialError);
        setPasswordError(initialPasswordError);
    };

    const cancel = () => {
        reset();
        setCurrentEditMenu('');
    };

    //TODO fix this to not update users info, but local storage patient for applicable fields
    const updateUserInfo = (userInfo: any) => {
        for (const prop in userInfo) {
            user.userInfo[prop] = userInfo[prop];
            initialState[prop] = userInfo[prop];
        }
        setState(userInfo);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }

            setError((prevError: any) => ({...prevError, phone: false}));
        }

        if (name === 'zip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        setState((prevState: any) => ({...prevState, [name]: value}));
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'newPassword') {
            const passwordUppercaseReg = /[A-Z]/;
            const passwordLowercaseReg = /[a-z]/;
            const passwordNumberReg = /[\d]/;

            setPasswordError({
                ...passwordError,
                passwordLetters: !passwordUppercaseReg.test(value) || !passwordLowercaseReg.test(value),
                passwordNumber: !passwordNumberReg.test(value),
                passwordLength: value.length <= 8
            });
        }

        if (name === 'confirmPassword') {
            setPasswordError({
                ...passwordError,
                passwordMatch: value !== passwordState.newPassword
            });
        }

        setPasswordState((prevPasswordState: any) => ({
            ...prevPasswordState,
            [name]: value
        }));
        setError((prevError: any) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const submitter = event.nativeEvent.submitter.value;

        if (submitter === 'Save Changes') {
            const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

            if (!phoneReg.test(state.phone)) {
                setError(prevError => ({
                    ...prevError,
                    phone: true,
                    message: 'Invalid format, please use ###-###-####'
                }));
                return;
            }

            const resultsOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                data: JSON.stringify(state)
            };

            setSaving(true);
            const resultsRes = await axios(`${settings.apiURL}/api/user`, resultsOptions);
            if (resultsRes.status !== 200) {
                setSaving(false);
                console.log('BAD CREDS');
                return;
            }

            setSaving(false);
            for (const prop in state) {
                user.userInfo[prop] = state[prop];
                initialState[prop] = state[prop];
            }
            //this just updates the local storage with the new patient name, without requiring them to relog in
            let patient = {
                id: state.id,
                firstName: state.firstName,
                lastName: state.lastName,
                fullName: state.firstName + ' ' + state.lastName,
                birthdate: state.birthday
            };
            localStorage.setItem('patient', JSON.stringify(patient));
        }

        if (submitter === 'Reset Password') {
            if (!passwordState.oldPassword) {
                setError(prevError => ({
                    ...prevError,
                    oldPassword: true,
                    message: 'Please enter your current password'
                }));
                return;
            }

            if (passwordError.passwordLength || passwordError.passwordLetters || passwordError.passwordNumber) {
                setError(prevError => ({
                    ...prevError,
                    newPassword: true,
                    message: 'Please ensure password meets requirements'
                }));
                return;
            }

            if (passwordError.passwordMatch) {
                setError(prevError => ({
                    ...prevError,
                    newPassword: true,
                    confirmPassword: true,
                    message: 'Please ensure passwords match'
                }));
                return;
            }

            // VERIFY OLD PASSWORD
            const oldPasswordResultsOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                data: JSON.stringify({
                    username: initialState.email,
                    password: passwordState.oldPassword
                })
            };

            setSaving(true);

            const oldPasswordResultsRes = await axios(
                `${settings.apiURL}/api/verifyPassword`,
                oldPasswordResultsOptions
            );
            const oldPasswordRes = oldPasswordResultsRes.data;

            if (oldPasswordResultsRes.status !== 200) {
                setSaving(false);
                setError(prevError => ({
                    ...prevError,
                    oldPassword: true,
                    message: oldPasswordRes.error
                }));
                return;
            }

            // RESET PASSWORD
            // from Reset.tsx

            const passwordResultsOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                data: JSON.stringify({
                    username: initialState.email,
                    password: passwordState.newPassword,
                    ref: 'admin'
                })
            };

            const passwordResultsRes = await axios(`${settings.apiURL}/api/admin/reset`, passwordResultsOptions);
            setSaving(false);
            if (passwordResultsRes.status !== 200) {
                const passwordRes = passwordResultsRes.data;
                console.error(passwordRes);
                setError(prevError => ({
                    ...prevError,
                    message: 'Could not reset password'
                }));
                return;
            }
        }

        if (submitter === 'Go to Stripe') {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                data: JSON.stringify({
                    user: initialState.email
                })
            };

            const res = await axios(`${settings.apiURL}/api/session/management`, options);
            if (res.status !== 200) {
                setError(prevError => ({
                    ...prevError,
                    message: 'Could not connect to Stripe'
                }));
                return;
            }
            const data = res.data;
            window.location.replace(data.url);
        }

        reset();
        setError(prevError => ({
            ...prevError,
            message: 'Success!'
        }));
        event.target.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    function processName (firstName: string, lastName: string) {
        if (firstName && lastName) {
            return firstName + ' ' + lastName;
        } else {
            return 'Please set your name';
        }
    }

    function processBirthdate (bd: string) {
        const d = new Date(bd);
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    }

    return (
        <PageContainer>
            <Desktop>
                <>
                    <DesktopMainMenu>
                        <Header3 className='Heading03Bold'>Settings</Header3>
                        <DesktopMainMenuOptions>
                            <DesktopMainMenuOption
                                ishovered={currentHover === 'Personal Details' ? 1 : 0}
                                onClick={() => {
                                    setCurrentMenu('Personal Details');
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                onMouseEnter={() => setCurrentHover('Personal Details')}
                                onMouseLeave={() => setCurrentHover('')}>
                                <OptionPhoto
                                    className='fa-light fa-clipboard-user fa-fw'
                                    ishovered={currentHover === 'Personal Details' ? 1 : 0}
                                />
                                <OptionText
                                    className={`ParagraphNormal${
                                        currentMenu === 'Personal Details' ? 'ExtraBold' : 'Regular'
                                    }`}>
                                    Personal Details
                                </OptionText>
                            </DesktopMainMenuOption>
                            <DesktopMainMenuOption
                                ishovered={currentHover === 'Password & Security' ? 1 : 0}
                                onClick={() => {
                                    setCurrentMenu('Password & Security');
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                onMouseEnter={() => setCurrentHover('Password & Security')}
                                onMouseLeave={() => setCurrentHover('')}>
                                <OptionPhoto
                                    className='fa-light fa-key fa-fw'
                                    ishovered={currentHover === 'Password & Security' ? 1 : 0}
                                />
                                <OptionText
                                    className={`ParagraphNormal${
                                        currentMenu === 'Password & Security' ? 'ExtraBold' : 'Regular'
                                    }`}>
                                    {'Password & Security'}
                                </OptionText>
                            </DesktopMainMenuOption>
                            {settings.env === 'development' && (
                                <DesktopMainMenuOption
                                    ishovered={currentHover === 'SMS Settings' ? 1 : 0}
                                    onClick={() => {
                                        setCurrentMenu('SMS Settings');
                                        setCurrentEditMenu('');
                                        reset();
                                    }}
                                    onMouseEnter={() => setCurrentHover('SMS Settings')}
                                    onMouseLeave={() => setCurrentHover('')}>
                                    <OptionPhoto
                                        className='fa-light fa-envelope-square fa-fw'
                                        ishovered={currentHover === 'SMS Settings' ? 1 : 0}
                                    />
                                    <OptionText
                                        className={`ParagraphNormal${
                                            currentMenu === 'SMS Settings' ? 'ExtraBold' : 'Regular'
                                        }`}>
                                        SMS Settings
                                    </OptionText>
                                </DesktopMainMenuOption>
                            )}
                        </DesktopMainMenuOptions>
                    </DesktopMainMenu>
                    <Menu>
                        <SettingsHeader value={currentMenu === 'Main' ? 'Personal Details' : currentMenu} />
                        {(currentMenu === 'Personal Details' || currentMenu === 'Main') && (
                            <>
                                <Fields>
                                    <SettingsField
                                        isActive={currentEditMenu === 'Name' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Name');
                                            reset();
                                        }}
                                        title='Name'
                                        value={processName(menuState.firstName, menuState.lastName)}
                                    />
                                    <SettingsField
                                        isActive={currentEditMenu === 'Birthday' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Birthday');
                                            reset();
                                        }}
                                        title='Date of Birth'
                                        value={
                                            menuState.birthday
                                                ? new Date(menuState.birthday).toLocaleDateString('en-US', {
                                                      timeZone: 'UTC'
                                                  })
                                                : 'Please set your birthday'
                                        }
                                    />
                                    <SettingsField
                                        isActive={currentEditMenu === 'Gender' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Gender');
                                            reset();
                                        }}
                                        title='Biological Sex'
                                        value={
                                            menuState.gender
                                                ? menuState.gender[0].toLowerCase() === 'm'
                                                    ? 'Male'
                                                    : menuState.gender[0].toLowerCase() === 'f'
                                                    ? 'Female'
                                                    : ''
                                                : ''
                                        }
                                    />
                                    <SettingsField
                                        isActive={currentEditMenu === 'Ethnicity' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Ethnicity');
                                            reset();
                                        }}
                                        title='Ethnicity'
                                        value={menuState.ethnicity ? menuState.ethnicity : ''}
                                    />
                                </Fields>
                                <Fields>
                                    <SettingsField title='Email' value={menuState.email ? menuState.email : ''} />
                                    <SettingsField
                                        isActive={currentEditMenu === 'Phone' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Phone');
                                            reset();
                                        }}
                                        title='Phone'
                                        value={menuState.phone ? menuState.phone : ''}
                                    />
                                    <SettingsField
                                        isActive={currentEditMenu === 'Home Address' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Home Address');
                                            reset();
                                        }}
                                        title='Home Address'
                                        value={`${menuState.address1}${
                                            menuState.address2 ? ` ${menuState.address2}` : ''
                                        }`}
                                    />
                                    <SettingsField
                                        isActive={currentEditMenu === 'Subscriptions' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Subscriptions');
                                            reset();
                                        }}
                                        title='Manage Subscriptions'
                                        value=''
                                    />
                                </Fields>
                            </>
                        )}

                        {currentMenu === 'Password & Security' && (
                            <>
                                <Fields>
                                    <SettingsField
                                        isActive={currentEditMenu === 'Password' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Password');
                                            reset();
                                        }}
                                        value='Password'
                                    />
                                    {settings.env === 'development' && (
                                        <SettingsField
                                            isActive={currentEditMenu === '2-Step Authentication' ? true : false}
                                            onClick={() => {
                                                setCurrentEditMenu('2-Step Authentication');
                                                reset();
                                            }}
                                            title='2-Step Authentication'
                                            value={
                                                state.twoFAMode !== null && state.twoFAMode !== ''
                                                    ? 'Enabled'
                                                    : 'Not enabled'
                                            }
                                        />
                                    )}
                                </Fields>
                            </>
                        )}

                        {currentMenu === 'SMS Settings' && (
                            <>
                                <Fields>
                                    <SettingsField
                                        isActive={currentEditMenu === 'Test Result SMS Notification' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('Test Result SMS Notification');
                                            reset();
                                        }}
                                        value='Test Result SMS Notification'
                                    />
                                </Fields>
                            </>
                        )}
                    </Menu>
                    <EditMenu onSubmit={handleSubmit}>
                        <SettingsHeader value={currentEditMenu} />
                        {currentEditMenu === 'Email' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <Label>
                                        <LabelText>Email Address</LabelText>
                                        <LeftIcon className='fa-light fa-envelope' />
                                        <FormInput
                                            placeholder='example@example.com'
                                            type='email'
                                            name='email'
                                            value={state?.email || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Name' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <Label>
                                        <LabelText>First Name</LabelText>
                                        {/* <LeftIcon icon={faEnvelope} /> */}
                                        <FormInput
                                            placeholder='First Name'
                                            type='text'
                                            name='firstName'
                                            value={state?.firstName || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <Label>
                                        <LabelText>Last Name</LabelText>
                                        <FormInput
                                            placeholder='Last Name'
                                            type='text'
                                            name='lastName'
                                            value={state?.lastName || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Birthday' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.birthday} onClick={e => e.preventDefault()}>
                                        <LabelText>Date of Birth</LabelText>
                                        <LeftIcon className='fa-light fa-calendar-days' />
                                        <StyledDatePicker
                                            error={error.birthday}
                                            //this makes the date object not have a timezone, and be 00:00:00 always
                                            selected={new Date(processBirthdate(state.birthday))}
                                            minDate={new Date('1/1/1870')}
                                            maxDate={new Date()}
                                            placeholderText='MM/DD/YYYY'
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode='select'
                                            onChange={(date: Date) => {
                                                setState({
                                                    ...state,
                                                    birthday: new Date(date.toISOString().slice(0, -1))
                                                });
                                                setError({...error, birthday: false});
                                            }}
                                        />
                                    </VariableLabel>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Gender' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.gender}>
                                        <LabelText>Biological Sex</LabelText>
                                        <Select name='gender' value={state?.gender || ''} onChange={handleChange}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            <option value='M'>Male</option>
                                            <option value='F'>Female</option>
                                        </Select>
                                    </VariableLabel>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Ethnicity' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.ethnicity}>
                                        <LabelText>Ethnicity</LabelText>
                                        <Select name='ethnicity' value={state?.ethnicity || ''} onChange={handleChange}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            {Ethnicities.map((ethnicity, ide) => (
                                                <option key={ide} value={ethnicity}>
                                                    {ethnicity}
                                                </option>
                                            ))}
                                        </Select>
                                    </VariableLabel>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Phone' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.phone}>
                                        <LabelText>Phone Number</LabelText>
                                        <LeftIcon className='fa-light fa-phone-flip' />
                                        <VariableFormInput
                                            error={error.phone}
                                            placeholder='555-555-5555'
                                            type='tel'
                                            name='phone'
                                            value={state?.phone || ''}
                                            onChange={handleChange}
                                        />
                                    </VariableLabel>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Home Address' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <Label className='Heading05Regular'>
                                        <LabelText>Street Address</LabelText>
                                        <FullInput
                                            placeholder='123 Apple Street'
                                            type='text'
                                            name='address1'
                                            value={state.address1 || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <OptionalContainer>
                                        <Label className='Heading05Regular'>
                                            <LabelText>Address Cont. - Apt/Suite/Etc</LabelText>
                                            <FullInput
                                                placeholder='Apt 100'
                                                type='text'
                                                name='address2'
                                                value={state.address2 || ''}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                        <Optional className='ParagraphSmallRegular'>Optional</Optional>
                                    </OptionalContainer>
                                    <Label className='Heading05Regular'>
                                        <LabelText>City</LabelText>
                                        <FullInput
                                            placeholder='Dallas'
                                            type='text'
                                            name='city'
                                            value={state.city || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <DualContainer>
                                        <DualLabel className='Heading05Regular'>
                                            <LabelText>State</LabelText>
                                            <Select
                                                name='state'
                                                value={state.state || ''}
                                                onChange={handleChange}
                                                style={{fontSize: '11px'}}>
                                                <option disabled value=''>
                                                    --
                                                </option>
                                                {States.map((usstate, ids) => (
                                                    <option key={ids} value={usstate.code}>
                                                        {usstate.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </DualLabel>
                                        <DualLabel className='Heading05Regular'>
                                            <LabelText>ZIP Code</LabelText>
                                            <FullInput
                                                placeholder='10001'
                                                type='text'
                                                name='zip'
                                                value={state.zip || ''}
                                                onChange={handleChange}
                                            />
                                        </DualLabel>
                                    </DualContainer>
                                    <Label>
                                        <LabelText>Country</LabelText>
                                        <Select name='country' value={state?.country || ''} onChange={handleChange}>
                                            <option disabled value='0'>
                                                -- Select Country --
                                            </option>
                                            {Countries.map((country, idc) => (
                                                <option key={idc} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </Select>
                                    </Label>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Password' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.oldPassword} className='Heading05Regular'>
                                        <LabelText>Old Password</LabelText>
                                        <LeftIcon className='fa-light fa-key' />
                                        <RightIcon
                                            className={showOld === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                            onClick={toggleOldPassword}
                                        />
                                        <VariableFormInput
                                            error={error.oldPassword}
                                            type={showOld}
                                            name='oldPassword'
                                            value={passwordState.oldPassword || ''}
                                            onChange={handlePasswordChange}
                                        />
                                    </VariableLabel>
                                    <PasswordContainer>
                                        <VariableLabel error={error.newPassword} className='Heading05Regular'>
                                            <LabelText>New Password</LabelText>
                                            <LeftIcon className='fa-light fa-key' />
                                            <RightIcon
                                                className={
                                                    showNew === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                                                }
                                                onClick={toggleNewPassword}
                                            />
                                            <VariableFormInput
                                                error={error.newPassword}
                                                type={showNew}
                                                name='newPassword'
                                                value={passwordState.newPassword || ''}
                                                onChange={handlePasswordChange}
                                            />
                                        </VariableLabel>
                                        <PasswordErrors>
                                            <PasswordError
                                                passes={passwordError.passwordLength || !passwordState.newPassword}
                                                value='At least 9 characters long'
                                            />
                                            <PasswordError
                                                passes={passwordError.passwordLetters || !passwordState.newPassword}
                                                value='Include at least 1 uppercase and lowercase letter'
                                            />
                                            <PasswordError
                                                passes={passwordError.passwordNumber || !passwordState.newPassword}
                                                value='Include at least 1 number'
                                            />
                                        </PasswordErrors>
                                    </PasswordContainer>
                                    <PasswordContainer>
                                        <VariableLabel error={error.confirmPassword} className='Heading05Regular'>
                                            <LabelText>Confirm New Password</LabelText>
                                            <LeftIcon className='fa-light fa-key' />
                                            <RightIcon
                                                className={
                                                    showConfirm === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                                                }
                                                onClick={toggleConfirmPassword}
                                            />
                                            <VariableFormInput
                                                error={error.confirmPassword}
                                                type={showConfirm}
                                                name='confirmPassword'
                                                value={passwordState.confirmPassword || ''}
                                                onChange={handlePasswordChange}
                                            />
                                        </VariableLabel>
                                        <PasswordErrors>
                                            <PasswordError
                                                passes={
                                                    !(
                                                        passwordState.newPassword === passwordState.confirmPassword &&
                                                        !!passwordState.confirmPassword
                                                    )
                                                }
                                                value='Passwords match'
                                            />
                                        </PasswordErrors>
                                    </PasswordContainer>
                                </EditFields>
                                <SettingsFooter
                                    buttonValue='Reset Password'
                                    onClick={cancel}
                                    spinnerCondition={saving}
                                />
                            </>
                        )}

                        {currentEditMenu === '2-Step Authentication' && (
                            <>
                                <TwoStepVerificationSettings
                                    user={state}
                                    onCancel={cancel}
                                    updateUserInfo={updateUserInfo}
                                />
                            </>
                        )}

                        {currentEditMenu === 'Test Result SMS Notification' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.phone}>
                                        <CheckboxInputContainer>
                                            <Checkbox
                                                error={error.smsEnabled}
                                                id='sms'
                                                type='checkbox'
                                                name='sms'
                                                checked={state.smsEnabled}
                                                onChange={e =>
                                                    setState((prevState: any) => ({
                                                        ...prevState,
                                                        smsEnabled: e.target.checked
                                                    }))
                                                }
                                            />
                                            <CheckboxLabel error={error.smsEnabled} htmlFor='sms'>
                                                Enable SMS
                                            </CheckboxLabel>
                                        </CheckboxInputContainer>
                                    </VariableLabel>
                                </EditFields>
                                <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}

                        {currentEditMenu === 'Subscriptions' && (
                            <>
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <Label>
                                        <LabelText>Go to Stripe to manage your subscription.</LabelText>
                                    </Label>
                                </EditFields>
                                <SettingsFooter buttonValue='Go to Stripe' onClick={cancel} spinnerCondition={saving} />
                            </>
                        )}
                    </EditMenu>
                </>
            </Desktop>

            <Mobile>
                <>
                    {currentMenu === 'Main' && !currentEditMenu && (
                        <Menu>
                            <SettingsHeader value='Settings' className='Heading05Bold' />
                            <Fields>
                                <SettingsField
                                    onClick={() => {
                                        setCurrentMenu('Personal Details');
                                        reset();
                                    }}
                                    value='Personal Details'
                                    padding='20px 24px 12px 24px'
                                />
                                <SettingsField
                                    onClick={() => {
                                        setCurrentMenu('Password & Security');
                                        reset();
                                    }}
                                    value='Password & Security'
                                    padding='20px 24px 12px 24px'
                                />
                                {settings.env === 'development' && (
                                    <SettingsField
                                        onClick={() => {
                                            setCurrentMenu('SMS Settings');
                                            reset();
                                        }}
                                        value='SMS Settings'
                                        padding='20px 24px 12px 24px'
                                    />
                                )}
                            </Fields>
                            <SettingsFooter onClick={() => navigate('/')} buttonValue='Close' />
                        </Menu>
                    )}

                    {currentMenu === 'Personal Details' && !currentEditMenu && (
                        <Menu>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentMenu('Main');
                                    reset();
                                }}
                                value={currentMenu}
                            />
                            <Fields>
                                <SettingsField
                                    isActive={currentEditMenu === 'Name' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Name');
                                        reset();
                                    }}
                                    title='Name'
                                    value={`${menuState.firstName} ${menuState.lastName}`}
                                />
                                <SettingsField
                                    isActive={currentEditMenu === 'Birthday' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Birthday');
                                        reset();
                                    }}
                                    title='Date of Birth'
                                    value={
                                        menuState.birthday
                                            ? new Date(menuState.birthday).toLocaleDateString('en-US', {
                                                  timeZone: 'UTC'
                                              })
                                            : ''
                                    }
                                />
                                <SettingsField
                                    isActive={currentEditMenu === 'Gender' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Gender');
                                        reset();
                                    }}
                                    title='Biological Sex'
                                    value={
                                        menuState.gender
                                            ? menuState.gender[0].toLowerCase() === 'm'
                                                ? 'Male'
                                                : menuState.gender[0].toLowerCase() === 'f'
                                                ? 'Female'
                                                : ''
                                            : ''
                                    }
                                />
                                <SettingsField
                                    isActive={currentEditMenu === 'Ethnicity' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Ethnicity');
                                        reset();
                                    }}
                                    title='Ethnicity'
                                    value={menuState.ethnicity ? menuState.ethnicity : ''}
                                />
                            </Fields>
                            <Fields>
                                <SettingsField title='Email' value={menuState.email ? menuState.email : ''} />
                                <SettingsField
                                    isActive={currentEditMenu === 'Phone' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Phone');
                                        reset();
                                    }}
                                    title='Phone'
                                    value={menuState.phone ? menuState.phone : ''}
                                />
                                <SettingsField
                                    isActive={currentEditMenu === 'Home Address' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Home Address');
                                        reset();
                                    }}
                                    title='Home Address'
                                    value={`${menuState.address1}${menuState.address2 ? ` ${menuState.address2}` : ''}`}
                                />
                                <SettingsField
                                    isActive={currentEditMenu === 'Subscriptions' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Subscriptions');
                                        reset();
                                    }}
                                    title='Manage Subscriptions'
                                />
                            </Fields>
                            <SettingsFooter onClick={() => navigate('/')} buttonValue='Close' />
                        </Menu>
                    )}

                    {currentMenu === 'Password & Security' && !currentEditMenu && (
                        <Menu>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentMenu('Main');
                                    reset();
                                }}
                                value={currentMenu}
                            />
                            <Fields>
                                <SettingsField
                                    isActive={currentEditMenu === 'Password' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Password');
                                        reset();
                                    }}
                                    title='Password'
                                    value='Change Password'
                                />
                                {settings.env === 'development' && (
                                    <SettingsField
                                        isActive={currentEditMenu === '2-Step Authentication' ? true : false}
                                        onClick={() => {
                                            setCurrentEditMenu('2-Step Authentication');
                                            reset();
                                        }}
                                        title='2-Step Authentication'
                                        value={
                                            state.twoFAMode !== null && state.twoFAMode !== ''
                                                ? 'Enabled'
                                                : 'Not enabled'
                                        }
                                    />
                                )}
                            </Fields>
                            <SettingsFooter onClick={() => navigate('/')} buttonValue='Close' />
                        </Menu>
                    )}

                    {currentMenu === 'SMS Settings' && !currentEditMenu && (
                        <Menu>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentMenu('Main');
                                    reset();
                                }}
                                value={currentMenu}
                            />
                            <Fields>
                                <SettingsField
                                    isActive={currentEditMenu === 'Test Result SMS Notification' ? true : false}
                                    onClick={() => {
                                        setCurrentEditMenu('Test Result SMS Notification');
                                        reset();
                                    }}
                                    value='Test Result SMS Notification'
                                />
                            </Fields>
                            <SettingsFooter onClick={() => navigate('/')} buttonValue='Close' />
                        </Menu>
                    )}

                    {currentEditMenu === 'Email' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <Label>
                                    <LabelText>Email Address</LabelText>
                                    <LeftIcon className='fa-light fa-envelope' />
                                    <FormInput
                                        placeholder='example@example.com'
                                        type='email'
                                        name='email'
                                        value={state?.email || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Name' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <Label>
                                    <LabelText>First Name</LabelText>
                                    {/* <LeftIcon icon={faEnvelope} /> */}
                                    <FormInput
                                        placeholder='First Name'
                                        type='text'
                                        name='firstName'
                                        value={state?.firstName || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label>
                                    <LabelText>First Name</LabelText>
                                    <FormInput
                                        placeholder='Last Name'
                                        type='text'
                                        name='lastName'
                                        value={state?.lastName || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Birthday' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <LabelText>Date of Birth</LabelText>
                                <LeftIcon className='fa-light fa-calendar-days' />
                                <StyledDatePicker
                                    error={error.birthday}
                                    //this makes the date object not have a timezone, and be 00:00:00 always
                                    selected={processBirthdate(state.birthday)}
                                    minDate={new Date('1/1/1870')}
                                    maxDate={new Date()}
                                    placeholderText='MM/DD/YYYY'
                                    showMonthDropdown
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dropdownMode='select'
                                    onChange={(date: Date) => {
                                        setState({
                                            ...state,
                                            birthday: new Date(date.toISOString().slice(0, -1))
                                        });
                                        setError({...error, birthday: false});
                                    }}
                                />
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Gender' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <Label>
                                    <LabelText>Biological Sex</LabelText>
                                    <Select name='gender' value={state?.gender || ''} onChange={handleChange}>
                                        <option disabled value=''>
                                            --
                                        </option>
                                        <option value='M'>Male</option>
                                        <option value='F'>Female</option>
                                    </Select>
                                </Label>
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Ethnicity' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <Label>
                                    <LabelText>Ethnicity</LabelText>
                                    <Select name='ethnicity' value={state?.ethnicity || ''} onChange={handleChange}>
                                        <option disabled value=''>
                                            --
                                        </option>
                                        {Ethnicities.map((ethnicity, ide) => (
                                            <option key={ide} value={ethnicity}>
                                                {ethnicity}
                                            </option>
                                        ))}
                                    </Select>
                                </Label>
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Phone' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <VariableLabel error={error.phone}>
                                    <LabelText>Phone Number</LabelText>
                                    <LeftIcon className='fa-light fa-phone-flip' />
                                    <FormInput
                                        placeholder='555-555-5555'
                                        type='tel'
                                        name='phone'
                                        value={state?.phone || ''}
                                        onChange={handleChange}
                                    />
                                </VariableLabel>
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Home Address' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <Label className='Heading05Regular'>
                                    <LabelText>Street Address</LabelText>
                                    <FullInput
                                        placeholder='123 Apple Street'
                                        type='text'
                                        name='address1'
                                        value={state.address1 || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <OptionalContainer>
                                    <Label className='Heading05Regular'>
                                        <LabelText>Address Cont. - Apt/Suite/Etc</LabelText>
                                        <FullInput
                                            placeholder='Apt 100'
                                            type='text'
                                            name='address2'
                                            value={state.address2 || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <Optional className='ParagraphSmallRegular'>Optional</Optional>
                                </OptionalContainer>
                                <Label className='Heading05Regular'>
                                    <LabelText>City</LabelText>
                                    <FullInput
                                        placeholder='Dallas'
                                        type='text'
                                        name='city'
                                        value={state.city || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <DualContainer>
                                    <DualLabel className='Heading05Regular'>
                                        <LabelText>State</LabelText>
                                        <Select
                                            name='state'
                                            value={state.state || ''}
                                            onChange={handleChange}
                                            style={{fontSize: '11px'}}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            {States.map((usstate, ids) => (
                                                <option key={ids} value={usstate.code}>
                                                    {usstate.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </DualLabel>
                                    <DualLabel className='Heading05Regular'>
                                        <LabelText>ZIP Code</LabelText>
                                        <FullInput
                                            placeholder='10001'
                                            type='text'
                                            name='zip'
                                            value={state.zip || ''}
                                            onChange={handleChange}
                                        />
                                    </DualLabel>
                                </DualContainer>
                                <Label>
                                    <LabelText>Country</LabelText>
                                    <Select name='country' value={state?.country || ''} onChange={handleChange}>
                                        <option disabled value='0'>
                                            -- Select Country --
                                        </option>
                                        {Countries.map((country, idc) => (
                                            <option key={idc} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </Select>
                                </Label>
                            </EditFields>
                            <SettingsFooter buttonValue='Save Changes' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === 'Password' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <VariableLabel error={error.oldPassword} className='Heading05Regular'>
                                    <LabelText>Old Password</LabelText>
                                    <LeftIcon className='fa-light fa-key' />
                                    <RightIcon
                                        className={showOld === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                        onClick={toggleOldPassword}
                                    />
                                    <VariableFormInput
                                        error={error.oldPassword}
                                        type={showOld}
                                        name='oldPassword'
                                        value={passwordState.oldPassword || ''}
                                        onChange={handlePasswordChange}
                                    />
                                </VariableLabel>
                                <PasswordContainer>
                                    <VariableLabel error={error.newPassword} className='Heading05Regular'>
                                        <LabelText>New Password</LabelText>
                                        <LeftIcon className='fa-light fa-key' />
                                        <RightIcon
                                            className={showNew === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                            onClick={toggleNewPassword}
                                        />
                                        <VariableFormInput
                                            error={error.newPassword}
                                            type={showNew}
                                            name='newPassword'
                                            value={passwordState.newPassword || ''}
                                            onChange={handlePasswordChange}
                                        />
                                    </VariableLabel>
                                    <PasswordErrors>
                                        <PasswordError
                                            passes={passwordError.passwordLength || !passwordState.newPassword}
                                            value='At least 9 characters long'
                                        />
                                        <PasswordError
                                            passes={passwordError.passwordLetters || !passwordState.newPassword}
                                            value='Include at least 1 uppercase and lowercase letter'
                                        />
                                        <PasswordError
                                            passes={passwordError.passwordNumber || !passwordState.newPassword}
                                            value='Include at least 1 number'
                                        />
                                    </PasswordErrors>
                                </PasswordContainer>
                                <PasswordContainer>
                                    <VariableLabel error={error.confirmPassword} className='Heading05Regular'>
                                        <LabelText>Confirm New Password</LabelText>
                                        <LeftIcon className='fa-light fa-key' />
                                        <RightIcon
                                            className={
                                                showConfirm === 'text' ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                                            }
                                            onClick={toggleConfirmPassword}
                                        />
                                        <VariableFormInput
                                            error={error.confirmPassword}
                                            type={showConfirm}
                                            name='confirmPassword'
                                            value={passwordState.confirmPassword || ''}
                                            onChange={handlePasswordChange}
                                        />
                                    </VariableLabel>
                                    <PasswordErrors>
                                        <PasswordError
                                            passes={
                                                !(
                                                    passwordState.newPassword === passwordState.confirmPassword &&
                                                    !!passwordState.confirmPassword
                                                )
                                            }
                                            value='Passwords match'
                                        />
                                    </PasswordErrors>
                                </PasswordContainer>
                            </EditFields>
                            <SettingsFooter buttonValue='Reset Password' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}

                    {currentEditMenu === '2-Step Authentication' && (
                        <>
                            <TwoStepVerificationSettings
                                user={state}
                                onCancel={cancel}
                                updateUserInfo={updateUserInfo}
                            />
                        </>
                    )}

                    {currentEditMenu === 'Test Result SMS Notification' && (
                        <>
                            <EditMenu onSubmit={() => {}}>
                                <SettingsHeader
                                    onClick={() => {
                                        setCurrentEditMenu('');
                                        reset();
                                    }}
                                    value={currentEditMenu}
                                />
                                <Error message={error.message} width='calc(100% - 88px)' />
                                <EditFields>
                                    <VariableLabel error={error.smsEnabled}>
                                        <LabelText>Enable SMS</LabelText>
                                        <LeftIcon className='fa-light fa-phone' />
                                    </VariableLabel>
                                </EditFields>
                            </EditMenu>
                        </>
                    )}

                    {currentEditMenu === 'Subscriptions' && (
                        <EditMenu onSubmit={handleSubmit}>
                            <SettingsHeader
                                onClick={() => {
                                    setCurrentEditMenu('');
                                    reset();
                                }}
                                value={currentEditMenu}
                            />
                            <Error message={error.message} width='calc(100% - 88px)' />
                            <EditFields>
                                <Label>
                                    <LabelText>Manage your subscription details on Stripe's customer portal.</LabelText>
                                </Label>
                            </EditFields>
                            <SettingsFooter buttonValue='Go to Stripe' onClick={cancel} spinnerCondition={saving} />
                        </EditMenu>
                    )}
                </>
            </Mobile>
        </PageContainer>
    );
};

export default Settings;
