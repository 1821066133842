import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {useLocation} from 'react-router-dom';
import AccordionChart from './AccordionChart';
import ThermofisherLogo from '../assets/thermofisher-logo-dark.svg';
import CompTest from './CompTest';
import {allergenTable} from '../services/AllergenTable';
import settings from '../settings';
import {useAuth} from '../hooks/useAuth';
import {useAppDispatch, useAppSelector} from '../hooks';
import {selectCurrentPatient} from '../slices/patientSlice';
import axios from 'axios';
import {
    ItemContainer,
    ChartContainer,
    CategoryContainer,
    CategoryIcon,
    TitleContainer,
    TitleRightContainer,
    TabContainer,
    ValueText,
    IconContainer,
    IconMask,
    IndicatorIcon,
    ExpandButton,
    ExpandButtonImage,
    ExpandedContainer,
    InfoText,
    FactSheetButton,
    Logo,
    SelectionContainer,
    SelectionButton
} from './styles/AllergyItem';

interface Props {
    result: any;
}

const AllergyItem: React.FC<Props> = ({result}) => {
    const [expanded, setExpanded] = useState(false);
    const [tab, setTab] = useState('Overview');
    const [show, setShow] = useState(false); // CompTest modal
    const {token, user} = useAuth();
    const location = useLocation();
    const currentPatient = useAppSelector(selectCurrentPatient);

    // Levels of Specific IgE / Class / Description of Class / Bar Section
    // < 0.10         0      Negative        1
    // 0.10 - 0.31    0/I    Equivocal/Low   2
    // 0.32 - 0.55    I      Low             2
    // 0.56 - 1.40    II     Moderate        3
    // 1.41 - 3.90    III    High            4
    // 3.91 - 19.00   IV     Very High       5
    // 19.01 - 100.00 V      Very High       5
    // >100.00        VI     Very High       5

    let section = 5; //which part of the graph the value falls in (seems different tests have diff ranges?)
    //let tickPercent = ((Math.log10(0.31) - Math.log10(0.04)) / (Math.log10(3.91) - Math.log10(0.04))) * 100; //22.6; //what percentage of the range the actual test result is, (how far along the bar the tick should be)
    let tickPercent = 0;
    let sensText = ['Very Low', 'Low', 'Moderate', 'High', 'Very High']; //array to just hold language for interpreting results
    let panel = result.panel_name; //'Food'; //e.g. Food, Pet, Nut, etc...
    let allergen = result.test_name; //the name of the allergen
    let resultValue = result.result; //the actual result value

    let trimmedValue = resultValue.replace(/[<>]/g, '');

    // TODO Bit of a hack but these ranges aren't logrithmic, and they're not even, so they're a bit difficult to map to a percentage bar like this
    // with evenly spaced sections. May need to rethink this a bit.
    if (trimmedValue <= 0.1) {
        // Negative
        section = 1;
        tickPercent = 0;
    } else if (trimmedValue < 0.31) {
        // Equivocal/Low
        section = 2;
        tickPercent = 25;
    } else if (trimmedValue < 0.55) {
        // Low
        section = 2;
        tickPercent = 30;
    } else if (trimmedValue < 1.4) {
        // Moderate
        section = 3;
        tickPercent = 50;
    } else if (trimmedValue < 3.9) {
        // High
        section = 4;
        tickPercent = 70;
    } else if (trimmedValue < 19.0) {
        // Very High
        section = 5;
        tickPercent = 85;
    } else if (trimmedValue < 100.0) {
        // Very High
        section = 5;
        tickPercent = 95;
    } else {
        // Very High
        section = 5;
        tickPercent = 100;
    }

    // Replace panel names
    if (panel === 'Adult Respiratory Allergy Panel') {
        panel = 'Adult Environmental Allergy';
    } else if (panel === 'Adult Food Allergy Panel') {
        panel = 'Adult Food Allergy';
    }

    //get object with allergen info
    let allergenInfo = allergenTable.find(obj => {
        return obj.name === allergen;
    });
    //sets default blank object if allergen not found in table
    allergenInfo = allergenInfo || {name: '', category: '', url: '', summary: ''};

    const categoryIcon =
        allergenInfo.category === 'Food'
            ? 'fa-thin fa-fork-knife'
            : allergenInfo.category === 'Grass Pollen'
            ? 'fa-light fa-spa'
            : allergenInfo.category === 'Tree Pollen'
            ? 'fa-solid fa-tree'
            : allergenInfo.category === 'Weed Pollen'
            ? 'fa-light fa-seedling'
            : allergenInfo.category === 'Year Round'
            ? 'fa-light fa-sun-bright'
            : '';
    //determines if there should be an expanded container, if it has url, summary, or category
    const canExpand = !!allergenInfo.category || !!allergenInfo.url || !!allergenInfo.summary;

    // Should we show compontent test button?
    let showCompTest = false;
    if (
        section >= 4 &&
        [
            'Almond',
            'Egg White',
            "Cow's Milk",
            'Peanut',
            'Cat Dander',
            'Dog Dander',
            'Cashew',
            'Hazelnut',
            'Walnut'
        ].includes(allergenInfo.name)
    ) {
        showCompTest = true;
    }

    const downloadFactSheet = () => {
        window.open(allergenInfo?.url, '_blank');
    };

    const handleCompTest = () => {
        recordCompTest();
        setShow(!show);
        //return ReactDOM.render(<CompTest show={true} />, document.getElementById('root'));
    };

    const recordCompTest = async () => {
        const request = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({
                orderID: location.pathname.split('/')[2],
                allergen: allergenInfo?.name,
                customerID: user.userInfo.id,
                firstName: currentPatient.firstName,
                lastName: currentPatient.lastName
            })
        };

        const resultsRes = await axios(`${settings.apiURL}/api/order/comp`, request);
    };

    return (
        <ItemContainer>
            <CompTest show={show} setShow={setShow} />
            <TitleContainer
                canExpand={canExpand}
                onClick={() => {
                    if (canExpand) setExpanded(!expanded);
                }}>
                <p className='ParagraphNormalBold' style={{width: '50%'}}>
                    {allergen}
                </p>
                <TitleRightContainer>
                    {/* this makes the diamond with a circle in it */}
                    <IconContainer>
                        <IconMask className='fa-solid fa-circle' />
                        <IndicatorIcon className='fa-solid fa-diamond' section={section} />
                    </IconContainer>
                    <p className='ParagraphSmallRegular'>{`${sensText[section - 1]} Sensitivity`}</p>
                    {canExpand && (
                        <ExpandButton onClick={() => setExpanded(!expanded)}>
                            <ExpandButtonImage
                                className={
                                    expanded ? 'fa-light fa-circle-chevron-up' : 'fa-light fa-circle-chevron-down'
                                }
                            />
                        </ExpandButton>
                    )}
                </TitleRightContainer>
            </TitleContainer>
            <ChartContainer>
                <ValueText className='ParagraphSmallRegular'>{resultValue}</ValueText>
                <AccordionChart section={section} value={tickPercent} />
            </ChartContainer>
            <ExpandedContainer isActive={expanded ? 1 : 0}>
                {showCompTest && (
                    <SelectionContainer>
                        <SelectionButton
                            className='ParagraphNormalRegular'
                            onClick={() => setTab('Overview')}
                            isActive={tab === 'Overview'}>
                            Overview
                        </SelectionButton>
                        <SelectionButton
                            className='ParagraphNormalRegular'
                            onClick={() => setTab('Molecular Aspects')}
                            isActive={tab === 'Molecular Aspects'}>
                            Molecular Aspects
                        </SelectionButton>
                        {/* <SelectionButton
                        className="ParagraphNormalRegular"
                        onClick={() => setTab('Prevention and Therapy')}
                        isActive={tab === 'Prevention and Therapy'}>
                        Prevention and Therapy
                    </SelectionButton>
                    <SelectionButton
                        className="ParagraphNormalRegular"
                        onClick={() => setTab('Common Symptoms')}
                        isActive={tab === 'Common Symptoms'}>
                        Common Symptoms
                    </SelectionButton> */}
                    </SelectionContainer>
                )}

                {tab === 'Overview' && (
                    <>
                        <TabContainer>
                            <TitleContainer>
                                {/* only shows if it has a summary */}
                                {allergenInfo.summary && (
                                    <p className='ParagraphNormalBold' style={{width: '50%'}}>
                                        Summary
                                    </p>
                                )}
                                <TitleRightContainer style={{gap: '12'}}>
                                    {/* only shows if it has a category */}
                                    {allergenInfo.category && (
                                        <>
                                            <p className='ParagraphNormalBold'>Category</p>
                                            <CategoryContainer>
                                                <CategoryIcon className={categoryIcon} />
                                                <p style={{color: 'white'}} className='ParagraphNormalRegular'>
                                                    {allergenInfo.category}
                                                </p>
                                            </CategoryContainer>
                                        </>
                                    )}
                                </TitleRightContainer>
                            </TitleContainer>
                            {/* only shows if it has a summary */}
                            {allergenInfo.summary && <InfoText>{allergenInfo.summary}</InfoText>}
                        </TabContainer>
                        {allergenInfo.url && (
                            <TabContainer>
                                <TitleContainer>
                                    <p className='ParagraphNormalBold' style={{width: '50%'}}>
                                        External Resources
                                    </p>
                                    <TitleRightContainer>
                                        <Logo src={ThermofisherLogo} alt='Thermofisher Logo' />
                                    </TitleRightContainer>
                                </TitleContainer>
                                {/* TODO:k make download work */}
                                <FactSheetButton className='ParagraphNormalRegular' onClick={downloadFactSheet}>
                                    <CategoryIcon className='fa-light fa-file-image' />
                                    <div>Download Allergen Facts Sheet</div>
                                </FactSheetButton>
                            </TabContainer>
                        )}
                    </>
                )}
                {tab === 'Molecular Aspects' && (
                    <TabContainer>
                        <TitleContainer>
                            <p className='ParagraphNormalBold'>Molecular Aspects</p>
                        </TitleContainer>
                        {/* TODO: finish molecular aspects */}
                        <ChartContainer>
                            <TitleContainer>
                                <i className='fa-light fa-circle-exclamation' />
                                <InfoText>
                                    You are highly reactive to this allergen. Order a component test for more specific
                                    allergen information.
                                </InfoText>
                            </TitleContainer>
                            {/* Leaving this here in case we continue work.
                            TODO: client paused work.  Will add once project is resumed. */}
                            {/* <FactSheetButton className="ParagraphNormalRegular" onClick={handleCompTest}>
                                <div>Order Component Test</div>
                            </FactSheetButton> */}
                            {/* <ValueText className="ParagraphSmallRegular">PLACEHOLDER</ValueText>
                            <AccordionChart section={section} value={tickPercent} /> */}
                        </ChartContainer>
                    </TabContainer>
                )}
                {tab === 'Prevention and Therapy' && (
                    <TabContainer>
                        <TitleContainer>
                            <p className='ParagraphNormalBold'>Prevention strategies</p>
                        </TitleContainer>
                        {/* TODO: make descriptions for prevention */}
                        <p className='ParagraphSmallBold'>Avoidance</p>
                        <p className='ParagraphSmallBold'>Other Topics</p>
                    </TabContainer>
                )}
                {tab === 'Common Symptoms' && (
                    <TabContainer>
                        <TitleContainer>
                            <p className='ParagraphNormalBold'>Common Symptoms</p>
                        </TitleContainer>
                        {/* TODO: finish common symptoms */}
                    </TabContainer>
                )}
            </ExpandedContainer>
        </ItemContainer>
    );
};

export default AllergyItem;
