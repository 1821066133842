import styled from 'styled-components';

interface Props {
    color?: string;
    size?: string;
    left?: string;
    gradient?: boolean;
    centered?: boolean;
}

const Spinner = styled.i<Props>`
    color: ${(props) => (props.color ? props.color : 'white')};
    font-size: ${(props) => (props.size ? props.size : '20px')};
    position: ${(props) => (props.centered ? 'static' : 'absolute')};
    top: 12.5px;
    left: ${(props) => (props.left ? props.left : 'calc(50% - 75px)')};
    --fa-animation-duration: 3s;

    ${(props) =>
        props.gradient &&
        `
        background: linear-gradient(#3ae7ff, #f328f8);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        `}
`;
Spinner.defaultProps = {
    className: 'fa-solid fa-spinner-third fa-spin',
};

export default Spinner;
