import styled from 'styled-components';

interface Props {
    value: string;
    width?: string;
    disabled?: boolean;
    onClick?: Function;
    radius?: string;
    invert?: boolean;
    margin?: string;
}

export const Button = styled.input<Props>`
    height: 50px;
    border-radius: ${(props) => (props.radius ? props.radius : '8px')};
    border-width: 0;
    background-color: ${(props) => (props.disabled ? 'var(--neutral-500)' : 'var(--primary-500)')};
    color: var(--text-0);
    font-size: 1.2rem;
    padding: 0 !important;
    margin: ${(props) => (props.margin ? props.margin : '0 auto')};
    transition: 0.2s;

    &:hover {
        cursor: ${(props) => (props.disabled ? '' : 'pointer')};
        background-color: ${(props) => (props.disabled ? '' : 'var(--primary-300)')};
    }

    &:active {
        background-color: var(--primary-50);
    }

    ${(props) =>
        props.width &&
        `
width: ${props.width};`}

    ${(props) =>
        props.invert &&
        `
    border-style: solid;
    border-width: 3px;
    border-color: var(--primary-500);
    background-color: transparent;
    color: var(--primary-500);
    
    &:hover {
        background-color: var(--neutral-100);  //not sure what color we prefer here
    }`}
`;
