import settings from '../settings'
import React, {useState, ChangeEvent} from 'react'
import {useParams, Link, useNavigate} from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import TopBar from '../components/TopBar'
import Error from '../components/Error'
import Spinner from '../components/Spinner'
import PrimaryButton from '../components/PrimaryButton'
// @ts-ignore
import BloodConsentPDF from '../assets/documents/BLOOD-cashpay-consent.pdf'
// @ts-ignore
import CovidConsentPDF from '../assets/documents/COVID-insurance-consent&CoA.pdf'
// @ts-ignore
import STDConsentPDF from '../assets/documents/STD-cashpay-consent.pdf'
import {States, Ethnicities} from '../services/constants'
import axios from 'axios'
import {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh'
import {
    Background,
    PageContainer,
    Form,
    FormContainer,
    FormInput,
    FormSection,
    Image,
    ImageContainer,
    Select,
    Label,
    LabelText,
    InnerContainer,
    Header,
    Paragraph,
    SubmitContainer,
    DualContainer,
    DualLabel,
    FullInput,
    LeftIcon,
    StyledDatePicker,
    SubText,
    SubTextContainer,
    SurveyQuestion,
    SquareInput,
    Symptom,
    SymptomCheckLabel,
    SymptomInputContainer,
    SymptomLabel,
    Symptoms,
    RadioInput,
    ConfidentialityContainer,
    ConfidentialityLabel,
    BackButton,
    InternalLink
} from './styles/Order'

interface Props {
    hasInsurance: boolean
}

const Order: React.FC<Props> = ({hasInsurance}) => {
    let ConsentForm = CovidConsentPDF
    const navigate = useNavigate()
    const companies: any = {
        'baylor-student-on-campus': 1,
        'baylor-student-off-campus': 2,
        'baylor-faculty': 3,
        'baylor-staff': 4,
        'varsity-spirit': 110,
        'medical-placement-assoc': 111,
        'parish-episcopal-school': 121,
        other: '',
        biote: '', // Just for redirect, quick hack
        livingfitnation: ''
    }
    let {company} = useParams<{company: string}>()
    // TODO is there a better way to handle this? if we don't handle the undefined case we get errors
    // on the next line because undefined cannot be used as an index
    if (company === undefined) company = ''
    const isValidCompany = companies[company] === '' || !!companies[company]
    const initialFormError = {
        patient: false,
        symptom: false,
        insurance: false,
        confidentiality: false
    }
    const initialError = {
        test: '',
        company: '',
        contact: '',
        symptoms: '',
        vaccinated: '',
        singleSymptom: '',
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        ethnicity: '',
        email: '',
        confirmEmail: '',
        phone: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        insured: '',
        insuranceName: '',
        subscriberId: '',
        claimsAddress: '',
        claimsCity: '',
        claimsState: '',
        claimsZip: '',
        idMethod: '',
        social: '',
        license: '',
        residenceState: '',
        confidentiality: '',
        message: '',
        kitID: ''
    }
    const initialState = {
        test: '',
        company: isValidCompany ? companies[company] : '',
        contact: '',
        symptoms: '',
        vaccinated: '',
        fever: false,
        chills: false,
        cough: false,
        shortnessOfBreath: false,
        fatigue: false,
        muscleOrBodyAches: false,
        headache: false,
        lossOfTasteOrSmell: false,
        soreThroat: false,
        nausea: false,
        vomiting: false,
        diarrhea: false,
        other: false,
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        ethnicity: '',
        email: '',
        confirmEmail: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        insured: '',
        insuranceName: '',
        subscriberId: '',
        groupId: '',
        claimsAddress: '',
        claimsCity: '',
        claimsState: '',
        claimsZip: '',
        idMethod: '',
        social: '',
        license: '',
        residenceState: '',
        confidentiality: false,
        kitID: ''
    }
    const [state, setState] = useState<{[x: string]: any}>(initialState)
    const [formError, setFormError] = useState<{[x: string]: any}>(initialFormError)
    const [error, setError] = useState<{[x: string]: any}>(initialError)
    const [step, setStep] = useState(isValidCompany || hasInsurance ? 1 : 0)
    const [submitting, setSubmitting] = useState(false)

    const nextStep = () => setStep(step + 1)
    const prevStep = () => {
        setError(initialError)
        setStep(step - 1)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value

        if (name === 'singleSymptom' || name === 'confidentiality') {
            setState(prevState => ({...prevState, [event.target.value]: value === 'true' ? true : false}))
            setError(prevError => ({...prevError, [name]: false}))
            return
        }

        if (name === 'phone') {
            const digitReg = /^[0-9]$/

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1]
                } else {
                    value = value.substring(0, value.length - 1)
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return
            }
        }

        if (name === 'zip' || name === 'claimsZip') {
            const digitReg = /^[0-9]$/

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return
            }
        }

        if (name === 'social') {
            const digitReg = /^[0-9]$/

            if (value.length === 4 || value.length === 7) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1]
                } else {
                    value = value.substring(0, value.length - 1)
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 11)) {
                return
            }
        }

        if (name === 'symptoms' && value === 'No') {
            setState(prevState => ({
                ...prevState,
                fever: false,
                chills: false,
                cough: false,
                shortnessOfBreath: false,
                fatigue: false,
                muscleOrBodyAches: false,
                headache: false,
                lossOfTasteOrSmell: false,
                soreThroat: false,
                nausea: false,
                vomiting: false,
                diarrhea: false,
                other: false
            }))
            setError(prevError => ({
                ...prevError,
                singleSymptom: false
            }))
        }

        if (name === 'insured') {
            if (value === 'Yes') {
                setState(prevState => ({
                    ...prevState,
                    social: '',
                    license: '',
                    residenceState: ''
                }))
                setError(prevError => ({
                    ...prevError,
                    idMethod: false,
                    social: false,
                    license: false,
                    residenceState: false
                }))
            }
            if (value === 'No') {
                setState(prevState => ({
                    ...prevState,
                    insuranceName: '',
                    subscriberId: '',
                    groupId: '',
                    claimsAddress: '',
                    claimsCity: '',
                    claimsState: '',
                    claimsZip: ''
                }))
                setError(prevError => ({
                    ...prevError,
                    insuranceName: false,
                    subscriberId: false,
                    claimsAddress: false,
                    claimsCity: false,
                    claimsState: false,
                    claimsZip: false
                }))
            }
        }

        if (name === 'idMethod') {
            if (value === 'SSN') {
                setState(prevState => ({
                    ...prevState,
                    license: ''
                }))
                setError(prevError => ({
                    ...prevError,
                    license: false
                }))
            }
            if (value === 'DL') {
                setState(prevState => ({
                    ...prevState,
                    social: ''
                }))
                setError(prevError => ({
                    ...prevError,
                    social: false
                }))
            }
        }

        setState(prevState => ({...prevState, [name]: value}))
        setError(prevError => ({...prevError, [name]: false}))
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()

        if (step === 0) {
            setError(prevError => ({
                ...prevError,
                company: state.company === '',
                message: state.company === '' ? 'Please fill in required fields' : ''
            }))

            if (state.company === '') {
                return
            }

            if (state.company === 'other') {
                setState(prevState => ({...prevState, company: ''}))
            }

            setError(initialError)
            nextStep()
        }

        if (step === 1) {
            if (submitting) {
                return
            } else {
                const emailReg =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
                const socialReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{2}[-\s.]?[0-9]{4}$/

                let hasPatientError =
                    state.test === '' ||
                    state.contact === '' ||
                    (hasInsurance && state.contact === 'No' && state.symptoms === 'No') ||
                    state.symptoms === '' ||
                    (hasInsurance && state.contact === 'No' && state.symptoms === 'No') ||
                    (state.company === 'varsity-spirit' && state.vaccinated === '') ||
                    state.firstName === '' ||
                    state.lastName === '' ||
                    state.birthday === '' ||
                    state.gender === '' ||
                    state.ethnicity === '' ||
                    !emailReg.test(state.email) ||
                    state.email !== state.confirmEmail ||
                    !phoneReg.test(state.phone) ||
                    state.address1 === '' ||
                    state.city === '' ||
                    state.state === '' ||
                    state.zip === ''

                if (company === 'livingfitnation') {
                    hasPatientError = hasPatientError || state.kitID === ''
                }

                const hasSymptomError =
                    hasInsurance &&
                    state.symptoms === 'Yes' &&
                    !state.fever &&
                    !state.chills &&
                    !state.cough &&
                    !state.shortnessOfBreath &&
                    !state.fatigue &&
                    !state.muscleOrBodyAches &&
                    !state.headache &&
                    !state.lossOfTasteOrSmell &&
                    !state.soreThroat &&
                    !state.nausea &&
                    !state.vomiting &&
                    !state.diarrhea &&
                    !state.other

                const hasInsuranceError =
                    hasInsurance &&
                    (state.insured === '' ||
                        (state.insured === 'Yes' && state.insuranceName === '') ||
                        (state.insured === 'Yes' && state.subscriberId === '') ||
                        (state.insured === 'Yes' && state.claimsAddress === '') ||
                        (state.insured === 'Yes' && state.claimsCity === '') ||
                        (state.insured === 'Yes' && state.claimsState === '') ||
                        (state.insured === 'Yes' && state.claimsZip === '') ||
                        (state.insured === 'No' && state.idMethod === '') ||
                        (state.insured === 'No' && state.idMethod === 'SSN' && !socialReg.test(state.social)) ||
                        (state.insured === 'No' && state.idMethod === 'DL' && state.license === '') ||
                        (state.insured === 'No' && state.residenceState === ''))

                const hasConfidentialityError = !state.confidentiality

                setError(prevError => ({
                    ...prevError,
                    test: state.test === '',
                    contact:
                        state.contact === '' || (hasInsurance && state.contact === 'No' && state.symptoms === 'No'),
                    symptoms:
                        state.symptoms === '' || (hasInsurance && state.contact === 'No' && state.symptoms === 'No'),
                    vaccinated: state.company === 'varsity-spirit' && state.vaccinated === '',
                    singleSymptom: hasSymptomError,
                    firstName: state.firstName === '',
                    lastName: state.lastName === '',
                    birthday: state.birthday === '',
                    gender: state.gender === '',
                    ethnicity: state.ethnicity === '',
                    email: !emailReg.test(state.email),
                    confirmEmail: !emailReg.test(state.confirmEmail) || state.email !== state.confirmEmail,
                    phone: !phoneReg.test(state.phone),
                    address1: state.address1 === '',
                    city: state.city === '',
                    state: state.state === '',
                    zip: state.zip === '',
                    insured: hasInsurance && state.insured === '',
                    insuranceName: hasInsurance && state.insured === 'Yes' && state.insuranceName === '',
                    subscriberId: hasInsurance && state.insured === 'Yes' && state.subscriberId === '',
                    claimsAddress: hasInsurance && state.insured === 'Yes' && state.claimsAddress === '',
                    claimsCity: hasInsurance && state.insured === 'Yes' && state.claimsCity === '',
                    claimsState: hasInsurance && state.insured === 'Yes' && state.claimsState === '',
                    claimsZip: hasInsurance && state.insured === 'Yes' && state.claimsZip === '',
                    idMethod: hasInsurance && state.insured === 'No' && state.idMethod === '',
                    social:
                        hasInsurance &&
                        state.insured === 'No' &&
                        state.idMethod === 'SSN' &&
                        !socialReg.test(state.social),
                    license: hasInsurance && state.insured === 'No' && state.idMethod === 'DL' && state.license === '',
                    residenceState:
                        hasInsurance && state.insured === 'No' && state.idMethod && state.residenceState === '',
                    confidentiality: hasConfidentialityError,
                    message:
                        hasPatientError || hasSymptomError || hasInsuranceError || hasConfidentialityError
                            ? 'Please fill in required fields'
                            : '',
                    kitID: state.kitID === ''
                }))

                setFormError({
                    patient: hasPatientError,
                    symptom: hasSymptomError,
                    insurance: hasInsuranceError,
                    confidentiality: hasConfidentialityError
                })

                if (hasPatientError) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                    return
                }

                if (hasSymptomError) {
                    const element: any = document.getElementById('insurance-order-symptoms')
                    element.scrollIntoView({
                        behavior: 'smooth'
                    })
                    return
                }

                if (hasInsuranceError) {
                    const element: any = document.getElementById('insurance-order-insurance-info')
                    element.scrollIntoView({
                        behavior: 'smooth'
                    })
                    return
                }

                if (hasConfidentialityError) {
                    return
                }

                const productLine =
                    state.test === 'pcr'
                        ? 'COVID-19 PCR'
                        : state.test === 'antibody' || state.test === 'igg'
                        ? 'COVID-19 Antibody'
                        : state.test === 'rapid-pcr'
                        ? 'Rapid COVID-19 RT-PCR'
                        : state.test === 'rapid-antigen'
                        ? 'Rapid COVID-19 Antigen'
                        : state.test === 'rapid-flu-covid'
                        ? 'Covid-19/Flu A&B'
                        : 'Blood Wellness'

                const specimenType =
                    state.test === 'pcr' ||
                    state.test === 'rapid-pcr' ||
                    state.test === 'rapid-antigen' ||
                    state.test === 'rapid-flu-covid'
                        ? 'Nasal Swab'
                        : state.test === 'antibody'
                        ? 'Serum'
                        : state.test === 'igg'
                        ? 'Whole Blood'
                        : 'Whole Blood'

                const panels =
                    state.test === 'pcr'
                        ? ['001']
                        : state.test === 'antibody'
                        ? ['003']
                        : state.test === 'igg'
                        ? ['004']
                        : state.test === 'rapid-pcr'
                        ? ['RPCR']
                        : state.test === 'rapid-antigen'
                        ? ['RC19A']
                        : state.test === 'rapid-flu-covid'
                        ? ['CFA']
                        : []

                const profiles =
                    state.test === 'womens-complete'
                        ? ['002']
                        : state.test === 'womens-hormone'
                        ? ['WHH']
                        : state.test === 'mens-complete'
                        ? ['001']
                        : state.test === 'mens-hormone'
                        ? ['MHH']
                        : state.test === 'testosterone'
                        ? ['TotT']
                        : state.test === 'heart-health'
                        ? ['HH']
                        : state.test === 'thyroid-health'
                        ? ['TH']
                        : state.test === 'basic-nutrition'
                        ? ['BNLR']
                        : state.test === 'biote-male'
                        ? ['6168-biote']
                        : state.test === 'biote-female'
                        ? ['6614']
                        : state.test === 'livingfit'
                        ? ['LFNGW']
                        : []

                const diagCodes = state.test === 'pcr' ? ['Z1152'] : []

                if (hasInsurance) {
                    if (state.fever || state.other) {
                        diagCodes.push('R509')
                    }
                    if (state.chills) {
                        diagCodes.push('R6883')
                    }
                    if (state.cough) {
                        diagCodes.push('R059')
                    }
                    if (state.shortnessOfBreath) {
                        diagCodes.push('R0602')
                    }
                    if (state.fatigue) {
                        diagCodes.push('R5383')
                    }
                    if (state.muscleOrBodyAches) {
                        diagCodes.push('R52')
                    }
                    if (state.headache) {
                        diagCodes.push('R51')
                    }
                    if (state.lossOfTasteOrSmell) {
                        diagCodes.push('R430')
                    }
                    if (state.soreThroat) {
                        diagCodes.push('R070')
                    }
                    if (state.nausea) {
                        diagCodes.push('R110')
                    }
                    if (state.vomiting) {
                        diagCodes.push('R1111')
                    }
                    if (state.diarrhea) {
                        diagCodes.push('K591')
                    }
                }

                let order: any = {
                    patient: {
                        firstName: state.firstName,
                        lastName: state.lastName,
                        gender: state.gender[0].toUpperCase(),
                        categoryId: state.company,
                        birthDate: state.birthday,
                        email: state.email,
                        race: state.ethnicity,
                        address: {
                            phone: state.phone,
                            address1: state.address1,
                            address2: state.address2,
                            city: state.city,
                            state: state.state,
                            zipCode: state.zip,
                            country: 'United States'
                        }
                    },
                    providerNPI: '1346818218',
                    lab: settings.env === 'production' ? 'Default' : 'Test',
                    productLine: productLine,
                    specimenType: specimenType,
                    panels: panels,
                    profiles: profiles,
                    diagCodes: diagCodes,
                    sendEmail: false,
                    doNotCollect: true,
                    createNoUser: true,
                    surveyAnswers: [
                        {
                            questionId: 1,
                            answerText: state.symptoms
                        },
                        {
                            questionId: 2,
                            answerText: state.contact
                        },
                        {
                            questionId: 3,
                            answerText: state.vaccinated
                        },
                        {
                            questionId: 4,
                            answerText: state.vaccinated
                        }
                    ]
                }

                if (company === 'livingfitnation') {
                    order.specimenId = state.kitID
                }

                if (hasInsurance) {
                    order = {
                        insurances: [
                            {
                                insuranceProviderName: state.insuranceName || 'CARES Act',
                                planNumber: state.subscriberId,
                                groupNumber: state.groupId,
                                relationship: 'Self',
                                coverageStatus: 'Primary',
                                insuranceType: 'Unknown'
                            }
                        ],
                        ...order
                    }

                    if (state.insured === 'No') {
                        if (state.social) {
                            order.patient.extra = {
                                idNumber: state.social
                            }
                        } else if (state.license) {
                            order.patient.extra = {
                                idNumber: state.license
                            }
                        }
                    }
                }

                const claim = {
                    contact: state.contact,
                    symptoms: state.symptoms,
                    fever: state.fever,
                    chills: state.chills,
                    cough: state.cough,
                    shortnessOfBreath: state.shortnessOfBreath,
                    fatigue: state.fatigue,
                    muscleOrBodyAches: state.muscleOrBodyAches,
                    headache: state.headache,
                    lossOfTasteOrSmell: state.lossOfTasteOrSmell,
                    soreThroat: state.soreThroat,
                    nausea: state.nausea,
                    vomiting: state.vomiting,
                    diarrhea: state.diarrhea,
                    other: state.other,
                    firstName: state.firstName,
                    lastName: state.lastName,
                    birthday: state.birthday,
                    gender: state.gender,
                    ethnicity: state.ethnicity,
                    email: state.email,
                    phone: state.phone,
                    address1: state.address1,
                    address2: state.address2,
                    city: state.city,
                    state: state.state,
                    zip: state.zip,
                    license: state.license,
                    residenceState: state.residenceState,
                    insuranceName: state.insuranceName,
                    subscriberId: state.subscriberId,
                    groupId: state.groupId,
                    claimsAddress: state.claimsAddress,
                    claimsCity: state.claimsCity,
                    claimsState: state.claimsState,
                    claimsZip: state.claimsZip,
                    social: state.social,
                    insured: state.insured === 'Yes'
                }

                setSubmitting(true)

                const orderRequestOptions: AxiosAuthRefreshRequestConfig = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    data: JSON.stringify(order),
                    skipAuthRefresh: true
                }

                const orderRes = await axios(`${settings.apiURL}/api/order/create`, orderRequestOptions)
                    .then(async (res: any) => {
                        if (res.status !== 200) {
                            setSubmitting(false)
                            // const message = (await res.json()).error;
                            setError(prevError => ({...prevError, message: 'Could not create order'}))
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            })
                            return
                        }

                        if (hasInsurance) {
                            const claimRequestOptions = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                data: JSON.stringify(claim)
                            }

                            const claimRes = await axios(`${settings.apiURL}/api/insurance`, claimRequestOptions)
                            setSubmitting(false)
                            if (claimRes.status !== 200) {
                                setError(prevError => ({...prevError, message: 'Could not submit claim'}))
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                })
                                return
                            }
                        }

                        setError(initialError)
                        nextStep()
                    })
                    .catch(() => {
                        setSubmitting(false)
                        // const message = (await res.json()).error;
                        setError(prevError => ({...prevError, message: 'Could not create order (ELIS error)'}))
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                        return
                    })
            }
        }

        if (step === 2) {
            navigate('/login')
            return
        }
    }

    return (
        <>
            <Background />
            <PageContainer>
                <TopBar />
                <FormContainer>
                    {step === 0 && (
                        <Form onSubmit={handleSubmit}>
                            <InnerContainer>
                                <FormSection>
                                    <Header className='Heading05Bold'>Group Information</Header>
                                </FormSection>
                                <Error message={error.message} width={'auto'} />
                                <FormSection>
                                    <ImageContainer>
                                        <Image className='fa-light fa-clipboard-user' />
                                    </ImageContainer>
                                    <Paragraph className='ParagraphNormalRegular'>Please select your group</Paragraph>
                                    <Label error={error.company} className='Heading05Regular'>
                                        <LabelText>Group</LabelText>
                                        <Select
                                            error={error.company}
                                            name='company'
                                            value={state.company || ''}
                                            onChange={handleChange}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            <option value={companies['baylor-student-on-campus']}>
                                                Baylor Student On-Campus
                                            </option>
                                            <option value={companies['baylor-student-off-campus']}>
                                                Baylor Student Off-Campus
                                            </option>
                                            <option value={companies['baylor-faculty']}>Baylor Faculty</option>
                                            <option value={companies['baylor-staff']}>Baylor Staff</option>
                                            <option value={companies['varsity-spirit']}>Varsity Spirit</option>
                                            <option value={companies['medical-placement-assoc']}>
                                                Medical Placement Assoc.
                                            </option>
                                            <option value='other'>Other MLD Affiliated Group</option>
                                        </Select>
                                    </Label>
                                </FormSection>
                                <FormSection>
                                    <SubmitContainer>
                                        <PrimaryButton value='Continue' width='100%' />
                                    </SubmitContainer>
                                </FormSection>
                            </InnerContainer>
                        </Form>
                    )}

                    {step === 1 && (
                        <Form onSubmit={handleSubmit}>
                            <InnerContainer>
                                <FormSection>
                                    <Header className='Heading05Bold'>Patient Information</Header>
                                </FormSection>
                                {(formError.patient || error.message) && (
                                    <Error message={error.message} width={'auto'} />
                                )}
                                <FormSection>
                                    <ImageContainer>
                                        <Image className='fa-light fa-clipboard-user' />
                                    </ImageContainer>
                                    <Paragraph className='ParagraphNormalRegular'>
                                        Please provide all of the following information:
                                    </Paragraph>
                                    <Label error={error.test} className='Heading05Regular'>
                                        <LabelText>Test</LabelText>
                                        <Select
                                            error={error.test}
                                            name='test'
                                            value={state.test || ''}
                                            onChange={handleChange}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            {company === 'biote' && (
                                                <>
                                                    <option value='biote-male'>BioTe Male</option>
                                                    <option value='biote-female'>BioTe Female</option>
                                                </>
                                            )}
                                            {company === 'livingfitnation' && (
                                                <>
                                                    <option value='livingfit'>
                                                        Living Fit Nation - General Wellness
                                                    </option>
                                                </>
                                            )}
                                            {company !== 'biote' && company !== 'livingfitnation' && (
                                                <>
                                                    <option value='pcr'>COVID-19 RT-PCR Test</option>
                                                    {!hasInsurance && (
                                                        <>
                                                            <option value='antibody'>
                                                                COVID-19 Total Antibody Test
                                                            </option>
                                                            <option value='igg'>COVID-19 Antibody IgG Test</option>
                                                            <option value='rapid-pcr'>
                                                                Rapid COVID-19 RT-PCR Test
                                                            </option>
                                                            <option value='rapid-antigen'>
                                                                Rapid COVID-19 Antigen Test
                                                            </option>
                                                            <option value='rapid-flu-covid'>
                                                                Rapid Flu/COVID-19 Test
                                                            </option>
                                                            <option value='womens-complete'>
                                                                Women's Complete Wellness
                                                            </option>
                                                            <option value='womens-hormone'>
                                                                Women's Hormone Health
                                                            </option>
                                                            <option value='mens-complete'>
                                                                Men's Complete Wellness
                                                            </option>
                                                            <option value='mens-hormone'>Men's Hormone Health</option>
                                                            <option value='testosterone'>Testosterone Level</option>
                                                            <option value='heart-health'>Heart Health</option>
                                                            <option value='thyroid-health'>Thyroid Health</option>"
                                                            <option value='basic-nutrition'>
                                                                Basic Nutrition &amp; Lifestyle Risk
                                                            </option>
                                                            <option value='immune-health'>Immune Health</option>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Select>
                                    </Label>
                                    <DualContainer>
                                        <DualLabel error={error.firstName} className='Heading05Regular'>
                                            <LabelText>First Name</LabelText>
                                            <FullInput
                                                error={error.firstName}
                                                type='text'
                                                name='firstName'
                                                value={state.firstName || ''}
                                                placeholder='John'
                                                onChange={handleChange}
                                            />
                                        </DualLabel>
                                        <DualLabel error={error.lastName} className='Heading05Regular'>
                                            <LabelText>Last Name</LabelText>
                                            <FullInput
                                                error={error.lastName}
                                                type='text'
                                                name='lastName'
                                                value={state.lastName || ''}
                                                placeholder='Doe'
                                                onChange={handleChange}
                                            />
                                        </DualLabel>
                                    </DualContainer>
                                    <Label
                                        error={error.birthday}
                                        className='Heading05Regular'
                                        onClick={e => e.preventDefault()}>
                                        <LabelText>Date of Birth</LabelText>
                                        <LeftIcon
                                            className='fa-solid fa-calendar-days'
                                            error={error.birthday ? 1 : 0}
                                        />
                                        {/* <FormInput error={error.birthday} type="date" name="birthday" value={state.birthday || ''} placeholder="MM/DD/YYY" onChange={handleChange} /> */}
                                        <StyledDatePicker
                                            error={error.birthday}
                                            selected={state.birthday}
                                            minDate={new Date('1/1/1870')}
                                            maxDate={new Date()}
                                            placeholderText='MM/DD/YYYY'
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode='select'
                                            onChange={date => {
                                                setState({...state, birthday: date})
                                                setError({...error, birthday: false})
                                            }}
                                        />
                                    </Label>
                                    <Label error={error.gender} className='Heading05Regular'>
                                        <LabelText>Biological Sex</LabelText>
                                        <Select
                                            error={error.gender}
                                            name='gender'
                                            value={state.gender || ''}
                                            onChange={handleChange}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>
                                        </Select>
                                    </Label>
                                    <Label error={error.ethnicity} className='Heading05Regular'>
                                        <LabelText>Ethnicity</LabelText>
                                        <Select
                                            error={error.ethnicity}
                                            name='ethnicity'
                                            value={state.ethnicity || ''}
                                            onChange={handleChange}>
                                            <option disabled value=''>
                                                --
                                            </option>
                                            {Ethnicities.map((ethnicity, ide) => (
                                                <option key={ide} value={ethnicity}>
                                                    {ethnicity}
                                                </option>
                                            ))}
                                        </Select>
                                    </Label>
                                    <Label error={error.address1} className='Heading05Regular'>
                                        <LabelText>Street Address</LabelText>
                                        <FullInput
                                            error={error.address1}
                                            placeholder='123 Apple Street'
                                            type='text'
                                            name='address1'
                                            value={state.address1 || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <SubTextContainer>
                                        <Label error={''} className='Heading05Regular'>
                                            <LabelText>Address Cont. - Apt/Suite/Etc</LabelText>
                                            <FullInput
                                                error={''}
                                                placeholder='Apt 100'
                                                type='text'
                                                name='address2'
                                                value={state.address2 || ''}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                        <SubText className='ParagraphSmallRegular'>Optional</SubText>
                                    </SubTextContainer>
                                    <Label error={error.city} className='Heading05Regular'>
                                        <LabelText>City</LabelText>
                                        <FullInput
                                            error={error.city}
                                            placeholder='Dallas'
                                            type='text'
                                            name='city'
                                            value={state.city || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <DualContainer>
                                        <DualLabel error={error.state} className='Heading05Regular'>
                                            <LabelText>State</LabelText>
                                            <Select
                                                error={error.state}
                                                name='state'
                                                value={state.state || ''}
                                                onChange={handleChange}>
                                                <option disabled value=''>
                                                    --
                                                </option>
                                                {States.map((usstate, ids) => (
                                                    <option key={ids} value={usstate.code}>
                                                        {usstate.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </DualLabel>
                                        <DualLabel error={error.zip} className='Heading05Regular'>
                                            <LabelText>ZIP Code</LabelText>
                                            <FullInput
                                                error={error.zip}
                                                placeholder='10001'
                                                type='text'
                                                name='zip'
                                                value={state.zip || ''}
                                                onChange={handleChange}
                                            />
                                        </DualLabel>
                                    </DualContainer>
                                    <Label error={error.email} className='Heading05Regular'>
                                        <LabelText>Email</LabelText>
                                        <LeftIcon className='fa-light fa-envelope' error={error.email ? 1 : 0} />
                                        <FormInput
                                            error={error.email}
                                            type='email'
                                            name='email'
                                            value={state.email || ''}
                                            placeholder='example@example.com'
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <SubTextContainer>
                                        <Label error={error.confirmEmail} className='Heading05Regular'>
                                            <LabelText>Confirm Email</LabelText>
                                            <LeftIcon
                                                className='fa-light fa-envelope'
                                                error={error.confirmEmail ? 1 : 0}
                                            />
                                            <FormInput
                                                error={error.confirmEmail}
                                                type='email'
                                                name='confirmEmail'
                                                value={state.confirmEmail || ''}
                                                placeholder='example@example.com'
                                                onChange={handleChange}
                                            />
                                        </Label>
                                        {error.confirmEmail && state.email !== state.confirmEmail && (
                                            <SubText
                                                className='ParagraphSmallRegular'
                                                style={{color: 'var(--error-500)'}}>
                                                Emails must match
                                            </SubText>
                                        )}
                                    </SubTextContainer>
                                    <Label error={error.phone} className='Heading05Regular'>
                                        <LabelText>Phone Number</LabelText>
                                        <LeftIcon className='fa-light fa-phone-flip' error={error.phone ? 1 : 0} />
                                        <FormInput
                                            error={error.phone}
                                            type='tel'
                                            name='phone'
                                            value={state.phone || ''}
                                            placeholder='555-555-5555'
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    {company === 'livingfitnation' && (
                                        <Label error={error.kitID} className='Heading05Regular'>
                                            <LabelText>Unique Test Kit ID</LabelText>
                                            {/* <LeftIcon className="fa-light fa-phone-flip" error={error.phone ? 1 : 0} /> */}
                                            <FormInput
                                                error={error.kitID}
                                                type='text'
                                                name='kitID'
                                                value={state.kitID || ''}
                                                // placeholder="555-555-5555"
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    )}
                                    {hasInsurance && (
                                        <Paragraph className='ParagraphNormalRegular'>
                                            <strong>Note:</strong> You must select "Yes" to one or both of the following
                                            questions for your test to qualify for insurance. If you are testing for any
                                            other purpose and cannot answer yes to either of the following questions,
                                            your test does not qualify for insurance.
                                        </Paragraph>
                                    )}
                                    <SurveyQuestion error={error.contact} className='ParagraphNormalRegular'>
                                        In the past 14 days, have you been in close contact with anyone who tested
                                        positive for COVID-19?
                                        <SymptomInputContainer>
                                            <RadioInput
                                                error={error.contact}
                                                id='yes1'
                                                type='radio'
                                                name='contact'
                                                value='Yes'
                                                checked={state.contact === 'Yes'}
                                                onChange={handleChange}
                                            />
                                            <SymptomCheckLabel htmlFor='yes1'>Yes</SymptomCheckLabel>
                                            <RadioInput
                                                error={error.contact}
                                                id='no1'
                                                type='radio'
                                                name='contact'
                                                value='No'
                                                checked={state.contact === 'No'}
                                                onChange={handleChange}
                                            />
                                            <SymptomCheckLabel htmlFor='no1'>No</SymptomCheckLabel>
                                        </SymptomInputContainer>
                                    </SurveyQuestion>
                                    <SurveyQuestion error={error.symptoms} className='ParagraphNormalRegular'>
                                        {hasInsurance ? (
                                            <p>
                                                Do you have any of the following symptoms: fever, cough, chills,
                                                shortness of breath or difficulty breathing, headache, sore throat, loss
                                                of taste or smell, muscle pain?
                                            </p>
                                        ) : (
                                            <p>
                                                Are you currently experiencing <strong>any</strong> symptoms?
                                            </p>
                                        )}
                                        <SymptomInputContainer>
                                            <RadioInput
                                                error={error.symptoms}
                                                id='yes2'
                                                type='radio'
                                                name='symptoms'
                                                value='Yes'
                                                checked={state.symptoms === 'Yes'}
                                                onChange={handleChange}
                                            />
                                            <SymptomCheckLabel htmlFor='yes2'>Yes</SymptomCheckLabel>
                                            <RadioInput
                                                error={error.symptoms}
                                                id='no2'
                                                type='radio'
                                                name='symptoms'
                                                value='No'
                                                checked={state.symptoms === 'No'}
                                                onChange={handleChange}
                                            />
                                            <SymptomCheckLabel htmlFor='no2'>No</SymptomCheckLabel>
                                        </SymptomInputContainer>
                                    </SurveyQuestion>
                                    {state.company.toString() === '110' && (
                                        <SurveyQuestion error={error.symptoms}>
                                            Vaccinated?
                                            <SymptomInputContainer>
                                                <RadioInput
                                                    error={error.vaccinated}
                                                    id='yes3'
                                                    type='radio'
                                                    name='vaccinated'
                                                    value='Yes'
                                                    checked={state.vaccinated === 'Yes'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomCheckLabel htmlFor='yes3'>Yes</SymptomCheckLabel>
                                                <RadioInput
                                                    error={error.vaccinated}
                                                    id='no3'
                                                    type='radio'
                                                    name='vaccinated'
                                                    value='No'
                                                    checked={state.vaccinated === 'No'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomCheckLabel htmlFor='no3'>No</SymptomCheckLabel>
                                            </SymptomInputContainer>
                                        </SurveyQuestion>
                                    )}
                                </FormSection>
                            </InnerContainer>

                            {hasInsurance && state.symptoms === 'Yes' && (
                                <InnerContainer id='insurance-order-symptoms'>
                                    <FormSection>
                                        <ImageContainer>
                                            <Image className='fa-light fa-face-thermometer' />
                                        </ImageContainer>
                                        <Paragraph className='ParagraphNormalRegular'>
                                            Select all of the symptoms you are experiencing
                                        </Paragraph>
                                        <Symptoms className='ParagraphNormalRegular'>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='fever'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='fever'
                                                    checked={state.fever}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='fever'>
                                                    Fever
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='chills'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='chills'
                                                    checked={state.chills}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='chills'>
                                                    Chills
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='cough'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='cough'
                                                    checked={state.cough}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='cough'>
                                                    Cough
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='shortnessOfBreath'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='shortnessOfBreath'
                                                    checked={state.shortnessOfBreath}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='shortnessOfBreath'>
                                                    Shortness of breath
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='fatigue'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='fatigue'
                                                    checked={state.fatigue}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='fatigue'>
                                                    Fatigue
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='muscleOrBodyAches'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='muscleOrBodyAches'
                                                    checked={state.muscleOrBodyAches}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='muscleOrBodyAches'>
                                                    Muscle or body aches
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='headache'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='headache'
                                                    checked={state.headache}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='headache'>
                                                    Headache
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='lossOfTasteOrSmell'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='lossOfTasteOrSmell'
                                                    checked={state.lossOfTasteOrSmell}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='lossOfTasteOrSmell'>
                                                    Loss of taste or smell
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='soreThroat'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='soreThroat'
                                                    checked={state.soreThroat}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='soreThroat'>
                                                    Sore Throat
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='nausea'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='nausea'
                                                    checked={state.nausea}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='nausea'>
                                                    Nausea
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='vomiting'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='vomiting'
                                                    checked={state.vomiting}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='vomiting'>
                                                    Vomiting
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='diarrhea'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='diarrhea'
                                                    checked={state.diarrhea}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='diarrhea'>
                                                    Diarrhea
                                                </SymptomLabel>
                                            </Symptom>
                                            <Symptom>
                                                <SquareInput
                                                    error={error.singleSymptom}
                                                    id='other'
                                                    type='checkbox'
                                                    name='singleSymptom'
                                                    value='other'
                                                    checked={state.other}
                                                    onChange={handleChange}
                                                />
                                                <SymptomLabel error={error.singleSymptom} htmlFor='other'>
                                                    Other
                                                </SymptomLabel>
                                            </Symptom>
                                        </Symptoms>
                                    </FormSection>
                                </InnerContainer>
                            )}

                            {hasInsurance && (
                                <InnerContainer id='insurance-order-insurance-info'>
                                    <FormSection>
                                        <Header className='Heading05Bold'>Insurance Information</Header>
                                    </FormSection>
                                    {formError.insurance && <Error message={error.message} />}
                                    <FormSection>
                                        <ImageContainer>
                                            <Image className='fa-light fa-clipboard-user' />
                                        </ImageContainer>
                                        <Paragraph className='ParagraphNormalRegular'>
                                            Please provide all of the following information:
                                        </Paragraph>
                                        <SurveyQuestion error={error.insured} className='ParagraphNormalRegular'>
                                            Are you insured?
                                            <SymptomInputContainer>
                                                <RadioInput
                                                    error={error.insured}
                                                    id='yes3'
                                                    type='radio'
                                                    name='insured'
                                                    value='Yes'
                                                    checked={state.insured === 'Yes'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomCheckLabel htmlFor='yes3'>Yes</SymptomCheckLabel>
                                                <RadioInput
                                                    error={error.insured}
                                                    id='no3'
                                                    type='radio'
                                                    name='insured'
                                                    value='No'
                                                    checked={state.insured === 'No'}
                                                    onChange={handleChange}
                                                />
                                                <SymptomCheckLabel htmlFor='no3'>No</SymptomCheckLabel>
                                            </SymptomInputContainer>
                                        </SurveyQuestion>

                                        {state.insured === 'Yes' && (
                                            <>
                                                <Label error={error.insuranceName} className='Heading05Regular'>
                                                    <LabelText>Insurance name or company</LabelText>
                                                    <FullInput
                                                        error={error.insuranceName}
                                                        type='text'
                                                        name='insuranceName'
                                                        value={state.insuranceName || ''}
                                                        placeholder='United Healthcare'
                                                        onChange={handleChange}
                                                    />
                                                </Label>
                                                <Label error={error.subscriberId} className='Heading05Regular'>
                                                    <LabelText>Subscriber or Member ID</LabelText>
                                                    <FullInput
                                                        error={error.subscriberId}
                                                        type='text'
                                                        name='subscriberId'
                                                        value={state.subscriberId || ''}
                                                        placeholder='12345678-01'
                                                        onChange={handleChange}
                                                    />
                                                </Label>
                                                <SubTextContainer>
                                                    <Label error={''} className='Heading05Regular'>
                                                        <LabelText>Group ID</LabelText>
                                                        <FullInput
                                                            error={''}
                                                            type='text'
                                                            name='groupId'
                                                            value={state.groupId || ''}
                                                            placeholder='RX0000'
                                                            onChange={handleChange}
                                                        />
                                                    </Label>
                                                    <SubText className='ParagraphSmallRegular'>Optional</SubText>
                                                </SubTextContainer>
                                                <Paragraph>
                                                    Please fill in your insurance claims address, which is typically
                                                    found on the back of your insurance card:
                                                </Paragraph>
                                                <Label error={error.claimsAddress} className='Heading05Regular'>
                                                    <LabelText>Claims Address</LabelText>
                                                    <FullInput
                                                        error={error.claimsAddress}
                                                        type='text'
                                                        name='claimsAddress'
                                                        value={state.claimsAddress || ''}
                                                        placeholder='123 Apple Street'
                                                        onChange={handleChange}
                                                    />
                                                </Label>
                                                <Label error={error.claimsCity} className='Heading05Regular'>
                                                    <LabelText>City</LabelText>
                                                    <FullInput
                                                        error={error.claimsCity}
                                                        type='text'
                                                        name='claimsCity'
                                                        value={state.claimsCity || ''}
                                                        placeholder='Dallas'
                                                        onChange={handleChange}
                                                    />
                                                </Label>
                                                <DualContainer>
                                                    <DualLabel error={error.claimsState} className='Heading05Regular'>
                                                        <LabelText>State</LabelText>
                                                        <Select
                                                            error={error.claimsState}
                                                            name='claimsState'
                                                            value={state.claimsState || ''}
                                                            onChange={handleChange}>
                                                            <option disabled value=''>
                                                                --
                                                            </option>
                                                            {States.map((usstate, ids) => (
                                                                <option key={ids} value={usstate.code}>
                                                                    {usstate.name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </DualLabel>
                                                    <DualLabel error={error.claimsZip} className='Heading05Regular'>
                                                        <LabelText>ZIP Code</LabelText>
                                                        <FullInput
                                                            error={error.claimsZip}
                                                            type='text'
                                                            name='claimsZip'
                                                            value={state.claimsZip || ''}
                                                            placeholder='10001'
                                                            onChange={handleChange}
                                                        />
                                                    </DualLabel>
                                                </DualContainer>
                                            </>
                                        )}

                                        {state.insured === 'No' && (
                                            <>
                                                <SurveyQuestion
                                                    error={error.idMethod}
                                                    className='ParagraphNormalRegular'>
                                                    Select a form of identification. We use this to process your claim.
                                                    <SymptomInputContainer>
                                                        <RadioInput
                                                            error={error.idMethod}
                                                            id='ssn'
                                                            type='radio'
                                                            name='idMethod'
                                                            value='SSN'
                                                            checked={state.idMethod === 'SSN'}
                                                            onChange={handleChange}
                                                        />
                                                        <SymptomCheckLabel htmlFor='ssn'>
                                                            Social Security Number
                                                        </SymptomCheckLabel>
                                                        <RadioInput
                                                            error={error.idMethod}
                                                            id='dl'
                                                            type='radio'
                                                            name='idMethod'
                                                            value='DL'
                                                            checked={state.idMethod === 'DL'}
                                                            onChange={handleChange}
                                                        />
                                                        <SymptomCheckLabel htmlFor='dl'>
                                                            Driver's License Number
                                                        </SymptomCheckLabel>
                                                    </SymptomInputContainer>
                                                </SurveyQuestion>

                                                {state.idMethod === 'SSN' && (
                                                    <Label error={error.social} className='Heading05Regular'>
                                                        <LabelText>Social Security Number</LabelText>
                                                        <FullInput
                                                            error={error.social}
                                                            type='text'
                                                            name='social'
                                                            value={state.social || ''}
                                                            placeholder='000-00-0000'
                                                            onChange={handleChange}
                                                        />
                                                    </Label>
                                                )}

                                                {state.idMethod === 'DL' && (
                                                    <Label error={error.license} className='Heading05Regular'>
                                                        <LabelText>Driver's License Number</LabelText>
                                                        <FullInput
                                                            error={error.license}
                                                            type='text'
                                                            name='license'
                                                            value={state.license || ''}
                                                            placeholder='0000-0000'
                                                            onChange={handleChange}
                                                        />
                                                    </Label>
                                                )}

                                                {state.idMethod && (
                                                    <Label error={error.residenceState} className='Heading05Regular'>
                                                        <LabelText>State of Residence</LabelText>
                                                        <Select
                                                            error={error.residenceState}
                                                            name='residenceState'
                                                            value={state.residenceState || ''}
                                                            onChange={handleChange}>
                                                            <option disabled value=''>
                                                                --
                                                            </option>
                                                            {States.map((usstate, ids) => (
                                                                <option key={ids} value={usstate.code}>
                                                                    {usstate.name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </Label>
                                                )}
                                            </>
                                        )}
                                    </FormSection>
                                </InnerContainer>
                            )}

                            <InnerContainer>
                                <FormSection>
                                    <Paragraph className='ParagraphNormalRegular'>Patient Confidentiality</Paragraph>
                                    {company === 'livingfit' && (
                                        <Paragraph className='ParagraphNormalRegular' style={{textAlign: 'left'}}>
                                            The test results will only be released to you, to the referring health care
                                            provider (if any), to the ordering laboratory (if any), to other health care
                                            providers involved in your care and treatment or to others as entitled by
                                            law. In the case of positive COVID-19 test results, CDC and local public
                                            health authorities will be notified.
                                        </Paragraph>
                                    )}
                                    <Paragraph className='ParagraphNormalRegular' style={{textAlign: 'left'}}>
                                        I hereby consent to the release of my test results to my employer, the referring
                                        health care provider (if any), to the ordering laboratory (if any), to other
                                        health care providers involved in my care and treatment or to others as entitled
                                        by law. 
                                    </Paragraph>
                                    {[
                                        'pcr',
                                        'antibody',
                                        'igg',
                                        'rapid-pcr',
                                        'rapid-antigen',
                                        'rapid-flu-covid'
                                    ].indexOf(state.test) >= 0 && (
                                        <ConfidentialityContainer className='ParagraphNormalRegular'>
                                            <SquareInput
                                                error={error.confidentiality}
                                                id='confidentiality'
                                                type='checkbox'
                                                name='confidentiality'
                                                value='confidentiality'
                                                checked={state.confidentiality}
                                                onChange={handleChange}
                                            />
                                            <ConfidentialityLabel
                                                error={error.confidentiality}
                                                htmlFor='confidentiality'>
                                                I have read the{' '}
                                                <InternalLink href={CovidConsentPDF} target='_blank'>
                                                    Informed Consent and Testing Agreement
                                                </InternalLink>{' '}
                                                and I give permission to MyLabsDirect to perform Covid-19 testing as
                                                described.
                                            </ConfidentialityLabel>
                                        </ConfidentialityContainer>
                                    )}
                                    {[
                                        'pcr',
                                        'antibody',
                                        'igg',
                                        'rapid-pcr',
                                        'rapid-antigen',
                                        'rapid-flu-covid'
                                    ].indexOf(state.test) < 0 && (
                                        <ConfidentialityContainer className='ParagraphNormalRegular'>
                                            <SquareInput
                                                error={error.confidentiality}
                                                id='confidentiality'
                                                type='checkbox'
                                                name='confidentiality'
                                                value='confidentiality'
                                                checked={state.confidentiality}
                                                onChange={handleChange}
                                            />
                                            <ConfidentialityLabel
                                                error={error.confidentiality}
                                                htmlFor='confidentiality'>
                                                I have read the{' '}
                                                <InternalLink href={BloodConsentPDF} target='_blank'>
                                                    Informed Consent and Testing Agreement
                                                </InternalLink>{' '}
                                                and I give permission to MyLabsDirect to perform testing as described.
                                            </ConfidentialityLabel>
                                        </ConfidentialityContainer>
                                    )}
                                </FormSection>
                                <FormSection>
                                    <SubmitContainer>
                                        {submitting && <Spinner />}
                                        <PrimaryButton value='Submit' width='100%' />
                                    </SubmitContainer>
                                    {!isValidCompany && (
                                        <BackButton className='ParagraphNormalRegular' onClick={prevStep}>
                                            Go back
                                        </BackButton>
                                    )}
                                </FormSection>
                            </InnerContainer>
                        </Form>
                    )}

                    {step === 2 && (
                        <Form onSubmit={handleSubmit}>
                            <InnerContainer>
                                <FormSection>
                                    <ImageContainer>
                                        <Image className='fa-light fa-circle-check' />
                                    </ImageContainer>
                                    <Paragraph className='ParagraphNormalRegular'>
                                        {
                                            "Success! Your order has been submitted. If you don't already have a MyLabsDirect account, an email has been sent to help you finish setting one up."
                                        }
                                    </Paragraph>
                                </FormSection>
                                {/* <FormSection>
                                    <SubmitContainer>
                                        <PrimaryButton value="Continue to MyLabsDirect Dashboard" width="100%" />
                                    </SubmitContainer>
                                </FormSection> */}
                            </InnerContainer>
                        </Form>
                    )}
                </FormContainer>
            </PageContainer>
        </>
    )
}

export default Order
