import DatePicker from 'react-datepicker';
import styled, {css} from 'styled-components';

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    border: solid 0 rgba(255, 255, 255, 0.5);
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 48px);
    padding: 24px;
    gap: 24px;
`;

export const Header = styled.div`
    height: 35px;
    text-align: left;
    color: var(--text-600);
    align-self: flex-start;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 120px;
    height: 120px;
    border-radius: 32px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
`;
export const Image = styled.i`
    font-size: 60px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Paragraph = styled.p`
    white-space: pre-line;
    text-align: center;
`;

export const Label = styled.label<{error?: string | undefined}>`
    font-size: 1rem;
    letter-spacing: 0.5px;
    font-weight: bold;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    width: 100%;
    height: 99px;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;

export const FormInput = styled.input<{error?: string | undefined}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin: 8px 0;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;

export const SubmitContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const BackButton = styled.button`
    color: var(--link-600);
    background-color: var(--neutral-0);
    border: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--link-300);
    }
`;

export const Error = styled.p<{hasSubmitted: boolean; message: string}>`
    width: calc(100% - 64px);
    height: ${(props) => (props.hasSubmitted ? (props.message.length > 45 ? '42px' : '21px') : '0')};
    color: var(--text-0);
    text-align: center;
    overflow: hidden;
    line-height: 1.5;
    margin: ${(props) => (props.hasSubmitted ? '16px 24px' : '0 24px')};
    padding: ${(props) => (props.hasSubmitted ? '8px' : '0')};
    border-radius: 8px;
    background-color: ${(props) => (props.hasSubmitted ? 'rgba(165, 20, 0, 1)' : 'rgba(165, 20, 0, 0)')};
    transition: height 0.2s, margin 0.2s, padding 0.2s, background-color 0.2s 0.2s;
`;

export const LeftIcon = styled.i<{error: number | undefined}>`
    font-size: 20px;
    position: absolute;
    left: 12px;
    top: 43px;
    color: ${(props) => (props.error ? 'var(--error-500)' : 'var(--neutral-300)')};
    transition: 0.2s;
`;

export const Select = styled.select<{error: boolean}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    padding-left: 40px;

    :hover {
        cursor: pointer;
    }

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;

export const SurveyQuestion = styled.div<{error: boolean | undefined}>`
    width: 100%;
    margin-top: 8px;
    font-size: 1rem;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    gap: 8px;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;

export const SymptomInputContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const SymptomOptionLabel = styled.label`
    padding-left: 12px;
    margin-right: 32px;

    &:hover {
        cursor: pointer;
    }
`;

export const RadioInput = styled(FormInput)`
    width: 20px;
    height: 20px;
    margin: 0;

    &:hover {
        cursor: pointer;
    }
`;

export const StyledDatePicker = styled(DatePicker)<{error: boolean | undefined}>`
    width: calc(100% - 56px);
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px var(--neutral-600);
    background-color: var(--neutral-0);
    margin-top: 8px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;
