import React from 'react';
import {Layout} from '../Components';
import CautionIcon from '../Resources/img/Step6.png';

const Step6 = () => {
    return (
        <Layout>
            <section className="white-bg">
                <div className="helper-media">
                    <img src={CautionIcon} alt="Caution" />
                </div>

                <h2 className="antibody-step-number">Please Read Before Continuing</h2>

                <p className="has-text-centered">
                    If you are not connected to telehealth support, be prepared to video record your test. {}
                </p>
                <p className="has-text-centered">
                    <strong>The following steps 5 through 9</strong> must be included in your video submission for your
                    test to be valid.
                </p>
            </section>
        </Layout>
    );
};

export default Step6;
