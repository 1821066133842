import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import Nav from '../components/Nav';
import Banner from '../components/Banner';
import Logo from '../assets/theme-full-color.svg';
// import {useAppDispatch, useAppSelector} from '../hooks';
// import {showBanner, setMessage} from '../slices/bannerSlice';
import {
    Background,
    MobileHeader,
    MobileLogo,
    NavImage,
    NavToggle,
    MobileNavOverlay,
    LogoLink,
    Container,
} from './styles/Layout';

interface Props {
    showNav: boolean;
}

const Layout: React.FC<Props> = (props) => {
    const [currentSize, setCurrentSize] = useState(window.innerWidth);
    const [navVisible, setNavVisible] = useState(currentSize > 800);
    // const dispatch = useAppDispatch();
    // const showBanner = ShowBanner('oh ho ho');

    useEffect(() => {
        // dispatch(showBanner());
        // dispatch(setMessage('Welcome to the dashboard!'));
        // showBanner();

        function handleResize() {
            setCurrentSize(window.innerWidth);
            if (window.innerWidth > 800) {
                setNavVisible(true);
            }
        }

        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <Background>
            <Banner />
            {props.showNav && (
                <>
                    <MobileHeader>
                        <NavToggle isRotated={navVisible} onClick={() => setNavVisible(!navVisible)}>
                            <NavImage className="fa-solid fa-bars" />
                        </NavToggle>
                        <LogoLink
                            itemProp=""
                            to="/"
                            onClick={() => {
                                (window as any).hj('event', 'v2_navbar_mldLogo');
                                setNavVisible(false);
                            }}>
                            <MobileLogo src={Logo} alt="" />
                        </LogoLink>
                    </MobileHeader>
                    <Nav
                        navVisible={navVisible}
                        setNavVisible={setNavVisible}
                        adminBanner={false}
                        setAdminBanner={() => {}}
                    />
                    <MobileNavOverlay
                        isActive={currentSize <= 800 && navVisible}
                        onClick={() => setNavVisible(false)}
                    />
                </>
            )}

            <Container>
                <Outlet />
            </Container>
        </Background>
    );
};

export default Layout;
