let apiURL: string = '';
let env = 'development';

// If this is a non-empty string, then a one-time service modal will be displayed
// to non-admin users on login, with whatever message is set in this variable.
const serviceMessage = '';

// We're doing this because of Azure's static web app service's limited support
// for setting environment variables in staging environments. To use Azure, we'd need
// to manually set this variable for every staging environment that gets created, which
// is one per PR to master.
if (window.location.hostname === 'dashboard.mylabsdirect.com') {
    apiURL = 'https://api.mylabsdirect.com';
    env = 'production';
} else if (window.location.hostname === 'localhost') {
    apiURL = 'http://localhost:3001';
    env = 'development';
} else if (window.location.hostname === '127.0.0.1') {
    apiURL = 'http://127.0.0.1:3001';
    env = 'development';
} else if (window.location.hostname === 'dash.mld.test') {
    apiURL = 'https://api.mld.test';
    env = 'development';
} else {
    apiURL = `https://staging-api.mylabsdirect.com`;
    env = 'development';
}

const settings = {
    apiURL,
    env,
    serviceMessage,
};

export default settings;
