import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import type {RootState} from '../store';
import settings from '../settings';
import axios from 'axios';
import {setSubPatients} from './patientSlice';

interface Test {
    id: number;
    elis_id: number;
    oid: string;
    createdDate: string;
    statusDate: string;
    amendedDate: string;
    status: string;
    resulted: string;
    final: boolean;
    issueCount: number;
    issues: string;
    customer_id: number;
    firstName: string;
    lastName: string;
    birthdate: string;
    sent: boolean;
    appointmentDate: string;
    appointmentLocation: string;
    appointmentType: string;
    appointmentDuration: string;
    appointmentCalendar: string;
    results: Result[];
}

interface Result {
    test_name: string;
    test_code: string;
    result: string;
    refRange: string;
    panel_name: string;
    panel_code: string;
    units: string;
    notes: string;
    abnormal?: string;
    statusDate?: string;
}

// Define a type for the slice state
interface TestState {
    //[index: string]: Test;
    tests: Test[];
    loading: boolean;
}

// Define the initial state using that type
const initialState = {
    tests: [] as Test[],
    loading: false,
};

export const fetchTests = createAsyncThunk('test/fetchTests', async (token: string, api) => {
    try {
        const req = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const res = await axios.get(`${settings.apiURL}/api/user/data`, req);

        const uniquePatients: any = {};
        const newPatients: any = [];

        const {patient} = api.getState() as RootState;

        uniquePatients[patient.currentPatient.fullName.toLowerCase()] = true;

        newPatients.push({
            id: patient.currentPatient.id,
            firstName: patient.currentPatient.firstName?.trim(),
            lastName: patient.currentPatient.lastName?.trim(),
            fullName: patient.currentPatient?.fullName?.trim(),
            birthdate: patient.currentPatient.birthdate,
        });

        for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].firstName || !res.data[i].lastName) {
                continue;
            }
            const patient = `${res.data[i].firstName} ${res.data[i].lastName}`.toLowerCase().trim();
            if (!uniquePatients[patient]) {
                uniquePatients[patient] = true;
                newPatients.push({
                    id: res.data[i].id,
                    firstName: res.data[i].firstName?.trim(),
                    lastName: res.data[i].lastName?.trim(),
                    fullName: patient || '',
                    birthdate: res.data[i].birthdate,
                });
            }
        }

        api.dispatch(setSubPatients(newPatients));

        return res.data;
    } catch (err) {
        console.error(err);
        return null;
    }
});

export const testSlice = createSlice({
    name: 'tests',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // setTests: (state, action: PayloadAction<Test[]>) => {
        //     Object.assign(state.tests.tests, action.payload);
        // },
    },
    extraReducers: {
        [fetchTests.fulfilled.type]: (state, action: PayloadAction<Test[]>) => {
            state.loading = false;
            Object.assign(state.tests, action.payload);
        },
        [fetchTests.pending.type]: (state, action: PayloadAction<Test[]>) => {
            state.loading = true;
        },
    },
});

// export const {setTests,} = testSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTests = (state: RootState) => state.tests;
export const selectTest = (state: RootState, idx: number) => state.tests.tests.find((t) => t.id === idx);
export const selectTestsByName = (state: RootState, name: string) => {
    return state.tests.tests.filter(
        (t) => `${t.firstName?.toLowerCase().trim()} ${t.lastName?.toLowerCase().trim()}` === name.toLowerCase()
    );
};

export default testSlice.reducer;
