import React, {ChangeEvent, FormEvent, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import Spinner from '../components/Spinner';
import TopBar from '../components/TopBar';
import Error from '../components/Error';
import PasswordError from '../components/PasswordError';
import settings from '../settings';
import {
    PageContainer,
    Form,
    FormBody,
    FormContainer,
    FormInput,
    LabelText,
    PasswordLabel,
    LeftIcon,
    RightIcon,
    ConfirmIcon,
    Header,
    PasswordErrors,
    Footer,
    SubmitContainer,
    Caption,
    LoginLink,
} from './styles/Verify';

const Verify: React.FC = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const ref = query.get('ref');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [verifying, setVerifying] = useState(false);
    const [verified, setVerified] = useState(false);
    const {token} = useParams<{token: string}>();
    const [password, setPassword] = useState('');
    const [error, setError] = useState<{[index: string]: boolean}>({});
    const [errorMsg, setErrorMsg] = useState('');
    const [show, setShow] = useState('password');

    const togglePassword = () => {
        setShow(show === 'password' ? 'text' : 'password');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // event.preventDefault();
        // const { name, value } = event.target;
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'password') {
            const passwordUppercaseReg = /[A-Z]/;
            const passwordLowercaseReg = /[a-z]/;
            const passwordNumberReg = /[\d]/;

            setError({
                ...error,
                passwordLetters: !passwordUppercaseReg.test(value) || !passwordLowercaseReg.test(value),
                passwordNumber: !passwordNumberReg.test(value),
                passwordLength: value.length <= 8,
            });
        }

        setPassword(value);
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setHasSubmitted(true);
        setError({});

        const passwordUppercaseReg = /[A-Z]/;
        const passwordLowercaseReg = /[a-z]/;
        const passwordNumberReg = /[\d]/;

        const error: {[key: string]: any} = {
            passwordLetters: !passwordUppercaseReg.test(password) || !passwordLowercaseReg.test(password),
            passwordNumber: !passwordNumberReg.test(password),
            passwordLength: password.length <= 8,
        };

        setError(error);

        for (const key in error) {
            if (error[key]) {
                setErrorMsg('Please enter a valid password');
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                return;
            }
        }

        setVerifying(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({password: password}),
        };

        const res = await fetch(`${settings.apiURL}/api/verify/${token}`, requestOptions);
        setVerifying(false);
        if (!res.ok) {
            setErrorMsg('Verification failed');
            console.log(res.json());
            return;
        }
        setVerified(true);
    };

    return (
        <PageContainer>
            <TopBar />
            <FormContainer>
                {/* {!verified && <Caption>Verifying your account...</Caption>} */}
                {!verified && (
                    <Form onSubmit={handleSubmit}>
                        <FormBody>
                            <Header>Create your password</Header>
                            <Error message={errorMsg} />
                            <PasswordLabel
                                error={
                                    hasSubmitted &&
                                    (error.passwordLetters || error.passwordNumber || error.passwordLength)
                                }>
                                <LabelText>Password</LabelText>
                                <LeftIcon className="fa-light fa-key" />
                                <RightIcon
                                    className={show === 'text' ? 'fa-light fa-eye-slash' : 'fa-light fa-eye'}
                                    onClick={togglePassword}
                                />
                                <FormInput
                                    error={
                                        hasSubmitted &&
                                        (error.passwordLetters || error.passwordNumber || error.passwordLength)
                                    }
                                    type={show}
                                    name="password"
                                    value={password || ''}
                                    onChange={handleChange}
                                />
                            </PasswordLabel>
                            <PasswordErrors>
                                <PasswordError
                                    passes={error.passwordLength || password === ''}
                                    value="At least 9 characters long"
                                />
                                <PasswordError
                                    passes={error.passwordLetters || password === ''}
                                    value="Include at least 1 uppercase and lowercase letter"
                                />
                                <PasswordError
                                    passes={error.passwordNumber || password === ''}
                                    value="Include at least 1 number"
                                />
                            </PasswordErrors>
                        </FormBody>
                        <Footer>
                            <SubmitContainer>
                                {verifying && <Spinner left="calc(50% - 95px)" />}
                                <PrimaryButton value="Set Password" width="100%" />
                            </SubmitContainer>
                        </Footer>
                    </Form>
                )}
                {/* {!!verified && <Redirect to={url} />} */}
                {!!verified && (
                    <>
                        <ConfirmIcon className="fa-solid fa-circle-check fa-10x" />
                        <Caption>
                            Your account has been verified and your password has been set! Please{' '}
                            <LoginLink to={ref ? `/login?ref=${ref}` : '/login'}>login</LoginLink>.
                        </Caption>
                    </>
                )}
            </FormContainer>
        </PageContainer>
    );
};

export default Verify;
