import React, {useState} from 'react';
import {Layout} from '../Components';
import WatchVideoComputer from '../Resources/img/Step3.png';
import ModalVideo from 'react-modal-video';

const Step3 = () => {
    const [open, setOpen] = useState(false);
    return (
        <Layout>
            <section className="white-bg">
                <div className="helper-media">
                    <ModalVideo
                        autoplay="true"
                        modestbranding="1"
                        showinfo="0"
                        channel="youtube"
                        isOpen={open}
                        videoId="q53-NcQ-YC4"
                        onClose={() => setOpen(false)}
                    />
                    <img
                        src={WatchVideoComputer}
                        alt="Video Tutorial"
                        onClick={() => setOpen(true)}
                        style={{cursor: 'pointer'}}
                    />
                </div>

                <h2 className="antibody-step-number">Step 2</h2>

                <h3 className="antibody-step-description">Visual Learner?</h3>

                <p className="has-text-centered">
                    Click above to watch a quick tutorial video on how to administer your test, or click next to view
                    step-by-step instructions.
                </p>
            </section>
        </Layout>
    );
};

export default Step3;
