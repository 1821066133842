import React from 'react';
import CovidIcon from '../assets/images/Fast Fact Covid.webp';
import NProteinIcon from '../assets/images/Fast Fact Nprotein.webp';
import SProteinIcon from '../assets/images/Fast Fact Sprotein.webp';
import VaccineIcon from '../assets/images/Fast Fact Vaccine.webp';
import FindNProteinIcon from '../assets/images/Fast Fact Findn.webp';
import FindSProteinIcon from '../assets/images/Fast Fact Finds.webp';
import {FastFactsContainer, FastFact, FastFactIcon} from './styles/FastFacts';

const FastFacts = () => {
    return (
        <FastFactsContainer>
            <FastFact>
                <FastFactIcon src={CovidIcon} alt="" />
                <p className="ParagraphSmallRegular">
                    The Coronavirus is made of four main structural proteins: nucleocapsid (N-protein), spike
                    (S-protein), membrane (M-protein), and envelope (E-protein).
                </p>
            </FastFact>
            <FastFact>
                <FastFactIcon src={NProteinIcon} alt="" />
                <p className="ParagraphSmallRegular">
                    N-protein plays an important role in replicating and copying viral RNA.
                </p>
            </FastFact>
            <FastFact>
                <FastFactIcon src={SProteinIcon} alt="" />
                <p className="ParagraphSmallRegular">
                    S-protein contains receptor sites that antibodies bind to in killing a virus.
                </p>
            </FastFact>
            <FastFact>
                <FastFactIcon src={VaccineIcon} alt="" />
                <p className="ParagraphSmallRegular">
                    Vaccines in the US only trigger antibody response against the S-protein.
                </p>
            </FastFact>
            <FastFact>
                <FastFactIcon src={FindNProteinIcon} alt="" />
                <p className="ParagraphSmallRegular">
                    Most antibody tests only detect antibodies that have been produced against the N-protein. This means
                    that you may have developed protective antibodies from a vaccine but you will get a false-negative
                    result.
                </p>
            </FastFact>
            <FastFact>
                <FastFactIcon src={FindSProteinIcon} alt="" />
                <p className="ParagraphSmallRegular">
                    Our test detects antibodies against the S-protein of the virus. This tells you if your vaccine was
                    effective at producing antibodies and/or if you still have antibodies from a previous infection.
                </p>
            </FastFact>
        </FastFactsContainer>
    );
};

export default FastFacts;
