import React from 'react';
import {Desktop, Mobile} from './MediaQueries';
import {getNameString, getColor} from '../utils/Test';
import {
    ItemContainer,
    ColorBar,
    InfoContainer,
    Icon,
    LeftContainer,
    RightContainer,
    TestName,
    LatestBadge,
    SmallIcon,
    LatestText,
    PendingBadge,
    PendingText,
} from './styles/ResultItem';

interface Props {
    result: any;
    index: number;
}

const ResultItem: React.FC<Props> = (props) => {
    const {result, index} = props;
    const date = new Date(result.statusDate);
    const birthdate = result.birthdate ? new Date(result.birthdate) : new Date();
    const status = result.status?.toLowerCase();
    const test_name = getNameString(result);
    const color = getColor(result, test_name);

    // TODO Mobile duplication
    return (
        <ItemContainer>
            <ColorBar color={color} />
            <InfoContainer>
                <LeftContainer>
                    <TestName className="ParagraphNormalRegular">{test_name}</TestName>
                    <Desktop>
                        <p className="ParagraphNormalRegular">{`${result.firstName || ''} ${result.lastName || ''}${
                            result.birthdate ? ` (${birthdate.toLocaleDateString('en-US', {timeZone: 'UTC'})})` : ''
                        }`}</p>
                    </Desktop>
                    {index === 0 && (
                        <LatestBadge>
                            <SmallIcon className="fa-light fa-circle-check" />
                            <LatestText className="ParagraphSmallRegular">Latest Results</LatestText>
                        </LatestBadge>
                    )}
                    <Mobile>
                        <p className="ParagraphNormalRegular">{`${result.firstName || ''} ${result.lastName || ''}${
                            result.birthdate ? ` (${birthdate.toLocaleDateString('en-US', {timeZone: 'UTC'})})` : ''
                        }`}</p>
                    </Mobile>
                    {(status === 'collected' ||
                        status === 'received' ||
                        status === 'processing' ||
                        result.unreleased) && (
                        <PendingBadge>
                            <SmallIcon className="fa-light fa-clock" />
                            <PendingText className="ParagraphSmallRegular">Results Pending</PendingText>
                        </PendingBadge>
                    )}
                </LeftContainer>
                {status === 'resulted' && !result.unreleased && (
                    <RightContainer>
                        <p className="ParagraphNormalRegular">
                            Results on {date.toLocaleDateString('en-US', {timeZone: 'UTC'})}
                        </p>
                        <Desktop>
                            <Icon className="fa-light fa-circle-chevron-right" />
                        </Desktop>
                    </RightContainer>
                )}
            </InfoContainer>
        </ItemContainer>
    );
};

export default ResultItem;
