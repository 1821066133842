import React from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import PrimaryButton from '../../components/PrimaryButton';
import {useAppDispatch} from '../../hooks';
import {setKitParam} from '../../slices/kitSlice';
import {Form, FormSection, SubmitContainer, Image, ImageContainer, Paragraph} from './styles/Step4';

export const Step4: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const dispatch = useAppDispatch();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        const submitter = event.nativeEvent.submitter.value;

        if (submitter === 'At an MLD location') {
            dispatch(setKitParam({location: 'mld'}));
        } else if (submitter === 'At my location') {
            dispatch(setKitParam({location: 'mobile'}));
        }
        props.goToNamedStep?.('step5');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <ImageContainer>
                    <Image className="fa-light fa-circle-check" />
                </ImageContainer>
            </FormSection>
            <FormSection>
                <Paragraph className="Heading05Bold">Thank you!</Paragraph>
                <Paragraph className="ParagraphNormalRegular">
                    Your kit has been registered! Next, you'll need to make an appointment with us to have your blood
                    drawn. Where would you like to be tested?
                </Paragraph>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    <PrimaryButton value="At an MLD location" width="100%" />
                </SubmitContainer>
                <SubmitContainer>
                    <PrimaryButton value="At my location" width="100%" />
                </SubmitContainer>
                <Paragraph className="ParagraphSmallRegular">Our mobile concierge services start at just $99</Paragraph>
                {/* <BackButton className="ParagraphNormalRegular" onClick={() => setStep(0)}>
                Go back
            </BackButton> */}
            </FormSection>
        </Form>
    );
};
