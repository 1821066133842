import React from 'react';
import Header from './Header';
import Footer from './Footer';
import {useLocation, useNavigate} from 'react-router-dom';

// Quick and dirty creation for the antibody layout. Minor modifications to the regular layout, instead it has an 'antibody' modifier in front
const AntibodyLayout = (props) => {
    const {children} = props;
    const navigate = useNavigate();
    const location = useLocation();
    const next = () => {
        let split = location.pathname.split('/');
        split[split.length - 1] = parseInt(getStep()) + 1;
        navigate(split.join('/'));
    };
    React.useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, [location]);
    const previous = () => {
        if (!isStepZero()) {
            let split = location.pathname.split('/');
            split[split.length - 1] = parseInt(getStep()) - 1;
            navigate(split.join('/'));
        } else if (isStepZero()) {
            navigate('/registerkit/select-kit'); //only thing without antibody here
        }
    };
    const getStep = () => {
        console.log(location.pathname.split('/'));
        return location.pathname.split('/')[4];
    };
    const isStepZero = () => {
        if (location.pathname === '/registerkit/antibody/step/0') {
            return true;
        }
        return false;
    };
    const isKitSelect = () => {
        if (location.pathname === '/registerkit/select-kit') {
            return true;
        }
        return false;
    };

    // Doesn't show "next" on these pages
    const isLastPage = () => {
        if (
            location.pathname === '/registerkit/antibody/step/11' ||
            location.pathname === '/registerkit/antibody/step/0'
        ) {
            return true;
        }
        return false;
    };

    const showRestart = () => {
        if (location.pathname === '/registerkit/antibody/step/11') {
            return true;
        }
        return false;
    };
    const restart = () => {
        navigate(`/`);
    };

    return (
        <div id="guest-layout">
            <Header />
            {children}

            <Footer
                showPrev={!isKitSelect()}
                showNext={!isLastPage() && !isKitSelect()}
                showRestart={showRestart()}
                restart={restart}
                next={next}
                previous={previous}
            />
        </div>
    );
};

export default AntibodyLayout;
