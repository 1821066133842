import Skeleton from '../components/Skeleton';
import ServiceModalCard from '../components/ServiceModalCard';
import Result from '../components/Result';
import PendingResult from '../components/PendingResult';
import KitCard from '../components/KitCard';
import AppointmentCard from '../components/AppointmentCard';
import EmptyDashboardCard from '../components/EmptyDashboardCard';
import HealthSummaryCard from '../components/HealthSummaryCard';
import {useAuth} from '../hooks/useAuth';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import settings from '../settings';
// import api from '../services/api';
import axios from 'axios';
// import {useDispatch} from 'react-redux';
// import {setPatient} from '../actions';
import {useAppDispatch, useAppSelector} from '../hooks';
import {setCurrentPatient, selectCurrentPatient, setSubPatients} from '../slices/patientSlice';
import {selectTests, selectTestsByName} from '../slices/testSlice';
import {PageContainer, ServiceModalOverlay} from './styles/Home';

interface Props {
    pinnedMetrics: any;
    hasViewedSwitcher: boolean;
    setHasViewedSwitcher: Function;
    hasViewedServiceModal: boolean;
    setHasViewedServiceModal: Function;
}

const Home: React.FC<Props> = ({
    pinnedMetrics,
    hasViewedSwitcher,
    setHasViewedSwitcher,
    hasViewedServiceModal,
    setHasViewedServiceModal,
}) => {
    const [latest, setLatest] = useState(false);
    const [latestResult, setLatestResult] = useState({
        id: 0,
    });
    //const [hasFetched, setHasFetched] = useState(true);
    const [serviceModalVisible, setServiceModalVisible] = useState(false);
    const {user, token} = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {loading} = useAppSelector(selectTests);
    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector((state) => selectTestsByName(state, currentPatient?.fullName));
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    // let currentPatientData = JSON.parse(localStorage.getItem('tests')!!) || [];

    // TODO quick patch to resolve this issue, should find a better way to handle this
    // if (!(currentPatientData instanceof Array)) {
    //     currentPatientData = [];
    // }

    // currentPatientData = currentPatientData.filter(
    //     (test: any) =>
    //         currentPatient.fullName &&
    //         currentPatient.fullName.toLowerCase() === `${test.firstName} ${test.lastName}`.toLowerCase()
    // );

    const healthHistory = currentPatientData.filter(
        (test: any) => test.oid.substring(test.oid.length - 10, test.oid.length - 8) === 'BW'
    );

    // for (let i = 0; i < pinnedMetrics.length; i++) {
    //     for (let j = 0; j < healthHistory.length; j++) {}
    // // }
    // useEffect(() => {
    //     if (currentPatientData.length > 0) {
    //         setHasFetched(true);
    //     }
    // }, [currentPatientData]);

    useEffect(() => {
        (async () => {
            (window as any).hj('identify', null, {
                email: user.userInfo?.email,
            });

            // if (!currentPatient.fullName) {
            //     const patient = {
            //         id: user.userInfo?.id,
            //         firstName: user.userInfo?.firstName,
            //         lastName: user.userInfo?.lastName,
            //         fullName: user.userInfo?.fullName,
            //         birthdate: user.userInfo?.birthday,
            //     };
            //     // localStorage.setItem('patient', JSON.stringify(patient));
            // dispatch(
            //     setCurrentPatient({
            //         id: user.userInfo?.id,
            //         firstName: user.userInfo?.firstName,
            //         lastName: user.userInfo?.lastName,
            //         fullName: user.userInfo?.fullName,
            //         birthdate: user.userInfo?.birthday,
            //     })
            // );
            // } else {
            // const resultsOptions = {
            //     method: 'GET',
            //     headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            // };

            // const resultsRes = await axios(`${settings.apiURL}/api/user/data`, resultsOptions);
            // if (resultsRes.status !== 200) {
            //     console.log('BAD CREDS');
            // }

            // const ret = await resultsRes.data;
            //
            // localStorage.setItem('tests', JSON.stringify(ret));
            // dispatch(setTests(ret));

            // setHasFetched(true);

            // const uniquePatients: any = {};
            // const newPatients: any = [];

            // uniquePatients[user.userInfo.fullName.toLowerCase()] = true;
            // newPatients.push({
            //     id: user.userInfo.id,
            //     firstName: user.userInfo.firstName,
            //     lastName: user.userInfo.lastName,
            //     fullName: user.userInfo.fullName,
            //     birthdate: user.userInfo.birthdate,
            // });

            // for (let i = 0; i < ret.length; i++) {
            //     const patient = `${ret[i].firstName} ${ret[i].lastName}`.toLowerCase();
            //     if (!uniquePatients[patient]) {
            //         uniquePatients[patient] = true;
            //         newPatients.push({
            //             id: ret[i].id,
            //             firstName: ret[i].firstName,
            //             lastName: ret[i].lastName,
            //             fullName: patient || '',
            //             birthdate: new Date(ret[i].birthdate),
            //         });
            //     }
            // }

            // dispatch(setSubPatients(newPatients));
            //localStorage.setItem('patients', JSON.stringify(newPatients));

            // const shouldDisplaySwitcher = !user.userInfo?.admin && !hasViewedSwitcher && newPatients.length !== 1;
            // const shouldDisplayServiceModal =
            //     !shouldDisplaySwitcher && !hasViewedServiceModal && !user.userInfo?.admin && settings.serviceMessage;

            // if (shouldDisplaySwitcher) {
            //     setHasViewedSwitcher(true);
            //     navigate('/patients?ref=initial');
            // }

            // if (shouldDisplayServiceModal) {
            //     setServiceModalVisible(true);
            //     setHasViewedServiceModal(true);
            // }
        })();
        // }
    }, []);

    return (
        <PageContainer>
            {loading && (
                <>
                    <Skeleton variant={1} />
                    <Skeleton variant={2} />
                    <Skeleton variant={1} />
                </>
            )}
            {!loading && (
                <>
                    {serviceModalVisible && (
                        <ServiceModalOverlay
                            id="service-modal-overlay"
                            onClick={(ev: any) => {
                                // @ts-ignore
                                if (ev.target.id === 'service-modal-overlay') {
                                    setServiceModalVisible(false);
                                }
                            }}>
                            <ServiceModalCard
                                setServiceModalVisible={setServiceModalVisible}
                                message={settings.serviceMessage}
                            />
                        </ServiceModalOverlay>
                    )}
                    {pinnedMetrics.length > 0 && healthHistory.length > 0 && (
                        <HealthSummaryCard pinnedMetrics={pinnedMetrics} />
                    )}
                    {currentPatientData.length === 0 && <EmptyDashboardCard />}
                    {currentPatientData.length > 0 && (
                        <>
                            <Result key={latestResult.id} result={latestResult} orderId={latestResult.id} />
                            {currentPatientData?.reverse().map((d: any) => {
                                const status = d.status?.toLowerCase();

                                if (status === 'created') {
                                    if (d.appointmentDate) {
                                        const now = new Date();
                                        const date = new Date(d.appointmentDate);
                                        date.setHours(date.getHours() + 24);

                                        return now < date ? <AppointmentCard key={d.id} appointment={d} /> : null;
                                    } else if (d.kit_id) {
                                        return <KitCard key={d.id} kit={d} />;
                                    }
                                } else if (
                                    status === 'collected' ||
                                    status === 'received' ||
                                    status === 'processing' ||
                                    d.unreleased
                                ) {
                                    return <PendingResult key={d.id} result={d} />;
                                } else if (!latest && status === 'resulted') {
                                    setLatest(true);
                                    setLatestResult(d);
                                }
                            })}
                        </>
                    )}
                </>
            )}
        </PageContainer>
    );
};

export default Home;
