import {configureStore} from '@reduxjs/toolkit';
import patientReducer from './slices/patientSlice';
import testReducer from './slices/testSlice';
import kitReducer from './slices/kitSlice';
import bannerReducer from './slices/bannerSlice';
import panelReducer from './slices/panelSlice';

export const store = configureStore({
    reducer: {
        patient: patientReducer,
        tests: testReducer,
        kit: kitReducer,
        banner: bannerReducer,
        panels: panelReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
