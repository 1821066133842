import styled from 'styled-components';
import {Device} from '../../services/constants';

export const FieldContainer = styled.div<{isActive: any; canHover: boolean; padding: any}>`
    width: calc(100% - 64px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 32px;
    background: none;
    background-color: ${(props) => (props.isActive ? 'var(--neutral-100)' : 'var(--neutral-0)')};
    border: none;
    border-bottom: 1px solid var(--neutral-100);
    transition: 0.2s;

    @media ${Device.mobile} {
        width: calc(100% - 96px);
        padding: ${(props) => (props.padding ? props.padding : '12px 24px')};
    }

    ${(props) =>
        props.canHover &&
        `
    &:hover {
        background-color: var(--neutral-100);
        cursor: pointer;
    }`}
`;

export const FieldDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

export const ChevronPhoto = styled.i`
    font-size: 16px;
`;
