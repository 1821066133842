import './App.css';
import './index.css';
import React from 'react';
import '@fortawesome/fontawesome-pro/css/all.css';
import {useState, useEffect} from 'react';
import {useLocation, useNavigate, Link} from 'react-router-dom';
import Loading from './containers/Loading';
import Nav from './components/Nav';
import Switcher from './components/Switcher';
import styled from 'styled-components';
import {useAuth} from './hooks/useAuth';
import Logo from './assets/theme-full-color.svg';
import SignInBackground from './assets/images/Sign In Background.jpeg';
import SymptomCheckerBackground from './assets/images/Symptom Checker Background.jpeg';
import CreateAccountBackground from './assets/images/Create Account Background.jpeg';
import ReferralBackground from './assets/images/Referral Background.jpeg';
import InsuranceBackground from './assets/images/Insurance Background.jpeg';
import DashboardBackground from './assets/images/Dashboard Background.jpeg';
import LabResultsBackground from './assets/images/Lab Results Background.jpeg';
import SupportBackground from './assets/images/Support Background.jpeg';
import RegisterKitBackground from './assets/images/Register Kit Background.jpeg';
import HexBackground from './assets/images/Hex Background.png';
import settings from './settings';
import {useAppDispatch, useAppSelector} from './hooks';
import {setCurrentPatient, setSubPatients} from './slices/patientSlice';
import {fetchTests, selectTestsByName, selectTests} from './slices/testSlice';
import axios from 'axios';
import createAuthRefreshInterceptor, {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';

function App () {
    const {isLoggedIn, user, login, token, setToken, logout} = useAuth();
    const location = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [backgrounds, setBackgrounds] = useState<{[index: string]: any}>({});
    const [currentSize, setCurrentSize] = useState(window.innerWidth);
    const [navVisible, setNavVisible] = useState(location.pathname === '/insurance' ? false : currentSize > 800);
    const [adminBanner, setAdminBanner] = useState<boolean>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const existingPinnedMetrics = localStorage.getItem('pinnedMetrics');
    const [pinnedMetrics, setPinnedMetrics] = useState(existingPinnedMetrics ? JSON.parse(existingPinnedMetrics) : []);
    const [hasViewedSwitcher, setHasViewedSwitcher] = useState(false);
    const [hasViewedServiceModal, setHasViewedServiceModal] = useState(false);

    // Sets sub patients when we fetch tests
    const {tests, loading} = useAppSelector(selectTests);
    useEffect(() => {
        if (user.userInfo) {
            const uniquePatients: any = {};
            const newPatients: any = [];

            uniquePatients[user.userInfo.fullName.toLowerCase()] = true;
            newPatients.push({
                id: user.userInfo.id,
                firstName: user.userInfo.firstName?.trim(),
                lastName: user.userInfo.lastName?.trim(),
                fullName: user.userInfo?.fullName?.trim(),
                birthdate: user.userInfo.birthday
            });

            for (let i = 0; i < tests.length; i++) {
                if (!tests[i].firstName || !tests[i].lastName) {
                    continue;
                }
                const patient = `${tests[i].firstName} ${tests[i].lastName}`.toLowerCase().trim();
                if (!uniquePatients[patient]) {
                    uniquePatients[patient] = true;
                    newPatients.push({
                        id: tests[i].id,
                        firstName: tests[i].firstName?.trim(),
                        lastName: tests[i].lastName?.trim(),
                        fullName: patient || '',
                        birthdate: tests[i].birthdate
                    });
                }
            }

            dispatch(setSubPatients(newPatients));
        }
    }, [tests]);

    const getExistingPinnedMetrics = async (customerId: number, token: string) => {
        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({customerId: customerId})
        };

        const resultsRes = await axios(`${settings.apiURL}/api/getPins`, resultsOptions);
        if (resultsRes.status !== 200) {
            console.log('Could not fetch pins');
            return;
        }

        const pinnedMetrics = await resultsRes.data;

        setPinnedMetrics(pinnedMetrics);
        //localStorage.setItem('pinnedMetrics', JSON.stringify(pinnedMetrics));
    };

    const exitImpersonation = async () => {
        const resultsOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
        };

        const resultsRes = await axios(`${settings.apiURL}/api/impersonate`, resultsOptions);
        if (resultsRes.status !== 200) {
            console.log('BAD CREDS');
            return;
        }
        const body = resultsRes.data;
        const returnUser = {
            ...body.result
        };

        setAdminBanner(false);

        if (returnUser.userInfo.admin) {
            login(returnUser);

            // localStorage.clear();
            // localStorage.setItem(
            //     'patient',
            //     JSON.stringify({
            //         id: returnUser.userInfo.id,
            //         firstName: returnUser.userInfo.firstName,
            //         lastName: returnUser.userInfo.lastName,
            //         fullName: returnUser.userInfo.fullName,
            //         birthdate: returnUser.userInfo.birthday,
            //     })
            // );

            dispatch(
                setCurrentPatient({
                    id: returnUser.userInfo.id,
                    firstName: returnUser.userInfo.firstName,
                    lastName: returnUser.userInfo.lastName,
                    fullName: returnUser.userInfo.fullName,
                    birthdate: returnUser.userInfo.birthday
                })
            );

            await getExistingPinnedMetrics(returnUser.userInfo.id, returnUser.token);
            //await getUserData();
        }
    };

    // Initiate Axios interceptor to refresh token when neccessary
    useEffect(() => {
        // Function that will be called to refresh authorization
        const refreshAuthLogic = (failedRequest: any) =>
            axios(`${settings.apiURL}/api/refresh`, {method: 'POST', withCredentials: true})
                .then(tokenRefreshResponse => {
                    //localStorage.setItem('token', tokenRefreshResponse.data.token);
                    setToken(tokenRefreshResponse.data.token);
                    failedRequest.response.config.headers['Authorization'] =
                        'Bearer ' + tokenRefreshResponse.data.token;
                    return Promise.resolve();
                })
                .catch(err => {
                    console.error(err);
                });

        // Instantiate the interceptor
        createAuthRefreshInterceptor(axios, refreshAuthLogic, {statusCodes: [500, 401]});
    }, [setToken]);

    useEffect(() => {
        // Try to login using refresh token at first
        axios(`${settings.apiURL}/api/refresh`, {
            method: 'POST',
            withCredentials: true,
            skipAuthRefresh: true
        } as AxiosAuthRefreshRequestConfig)
            .then(async tokenRefreshResponse => {
                //localStorage.setItem('token', tokenRefreshResponse.data.token);
                const data = await tokenRefreshResponse.data;
                login(data.result);
                dispatch(
                    setCurrentPatient({
                        id: data.result.userInfo.id,
                        firstName: data.result.userInfo.firstName,
                        lastName: data.result.userInfo.lastName,
                        fullName: `${data.result.userInfo.firstName} ${data.result.userInfo.lastName}`,
                        birthdate: data.result.userInfo.birthday
                    })
                );

                dispatch(fetchTests(data.result.token));

                if (location.pathname === '/login') {
                    navigate('/home');
                } else {
                    navigate(location.pathname);
                }

                // User is being logged out if this fails...
                await getExistingPinnedMetrics(data.result.userInfo.id, data.result.token);
            })
            .catch(err => {
                console.log('An error occurred while parsing the token refresh response: ');
                console.log(err);
                localStorage.clear();
                logout();
            });
    }, []);

    document.title = 'MyLabsDirect';

    return (
        <Switcher
            backgrounds={backgrounds}
            setAdminBanner={setAdminBanner}
            pinnedMetrics={pinnedMetrics}
            setPinnedMetrics={setPinnedMetrics}
            hasViewedSwitcher={hasViewedSwitcher}
            setHasViewedSwitcher={setHasViewedSwitcher}
            hasViewedServiceModal={hasViewedServiceModal}
            setHasViewedServiceModal={setHasViewedServiceModal}
        />
    );
}

export default App;
