import React, {useState} from 'react';
import {StepWizardChildProps} from 'react-step-wizard';
import PrimaryButton from '../../components/PrimaryButton';
import {useAppSelector, useAppDispatch} from '../../hooks';
import {setKitParam} from '../../slices/kitSlice';
import {Form, FormSection, Image, ImageContainer, Error, Paragraph, SubmitContainer, BackButton} from './styles/Step1';

export const Step1: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector((state) => state.kit);
    const dispatch = useAppDispatch();

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: '',
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        const submitter = event.nativeEvent.submitter.value;
        // TODO change identifier from string to an actual ID
        if (submitter === 'Yes, select my organization') {
            props.goToNamedStep?.('step2');
        } else if (submitter === 'No, this is a personal kit') {
            dispatch(setKitParam({company: null, vaccinated: ''}));
            props.goToNamedStep?.('step3');
        }
        return;
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Error className="ParagraphSmallRegular" hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                <ImageContainer>
                    <Image className="fa-light fa-box-open" />
                </ImageContainer>
                <Paragraph className="Heading06Bold">
                    {`Are you registering this ${
                        (
                            kitInfo.kitId1.length === 15
                                ? kitInfo.kitId1.substring(3, 5) === '01'
                                : kitInfo.kitId1.substring(0, 2) === '01'
                        )
                            ? 'COVID-19\nRT-PCR '
                            : (
                                  kitInfo.kitId1.length === 15
                                      ? kitInfo.kitId1.substring(3, 5) === '02'
                                      : kitInfo.kitId1.substring(0, 2) === '02'
                              )
                            ? 'COVID-19 Total Antibody '
                            : (
                                  kitInfo.kitId1.length === 15
                                      ? kitInfo.kitId1.substring(3, 5) === '03'
                                      : kitInfo.kitId1.substring(0, 2) === '03'
                              )
                            ? 'COVID-19 IgG Antibody '
                            : ''
                    }test kit as part of your organization requirements?`}
                </Paragraph>
                <Paragraph className="ParagraphNormalRegular">
                    This may include your workplace, company, school, or group
                </Paragraph>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    <PrimaryButton value="Yes, select my organization" width="100%" invert={true} />
                </SubmitContainer>
                <SubmitContainer>
                    <PrimaryButton value="No, this is a personal kit" width="100%" />
                </SubmitContainer>
                <BackButton className="ParagraphNormalRegular" onClick={props.previousStep}>
                    Go back
                </BackButton>
            </FormSection>
        </Form>
    );
};
