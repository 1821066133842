import React from 'react';
import MicroscopeIcon from '../assets/Lab Results.svg';
import {getColor, getNameString} from '../utils/Test';
import {
    ResultAccent,
    ResultBody,
    ResultContainer,
    ResultContent,
    ResultHeader,
    ResultItem,
    MicroscopePhoto,
    Icon,
    Button
} from './styles/Result';

interface Props {
    result: any;
    orderId: any;
}

const Result: React.FC<Props> = props => {
    const {result, orderId} = props;

    if (result.id === 0) {
        return null;
    }

    const oidPrefix = result.oid.substring(result.oid.length - 10, result.oid.length - 8);
    // TODO Prefix
    const hasMultipleResults =
        oidPrefix === 'BW' || oidPrefix === 'CF' || oidPrefix === 'BT' || oidPrefix === 'AL' || oidPrefix === 'ST';
    let resultIndex = 0;

    const resultsArr = result.results.map((r: any) => {
        const isInconclusive = r.result?.toLowerCase() === 'inconclusive';
        const birthdate = result.birthdate ? new Date(result.birthdate) : new Date();
        const test_name = getNameString(result);
        const color = getColor(result, test_name);
        let displayName = '';

        if (r.panel_name) {
            if (r.panel_name.includes('DTC - ')) {
                displayName = r.panel_name.substring(6, r.panel_name.length);
            } else {
                displayName = r.panel_name;
            }
        }

        return (
            <ResultContainer key={resultIndex++}>
                <ResultAccent color={color} />
                <ResultContent>
                    <ResultHeader>
                        <p className='Heading04ExtraBold'>Lab Results</p>
                        <MicroscopePhoto color={color}>
                            <img src={MicroscopeIcon} alt='' />
                        </MicroscopePhoto>
                    </ResultHeader>
                    <ResultBody>
                        {(result.firstName || result.lastName) && (
                            <p className='Heading05ExtraBold'>{`${result.firstName || ''} ${result.lastName || ''}${
                                result.birthdate ? ` (${birthdate.toLocaleDateString('en-US', {timeZone: 'UTC'})})` : ''
                            }`}</p>
                        )}
                        {/* TODO Prefix */}
                        <p className='Heading05ExtraBold'>
                            {oidPrefix === 'BW'
                                ? result.profile ?? displayName
                                : oidPrefix === 'CF'
                                ? 'Rapid Flu/COVID-19 Test'
                                : oidPrefix === 'BT'
                                ? 'Blood Type Test'
                                : oidPrefix === 'AL'
                                ? 'Allergy Test'
                                : oidPrefix === 'ST'
                                ? 'STD Test'
                                : r.test_name}
                        </p>
                        <ResultItem>
                            <Icon className='fa-light fa-calendar-day fa-fw' color='var(--text-600)' />
                            <p className='ParagraphNormalRegular'>{`Results on ${new Date(
                                result.statusDate
                            ).toLocaleDateString('en-US', {timeZone: 'UTC'})}`}</p>
                        </ResultItem>
                        {!isInconclusive && !hasMultipleResults && (
                            <ResultItem>
                                <Icon className='fa-light fa-virus' color='var(--text-600)' />
                                <p className='ParagraphNormalRegular'>{`Result: ${r.result}`}</p>
                            </ResultItem>
                        )}
                        <ResultItem>
                            <Icon
                                className={
                                    isInconclusive
                                        ? 'fa-light fa-diamond-exclamation'
                                        : 'fa-regular fa-circle-check fa-fw'
                                }
                                color={isInconclusive ? 'var(--warning-50)' : 'var(--mld-scheme-lab-results)'}
                            />
                            <p className='ParagraphNormalRegular'>
                                {isInconclusive
                                    ? 'Your results are inconclusive - please contact MyLabsDirect support'
                                    : 'Lab results are ready to view'}
                            </p>
                        </ResultItem>
                    </ResultBody>
                    <Button
                        $color={color}
                        className='PrimaryLarge ViewMyLabResults'
                        to={isInconclusive ? '/support' : `/result/${orderId}`}>
                        {isInconclusive ? 'Contact Support' : 'View My Lab Results'}
                    </Button>
                </ResultContent>
            </ResultContainer>
        );
    });

    if (!hasMultipleResults) {
        return resultsArr;
    } else {
        return resultsArr[0];
    }
};

export default Result;
