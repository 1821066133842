import React, {ChangeEvent, FormEvent, useState} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import TopBar from '../components/TopBar';
import Spinner from '../components/Spinner';
import Error from '../components/Error';
import ReactTooltip from 'react-tooltip';
import settings from '../settings';
import {Countries, States, Ethnicities} from '../services/constants';
import axios from 'axios';
import {
    PageContainer,
    FormContainer,
    Header,
    FormBody,
    Label,
    LabelText,
    Form,
    LeftIcon,
    StyledDatePicker,
    RadioContainer,
    RadioInput,
    RadioLabel,
    RadioOption,
    Select,
    Info,
    FormFooter,
    Optional,
    FormInput,
    ZipLabel,
    StateZipContainer,
    Submit,
    PassportIcon,
    PassportText,
    SubmitContainer,
    ConfirmIcon,
    Caption,
    LoginLink,
} from './styles/Create';

const Create: React.FC = () => {
    const [state, setState] = useState<{[index: string]: any}>({
        birthday: '',
        gender: '',
        ethnicity: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        license: '',
        country: 'United States',
        ref: '',
    });
    const [sent, setSent] = useState(false);
    const [step, setStep] = useState(0);
    const [error, setError] = useState<{[index: string]: boolean}>({});
    const [creating, setCreating] = useState(false);
    const {token} = useParams<{token: string}>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');
    // const [show, setShow] =  useState('password');

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    // const togglePassword = () => {
    //     setShow(show === 'password' ? 'text' : 'password');
    // }

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // event.preventDefault();
        // const { name, value } = event.target;
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        const value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;
        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        // TODO A bit hacky but had a hard time figuring out a better way to do the validation on submit properly
        let valid = true;
        for (const key in state) {
            if (key === 'address2' || key === 'license' || key === 'ref') continue;
            setError((prevError) => ({...prevError, [key]: !state[key]}));
            if (!state[key]) {
                valid = false;
            }
        }
        if (!valid) return;

        setCreating(true);
        const body = {...state, token: token, ref: queryParams.get('ref')};

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify(body),
        };

        const res = await axios(`${settings.apiURL}/api/setProfile`, requestOptions);
        setCreating(false);
        if (res.status !== 200) {
            console.error('Error communicating with the server');
            return;
        }

        setSent(true);
    };

    const validateStepOne = async () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        if (!!state.birthday && !!state.gender && !!state.ethnicity) {
            setError({});
            nextStep();
        }
    };

    const validateStepTwo = async () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        if (!!state.address1 && !!state.city && !!state.state && !!state.zip) {
            setError({});
            nextStep();
        }
    };

    return (
        <PageContainer>
            <ReactTooltip />
            <TopBar
                location={ref ? `/login?ref=${ref}` : '/login'}
                isBackBar={true}
                buttonvisible={step > 0 && !sent ? true : false}
                onClick={() => {
                    setError({});
                    prevStep();
                }}
            />
            <FormContainer>
                <Header className="Heading05Bold">Create Your Profile</Header>
                <Error
                    message={
                        !!Object.keys(error).filter((k) => error[k]).length ? 'Please fill out required fields' : ''
                    }
                />
                {!sent && (
                    <Form onSubmit={handleSubmit}>
                        {/* Step one */}
                        {step === 0 && (
                            <>
                                <FormBody>
                                    <Label error={error.birthday} onClick={(e) => e.preventDefault()}>
                                        <LabelText>Date of Birth</LabelText>
                                        <LeftIcon className="fa-solid fa-calendar-days" />
                                        {/* <FormInput error={error.birthday} placeholder="MM/DD/YYYY" type="date" name="birthday" value={state?.birthday || ''} onChange={handleChange} /> */}
                                        <StyledDatePicker
                                            error={error.birthday}
                                            selected={state.birthday}
                                            minDate={new Date('1/1/1870')}
                                            maxDate={new Date()}
                                            placeholderText="MM/DD/YYYY"
                                            showMonthDropdown
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dropdownMode="select"
                                            onChange={(date) => {
                                                setState({...state, birthday: date});
                                                setError({...error, birthday: false});
                                            }}
                                        />
                                    </Label>
                                    <Label error={error.gender}>
                                        <LabelText>Biological Sex</LabelText>
                                        <RadioContainer>
                                            <RadioOption>
                                                <RadioInput
                                                    error={error.gender}
                                                    id="male"
                                                    type="radio"
                                                    name="gender"
                                                    value="Male"
                                                    onChange={handleChange}
                                                    checked={state.gender === 'Male'}
                                                />
                                                <RadioLabel htmlFor="male">Male</RadioLabel>
                                            </RadioOption>
                                            <RadioOption>
                                                <RadioInput
                                                    error={error.gender}
                                                    id="female"
                                                    type="radio"
                                                    name="gender"
                                                    value="Female"
                                                    onChange={handleChange}
                                                    checked={state.gender === 'Female'}
                                                />
                                                <RadioLabel htmlFor="female">Female</RadioLabel>
                                            </RadioOption>
                                        </RadioContainer>
                                    </Label>
                                    <Label error={error.ethnicity}>
                                        <LabelText>Ethnicity</LabelText>
                                        <Select
                                            error={error.ethnicity}
                                            name="ethnicity"
                                            value={state?.ethnicity || ''}
                                            onChange={handleChange}>
                                            <option disabled value="">
                                                --
                                            </option>
                                            {Ethnicities.map((ethnicity, ide) => (
                                                <option key={ide} value={ethnicity}>
                                                    {ethnicity}
                                                </option>
                                            ))}
                                        </Select>
                                    </Label>
                                    <Info data-tip="This data helps us better deliver accurate results">
                                        <i className="fa-light fa-circle-question" /> How do you use this information?
                                    </Info>
                                </FormBody>
                                <FormFooter>
                                    <Submit type="submit" value="Continue" onClick={validateStepOne} />
                                </FormFooter>
                            </>
                        )}

                        {/* Step two */}
                        {step === 1 && (
                            <>
                                <FormBody>
                                    <Label error={error.address1}>
                                        <LabelText>Street Address</LabelText>
                                        <FormInput
                                            error={error.address1}
                                            placeholder="123 Apple Street"
                                            type="text"
                                            name="address1"
                                            value={state?.address1 || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <Label error={error.address2}>
                                        <LabelText>Address Cont. - Apt/Suite/Etc</LabelText>
                                        <FormInput
                                            error={error.address2}
                                            placeholder="Apt 100"
                                            type="text"
                                            name="address2"
                                            value={state?.address2 || ''}
                                            onChange={handleChange}
                                        />
                                        <Optional>Optional</Optional>
                                    </Label>
                                    <Label error={error.city}>
                                        <LabelText>City</LabelText>
                                        <FormInput
                                            error={error.city}
                                            placeholder="Dallas"
                                            type="text"
                                            name="city"
                                            value={state?.city || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <StateZipContainer>
                                        <Label error={error.state}>
                                            <LabelText>State</LabelText>
                                            <Select
                                                error={error.state}
                                                name="state"
                                                value={state?.state || ''}
                                                onChange={handleChange}
                                                style={{fontSize: '11px', maxWidth: '93px'}}>
                                                <option disabled value="">
                                                    --
                                                </option>
                                                {States.map((usstate, ids) => (
                                                    <option key={ids} value={usstate.code}>
                                                        {usstate.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Label>
                                        <ZipLabel error={error.zip}>
                                            <LabelText>ZIP Code</LabelText>
                                            <FormInput
                                                error={error.zip}
                                                placeholder="10001"
                                                type="text"
                                                name="zip"
                                                value={state?.zip || ''}
                                                onChange={handleChange}
                                            />
                                        </ZipLabel>
                                    </StateZipContainer>
                                    <Label error={error.country}>
                                        <LabelText>Country</LabelText>
                                        <Select
                                            error={error.country}
                                            name="country"
                                            value={state?.country || ''}
                                            onChange={handleChange}>
                                            <option disabled value="0">
                                                -- Select Country --
                                            </option>
                                            {Countries.map((country, idc) => (
                                                <option key={idc} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </Select>
                                    </Label>
                                </FormBody>
                                <FormFooter>
                                    <Submit type="submit" value="Continue" onClick={validateStepTwo} />
                                </FormFooter>
                            </>
                        )}

                        {/* Step three */}
                        {step === 2 && (
                            <>
                                <FormBody>
                                    <Label error={error.license}>
                                        <PassportText>Passport/Driver's License Number</PassportText>
                                        <PassportIcon className="fa-solid fa-id-card" />
                                        <FormInput
                                            error={error.license}
                                            type="text"
                                            name="license"
                                            value={state?.license || ''}
                                            onChange={handleChange}
                                        />
                                        <Optional>Optional, required for travel</Optional>
                                        {/* <Info><FontAwesomeIcon icon={faQuestionCircle} />How do you use this information?</Info> */}
                                    </Label>
                                </FormBody>
                                <FormFooter>
                                    <SubmitContainer>
                                        {creating && <Spinner />}
                                        <Submit type="submit" value="Continue" onClick={handleSubmit} />
                                    </SubmitContainer>
                                </FormFooter>
                            </>
                        )}
                    </Form>
                )}
                {!!sent && (
                    <>
                        <ConfirmIcon className="fa-solid fa-circle-check fa-10x" />
                        <Caption>
                            Your account has been created! Please{' '}
                            <LoginLink to={ref ? `/login?ref=${ref}` : '/login'}>login</LoginLink>.
                        </Caption>
                    </>
                )}
            </FormContainer>
        </PageContainer>
    );
};

export default Create;
