import React from 'react';
import Gauge from './Gauge';
import HistogramBar from './HistogramBar';
import Slider from './Slider';
import {useAppSelector} from '../hooks';
import {selectTestsByName} from '../slices/testSlice';
import {selectCurrentPatient} from '../slices/patientSlice';
import {Desktop} from './MediaQueries';
import {
    HealthHistoryContainer,
    Histogram,
    HistogramBars,
    HistogramLabels,
    GaugeDetails,
    GaugeField,
    GaugeFields,
    GaugeUnits,
    GaugeUnitsContainer,
    GraphLabel,
    GraphLabelDetails,
    LatestResultContainer,
    AllResultsContainer,
    BoundaryLines,
    ResultHeader,
    Separator,
} from './styles/HealthHistory';

interface Props {
    metric: string;
    isLabResult?: boolean;
    metricdate?: string;
}

const HealthHistory: React.FC<Props> = ({metric, isLabResult, metricdate}) => {
    const historicalData = [];
    let selectedTest: any = {
        test_name: 'DEFAULT',
        result: 1,
        units: 'units',
        refRange: '1-2',
        abnormal: '',
        statusDate: new Date('01/01/1900'),
    };
    let metricRefRangesStr = ['1', '2'];
    let metricRefRanges = [1, 2];
    let metricUnits = '';
    let operator = '';

    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentPatientData = useAppSelector((state) => selectTestsByName(state, currentPatient.fullName));
    // const currentPatient = JSON.parse(localStorage.getItem('patient')!!) || {};
    // const currentPatientData = JSON.parse(localStorage.getItem('tests')!!).filter(
    //     (test: any) =>
    //         currentPatient.fullName &&
    //         currentPatient.fullName.toLowerCase() === `${test.firstName} ${test.lastName}`.toLowerCase()
    // );

    const healthHistory = currentPatientData.filter(
        (test: any) => test.oid.substring(test.oid.length - 10, test.oid.length - 8) === 'BW'
    );

    for (let i = 0; i < healthHistory.length; i++) {
        const panel = healthHistory[i].results;
        for (let j = 0; j < panel.length; j++) {
            const test = panel[j];

            if (test.test_name === metric) {
                if (isLabResult && test.statusDate === metricdate) {
                    selectedTest = test;
                } else if (!isLabResult && selectedTest.test_name === 'DEFAULT') {
                    selectedTest = test;
                }
                metricUnits = test.units;

                if (selectedTest.refRange) {
                    operator = selectedTest.refRange[0];

                    if (operator === '<' || operator === '>') {
                        metricRefRangesStr = [selectedTest.refRange.split(operator)[1]].map((value: string) => value);
                        if (metricRefRangesStr[0].includes('-')) {
                            metricRefRangesStr = metricRefRangesStr[0].split('-').map((value: string) => value);
                        }
                    } else {
                        metricRefRangesStr = selectedTest.refRange.split('-').map((value: string) => value);
                    }
                } else {
                    metricRefRangesStr = [];
                }
                if (historicalData.length < 4) {
                    historicalData.push(test);
                }
                break;
            }
        }
    }

    metricRefRanges = metricRefRangesStr.map(Number);
    historicalData.reverse();

    const selectedColor =
        selectedTest.abnormal === 'Low'
            ? 'var(--data-assessment-low)'
            : selectedTest.abnormal === 'High'
            ? 'var(--data-assessment-high)'
            : 'var(--data-assessment-normal)';

    const calculateBarHeight = (test: any) => {
        let refRanges = [];
        let refRangeLower = 0;
        let refRangeUpper = 0;

        if (!test.refRange) {
            refRangeUpper = 10;
            refRangeLower = 0;
        } else if (test.refRange[0] === '<') {
            refRanges = test.refRange.split('<');
            refRangeUpper = parseFloat(refRanges[1]);
            refRangeLower = Math.min(refRangeUpper - 10, test.result);
        } else if (test.refRange[0] === '>') {
            refRanges = test.refRange.split('>');
            refRangeLower = parseFloat(refRanges[1]);
            refRangeUpper = Math.max(refRangeLower + 10, test.result);
        } else {
            refRanges = test.refRange.split('-');
            refRangeLower = parseFloat(refRanges[0]);
            refRangeUpper = parseFloat(refRanges[1]);
        }

        return 44 + (66 * (parseFloat(test.result) - refRangeLower)) / (refRangeUpper - refRangeLower);
    };

    let displayResult = selectedTest.result;
    if (parseFloat(displayResult) !== NaN) {
        displayResult = parseFloat(displayResult).toFixed(2);
    }

    return (
        <HealthHistoryContainer>
            <Slider>
                <>
                    <LatestResultContainer>
                        <ResultHeader className="Heading06Bold">
                            {isLabResult ? 'Lab Result' : 'Latest Result'}
                        </ResultHeader>
                        <Gauge
                            value={parseFloat(selectedTest.result)}
                            min={
                                !selectedTest.refRange
                                    ? parseFloat(selectedTest.result) / 2
                                    : metricRefRanges.length > 1
                                    ? metricRefRanges[0] === 0
                                        ? 0
                                        : metricRefRanges[0] - (metricRefRanges[1] - metricRefRanges[0]) / 2
                                    : Math.min(metricRefRanges[0] - 10, selectedTest.result)
                            }
                            max={
                                !selectedTest.refRange
                                    ? parseFloat(selectedTest.result) * 1.5
                                    : metricRefRanges.length > 1
                                    ? metricRefRanges[0] === 0
                                        ? 1.33 * metricRefRanges[1]
                                        : metricRefRanges[1] + (metricRefRanges[1] - metricRefRanges[0]) / 2
                                    : Math.max(metricRefRanges[0] + 10, selectedTest.result)
                            }
                            color={selectedColor}
                            type={metricRefRanges.length === 1 ? 'singleRef' : 'doubleRef'}
                        />
                        <GaugeDetails>
                            <GaugeUnitsContainer>
                                <GaugeUnits className="Heading03Bold">{displayResult}</GaugeUnits>
                                <GraphLabelDetails className="ParagraphNormalRegular">
                                    {selectedTest.units}
                                </GraphLabelDetails>
                            </GaugeUnitsContainer>
                            <GaugeFields>
                                <GaugeField>
                                    {metricRefRanges.length > 0 && (
                                        <>
                                            <GraphLabel className="ParagraphNormalRegular">Normal Range</GraphLabel>
                                            <GraphLabelDetails className="ParagraphNormalRegular">
                                                {metricRefRanges.length > 1
                                                    ? `${metricRefRangesStr[0]} to ${metricRefRangesStr[1]}`
                                                    : `${operator}${metricRefRangesStr[0]}`}
                                            </GraphLabelDetails>
                                        </>
                                    )}
                                </GaugeField>
                                <GaugeField>
                                    <GraphLabel className="ParagraphNormalRegular">Test Date</GraphLabel>
                                    <GraphLabelDetails className="ParagraphNormalRegular">
                                        {new Date(selectedTest.statusDate).toLocaleDateString('en-US', {
                                            timeZone: 'UTC',
                                        })}
                                    </GraphLabelDetails>
                                </GaugeField>
                            </GaugeFields>
                        </GaugeDetails>
                    </LatestResultContainer>
                    <Desktop>
                        <Separator />
                    </Desktop>
                </>
                <AllResultsContainer>
                    <ResultHeader className="Heading06Bold">Recent Results</ResultHeader>
                    <Histogram>
                        <Desktop>
                            <HistogramLabels>
                                {metricRefRanges.length > 0 && (
                                    <>
                                        <GraphLabel className="ParagraphNormalRegular">Normal Range</GraphLabel>
                                        <GraphLabelDetails className="ParagraphNormalRegular">
                                            {metricRefRanges.length > 1
                                                ? `${metricRefRangesStr[0]} to ${metricRefRangesStr[1]}`
                                                : `${operator}${metricRefRangesStr[0]}`}
                                        </GraphLabelDetails>
                                        <GraphLabel className="ParagraphNormalRegular">{metricUnits}</GraphLabel>
                                    </>
                                )}
                            </HistogramLabels>
                        </Desktop>
                        <HistogramBars>
                            <BoundaryLines operator={operator} />
                            {historicalData.map((test, testid) => {
                                return (
                                    <HistogramBar
                                        key={testid}
                                        test={test}
                                        color={test === selectedTest ? selectedColor : 'var(--primary-300)'}
                                        height={calculateBarHeight(test)}
                                    />
                                );
                            })}
                        </HistogramBars>
                    </Histogram>
                </AllResultsContainer>
            </Slider>
        </HealthHistoryContainer>
    );
};

export default HealthHistory;
