import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import logo from '../assets/theme-full-color.svg';
import SkeletonPatient from '../components/SkeletonPatient';
import PatientSelection from '../components/PatientSelection';
import {useAuth} from '../hooks/useAuth';
// import {useAppSelector} from '../hooks';
// import {selectPatient} from '../slices/patientSlice';
// import {selectTests} from '../slices/testSlice';
import settings from '../settings';
// import { current } from '@reduxjs/toolkit';
import {useAppDispatch, useAppSelector} from '../hooks';
import {setCurrentPatient, selectCurrentPatient, setSubPatients, selectSubPatients} from '../slices/patientSlice';
import axios from 'axios';
import {Desktop} from '../components/MediaQueries';
import {
    PageContainer,
    PatientSwitcherPage,
    Patients,
    Logo,
    LogoLink,
    Heading,
    CloseLink,
} from './styles/PatientSwitcher';

const PatientSwitcher: React.FC = () => {
    // const currentPatient = useAppSelector(selectPatient);
    // const data = useAppSelector(selectTests);
    const {user, token} = useAuth();
    const currentPatient = useAppSelector(selectCurrentPatient);
    const existingPatients = useAppSelector(selectSubPatients);
    const [fetching, setFetching] = useState(false);
    const [patients, setPatients] = useState([] as any);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');

    useEffect(() => {
        (async () => {
            if (existingPatients) {
                setPatients(existingPatients);
            } else {
                // setFetching(true);
                // const resultsOptions = {
                //     method: 'GET',
                //     headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                // };
                // const resultsRes = await axios(`${settings.apiURL}/api/user/data`, resultsOptions);
                // if (resultsRes.status !== 200) {
                //     console.log('BAD CREDS');
                // }
                // const ret = resultsRes.data;
                //localStorage.setItem('tests', JSON.stringify(ret));
                // dispatch(setTests(ret));
                // setFetching(false);
                // const uniquePatients: any = {};
                // const newPatients: any = [];
                // if (currentPatient.fullName) {
                //     uniquePatients[currentPatient.fullName.toLowerCase()] = true;
                //     newPatients.push({
                //         id: currentPatient.id,
                //         firstName: currentPatient.firstName,
                //         lastName: currentPatient.lastName,
                //         fullName: currentPatient.fullName,
                //         birthdate: currentPatient.birthdate,
                //     });
                // } else {
                //     uniquePatients[user.userInfo.fullName.toLowerCase()] = true;
                //     newPatients.push({
                //         id: user.userInfo.id,
                //         firstName: user.userInfo.firstName,
                //         lastName: user.userInfo.lastName,
                //         fullName: user.userInfo.fullName,
                //         birthdate: new Date(user.userInfo.birthday),
                //     });
                // }
                // for (let i = 0; i < ret.length; i++) {
                //     const patient = `${ret[i].firstName} ${ret[i].lastName}`.toLowerCase();
                //     if (!uniquePatients[patient]) {
                //         uniquePatients[patient] = true;
                //         newPatients.push({
                //             id: ret[i].id,
                //             firstName: ret[i].firstName,
                //             lastName: ret[i].lastName,
                //             fullName: patient || '',
                //             birthdate: new Date(ret[i].birthdate),
                //         });
                //     }
                // }
                // //localStorage.setItem('patients', JSON.stringify(newPatients));
                // setPatients(newPatients);
            }
        })();
    }, []);

    return (
        <PageContainer>
            <PatientSwitcherPage>
                <Desktop>
                    <LogoLink to="/">
                        <Logo src={logo} />
                    </LogoLink>
                </Desktop>
                <Heading className="Heading02Regular">Select A Patient To View</Heading>
                <Patients>
                    {fetching && (
                        <>
                            <SkeletonPatient variant={1} />
                            <SkeletonPatient variant={3} />
                            <SkeletonPatient variant={2} />
                        </>
                    )}
                    {!fetching && (
                        <>
                            {patients.map((patient: any) => {
                                return (
                                    <PatientSelection
                                        key={patient.id}
                                        patient={patient}
                                        isCurrent={currentPatient.fullName === patient.fullName && ref !== 'initial'}
                                    />
                                );
                            })}
                        </>
                    )}
                </Patients>
                {currentPatient.fullName && ref !== 'initial' && <CloseLink to="/">Close</CloseLink>}
            </PatientSwitcherPage>
        </PageContainer>
    );
};

export default PatientSwitcher;
