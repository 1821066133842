import React, {FormEvent, useState, ChangeEvent} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {useAuth} from '../hooks/useAuth';
import settings from '../settings';
import {TextEditor} from 'react-data-grid';
import copy from 'clipboard-copy';
import 'react-datepicker/dist/react-datepicker.css';
import {useAppDispatch} from '../hooks';
import {setCurrentPatient} from '../slices/patientSlice';
import {fetchTests} from '../slices/testSlice';
import {showBanner, setMessage, hideBanner} from '../slices/bannerSlice';
import {Ethnicities, States} from '../services/constants';
import axios from 'axios';
import {
    PageContainer,
    Overlay,
    Select,
    OverlayPhoto,
    OverlayingSpinner,
    AdminPage,
    BannerText,
    TabButton,
    TabButtonContainer,
    TabContainer,
    TabLi,
    TabUl,
    TextInputField,
    TinyImg,
    Title,
    Toast,
    ModalTabContainer,
    Form,
    Label,
    LabelContainer,
    AddTestLabelContainer,
    Input,
    InputEmail,
    SubmitContainer,
    Button,
    UserGrid,
    WorkingText,
    Paragraph,
    PhotoContainer,
    Photo,
    PasswordError,
    PasswordErrorsContainer,
    StyledDatePicker,
    ModalBackgroundOverlay,
    ModalFieldGroup,
    ModalWindow,
    RowContainer,
    FieldContainer,
    FieldDescription,
    FieldLabel,
    IconFieldContainer,
    LeftIcon,
    DatePickerField,
    SelectField,
    RadioButton,
    RadioButtonLabel,
    RadioFieldContainer,
    VerificationLabelButton,
    VerticalLine,
    RegularButton,
    ActionButtonContainer,
    SecondaryButton,
    PrimaryButton,
} from './styles/Admin';
import {syncBuiltinESMExports} from 'module';

const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
});

function TimestampFormatter({timestamp}: {timestamp: number}) {
    // Multiply by 1000 to convert from Unix timestamp format
    return <>{dateFormatter.format(timestamp)}</>;
}

interface State {
    photoEmail: string;
    kitId: string;
    search: string;
    passwordEmail: string;
    password: string;
}

interface AddTestState {
    testName: string;
    color: string;
    layout: string;
    specimenType: string;
    providerNPI: string;
    diagCodes: string;
    inRegisterDrop: boolean;
    inOrderDrop: boolean;
}

interface PasswordErrors {
    passwordLetters: boolean;
    passwordNumber: boolean;
    passwordLength: boolean;
}

interface Users {
    firstName: string;
    lastName: string;
    email: string;
    birthday: string;
    phone: string;
}

interface Orders {
    oid: string;
    firstName: string;
    lastName: string;
    email: string;
}

const Admin: React.FC<{setAdminBanner: Function; setPinnedMetrics: Function}> = ({
    setAdminBanner,
    setPinnedMetrics,
}) => {
    const dispatch = useAppDispatch();

    const initialToast: any = {
        error: false,
        message: '',
    };
    const [toast, setToast] = useState(initialToast);
    const [state, setState] = useState<State>({
        photoEmail: '',
        kitId: '',
        search: '',
        passwordEmail: '',
        password: '',
    });
    const [addTestState, setAddTestState] = useState<AddTestState>({
        testName: '',
        color: '',
        layout: '',
        specimenType: '',
        providerNPI: '',
        diagCodes: '',
        inRegisterDrop: false,
        inOrderDrop: false,
    });

    // State for handling password rules
    const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({
        passwordLetters: true,
        passwordNumber: true,
        passwordLength: true,
    });

    // State for handling users search
    const [users, setUsers] = useState<Users>({
        firstName: '',
        lastName: '',
        email: '',
        birthday: '',
        phone: '',
    });

    // State for handling orders search
    const [orders, setOrders] = useState<Orders>({
        oid: '',
        firstName: '',
        lastName: '',
        email: '',
    });

    // activeTab must equal 'video || users || orders || reset || rejectedEmails'
    const [activeTab, setActiveTab] = useState('users');
    // activeModalTab must equal 'patient-info || 'address-info'
    const [activeModalTab, setActiveModalTab] = useState('patient-info');
    const [isModalHidden, setIsModalHidden] = useState(true);
    const [selectedUser, setSelectedUser] = useState<{[index: string]: any}>({});
    const [userInfoError, setUserInfoError] = useState<{[index: string]: boolean}>({});
    const [isUpdatingUser, setIsUpdatingUser] = useState(false);

    const [searchingUsers, setSearchingUsers] = useState(false);
    // const [searchedUsers, setSearchedUsers] = useState(false);
    const [results, setResults] = useState([]);
    const [resultsOrders, setResultsOrders] = useState([]);
    const [searchingPhotos, setSearchingPhotos] = useState(false);
    const [searchedPhotos, setSearchedPhotos] = useState(false);
    const [searchingKit, setSearchingKit] = useState(false);
    const [searchedKit, setSearchedKit] = useState(false);
    const [resettingPassword, setResettingPassword] = useState(false);
    const [photos, setPhotos] = useState<{[index: string]: any}>({
        frontInsurance: '',
        backInsurance: '',
        frontId: '',
    });
    const [photoModal, setPhotoModal] = useState('');
    const [kit, setKit] = useState({
        id: 0,
        kit_id: '',
        video_url: '',
    });
    const [sendingMail, setSendingMail] = useState(false);
    const [sentMail, setSentMail] = useState(false);
    const {token, login, setToken} = useAuth();
    const navigate = useNavigate();
    const [loadingRejectedEmails, setLoadingRejectedEmails] = useState(false);
    const [rejectedEmails, setRejectedEmails] = useState([]);
    const [rejectedEmailsFilter, setRejectedEmailsFilter] = useState<{[x: string]: any}>({
        startDatetime: '',
        endDatetime: '',
    });
    const [impersonationToken, setImpersonationToken] = useState('');

    const columnsRejectedEmails = [
        {
            key: 'created',
            name: 'Date',
            resiizable: true,
            formatter(props: any) {
                return <TimestampFormatter timestamp={props.row.created} />;
            },
        },
        {key: 'type', name: 'Type', resizable: true},
        {key: 'email', name: 'Email', resizable: true},
        {key: 'reason', name: 'Reason', resizable: true},
    ];

    const columns = [
        {
            key: 'action',
            name: 'Action',
            resizable: true,
            formatter(props: any) {
                return (
                    <button
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            setIsModalHidden(false);
                            setSelectedUser(props.row);
                        }}>
                        Edit
                    </button>
                );
            },
        },
        // {key: 'id', name: 'Database ID', resizable: true},
        {key: 'firstName', name: 'First Name', resizable: true},
        {key: 'lastName', name: 'Last Name', resizable: true},
        {key: 'email', name: 'Email', resizable: true},
        {key: 'birthday', name: 'Birthday', resizable: true},
        // {key: 'address1', name: 'Address', resizable: true},
        // {key: 'city', name: 'City', resizable: true},
        // {key: 'state', name: 'State', resizable: true},
        // {key: 'zip', name: 'Zip', resizable: true},
        // {key: 'phone', name: 'Phone', resizable: true},
        // {key: 'category_id', name: 'Category ID', resizable: true},
        // {key: 'category_name', name: 'Category Name', resizable: true},
        // {
        //     key: 'verified',
        //     name: 'Verified',
        //     resizable: true,
        //     formatter({row, onRowChange, isCellSelected}: any) {
        //         return (
        //             <SelectCellFormatter
        //                 value={row.verified}
        //                 onChange={() => {
        //                     onRowChange({...row, verified: !row.verified});
        //                 }}
        //                 // onClick={event => event.stopPropagation()}
        //                 isCellSelected={isCellSelected}
        //             />
        //         );
        //     },
        // },
        // {
        //     key: 'resend',
        //     name: 'Resend verification',
        //     resizable: true,
        //     formatter(props: any) {
        //         if (props.row.verified == true) return <></>;
        //         else return <button onClick={() => resendVerify(props.row.email)}>Resend</button>;
        //     },
        // },
        // {
        //     key: 'link',
        //     name: 'Copy verification link',
        //     resizable: true,
        //     formatter(props: any) {
        //         if (props.row.verified == true) return <></>;
        //         else return <button onClick={() => copyLink(props.row.token)}>Copy</button>;
        //     },
        // },
        // {
        //     key: 'reset',
        //     name: 'Reset Password',
        //     resizable: true,
        //     formatter(props: any) {
        //         if (props.row.verified == false) return <></>;
        //         else return <button onClick={() => sendReset(props.row.email)}>Reset</button>;
        //     },
        // },
        // {
        //     key: 'view',
        //     name: 'User impersonation',
        //     resizable: true,
        //     formatter(props: any) {
        //         return <button onClick={() => impersonateUser(props.row.email)}>View</button>;
        //     },
        // },
    ];

    const columnsOrders = [
        {key: 'oid', name: 'Order ID', resizable: true},
        {key: 'firstName', name: 'First Name', editor: TextEditor, resizable: true},
        {key: 'lastName', name: 'Last Name', editor: TextEditor, resizable: true},
        {key: 'email', name: 'Email', editor: TextEditor, resizable: true},
        {key: 'birthdate', name: 'Birthday', editor: TextEditor, resizable: true},
        {key: 'status', name: 'Status', editor: TextEditor, resizable: true},
    ];

    const copyLink = (token: string) => {
        copy(window.location.origin + '/verify/' + token);
    };

    const resendVerify = async (email: any) => {
        setSendingMail(true);

        if (!email || email === 'null') {
            setSendingMail(false);
            setSentMail(true);
            setToast({
                error: true,
                message: 'Error: Invalid email',
            });
            return;
        }

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({username: email}),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/sendVerify`, resultsOptions);
        if (resultsRes.status !== 200) {
            setSendingMail(false);
            setSentMail(true);
            setToast({
                error: true,
                message: 'Error: Could not send verification link',
            });
            return;
        }

        setSendingMail(false);
        setSentMail(true);
        setToast({
            error: false,
            message: `Successfully resent verification link to ${email}`,
        });
    };

    const impersonateUser = async (email: any) => {
        if (!email || email === 'null' || email === 'NULL') {
            alert('Please set an email before impersonating user');
            return;
        }

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({username: email}),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/impersonate`, resultsOptions);
        if (resultsRes.status !== 200) {
            console.log('BAD CREDS');
            return;
        }
        const body = resultsRes.data;
        login(body.result);
        setToken(body.result.token);
        setImpersonationToken(body.result.token);
        //setAdminBanner(true);
        // localStorage.setItem(
        //     'patient',
        //     JSON.stringify({
        //         id: body.result.userInfo.id,
        //         firstName: body.result.userInfo.firstName,
        //         lastName: body.result.userInfo.lastName,
        //         fullName: body.result.userInfo.fullName,
        //         birthdate: body.result.userInfo.birthday,
        //     })
        // );
        //let message =
        dispatch(
            setMessage(
                <p>
                    Impersonating user{' '}
                    <Link
                        to="/admin"
                        onClick={() => {
                            exitImpersonation(body.result.token);
                        }}>
                        Close
                    </Link>
                </p>
            )
        );
        dispatch(showBanner());
        dispatch(
            setCurrentPatient({
                id: body.result.userInfo.id,
                firstName: body.result.userInfo.firstName,
                lastName: body.result.userInfo.lastName,
                fullName: body.result.userInfo.fullName,
                birthdate: body.result.userInfo.birthday,
            })
        );

        dispatch(fetchTests(body.result.token));

        const pinsResultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({customerId: body.result.userInfo.id}),
        };

        const pinsResultsRes = await axios(`${settings.apiURL}/api/getPins`, pinsResultsOptions);
        if (pinsResultsRes.status !== 200) {
            console.log('Could not fetch pins');
            return;
        }

        const pinnedMetrics = pinsResultsRes.data;
        localStorage.setItem('pinnedMetrics', JSON.stringify(pinnedMetrics));
        setPinnedMetrics(pinnedMetrics);

        navigate('/');
    };

    const exitImpersonation = async (oldToken: string) => {
        const resultsOptions = {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${oldToken}`},
        };

        const resultsRes = await axios(`${settings.apiURL}/api/impersonate`, resultsOptions);
        if (resultsRes.status !== 200) {
            console.log('BAD CREDS');
            return;
        }
        const body = resultsRes.data;
        const returnUser = {
            ...body.result,
        };

        //setAdminBanner(false);
        dispatch(hideBanner());

        if (returnUser.userInfo.admin) {
            login(returnUser);

            // localStorage.clear();
            // localStorage.setItem(
            //     'patient',
            //     JSON.stringify({
            //         id: returnUser.userInfo.id,
            //         firstName: returnUser.userInfo.firstName,
            //         lastName: returnUser.userInfo.lastName,
            //         fullName: returnUser.userInfo.fullName,
            //         birthdate: returnUser.userInfo.birthday,
            //     })
            // );

            dispatch(
                setCurrentPatient({
                    id: returnUser.userInfo.id,
                    firstName: returnUser.userInfo.firstName,
                    lastName: returnUser.userInfo.lastName,
                    fullName: returnUser.userInfo.fullName,
                    birthdate: returnUser.userInfo.birthday,
                })
            );

            dispatch(fetchTests(body.result.token));

            //await getExistingPinnedMetrics(returnUser.userInfo.id, returnUser.token);
            //await getUserData();
        }
    };

    const sendReset = async (email: any) => {
        setSendingMail(true);

        if (!email || email === 'null') {
            setSendingMail(false);
            setSentMail(true);
            setToast({
                error: true,
                message: 'Error: Invalid email',
            });
            return;
        }

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({username: email}),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/reset`, resultsOptions);
        if (resultsRes.status !== 200) {
            setSendingMail(false);
            setSentMail(true);
            setToast({
                error: true,
                message: 'Error: Could not send reset link',
            });
            return;
        }

        setSendingMail(false);
        setSentMail(true);
        setToast({
            error: false,
            message: `Successfully resent reset link to ${email}`,
        });
    };

    const handlePhotoSearch = async (event: FormEvent) => {
        event.preventDefault();

        const newPhotos = {
            frontInsurance: '',
            backInsurance: '',
            frontId: '',
        };

        setSearchedPhotos(false);
        setSearchingPhotos(true);

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({email: state.photoEmail}),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/claim`, resultsOptions);
        if (resultsRes.status !== 200) {
            console.log('Could not get order');
            setPhotos(newPhotos);
            setSearchedPhotos(true);
            setSearchingPhotos(false);
            return;
        }

        const result = resultsRes.data;
        console.log(result);

        if (result.success) {
            if (result.frontInsurance) {
                newPhotos.frontInsurance = result.frontInsurance;
            }
            if (result.backInsurance) {
                newPhotos.backInsurance = result.backInsurance;
            }
            if (result.frontId) {
                newPhotos.frontId = result.frontId;
            }
        }

        setSearchedPhotos(true);
        setSearchingPhotos(false);
        setPhotos(newPhotos);
    };

    const handleKitSearch = async (event: FormEvent) => {
        event.preventDefault();

        setSearchedKit(false);
        setSearchingKit(true);

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({kit_id: state.kitId}),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/kit/getKit`, resultsOptions);
        if (resultsRes.status !== 200) {
            console.log('Could not get kit');
            setSearchedKit(true);
            setSearchingKit(false);
            return;
        }

        const results = resultsRes.data;

        setSearchedKit(true);
        setSearchingKit(false);
        setKit(results);
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setToast(initialToast);

        const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

        if (users.phone && !phoneReg.test(users.phone)) {
            setToast({
                error: true,
                message: 'Invalid phone number. Please use this format: ###-###-####',
            });
            return;
        } else if (users.birthday && isNaN(Number(new Date(users.birthday)))) {
            setToast({
                error: true,
                message: 'Invalid date of birth. Please use this format: MM/DD/YYYY',
            });
            return;
        }

        setSearchingUsers(true);

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({...users}),
            withCredentials: true,
        };

        try {
            const resultsRes = await axios(`${settings.apiURL}/api/users`, resultsOptions);
            if (resultsRes.status !== 200) {
                console.log('Could not search users');
                setSearchingUsers(false);
                return;
            }

            const results = resultsRes.data;
            if (results.length === 0) {
                setToast({
                    error: false,
                    message: 'No User Found.',
                });
            }

            setResults(results);
        } catch (error) {
            console.error(error);
            setResultsOrders([]);
        } finally {
            setSearchingUsers(false);
        }
    };

    const handleSubmitOrders = async (event: FormEvent) => {
        event.preventDefault();

        setSearchingUsers(true);

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({...orders}),
        };

        try {
            const resultsRes = await axios(`${settings.apiURL}/api/order/search`, resultsOptions);
            if (resultsRes.status !== 200) {
                console.log('Could not search users');
                setSearchingUsers(false);
                return;
            }
            const results = resultsRes.data;
            // Modify date string in returned result
            const resultsAltered = results.map((order: any) => ({
                ...order,
                birthdate: order.birthdate ? order.birthdate.split('T')[0] : null,
            }));
            if (resultsAltered.length === 0) {
                setToast({
                    error: false,
                    message: 'No Order Found.',
                });
            }
            setResultsOrders(resultsAltered);
        } catch (error) {
            console.error(error);
            setResultsOrders([]);
        } finally {
            setSearchingUsers(false);
        }
    };

    const handlePasswordReset = async (event: FormEvent) => {
        event.preventDefault();

        if (!state.passwordEmail) {
            setToast({
                error: true,
                message: 'Please enter user email',
            });
            return;
        }

        const errors = Object.values(passwordErrors);
        for (let i = 0; i < errors.length; i++) {
            if (errors[i]) {
                setToast({
                    error: true,
                    message: 'Password does not meet requirements',
                });
                return;
            }
        }

        setResettingPassword(true);

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify({
                username: state.passwordEmail,
                password: state.password,
                ref: 'admin',
            }),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/admin/reset`, resultsOptions);
        if (resultsRes.status !== 200) {
            const res = resultsRes.data;
            setResettingPassword(false);
            setToast({
                error: true,
                message: res.error,
            });
            return;
        }

        setToast({
            error: false,
            message: 'Successfully reset password.',
        });
        setResettingPassword(false);
    };

    const sendUpdate = async (row: any) => {
        setIsUpdatingUser(true);
        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            data: JSON.stringify(row),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/user`, resultsOptions);
        setIsUpdatingUser(false);
        if (resultsRes.status !== 200) {
            console.log('BAD CREDS');
            return;
        }
    };

    const handleUpdate = (rows: any, data: any) => {
        data?.indexes.map((idx: any) => {
            sendUpdate(rows[idx]);
        });

        setResults(rows);
    };

    const getRejectedEmails = async () => {
        const params = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        };
        const queryParams = {
            startDatetime: `${Math.floor(rejectedEmailsFilter.startDatetime.getTime() / 1000)}`,
            endDatetime: `${Math.ceil(rejectedEmailsFilter.endDatetime.getTime() / 1000)}`,
        };
        setLoadingRejectedEmails(true);
        axios(`${settings.apiURL}/api/email/rejected?${new URLSearchParams(queryParams).toString()}`, params)
            .then((response) => response.data)
            .then((data) => {
                setRejectedEmails(data);
                setToast({
                    error: false,
                    message: `Successfully retrieved rejected emails.`,
                });
            })
            .catch((err) => {
                console.log(err);
                setToast({
                    error: true,
                    message: 'Error: Could not load rejected emails.',
                });
            })
            .finally(() => setLoadingRejectedEmails(false));
    };

    const handleUserInfoChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;
            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }

            setUserInfoError((prevError: any) => ({...prevError, phone: false}));
        }

        if (name === 'zip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        if (name === 'category_id') {
            const digitReg = /^[0-9]$/;
            if (value != '' && !digitReg.test(value[value.length - 1])) {
                return;
            }
        }

        setSelectedUser((prevState: any) => ({...prevState, [name]: value}));
    };

    const handleAddTest = async (event: FormEvent) => {
        event.preventDefault();
        setToast(initialToast);

        if (!addTestState.testName) {
            setToast({
                error: true,
                message: 'Please enter test name',
            });
            return;
        } else if (!addTestState.diagCodes) {
            setToast({
                error: true,
                message: 'Please enter diagnosis codes',
            });
            return;
        } else if (!addTestState.specimenType) {
            setToast({
                error: true,
                message: 'Please enter specimen type',
            });
            return;
        } else if (!addTestState.providerNPI) {
            setToast({
                error: true,
                message: 'Please enter provider npi',
            });
            return;
        } else if (!addTestState.color) {
            setToast({
                error: true,
                message: 'Please enter desired color',
            });
            return;
        } else if (!addTestState.layout) {
            setToast({
                error: true,
                message: 'Please enter desired layout',
            });
            return;
        }

        //add hook to add data into db
        const body = {
            name: addTestState.testName,
            specimenType: addTestState.specimenType,
            diagCodes: addTestState.diagCodes.replace(/\s/g, '').split(','), //removes whitespace and returns array
            color: addTestState.color,
            resultType: addTestState.layout,
            providerNPI: addTestState.providerNPI,
            orderDrop: addTestState.inOrderDrop,
            registerDrop: addTestState.inRegisterDrop,
        };

        const resultOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, //TODO: determine if auth token is needed
            data: JSON.stringify(body),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/saveProducts`, resultOptions);
        if (resultsRes.status !== 200) {
            //TODO: find out correct status code
            setToast({
                error: true,
                message: 'Error: Could not add product',
            });
            return;
        }

        setToast({
            error: false,
            message: `Successfully added the product`,
        });
    };

    const syncTest = async () => {
        setToast(initialToast);

        if (!addTestState.testName) {
            setToast({
                error: true,
                message: 'Please enter test name',
            });
            return;
        }

        //add hook to pull data from ELIS and link it to the name
        const resultOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}, //TODO: determine if auth token is needed
            data: JSON.stringify({name: addTestState.testName}),
        };

        const resultsRes = await axios(`${settings.apiURL}/api/syncProduct`, resultOptions); //TODO: change api route
        if (resultsRes.status !== 200) {
            //TODO: find out correct status code
            setToast({
                error: true,
                message: 'Error: Could not sync product',
            });
            return;
        }

        setToast({
            error: false,
            message: `Successfully synced the product`,
        });
    };

    return (
        <>
            <PageContainer>
                {photoModal && (
                    <Overlay
                        id="admin-overlay"
                        onClick={(ev: any) => {
                            // @ts-ignore
                            if (ev.target.id === 'admin-overlay') {
                                setPhotoModal('');
                            }
                        }}>
                        <OverlayPhoto src={photos[photoModal]} alt="" />
                    </Overlay>
                )}
                <AdminPage>
                    <BannerText className="Heading03Bold">Admin</BannerText>
                    <TabContainer>
                        <TabUl>
                            <TabLi
                                onClick={(e) => {
                                    setActiveTab('users');
                                    setToast(initialToast);
                                }}>
                                Search For Users
                            </TabLi>
                            <TabLi
                                onClick={(e) => {
                                    setActiveTab('orders');
                                    setToast(initialToast);
                                }}>
                                Search For Orders
                            </TabLi>
                            <TabLi
                                onClick={(e) => {
                                    setActiveTab('photos');
                                    setToast(initialToast);
                                }}>
                                User Insurance Photos
                            </TabLi>
                            <TabLi
                                onClick={(e) => {
                                    setActiveTab('video');
                                    setToast(initialToast);
                                }}>
                                User Kit Videos
                            </TabLi>
                            <TabLi
                                onClick={(e) => {
                                    setActiveTab('reset');
                                    setToast(initialToast);
                                }}>
                                Manual Password Reset
                            </TabLi>
                            <TabLi onClick={(e) => setActiveTab('rejectedEmails')}>Rejected Emails</TabLi>
                            <TabLi
                                onClick={(e) => {
                                    setActiveTab('addtest');
                                    setToast(initialToast);
                                }}>
                                Add Test
                            </TabLi>
                        </TabUl>
                    </TabContainer>

                    {activeTab === 'users' && (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <Title className="ParagraphLargeBold">Search for users in database:</Title>
                                <LabelContainer>
                                    <Label htmlFor="searchUsers-fname">
                                        First Name:{' '}
                                        <Input
                                            id="searchUsers-fname"
                                            type="text"
                                            value={users.firstName}
                                            name="searchUsers-fname"
                                            placeholder="optional"
                                            onChange={(e) => setUsers({...users, firstName: e.target.value})}
                                        />
                                    </Label>
                                    <Label htmlFor="searchUsers-lname">
                                        Last Name:{' '}
                                        <Input
                                            id="searchUsers-lname"
                                            type="text"
                                            value={users.lastName}
                                            name="searchUsers-lname"
                                            placeholder="optional"
                                            onChange={(e) => setUsers({...users, lastName: e.target.value})}
                                        />
                                    </Label>
                                    <Label htmlFor="searchUsers-email">
                                        Email:{' '}
                                        <InputEmail
                                            id="searchUsers-email"
                                            type="text"
                                            value={users.email}
                                            name="searchUsers-email"
                                            placeholder="optional"
                                            onChange={(e) => setUsers({...users, email: e.target.value})}
                                        />
                                    </Label>
                                    <br />
                                    <Label htmlFor="searchUsers-birthday">
                                        Date of Birth:{' '}
                                        <InputEmail
                                            id="searchUsers-birthday"
                                            type="text"
                                            value={users.birthday}
                                            name="searchUsers-birthday"
                                            placeholder="optional - MM/DD/YYYY"
                                            onChange={(e) => {
                                                const digitReg = /^[0-9]$/;
                                                let value = e.target.value;

                                                if (value.length === 3 || value.length === 6) {
                                                    if (value[value.length - 1] !== '/') {
                                                        value =
                                                            value.substring(0, value.length - 1) +
                                                            '/' +
                                                            value[value.length - 1];
                                                    } else {
                                                        value = value.substring(0, value.length - 1);
                                                    }
                                                }

                                                if (
                                                    value !== '' &&
                                                    (!digitReg.test(value[value.length - 1]) || value.length > 10)
                                                ) {
                                                    return;
                                                }

                                                setUsers({...users, birthday: value});
                                            }}
                                        />
                                    </Label>
                                    <Label htmlFor="searchUsers-phone">
                                        Phone Number:{' '}
                                        <InputEmail
                                            id="searchUsers-phone"
                                            type="text"
                                            value={users.phone}
                                            name="searchUsers-phone"
                                            placeholder="optional - 555-555-5555"
                                            onChange={(e) => {
                                                const digitReg = /^[0-9]$/;
                                                let value = e.target.value;

                                                if (value.length === 4 || value.length === 8) {
                                                    if (value[value.length - 1] !== '-') {
                                                        value =
                                                            value.substring(0, value.length - 1) +
                                                            '-' +
                                                            value[value.length - 1];
                                                    } else {
                                                        value = value.substring(0, value.length - 1);
                                                    }
                                                }

                                                if (
                                                    value !== '' &&
                                                    (!digitReg.test(value[value.length - 1]) || value.length > 12)
                                                ) {
                                                    return;
                                                }

                                                setUsers({...users, phone: value});
                                            }}
                                        />
                                    </Label>
                                </LabelContainer>

                                <SubmitContainer>
                                    <Button type="submit">Search Users</Button>
                                    {sendingMail || searchingUsers ? (
                                        <WorkingText>Working...</WorkingText>
                                    ) : !toast.message ? null : (
                                        <Toast error={toast.error}>{toast.message}</Toast>
                                    )}
                                </SubmitContainer>
                            </Form>
                            <UserGrid
                                columns={columns}
                                rows={results}
                                // onRowsChange={handleUpdate}
                                // onRowDoubleClick={(row: any) => {
                                //     setIsModalHidden(false);
                                //     setSelectedUser(row);
                                // }}
                            />
                        </>
                    )}
                    {activeTab === 'orders' && (
                        <>
                            <Form onSubmit={handleSubmitOrders}>
                                <Title className="ParagraphLargeBold">Search for orders in database:</Title>
                                <LabelContainer>
                                    <Label htmlFor="searchOrders-oid">
                                        Order ID:{' '}
                                        <Input
                                            id="searchOrders-oid"
                                            type="text"
                                            value={orders.oid}
                                            name="searchOrders-oid"
                                            placeholder="optional"
                                            onChange={(e) => setOrders({...orders, oid: e.target.value})}
                                        />
                                    </Label>
                                    <Label htmlFor="searchOrders-fname">
                                        First Name:{' '}
                                        <Input
                                            id="searchOrders-fname"
                                            type="text"
                                            value={orders.firstName}
                                            name="searchOrders-fname"
                                            placeholder="optional"
                                            onChange={(e) => setOrders({...orders, firstName: e.target.value})}
                                        />
                                    </Label>
                                    <Label htmlFor="searchOrders-lname">
                                        Last Name:{' '}
                                        <Input
                                            id="searchOrders-lname"
                                            type="text"
                                            value={orders.lastName}
                                            name="searchOrders-lname"
                                            placeholder="optional"
                                            onChange={(e) => setOrders({...orders, lastName: e.target.value})}
                                        />
                                    </Label>
                                    <Label htmlFor="searchOrders-email">
                                        Email:{' '}
                                        <InputEmail
                                            id="searchOrders-email"
                                            type="text"
                                            value={orders.email}
                                            name="searchOrders-email"
                                            placeholder="optional"
                                            onChange={(e) => setOrders({...orders, email: e.target.value})}
                                        />
                                    </Label>
                                </LabelContainer>

                                <SubmitContainer>
                                    <Button type="submit">Search Orders</Button>
                                    {searchingUsers ? (
                                        <WorkingText>Working...</WorkingText>
                                    ) : !toast.message ? null : (
                                        <Toast error={toast.error}>{toast.message}</Toast>
                                    )}
                                </SubmitContainer>
                            </Form>

                            <UserGrid columns={columnsOrders} rows={resultsOrders} />
                        </>
                    )}

                    {activeTab === 'photos' && (
                        <Form onSubmit={handlePhotoSearch}>
                            <Title className="ParagraphLargeBold">View submitted photos:</Title>
                            <LabelContainer>
                                <Label htmlFor="photoEmail">
                                    Enter Claimant Email
                                    <Input
                                        id="photoEmail"
                                        type="text"
                                        name="photoEmail"
                                        value={state.photoEmail}
                                        placeholder="example@example.com"
                                        onChange={(e) => setState({...state, photoEmail: e.target.value})}
                                    />
                                </Label>
                            </LabelContainer>

                            <SubmitContainer>
                                <button type="submit">Search Claims</button>
                                {searchingPhotos ? (
                                    <WorkingText>Working...</WorkingText>
                                ) : !searchedPhotos ? null : !photos.frontInsurance &&
                                  !photos.backInsurance &&
                                  !photos.frontId ? (
                                    <Paragraph>No photos have been submitted by this user</Paragraph>
                                ) : null}
                            </SubmitContainer>
                            {(photos.frontInsurance || photos.backInsurance || photos.frontId) && (
                                <Paragraph>Latest Submitted Photos:</Paragraph>
                            )}
                            <PhotoContainer>
                                {photos.frontInsurance && (
                                    <div onClick={() => setPhotoModal('frontInsurance')}>
                                        <Paragraph>Insurance Card Front</Paragraph>
                                        <Photo url={photos.frontInsurance} />
                                    </div>
                                )}
                                {photos.backInsurance && (
                                    <div onClick={() => setPhotoModal('backInsurance')}>
                                        <Paragraph>Insurance Card Back</Paragraph>
                                        <Photo url={photos.backInsurance} />
                                    </div>
                                )}
                                {photos.frontId && (
                                    <div onClick={() => setPhotoModal('frontId')}>
                                        <Paragraph>ID</Paragraph>
                                        <Photo url={photos.frontId} />
                                    </div>
                                )}
                            </PhotoContainer>
                        </Form>
                    )}

                    {activeTab === 'video' && (
                        <Form onSubmit={handleKitSearch}>
                            <Title className="ParagraphLargeBold">Download submitted video:</Title>
                            <LabelContainer>
                                <Label htmlFor="kitId">
                                    Enter RT-PCR Kit ID
                                    <Input
                                        id="kitId"
                                        type="text"
                                        name="kitId"
                                        value={state.kitId}
                                        placeholder="01-0000-0000"
                                        onChange={(e) => setState({...state, kitId: e.target.value})}
                                    />
                                </Label>
                            </LabelContainer>

                            <SubmitContainer>
                                <button type="submit">Search Kit IDs</button>
                                {kit.video_url ? (
                                    <a href={kit.video_url}>{`Download submitted video for Kit ${kit.kit_id}`}</a>
                                ) : searchingKit ? (
                                    <WorkingText>Working...</WorkingText>
                                ) : !searchedKit ? null : !!kit.id ? (
                                    <Paragraph>This kit has been registered, but no video has been uploaded</Paragraph>
                                ) : (
                                    <Paragraph>This kit has not been registered</Paragraph>
                                )}
                            </SubmitContainer>
                        </Form>
                    )}

                    {activeTab === 'reset' && (
                        <Form onSubmit={handlePasswordReset}>
                            <Title className="ParagraphLargeBold">Enter user info:</Title>
                            <LabelContainer>
                                <Label htmlFor="passwordEmail">
                                    Enter User Email
                                    <InputEmail
                                        id="passwordEmail"
                                        type="text"
                                        name="passwordEmail"
                                        value={state.passwordEmail}
                                        placeholder="example@example.com"
                                        onChange={(e) => setState({...state, passwordEmail: e.target.value})}
                                    />
                                </Label>
                                <br />
                                <Label htmlFor="password">
                                    Enter New Password
                                    <InputEmail
                                        id="password"
                                        type="text"
                                        name="password"
                                        value={state.password}
                                        placeholder="Password123"
                                        onChange={(e) => {
                                            const passwordUppercaseReg = /[A-Z]/;
                                            const passwordLowercaseReg = /[a-z]/;
                                            const passwordNumberReg = /[\d]/;

                                            setPasswordErrors({
                                                passwordLetters:
                                                    !passwordUppercaseReg.test(e.target.value) ||
                                                    !passwordLowercaseReg.test(e.target.value),
                                                passwordNumber: !passwordNumberReg.test(e.target.value),
                                                passwordLength: e.target.value.length <= 8,
                                            });

                                            setState({...state, password: e.target.value});
                                        }}
                                    />
                                </Label>
                            </LabelContainer>

                            <PasswordErrorsContainer>
                                <PasswordError error={passwordErrors.passwordLength}>
                                    Password must be at least 9 characters long.
                                </PasswordError>
                                <PasswordError error={passwordErrors.passwordLetters}>
                                    Password must include at least 1 uppercase and lowercase letter.
                                </PasswordError>
                                <PasswordError error={passwordErrors.passwordNumber}>
                                    Password must include at least 1 number.
                                </PasswordError>
                            </PasswordErrorsContainer>

                            <SubmitContainer>
                                <button type="submit">Reset Password</button>
                                {resettingPassword ? (
                                    <WorkingText>Working...</WorkingText>
                                ) : !toast.message ? null : (
                                    <Toast error={toast.error}>{toast.message}</Toast>
                                )}
                            </SubmitContainer>
                        </Form>
                    )}

                    {activeTab === 'rejectedEmails' && (
                        <>
                            <div>
                                <Title className="ParagraphLargeBold">Search for rejected emails in database:</Title>
                                <TabContainer style={{marginTop: '10px'}}>
                                    <div style={{width: '210px', marginTop: '6px'}}>Start date/time</div>
                                    <StyledDatePicker
                                        selected={rejectedEmailsFilter.startDatetime}
                                        error={!rejectedEmailsFilter.startDatetime}
                                        placeholderText="MM/dd/YYYY h:mm aa"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        onChange={(date) => {
                                            setRejectedEmailsFilter({...rejectedEmailsFilter, startDatetime: date});
                                        }}
                                    />
                                </TabContainer>
                                <TabContainer style={{marginTop: '10px'}}>
                                    <div style={{width: '210px', marginTop: '6px'}}>End date/time</div>
                                    <StyledDatePicker
                                        selected={rejectedEmailsFilter.endDatetime}
                                        error={!rejectedEmailsFilter.endDatetime}
                                        placeholderText="MM/DD/YYYY h::mm aa"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        showTimeSelect
                                        dateFormat="MM/dd/yyyy h:mm aa"
                                        onChange={(date) => {
                                            setRejectedEmailsFilter({...rejectedEmailsFilter, endDatetime: date});
                                        }}
                                    />
                                </TabContainer>
                                <button
                                    disabled={!rejectedEmailsFilter.startDatetime || !rejectedEmailsFilter.endDatetime}
                                    style={{marginTop: '35px', marginLeft: '202px', height: '35px', width: '158px'}}
                                    onClick={getRejectedEmails}>
                                    Search Rejected Emails
                                </button>

                                <br />
                                {loadingRejectedEmails ? (
                                    <Paragraph>Working...</Paragraph>
                                ) : rejectedEmails ? null : (
                                    <Toast error={toast.error}>{toast.message}</Toast>
                                )}
                            </div>

                            <UserGrid columns={columnsRejectedEmails} rows={rejectedEmails} />
                        </>
                    )}
                    {activeTab === 'addtest' && (
                        <Form onSubmit={handleAddTest}>
                            <Title className="ParagraphLargeBold">Add a test type:</Title>
                            <AddTestLabelContainer>
                                <Label htmlFor="testname">
                                    Test Name
                                    <InputEmail
                                        id="testname"
                                        type="text"
                                        name="testname"
                                        value={addTestState.testName}
                                        placeholder="COVID19/Flu"
                                        onChange={(e) => setAddTestState({...addTestState, testName: e.target.value})}
                                    />
                                </Label>
                                <Label htmlFor="diagCodes">
                                    Diagnosis Codes (separated by commas)
                                    <InputEmail
                                        id="diagCodes"
                                        type="text"
                                        name="diagCodes"
                                        value={addTestState.diagCodes}
                                        placeholder="R512, R519, R965"
                                        onChange={(e) => setAddTestState({...addTestState, diagCodes: e.target.value})}
                                    />
                                </Label>
                                <Label htmlFor="specimenType">
                                    Specimen Type
                                    <Input
                                        id="specimenType"
                                        type="text"
                                        name="specimenType"
                                        value={addTestState.specimenType}
                                        placeholder="Urine"
                                        onChange={(e) =>
                                            setAddTestState({...addTestState, specimenType: e.target.value})
                                        }
                                    />
                                </Label>
                                <Label htmlFor="providerNPI">
                                    Provider NPI
                                    <Input
                                        id="providerNPI"
                                        type="text"
                                        name="providerNPI"
                                        value={addTestState.providerNPI}
                                        placeholder="123456879"
                                        onChange={(e) =>
                                            setAddTestState({...addTestState, providerNPI: e.target.value})
                                        }
                                    />
                                </Label>
                                <Title className="ParagraphLargeBold">Display Options</Title>
                                <Label htmlFor="color">
                                    Desired Color (hexcode e.g. #0CC6B8)
                                    <Input
                                        id="color"
                                        type="text"
                                        name="color"
                                        value={addTestState.color}
                                        placeholder="#FFFFFF"
                                        onChange={(e) => setAddTestState({...addTestState, color: e.target.value})}
                                    />
                                </Label>
                                <Label htmlFor="layout">
                                    Desired Layout
                                    <Select
                                        id="layout"
                                        name="layout"
                                        value={addTestState.layout}
                                        placeholder="Panel Test"
                                        onChange={(e) => setAddTestState({...addTestState, layout: e.target.value})}>
                                        <option disabled value="">
                                            --
                                        </option>
                                        <option value="allergy">Allergy Test</option>
                                        <option value="binary">Binary Test (Covid, Flu, etc...)</option>
                                        <option value="blood">Panel Test (Blood Wellness, etc...)</option>
                                    </Select>
                                </Label>
                                <Label htmlFor="inOrderDrop">
                                    Should the test be included in the order form dropdown?
                                    <input
                                        id="inOrderDrop"
                                        type="checkbox"
                                        name="inOrderDrop"
                                        onChange={(e) =>
                                            setAddTestState({...addTestState, inOrderDrop: e.target.checked})
                                        }
                                    />
                                </Label>
                                <Label htmlFor="inRegisterDrop">
                                    Should the test be included in the register kit dropdown?
                                    <input
                                        id="inRegisterDrop"
                                        type="checkbox"
                                        name="inRegisterDrop"
                                        onChange={(e) =>
                                            setAddTestState({...addTestState, inRegisterDrop: e.target.checked})
                                        }
                                    />
                                </Label>
                            </AddTestLabelContainer>
                            <SubmitContainer>
                                <button type="submit">Submit Kit Type</button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        syncTest();
                                    }}>
                                    Sync Profile/Panel from ELIS
                                </button>
                                {!toast.message ? null : <Toast error={toast.error}>{toast.message}</Toast>}
                            </SubmitContainer>
                        </Form>
                    )}
                </AdminPage>
            </PageContainer>
            {isModalHidden || (
                <ModalBackgroundOverlay onClick={() => setIsModalHidden(true)}>
                    <ModalWindow onClick={(e) => e.stopPropagation()}>
                        <ModalTabContainer>
                            <TabButtonContainer>
                                <TabButton
                                    selected={activeModalTab === 'patient-info'}
                                    onClick={() => setActiveModalTab('patient-info')}>
                                    Patient Info
                                </TabButton>
                                <TabButton
                                    selected={activeModalTab === 'address-info'}
                                    onClick={() => setActiveModalTab('address-info')}>
                                    Address Info
                                </TabButton>
                            </TabButtonContainer>
                            <TinyImg className="fa-light fa-circle-xmark" onClick={() => setIsModalHidden(true)} />
                        </ModalTabContainer>
                        <ModalFieldGroup>
                            {isUpdatingUser && <OverlayingSpinner color="#3c99dc" size="100px" left="47.5%" />}
                            {activeModalTab === 'patient-info' && (
                                <>
                                    <RowContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">First name</FieldLabel>
                                            <TextInputField
                                                name="firstName"
                                                placeholder="John"
                                                type="text"
                                                value={selectedUser.firstName || ''}
                                                onChange={handleUserInfoChange}
                                            />
                                        </FieldContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Last name</FieldLabel>
                                            <TextInputField
                                                name="lastName"
                                                placeholder="Doe"
                                                type="text"
                                                value={selectedUser.lastName || ''}
                                                onChange={handleUserInfoChange}
                                            />
                                        </FieldContainer>
                                    </RowContainer>
                                    <FieldContainer width="50%">
                                        <FieldLabel className="ParagraphNormalRegular">Date of Birth</FieldLabel>
                                        <IconFieldContainer>
                                            <LeftIcon className="fa-light fa-calendar-days" />
                                            <DatePickerField
                                                error={userInfoError?.birthday}
                                                selected={new Date(selectedUser.birthday)}
                                                minDate={new Date('1/1/1870')}
                                                maxDate={new Date()}
                                                placeholderText="MM/DD/YYYY"
                                                showMonthDropdown
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dropdownMode="select"
                                                dateFormat="MM/dd/yyyy"
                                                onChange={(date: Date) => {
                                                    setSelectedUser({...selectedUser, birthday: date});
                                                    setUserInfoError({...userInfoError, birthday: false});
                                                }}
                                            />
                                        </IconFieldContainer>
                                    </FieldContainer>
                                    <RowContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Ethnicity</FieldLabel>
                                            <SelectField
                                                name="ethnicity"
                                                value={selectedUser.ethnicity || ''}
                                                onChange={handleUserInfoChange}>
                                                <option disabled value="">
                                                    --
                                                </option>
                                                {Ethnicities.map((ethnicity: string, index: number) => (
                                                    <option key={index} value={ethnicity}>
                                                        {ethnicity}
                                                    </option>
                                                ))}
                                            </SelectField>
                                        </FieldContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Biological Sex</FieldLabel>
                                            <RowContainer>
                                                <RadioFieldContainer>
                                                    <RadioButton
                                                        type="radio"
                                                        name="gender"
                                                        value="M"
                                                        checked={selectedUser.gender === 'M'}
                                                        onChange={handleUserInfoChange}
                                                    />
                                                    <RadioButtonLabel>Male</RadioButtonLabel>
                                                </RadioFieldContainer>
                                                <RadioFieldContainer>
                                                    <RadioButton
                                                        type="radio"
                                                        name="gender"
                                                        value="F"
                                                        checked={selectedUser.gender === 'F'}
                                                        onChange={handleUserInfoChange}
                                                    />
                                                    <RadioButtonLabel>Female</RadioButtonLabel>
                                                </RadioFieldContainer>
                                            </RowContainer>
                                        </FieldContainer>
                                    </RowContainer>
                                    <RowContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">
                                                Email - Cannot Edit
                                            </FieldLabel>
                                            <IconFieldContainer>
                                                <LeftIcon className="fa-light fa-envelope" />
                                                <TextInputField
                                                    type="text"
                                                    placeholder="john.doe@example"
                                                    value={selectedUser.email}
                                                    hasIcon
                                                    readOnly
                                                />
                                            </IconFieldContainer>
                                        </FieldContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Phone Number</FieldLabel>
                                            <IconFieldContainer>
                                                <LeftIcon className="fa-light fa-phone-flip" />
                                                <TextInputField
                                                    name="phone"
                                                    type="tel"
                                                    placeholder="555-555-5555"
                                                    value={selectedUser.phone}
                                                    onChange={handleUserInfoChange}
                                                    hasIcon
                                                />
                                            </IconFieldContainer>
                                        </FieldContainer>
                                    </RowContainer>
                                    <RowContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Category ID</FieldLabel>
                                            <TextInputField
                                                name="category_id"
                                                placeholder="Category ID"
                                                type="text"
                                                value={selectedUser.category_id || ''}
                                                onChange={handleUserInfoChange}
                                            />
                                        </FieldContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Category Name</FieldLabel>
                                            <TextInputField
                                                name="category_name"
                                                placeholder="Category Name"
                                                type="text"
                                                value={selectedUser.category_name || ''}
                                                onChange={handleUserInfoChange}
                                            />
                                        </FieldContainer>
                                    </RowContainer>
                                </>
                            )}
                            {activeModalTab === 'address-info' && (
                                <>
                                    <FieldContainer width="100%">
                                        <FieldLabel className="ParagraphNormalRegular">Street Address</FieldLabel>
                                        <TextInputField
                                            name="address1"
                                            placeholder="123 Apple Street"
                                            type="text"
                                            value={selectedUser.address1 || ''}
                                            onChange={handleUserInfoChange}
                                        />
                                    </FieldContainer>
                                    <FieldContainer width="100%">
                                        <FieldLabel className="ParagraphNormalRegular">
                                            Address Cont. - Apt/Suite/Etc
                                        </FieldLabel>
                                        <TextInputField
                                            name="address2"
                                            placeholder="Apt 1000"
                                            type="text"
                                            value={selectedUser.address2 || ''}
                                            onChange={handleUserInfoChange}
                                        />
                                        <FieldDescription>Optional</FieldDescription>
                                    </FieldContainer>
                                    <FieldContainer width="50%">
                                        <FieldLabel className="ParagraphNormalRegular">City</FieldLabel>
                                        <TextInputField
                                            name="city"
                                            placeholder="Dallas"
                                            type="text"
                                            value={selectedUser.city || ''}
                                            onChange={handleUserInfoChange}
                                        />
                                    </FieldContainer>
                                    <RowContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">State</FieldLabel>
                                            <SelectField
                                                name="state"
                                                value={selectedUser.state || ''}
                                                onChange={handleUserInfoChange}>
                                                <option disabled value="">
                                                    --
                                                </option>
                                                {States.map((state: any, index: number) => (
                                                    <option key={index} value={state.code}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </SelectField>
                                        </FieldContainer>
                                        <FieldContainer width="50%">
                                            <FieldLabel className="ParagraphNormalRegular">Zip Code</FieldLabel>
                                            <TextInputField
                                                name="zip"
                                                placeholder="75070"
                                                type="text"
                                                value={selectedUser.zip || ''}
                                                onChange={handleUserInfoChange}
                                            />
                                        </FieldContainer>
                                    </RowContainer>
                                </>
                            )}
                            <ActionButtonContainer>
                                <VerificationLabelButton
                                    isVerified={selectedUser.verified}
                                    onClick={() =>
                                        setSelectedUser((prevState: any) => ({
                                            ...prevState,
                                            verified: !prevState.verified,
                                        }))
                                    }>
                                    {selectedUser.verified ? 'Verified' : 'Not Verified'}
                                </VerificationLabelButton>
                                <VerticalLine />
                                <RegularButton
                                    onClick={() => sendReset(selectedUser.email)}
                                    disabled={!selectedUser.verified}>
                                    Reset Password
                                </RegularButton>
                                <RegularButton
                                    onClick={() => copyLink(selectedUser.token)}
                                    disabled={selectedUser.verified}>
                                    Copy Verification Link
                                </RegularButton>
                                <RegularButton
                                    onClick={() => resendVerify(selectedUser.email)}
                                    disabled={selectedUser.verified}>
                                    Resend Verification
                                </RegularButton>
                                <RegularButton onClick={() => impersonateUser(selectedUser.email)}>
                                    Impersonate User
                                </RegularButton>
                            </ActionButtonContainer>
                            <ActionButtonContainer>
                                <PrimaryButton onClick={() => sendUpdate(selectedUser)}>Save</PrimaryButton>
                                <SecondaryButton
                                    onClick={() => {
                                        sendUpdate(selectedUser);
                                        setIsModalHidden(true);
                                    }}>
                                    Save and Close
                                </SecondaryButton>
                            </ActionButtonContainer>
                        </ModalFieldGroup>
                    </ModalWindow>
                </ModalBackgroundOverlay>
            )}
        </>
    );
};

export default Admin;
