import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Device} from '../../services/constants';

export const LogoLink = styled(Link)`
    margin: 0 auto;
`;

export const Logo = styled.img`
    width: 232px;
    height: 52px;
    // margin: 0 auto;
    cursor: pointer;
`;

export const NavBar = styled.div<{navVisible: boolean; adminBanner: boolean; setAdminBanner: Function}>`
    position: sticky;
    top: 8px;
    left: 8px;
    min-width: 296px;
    width: 296px;
    overflow: auto;
    min-height: 615px;
    // height: calc(100vh - 64px);
    height: ${(props) => (props.adminBanner ? 'calc(100% - 128px)' : 'calc(100vh - 64px)')};
    flex-grow: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 4px;
    display: flex;
    gap: 36px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    z-index: 2;
    transition: 0.2s;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
            height: calc(100vh - 130px);
        }
    }

    @media ${Device.mobile} {
        width: ${(props) => (props.navVisible ? '296px' : '0')};
        padding: ${(props) => (props.navVisible ? '24px 4px' : '0')};
        display: ${(props) => (props.navVisible ? 'flex' : 'none')};
        position: fixed;
        top: 60px;
        left: 0;
        min-width: 0;
        min-height: 0;
        height: ${(props) => (props.adminBanner ? 'calc(100% - 174px)' : 'calc(100% - 110px)')};
        border-radius: 0;
        box-shadow: 0;
        z-index: 4;
        margin: 0;
        //height: calc(100% - 110px);
    }
`;

export const UserPhoto = styled.i`
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    background: linear-gradient(to right, #c198ff 30%, #5cd9ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: var(--neutral-100);
    background-color: var(--neutral-0);
`;

export const UserName = styled.div`
    text-align: center;
    text-transform: capitalize;
    color: #222222;
`;

export const UserFrame = styled.div`
    position: relative;
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 20px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    line-height: 30px;
`;

export const MenuButton = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 0 0 auto;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--neutral-200);
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;

export const ContextMenu = styled.div<{visible: boolean}>`
    position: absolute;
    top: 44px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    width: 181px;
    height: ${(props) => (props.visible ? 'fit-content' : '0')};
    // height: ${(props) => (props.visible ? '36px' : '0')};
    overflow: hidden;
    padding: ${(props) => (props.visible ? '8px' : '0')};
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
    background-color: var(--neutral-500);
    transition: 0.2s;
`;

//the $ makes it a transient prop, thus not being passed to the actual Link
export const ContextMenuItem = styled(Link)<{$visible: boolean}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 16px);
    height: ${(props) => (props.$visible ? '100%' : '0')};
    padding: ${(props) => (props.$visible ? '8px' : '0')};
    border-radius: 8px;
    text-decoration: none;
    background-color: var(--neutral-500);
    color: var(--text-0);
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: var(--neutral-400);
    }
    &:active {
        background-color: var(--neutral-300);
    }
`;

export const ContextMenuDivider = styled.div<{visible: boolean}>`
    width: ${(props) => (props.visible ? '100%' : '0')};
    height: ${(props) => (props.visible ? '1px' : '0')};
    background-color: var(--neutral-0);
`;

export const ContextMenuText = styled.p`
    font-size: 0.833rem;
`;

export const MenuIcon = styled.i`
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 100;
`;

export const HeaderButton = styled.i`
    font-size: 24px;
`;

export const NavLinks = styled.div`
    width: 192px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        &:not(:last-child) {
            margin-top: 24px;
        }
    }
`;

// export const ConnectingLine = styled.div`
//     position: absolute;
//     top: 36px;
//     left: 17.5px;
//     width: 1px;
//     height: 70%;
//     border-left: 2px solid var(--neutral-200);
// `;

export const LinkPhoto = styled.div<{ishovered: number; isCurrentPage: boolean}>`
    width: 22px;
    height: 22px;
    padding: 5px;
    box-shadow: 0 0 0 2px
        ${(props) =>
            props.ishovered ? 'var(--link-300)' : props.isCurrentPage ? 'var(--text-400)' : 'rgba(0, 0, 0, 0)'};
    border-radius: 50%;
    z-index: 1;
    transition: 0.2s;
    display: flex;
    justify-content: center;
`;

export const LinkText = styled.p`
    margin-left: 12px;
    transition: 0.2s;
`;

export const NavItemImage = styled.i`
    position: absolute;
    font-size: 22px;
    color: white;
    font-weight: 100;
    -webkit-text-stroke: 0.1px white;
`;

export const SmallNavItemImage = styled(NavItemImage)`
    font-size: 18px;
    margin-top: 3px;
`;

export const MicroscopeImage = styled.img`
    width: 22px;
    height: 22px;
`;

export const DashboardPhoto = styled(LinkPhoto)`
    background-color: var(--mld-scheme-dashboard);
`;

export const LabResultsPhoto = styled(LinkPhoto)`
    background-color: var(--mld-scheme-lab-results);
`;

export const HealthSummaryPhoto = styled(LinkPhoto)`
    background-color: var(--mld-scheme-health-summary);
`;

export const ShopTestsPhoto = styled(LinkPhoto)`
    background-color: var(--mld-scheme-shop-tests);
`;

export const TestKitsPhoto = styled(LinkPhoto)`
    background-color: var(--mld-scheme-test-kits);
`;

export const SupportPhoto = styled(LinkPhoto)`
    background-color: var(--mld-scheme-support);
`;

export const AdminPhoto = styled(LinkPhoto)`
    background-color: var(--neutral-600);
`;

export const NavItem = styled(Link)<{ishovered: number}>`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-400);
    color: ${(props) => (props.ishovered ? 'var(--link-300)' : 'var(--text-400)')};

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 24px;
    }
`;

//may be a bettter way to do this, but as of now, can't inherit styles from
//something with a different tag without using "as 'tag'" and Link doesn't support that
export const ExternalNavItem = styled.a<{ishovered: number}>`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-400);
    color: ${(props) => (props.ishovered ? 'var(--link-300)' : 'var(--text-400)')};

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        margin-bottom: 24px;
    }
`;

export const BottomLinks = styled.div`
    width: 192px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    // gap: 24px;
    gap: 8px;
    margin-top: auto;
`;

export const BottomLink = styled(NavItem)`
    color: ${(props) => (props.ishovered ? 'var(--error-300)' : 'var(--text-400)')};
`;

export const BottomLinkPhoto = styled.i<{ishovered: number}>`
    font-size: 19.2px;
    transition: 0.2s;
    color: ${(props) => (props.ishovered ? 'var(--link-300)' : 'var(--text-400)')};
`;

export const LogoutPhoto = styled(BottomLinkPhoto)`
    color: ${(props) => (props.ishovered ? 'var(--error-300)' : 'var(--text-400)')};
`;

// export const Separator = styled.div`
//     width: 100%;
//     height: 1px;
//     background-color: var(--neutral-100);
// `;
