import React from 'react';
import image from '../Resources/img/Step2.png';
import {Layout} from '../Components';

const Step2 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={image} alt="Prep Sample" />
            </div>

            <h2 className="antibody-step-number">Please Read Before Continuing</h2>

            <p className="has-text-centered">
                Your At Home Test must be supervised remotely by one of our telehealth providers or you can submit a
                video recording of your self-administered test.
            </p>
            <p className="has-text-centered">
                For FREE telehealth support schedule your appointment{' '}
                <a href="https://mylabsdirect.as.me/at-home-test" target="_blank" rel="noreferrer">
                    here
                </a>{' '}
                or call <a href="tel:18773557978">1-877-355-7978</a>.
            </p>
            <p className="has-text-centered">
                To self-record please proceed and note <strong>steps 6-10</strong> must be recorded and uploaded.
            </p>

            <p className="has-text-centered">
                <em>*One of the above options must be utilized for test to be valid.</em>
            </p>
        </section>
    </Layout>
);
export default Step2;
