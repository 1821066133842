import React, {useState} from 'react';
import {Layout} from '../Components';
import RecordYourself from '../Resources/img/Step6.png';
import {useAuth} from '../../hooks/useAuth';
import {useLocation, useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import settings from '../../settings';
const Step12 = () => {
    const {token} = useAuth();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const idParam = query.get('id');
    const [kitId, setKitId] = useState(!idParam || idParam === 'null' ? '' : idParam);
    const [error, setError] = useState({kitId: false});
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        file: null,
        uploading: false,
        uploaded: false,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        let value = event.target.value;

        if (name === 'kitId') {
            const digitReg = /^[0-9]$/;

            if (value.length === 3 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }
        }

        setKitId(value);
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const notify = (message, type, onClose = () => {}) => {
        console.log(message, type, 'notified');
        const config = {
            position: toast.POSITION.TOP_CENTER,
            bodyClassName: 'has-text-white	is-uppercase	is-size-5',
            autoClose: type === 'error' ? 5000 : 2000,
            onClose,
        };
        if (type === 'success') {
            toast.success(message, {
                ...config,
            });
        } else if (type === 'error') {
            toast.error(message, {
                ...config,
            });
        }
    };
    const next = () => {
        navigate(`/registerkit/step/13?id=${kitId}`);
    };

    const videoUploadHandler = async (event) => {
        setState({
            file: event.target.files[0],
            uploading: true,
        });

        try {
            // First, check to see if the kit has been registered
            const kitIdRequestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({kit_id: kitId}),
            };

            const kitIdRes = await fetch(`${settings.apiURL}/api/kit/getKit`, kitIdRequestOptions);
            if (!kitIdRes.ok) {
                throw new Error('Could not check kit ID');
            }

            const kitIdResponse = await kitIdRes.json();
            if (!kitIdResponse.kit_id) {
                notify('This kit has not yet been registered.', 'error', {}); // If it's an error don't want to go to next screen
                setState({
                    ...state,
                    uploading: false,
                });
                return;
            }

            // If it has been registered, continue with the upload
            const formData = new FormData();
            formData.append('name', kitId);
            formData.append('fileToUpload', event.target.files[0]);

            const videoUploadRequestOptions = {
                method: 'POST',
                headers: {Authorization: `Bearer ${token}`},
                body: formData,
            };

            const videoUploadRes = await fetch(`${settings.apiURL}/api/kit/fileUpload`, videoUploadRequestOptions);
            if (!videoUploadRes.ok) {
                throw new Error('Could not upload video');
            }

            const videoUploadResponse = await videoUploadRes.json();

            const videoUpdateRequestOptions = {
                method: 'PATCH',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({
                    kit_id: kitId,
                    video_url: videoUploadResponse.video_url,
                }),
            };

            const videoUpdateRes = await fetch(`${settings.apiURL}/api/kit/video_url`, videoUpdateRequestOptions);
            if (!videoUpdateRes.ok) {
                throw new Error('Could not update video url');
            }

            notify('Video uploaded successfully!', 'success', next);

            setState({
                ...state,
                uploading: false,
                uploaded: true,
            });
        } catch (error) {
            notify("Video Couldn't be uploaded please try again!", 'error', {}); // If it's an error don't want to go to next screen
            console.log(error);
            setState({
                ...state,
                uploading: false,
            });
        }
    };
    return (
        <Layout>
            <section className="white-bg">
                <ToastContainer />
                <h4 style={{fontWeight: '900', color: '#5fd0df'}}>
                    This step is only for users not choosing the telehealth option.
                    <br />
                    Please call or text <a href="tel:18773557978">1-877-355-7978</a> with questions.
                </h4>

                <h2 className="antibody-step-number">Step 10</h2>

                <h3 className="antibody-step-description">Record Yourself While Testing </h3>

                <div className="helper-media">
                    <img src={RecordYourself} alt="Record Yourself" />
                </div>

                <p className="has-text-centered">
                    <strong>Record a video of yourself administering steps 5 through 9.</strong>
                </p>

                <p className="has-text-centered">Your video must clearly show you administering test.</p>

                <p className="has-text-centered">
                    <small>
                        <em>If the video is not submitted My Labs Direct will not be able to provide test results.</em>
                    </small>
                </p>

                <form>
                    {/* <article
            class="message is-success"
            style={{ display: state.uploaded ? "block" : "none" }}
          >
            <div class="message-header" style={{ justifyContent: "center" }}>
              Success
            </div>
            <div class="message-body">
              Your video has been uploaded successfully!
            </div>
          </article> */}
                    <article class="message is-warning" style={{display: state.uploading ? 'block' : 'none'}}>
                        <div class="message-header" style={{justifyContent: 'center'}}>
                            Your Video is Uploading
                        </div>
                        <div class="message-body">Please be patient, this may take several minutes to complete.</div>
                    </article>
                    <p
                        className="has-text-centered"
                        style={{'margin-bottom': 0, color: error.kitId ? 'var(--error-300)' : ''}}>
                        Enter Kit ID:
                    </p>
                    <input
                        type="text"
                        name="kitId"
                        value={kitId}
                        onChange={handleChange}
                        style={{'margin-bottom': '16px'}}
                        disabled={idParam?.length > 0 ? true : false}
                    />
                    <button
                        id="record-button"
                        className={`button is-primary ${state.uploading ? 'is-loading' : ''}`}
                        style={{
                            display: state.uploaded ? 'none' : 'block',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            const id = kitId.substring(0, 2);
                            const idReg = /^[+]?[(]?[0-9]{2}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{4}$/;

                            if ((id !== '01' && id !== '04') || !idReg.test(kitId)) {
                                setError((prevError) => ({...prevError, kitId: true}));
                                return;
                            }
                            document.getElementById('file-upload-button').click();
                        }}>
                        Click Here to Upload Now
                    </button>
                    <div>
                        <small>
                            <strong>
                                <em>
                                    NOTE: A stable internet connection is <u>required</u>.
                                </em>
                            </strong>
                        </small>
                    </div>
                    <input
                        type="file"
                        id="file-upload-button"
                        name="file-upload"
                        accept="video/*"
                        style={{display: 'none'}}
                        onChange={videoUploadHandler}
                        capture="user"
                    />
                </form>
            </section>
        </Layout>
    );
};
export default Step12;
