import React from 'react';
import {
    ResultAccent,
    ResultBody,
    ResultContainer,
    ResultContent,
    ResultHeader,
    ResultItem,
    CornerIcon,
    CornerPhoto,
    Icon
} from './styles/AppointmentCard';

interface Props {
    appointment: any;
}

const AppointmentCard: React.FC<Props> = ({appointment}) => {
    const dateOptions: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const timeOptions: Intl.DateTimeFormatOptions = {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'};
    let date = new Date(appointment.appointmentDate);
    const dateString = `${date.toLocaleDateString('en-us', dateOptions)} at ${date.toLocaleTimeString(
        'en-us',
        timeOptions
    )}`;

    function makeAppointmentCalendar (cal: string) {
        switch (cal) {
            case 'Concierge':
                return 'Mobile Concierge';
            case 'Virtual Proctor':
            case 'Virtual Proctor 2':
            case 'Virtual Proctor 3':
                return `Virtual Testing at MylabsDirect ${cal}`;
            default:
                return cal;
        }
    }

    return (
        <ResultContainer>
            <ResultAccent />
            <ResultContent>
                <ResultHeader>
                    <p className='Heading04ExtraBold'>Appointment</p>
                    <CornerPhoto>
                        <CornerIcon className='fa-light fa-vial' />
                    </CornerPhoto>
                </ResultHeader>
                <ResultBody>
                    <p className='Heading05ExtraBold'>{appointment.appointmentType}</p>
                    <ResultItem>
                        <Icon
                            className={
                                appointment.appointmentCalendar === 'Concierge'
                                    ? 'fa-light fa-truck-medical'
                                    : 'fa-light fa-hospital-user'
                            }
                        />
                        <p className='ParagraphNormalRegular'>
                            {makeAppointmentCalendar(appointment.appointmentCalendar)}
                        </p>
                    </ResultItem>
                    <ResultItem>
                        <Icon className='fa-light fa-calendar-clock' />
                        <p className='ParagraphNormalRegular'>{`${dateString}`}</p>
                    </ResultItem>
                    <ResultItem>
                        <Icon className='fa-light fa-location-dot' />
                        <p className='ParagraphNormalRegular'>{appointment.appointmentLocation}</p>
                    </ResultItem>
                </ResultBody>
            </ResultContent>
        </ResultContainer>
    );
};

export default AppointmentCard;
