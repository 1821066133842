import React, {useState, useRef, FormEvent} from 'react';
import {useAuth} from '../hooks/useAuth';
import QRCode from 'qrcode.react';
import Spinner from '../components/Spinner';
import Error from '../components/Error';
import settings from '../settings';
import Dialog from '@mui/material/Dialog';
import {
    Switch,
    SwitchWrapper,
    Input,
    MainContainer,
    StyledIcon,
    Info,
    InfoContainer,
    Header,
    GroupContainer,
    DigitContainer,
    MethodIcon,
    HorizontalContainer,
    HorizontalLine,
    CancelContainer,
    KeyIconContainer,
    Cancel,
    KeyIcon,
    LeftIcon,
    RightIcon,
    SmallKeyIcon,
    FieldName,
    FieldNameText,
    Form,
    FormInput,
    Button,
    OverlayingSpinner,
    DigitInput,
    LinkButton,
} from './styles/TwoStepVerificationSettings';

interface Props {
    user: any;
    onCancel: () => void;
    updateUserInfo: (userInfo: any) => void;
}

interface SwitchInputProps {
    checked: boolean;
    onChange: (value: boolean) => void;
}

const SwitchInput: React.FC<SwitchInputProps> = ({checked, onChange}) => {
    return (
        <SwitchWrapper>
            <Input checked={checked} type="checkbox" onChange={(e) => onChange(e.target.checked)} />
            <Switch />
        </SwitchWrapper>
    );
};

export const VerificationMode = {SMS: 'SMS', EMAIL: 'EMAIL', TOTP: 'TOTP', None: ''};

const DialogType = {
    None: '',
    Multiple2FAWarning: 'Multiple2FAWarning',
    Disable2FAWarning: 'Disable2FAWarning',
    Verification2FADisable: 'Verification2FADisable',
    Verification2FAEnable: 'Verification2FAEnable',
    TOTP2FAEnable: 'TOTP2FAEnable',
};

const TwoStepVerificationSettings: React.FC<Props> = ({user, onCancel, updateUserInfo}) => {
    const codeLength = 6;

    const [verificationCode, setVerificationCode] = useState<string[]>(Array(codeLength).fill(''));
    const [isUserVerified, setIsUserVerified] = useState(false);
    const [errorPassword, setErrorPassword] = useState('');
    const [mode, setMode] = useState(user.twoFAMode);
    const [displayPassword, setDisplayPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [verifying, setVerifying] = useState(false);

    const [dialogType, setDialogType] = useState(DialogType.None);
    const [totpRegistrationDetails, setTOTPRegistrationDetails] = useState({
        uri: '',
        key: '',
    });
    const [verificationError, setVerificationError] = useState<{[index: string]: boolean}>({
        codeVerificationFailed: false,
        invalidCode: false,
        codeSendFailed: false,
        twoFAUpdateFail: false,
    });
    const [totpRegistrationError, setTOTPRegistrationError] = useState('');
    const [processing, setProcessing] = useState(false);

    const codeInputRefs = useRef(new Array(6));
    // const canvasRef = useRef(null);

    const {token} = useAuth();

    const verifyPassword = async () => {
        setVerifying(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({username: user.email, password: password}),
        };

        const res = await fetch(`${settings.apiURL}/api/verifyPassword`, requestOptions).catch(() =>
            setErrorPassword('Could not verify. Please try again later')
        );
        setVerifying(false);
        if (!res) {
            return;
        }

        const userRes = await res!!.json();
        if (!res!!.ok) {
            setErrorPassword(userRes.error);
            return;
        }

        setErrorPassword('');
        setIsUserVerified(true);
    };

    const registerTOTP = async () => {
        setProcessing(true);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({}),
        };
        const res = await fetch(`${settings.apiURL}/api/2FA/TOTP/register`, requestOptions).catch(() =>
            setTOTPRegistrationError('Error during TOTP registration.')
        );

        setProcessing(false);
        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            setTOTPRegistrationError(dataRes.error);
        }

        setTOTPRegistrationDetails({uri: dataRes.uri, key: dataRes.key});
        setTOTPRegistrationError('');
    };

    const sendVerificationCodeBySMS = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({phone: user.phone}),
        };
        const res = await fetch(`${settings.apiURL}/api/2FA/SMS/verificationCode`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, codeSendFailed: true}))
        );

        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            if (dataRes.error === 'Two factor authentication failure') {
                setVerificationError((prev) => ({...prev, codeSendFailed: true}));
            }
        }
    };

    const sendVerificationCodeByEmail = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({name: user.firstName, email: user.email}),
        };

        const res = await fetch(`${settings.apiURL}/api/2FA/email/verificationCode`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, codeSendFailed: true}))
        );

        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            if (dataRes.error === 'Two factor authentication failure') {
                setVerificationError((prev) => ({...prev, codeSendFailed: true}));
            }
        }
    };

    const handleSMSCodeSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const code = verificationCode.join('');
        if (code.length !== codeLength) {
            setVerificationError((prevState) => ({...verificationError, invalidLength: true}));
            return;
        }

        setProcessing(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({phone: user.phone, verificationCode: verificationCode.join('')}),
        };

        const res = await fetch(`${settings.apiURL}/api/2FA/SMS/verify`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, codeVerificationFailed: true}))
        );
        setProcessing(false);

        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            setVerificationError((prev) => ({
                ...prev,
                invalidCode: dataRes!!.error === 'Invalid code',
                codeVerificationFailed: dataRes!!.error === 'Two factor authentication failure',
            }));
            return;
        }

        setVerificationError({});
        updateTwoFAMode();
    };

    const handleEmailCodeSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const code = verificationCode.join('');
        if (code.length !== codeLength) {
            setVerificationError((prevState) => ({...verificationError, invalidLength: true}));
            return;
        }

        setProcessing(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({email: user.email, verificationCode: verificationCode.join('')}),
        };

        const res = await fetch(`${settings.apiURL}/api/2FA/email/verify`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, codeVerificationFailed: true}))
        );
        setProcessing(false);

        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            setVerificationError((prev) => ({
                ...prev,
                invalidCode: dataRes!!.error === 'Invalid code',
                codeVerificationFailed: dataRes!!.error === 'Two factor authentication failure',
            }));
            return;
        }

        setVerificationError({});
        updateTwoFAMode();
    };

    const handleTOTPRegistrationCodeSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const code = verificationCode.join('');
        if (code.length !== codeLength) {
            setVerificationError((prevState) => ({...verificationError, invalidLength: true}));
            return;
        }

        setProcessing(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({verificationCode: verificationCode.join('')}),
        };

        const res = await fetch(`${settings.apiURL}/api/2FA/TOTP/register/verification`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, codeVerificationFailed: true}))
        );
        setProcessing(false);

        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            setVerificationError((prev) => ({
                ...prev,
                invalidCode: dataRes!!.error === 'Invalid code',
                codeVerificationFailed: dataRes!!.error === 'Two factor authentication failure',
            }));
            return;
        }

        setVerificationError({});
        updateTwoFAMode();
    };

    const handleTOTPCodeSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const code = verificationCode.join('');
        if (code.length !== codeLength) {
            setVerificationError((prevState) => ({...verificationError, invalidLength: true}));
            return;
        }

        setProcessing(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({verificationCode: verificationCode.join('')}),
        };

        const res = await fetch(`${settings.apiURL}/api/2FA/TOTP/verify`, requestOptions).catch(() =>
            setVerificationError((prev) => ({...prev, codeVerificationFailed: true}))
        );
        setProcessing(false);

        if (!res) {
            return;
        }

        const dataRes = await res!!.json();
        if (!res!!.ok) {
            setVerificationError((prev) => ({
                ...prev,
                invalidCode: dataRes!!.error === 'Invalid code',
                codeVerificationFailed: dataRes!!.error === 'Two factor authentication failure',
            }));
            return;
        }

        setVerificationError({});
        updateTwoFAMode();
    };

    const updateTwoFAMode = async () => {
        setProcessing(true);

        const resultsOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({...user, twoFAMode: mode}),
        };

        const resultsRes = await fetch(`${settings.apiURL}/api/user`, resultsOptions);
        setProcessing(false);
        if (!resultsRes.ok) {
            setVerificationError((prev) => ({
                ...prev,
                twoFAUpdateFail: true,
            }));
            return;
        }

        updateUserInfo({...user, twoFAMode: mode});
        setDialogType(DialogType.None);
    };

    const onVerificationCodeKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        switch (e.key) {
            case 'ArrowLeft':
                if (index != 0) {
                    codeInputRefs.current[index - 1].focus();
                }
                break;
            case 'ArrowRight':
                if (index != 5) {
                    codeInputRefs.current[index + 1].focus();
                }
                break;
            case 'Backspace':
                if (index !== 0 && verificationCode[index] === '') {
                    codeInputRefs.current[index - 1].focus();
                }
                setVerificationCode((oldVerificationCode) => {
                    const newVerificationCode = [...oldVerificationCode];
                    newVerificationCode[index] = '';
                    return newVerificationCode;
                });
                e.preventDefault();
                break;
            case 'Delete':
                setVerificationCode((oldVerificationCode) => {
                    const newVerificationCode = [...oldVerificationCode];
                    newVerificationCode[index] = '';
                    return newVerificationCode;
                });
                e.preventDefault();
                break;

            default:
                if (e.key.length === 1) {
                    setVerificationCode((oldVerificationCode) => {
                        const newVerificationCode = [...oldVerificationCode];
                        newVerificationCode[index] = e.key;
                        return newVerificationCode;
                    });
                    if (index !== 5) {
                        codeInputRefs.current[index + 1].focus();
                    }
                    e.preventDefault();
                }
                break;
        }
    };

    const cancelEnabling2FA = () => {
        setDialogType(DialogType.None);
        setMode(user.twoFAMode);
        setVerificationError({});
    };

    const cancelDisabling2FA = () => {
        setDialogType(DialogType.None);
        setMode(user.twoFAMode);
        setVerificationError({});
    };

    return (
        <MainContainer>
            {isUserVerified ? (
                <>
                    <InfoContainer>
                        <StyledIcon className="fa-light fa-shield-keyhole" />
                        <Info>
                            2-Step Verification significantly increases the security of your account. Even if somebody
                            guesses your password, they won’t be able to access your account without a temporary code
                            sent to your saved devices.
                        </Info>
                    </InfoContainer>
                    <div style={{width: '100%'}}>
                        <Header>Verification Methods</Header>
                        <Info>Choose how you would like to receive your 2-Step Verification codes</Info>
                        <GroupContainer>
                            <HorizontalContainer>
                                <MethodIcon className="fa-solid fa-message-sms" />
                                <div>Text message</div>
                                <div style={{marginLeft: 'auto'}}>
                                    <SwitchInput
                                        checked={mode === VerificationMode.SMS}
                                        onChange={(val) => {
                                            setVerificationCode(Array(codeLength).fill(''));
                                            if (!val) {
                                                setMode(VerificationMode.None);
                                                setDialogType(DialogType.Disable2FAWarning);
                                            } else {
                                                setMode(VerificationMode.SMS);
                                                if (user.twoFAMode !== null && user.twoFAMode !== DialogType.None) {
                                                    setDialogType(DialogType.Multiple2FAWarning);
                                                } else {
                                                    sendVerificationCodeBySMS();
                                                    setDialogType(DialogType.Verification2FAEnable);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </HorizontalContainer>
                            <div>Codes will be sent to {user.phone}</div>
                            <div>Change phone number</div>
                        </GroupContainer>
                        <GroupContainer>
                            <HorizontalContainer>
                                <MethodIcon className="fa-solid fa-envelope" />
                                <div>Email</div>
                                <div style={{marginLeft: 'auto'}}>
                                    <SwitchInput
                                        checked={mode === VerificationMode.EMAIL}
                                        onChange={(val) => {
                                            setVerificationCode(Array(codeLength).fill(''));
                                            if (!val) {
                                                setMode(VerificationMode.None);
                                                setDialogType(DialogType.Disable2FAWarning);
                                            } else {
                                                setMode(VerificationMode.EMAIL);
                                                if (user.twoFAMode !== null && user.twoFAMode !== DialogType.None) {
                                                    setDialogType(DialogType.Multiple2FAWarning);
                                                } else {
                                                    sendVerificationCodeByEmail();
                                                    setDialogType(DialogType.Verification2FAEnable);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </HorizontalContainer>
                            <div>Codes will be sent to {user.email}</div>
                            <div>Change email address</div>
                        </GroupContainer>
                        <GroupContainer>
                            <HorizontalContainer>
                                <MethodIcon className="fa-solid fa-mobile" />
                                <div>Authentication App</div>
                                <div style={{marginLeft: 'auto'}}>
                                    <SwitchInput
                                        checked={mode === VerificationMode.TOTP}
                                        onChange={(val) => {
                                            setVerificationCode(Array(codeLength).fill(''));
                                            if (!val) {
                                                setMode(VerificationMode.None);
                                                setDialogType(DialogType.Disable2FAWarning);
                                            } else {
                                                setMode(VerificationMode.TOTP);
                                                if (user.twoFAMode !== null && user.twoFAMode !== DialogType.None) {
                                                    setDialogType(DialogType.Multiple2FAWarning);
                                                } else {
                                                    setDialogType(DialogType.TOTP2FAEnable);
                                                    registerTOTP();
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </HorizontalContainer>
                        </GroupContainer>
                        <CancelContainer>
                            <Cancel className="ParagraphNormalRegular" onClick={onCancel}>
                                Cancel
                            </Cancel>
                        </CancelContainer>
                    </div>
                </>
            ) : (
                <>
                    <KeyIconContainer>
                        <KeyIcon className="fa-light fa-key" />
                    </KeyIconContainer>
                    <Info style={{fontSize: '1rem', color: 'var(--text-400)'}}>
                        Please enter your password to continue to these settings
                    </Info>
                    <div style={{width: '100%'}}>
                        <Error message={errorPassword} />
                        <FieldName error={errorPassword !== ''}>
                            <FieldNameText>Password</FieldNameText>
                            <LeftIcon className="fa-solid fa-key" error={errorPassword ? 1 : 0} />
                            <RightIcon
                                className={displayPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
                                onClick={() => setDisplayPassword(!displayPassword)}
                            />
                            <FormInput
                                error={errorPassword !== ''}
                                type={displayPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FieldName>
                        <div style={{position: 'relative'}}>
                            {verifying && <Spinner left="28%" />}
                            <Button
                                bgColor="var(--primary-700)"
                                hoverBGColor="var(--primary-300)"
                                activeBGColor="var(--primary-50)"
                                onClick={verifyPassword}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <Dialog open={dialogType === DialogType.Multiple2FAWarning}>
                <div
                    style={{
                        width: '332px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '24px',
                        padding: '24px',
                    }}>
                    <SmallKeyIcon className="fa-light fa-diamond-exclamation" />
                    <span style={{fontFamily: 'Nunito', fontSize: '1rem', fontWeight: '600', color: 'var(--text-600'}}>
                        You can only have one verification method enabled at a time
                    </span>
                    <span style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                        Enabling {mode} verification will disable {user.twoFAMode} verification.
                    </span>
                    <Button
                        bgColor="var(--primary-500)"
                        activeBGColor="var(--primary-300)"
                        hoverBGColor="var(--primary-50)"
                        onClick={() => {
                            if (mode === VerificationMode.SMS) {
                                sendVerificationCodeBySMS();
                                setDialogType(DialogType.Verification2FAEnable);
                            } else if (mode === VerificationMode.EMAIL) {
                                sendVerificationCodeByEmail();
                                setDialogType(DialogType.Verification2FAEnable);
                            } else if (mode === VerificationMode.TOTP) {
                                registerTOTP();
                                setDialogType(DialogType.TOTP2FAEnable);
                            }
                        }}
                        style={{marginTop: '24px'}}>
                        Continue
                    </Button>
                    <Button
                        bgColor="var(--neutral-500)"
                        activeBGColor="var(--neutral-400)"
                        hoverBGColor="var(--neutral-300)"
                        onClick={cancelEnabling2FA}
                        style={{marginTop: '12px'}}>
                        Cancel
                    </Button>
                </div>
            </Dialog>
            <Dialog open={dialogType === DialogType.Disable2FAWarning}>
                <div
                    style={{
                        width: '332px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '24px',
                        padding: '24px',
                    }}>
                    <SmallKeyIcon className="fa-light fa-diamond-exclamation" />
                    <span style={{fontFamily: 'Nunito', fontSize: '1rem', fontWeight: '600', color: 'var(--text-600'}}>
                        Disable 2-Step Verification
                    </span>
                    <span style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                        2-Step Verification significantly increases the security of your account. Are you sure you would
                        like to disable {user.twoFAMode} verification?
                    </span>
                    <Button
                        bgColor="var(--primary-500)"
                        activeBGColor="var(--primary-300)"
                        hoverBGColor="var(--primary-50)"
                        onClick={() => {
                            if (user.twoFAMode === VerificationMode.SMS) {
                                sendVerificationCodeBySMS();
                            } else if (user.twoFAMode === VerificationMode.EMAIL) {
                                sendVerificationCodeByEmail();
                            }
                            setDialogType(DialogType.Verification2FADisable);
                        }}
                        style={{marginTop: '24px'}}>
                        Continue
                    </Button>
                    <Button
                        bgColor="var(--neutral-500)"
                        activeBGColor="var(--neutral-400)"
                        hoverBGColor="var(--neutral-300)"
                        onClick={cancelDisabling2FA}
                        style={{marginTop: '12px'}}>
                        Cancel
                    </Button>
                </div>
            </Dialog>
            <Dialog open={dialogType === DialogType.Verification2FAEnable}>
                <div
                    style={{
                        width: '332px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '24px',
                        padding: '24px',
                    }}>
                    <SmallKeyIcon className="fa-light fa-diamond-exclamation" />
                    <span style={{fontFamily: 'Nunito', fontSize: '1rem', fontWeight: '600', color: 'var(--text-600'}}>
                        Enable 2-Step Verification
                    </span>
                    <span style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                        Please enter your verification code to enable {mode} verification.
                    </span>
                    <Error
                        message={
                            verificationError!!.codeSendFailed
                                ? 'Failed to send validation code'
                                : verificationError!!.invalidCode
                                ? 'Invalid code'
                                : verificationError!!.codeVerificationFailed
                                ? 'Failed when verifying the code'
                                : 'Failed when updating the 2-Step Authentication mode'
                        }
                    />
                    <Form
                        onSubmit={mode === VerificationMode.SMS ? handleSMSCodeSubmit : handleEmailCodeSubmit}
                        style={{position: 'relative'}}>
                        {processing && <OverlayingSpinner color="#3c99dc" size="50px" left="42%" />}
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {Array(codeLength)
                                .fill(null)
                                .map((_, index) => (
                                    <DigitContainer key={index}>
                                        <DigitInput
                                            type="text"
                                            name={`code-${index}`}
                                            value={verificationCode[index]}
                                            onKeyDown={(e) => onVerificationCodeKeyPress(e, index)}
                                            ref={(el) => (codeInputRefs.current[index] = el)}
                                        />
                                    </DigitContainer>
                                ))}
                        </div>
                        <Button
                            bgColor="var(--primary-500)"
                            activeBGColor="var(--primary-300)"
                            hoverBGColor="var(--primary-50)"
                            type="submit"
                            style={{marginTop: '24px'}}>
                            Enable verification method
                        </Button>
                        <Button
                            bgColor="var(--neutral-500)"
                            activeBGColor="var(--neutral-400)"
                            hoverBGColor="var(--neutral-300)"
                            onClick={cancelDisabling2FA}
                            style={{marginTop: '12px'}}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </Dialog>
            <Dialog open={dialogType === DialogType.Verification2FADisable}>
                <div
                    style={{
                        width: '332px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        padding: '24px',
                    }}>
                    <SmallKeyIcon className="fa-light fa-diamond-exclamation" />
                    <span style={{fontFamily: 'Nunito', fontSize: '1rem', fontWeight: '600', color: 'var(--text-600'}}>
                        Disable 2-Step Verification
                    </span>
                    <span style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                        Please enter your verification code to disable {user.twoFAMode} verification.
                    </span>
                    <Error
                        message={
                            verificationError!!.codeSendFailed
                                ? 'Failed to send validation code'
                                : verificationError!!.invalidCode
                                ? 'Invalid code'
                                : verificationError!!.codeVerificationFailed
                                ? 'Failed when verifying the code'
                                : 'Failed when updating the 2-Step Authentication mode'
                        }
                    />
                    <Form
                        onSubmit={
                            user.twoFAMode === VerificationMode.SMS
                                ? handleSMSCodeSubmit
                                : user.twoFAMode === VerificationMode.EMAIL
                                ? handleEmailCodeSubmit
                                : handleTOTPCodeSubmit
                        }
                        style={{position: 'relative'}}>
                        {processing && <OverlayingSpinner color="#3c99dc" size="50px" left="42%" />}
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {Array(codeLength)
                                .fill(null)
                                .map((_, index) => (
                                    <DigitContainer key={index}>
                                        <DigitInput
                                            type="text"
                                            name={`code-${index}`}
                                            value={verificationCode[index]}
                                            onKeyDown={(e) => onVerificationCodeKeyPress(e, index)}
                                            ref={(el) => (codeInputRefs.current[index] = el)}
                                        />
                                    </DigitContainer>
                                ))}
                        </div>
                        <Button
                            bgColor="var(--primary-500)"
                            activeBGColor="var(--primary-300)"
                            hoverBGColor="var(--primary-50)"
                            type="submit"
                            style={{marginTop: '24px'}}>
                            Disable verification method
                        </Button>
                        <Button
                            bgColor="var(--neutral-500)"
                            activeBGColor="var(--neutral-400)"
                            hoverBGColor="var(--neutral-300)"
                            onClick={cancelDisabling2FA}
                            style={{marginTop: '12px'}}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </Dialog>
            <Dialog open={dialogType === DialogType.TOTP2FAEnable}>
                <div
                    style={{
                        width: '332px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px',
                        padding: '24px',
                    }}>
                    <SmallKeyIcon className="fa-light fa-key" />
                    <span style={{fontFamily: 'Nunito', fontSize: '1rem', fontWeight: '600', color: 'var(--text-600'}}>
                        Activate 2-Step Verification
                    </span>
                    <Error message={totpRegistrationError} />
                    <div>
                        <QRCode value={totpRegistrationDetails.uri} />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '4px 8px',
                            borderWidth: '1px',
                            borderRadius: '8px',
                            boxShadow: '0 1px 4px 0 rgba(0,0,0,0.15)',
                            backgroundColor: 'var(--neutral-0)',
                        }}>
                        <span
                            style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                            {totpRegistrationDetails.key}
                        </span>
                        <LinkButton
                            onClick={() => {
                                navigator.clipboard.writeText('test copy');
                            }}>
                            COPY
                        </LinkButton>
                    </div>
                    <span style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                        Open the authentication app and scan the QR code, or manually enter the 2FA key below the image.
                    </span>
                    <HorizontalLine />
                    <span style={{fontFamily: 'Nunito', alignSelf: 'stretch', flexGrow: 0, color: 'var(--text-600)'}}>
                        Enter the six digit code generated by the app
                    </span>
                    <Error
                        message={
                            verificationError!!.codeSendFailed
                                ? 'Failed to send validation code'
                                : verificationError!!.invalidCode
                                ? 'Invalid code'
                                : verificationError!!.codeVerificationFailed
                                ? 'Failed when verifying the code'
                                : 'Failed when updating the 2-Step Authentication mode'
                        }
                    />
                    <Form onSubmit={handleTOTPRegistrationCodeSubmit} style={{position: 'relative'}}>
                        {processing && <OverlayingSpinner color="#3c99dc" size="50px" left="42%" />}
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {Array(codeLength)
                                .fill(null)
                                .map((_, index) => (
                                    <DigitContainer key={index}>
                                        <DigitInput
                                            type="text"
                                            name={`code-${index}`}
                                            value={verificationCode[index]}
                                            onKeyDown={(e) => onVerificationCodeKeyPress(e, index)}
                                            ref={(el) => (codeInputRefs.current[index] = el)}
                                        />
                                    </DigitContainer>
                                ))}
                        </div>
                        <Button
                            bgColor="var(--primary-500)"
                            activeBGColor="var(--primary-300)"
                            hoverBGColor="var(--primary-50)"
                            type="submit"
                            style={{marginTop: '24px'}}>
                            Activate verification method
                        </Button>
                        <Button
                            bgColor="var(--neutral-500)"
                            activeBGColor="var(--neutral-400)"
                            hoverBGColor="var(--neutral-300)"
                            onClick={cancelEnabling2FA}
                            style={{marginTop: '12px'}}>
                            Cancel
                        </Button>
                    </Form>
                </div>
            </Dialog>
        </MainContainer>
    );
};

export default TwoStepVerificationSettings;
