import React from 'react';

function Footer(props) {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <div id="registerkit-navigation">
                    {props.showRestart ? (
                        <button
                            id="prev-button"
                            className={`nav-button nav-button-full`}
                            onClick={() => {
                                props.restart();
                            }}>
                            Back to Dashboard
                        </button>
                    ) : (
                        <>
                            {props.showPrev && (
                                <button
                                    id="prev-button"
                                    className={`nav-button ${!props.showNext ? 'nav-button-full' : ''}`}
                                    onClick={() => props.previous()}>
                                    &laquo; Previous
                                </button>
                            )}
                            {props.showNext && (
                                <button
                                    id="next-button"
                                    className={`nav-button ${!props.showPrev ? 'nav-button-full' : ''}`}
                                    onClick={() => props.next()}>
                                    Next &raquo;
                                </button>
                            )}{' '}
                        </>
                    )}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
