import settings from '../settings';
import React, {useState, ChangeEvent} from 'react';
import {useLocation} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import TopBar from '../components/TopBar';
import Spinner from '../components/Spinner';
import PrimaryButton from '../components/PrimaryButton';
import Error from '../components/Error';
// @ts-ignore
import ConsentForm from '../assets/documents/COVID-insurance-consent&CoA.pdf';
import 'event-submitter-polyfill';
import {States, Ethnicities} from '../services/constants';
import axios from 'axios';
import {
    Background,
    PageContainer,
    Form,
    FormContainer,
    FormInput,
    FormSection,
    Image,
    ImageContainer,
    Paragraph,
    Icon,
    IconParagraph,
    IdIcon,
    AdIcon,
    LeftIcon,
    Select,
    ShopLink,
    Spacer,
    SquareInput,
    StyledDatePicker,
    SubmitContainer,
    SurveyContainer,
    SurveyQuestion,
    Symptom,
    SymptomCheckLabel,
    SymptomInputContainer,
    SymptomLabel,
    Symptoms,
    ExternalLink,
    RadioInput,
    Header,
    BackButton,
    Advertisement,
    CenterHeader,
    Label,
    LabelText,
    DualContainer,
    DualLabel,
    FullInput,
    Optional,
    OptionalContainer,
    PhotoSubmit,
    PhotoSubmitLabel,
    PhotoUploadContainer,
    PriorityBanner,
    AttestationName,
    AcuityFormSection,
    ConfidentialityContainer,
    ConfidentialityLabel,
    InternalLink,
} from './styles/Insurance';

interface Props {
    hasScheduler: boolean;
}

const Insurance: React.FC<Props> = ({hasScheduler}) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref') || '';

    const validTests: {[index: string]: boolean} = {
        pcr: true,
        'rapid-antigen': true,
    };

    const initialError = {
        contact: '',
        symptoms: '',
        singleSymptom: '',
        test: '',
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        ethnicity: '',
        email: '',
        confirmEmail: '',
        phone: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        license: '',
        residenceState: '',
        insuranceName: '',
        subscriberId: '',
        claimsAddress: '',
        claimsCity: '',
        claimsState: '',
        claimsZip: '',
        social: '',
        confidentiality: '',
        message: '',
    };
    const initialSymptoms = {
        fever: false,
        chills: false,
        cough: false,
        shortnessOfBreath: false,
        fatigue: false,
        muscleOrBodyAches: false,
        headache: false,
        lossOfTasteOrSmell: false,
        soreThroat: false,
        nausea: false,
        vomiting: false,
        diarrhea: false,
        other: false,
    };
    const initialPhotos = {
        frontInsurance: null,
        backInsurance: null,
        frontId: null,
    };
    const initialState = {
        contact: '',
        symptoms: '',
        fever: false,
        chills: false,
        cough: false,
        shortnessOfBreath: false,
        fatigue: false,
        muscleOrBodyAches: false,
        headache: false,
        lossOfTasteOrSmell: false,
        soreThroat: false,
        nausea: false,
        vomiting: false,
        diarrhea: false,
        other: false,
        test: validTests[ref] ? ref : '',
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        ethnicity: '',
        email: '',
        confirmEmail: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        license: '',
        residenceState: '',
        insuranceName: '',
        subscriberId: '',
        groupId: '',
        claimsAddress: '',
        claimsCity: '',
        claimsState: '',
        claimsZip: '',
        social: '',
        insured: false,
        confidentiality: false,
    };
    const [state, setState] = useState<{[x: string]: any}>(initialState);
    const [photos, setPhotos] = useState<{[x: string]: any}>(initialPhotos);
    const [error, setError] = useState<{[x: string]: any}>(initialError);
    const [step, setStep] = useState(hasScheduler ? 0 : 1);
    const [submitting, setSubmitting] = useState(false);
    const states: any = {
        AL: 'Alabama',
        AK: 'Alaska',
        AX: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => {
        setError(initialError);
        setStep(step - 1);
    };
    // const reset = () => {
    //     setError(initialError);
    //     setState(initialState);
    //     setStep(0);
    // }

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        if (name === 'contact' || name === 'symptoms') {
            if (error.message && value === 'Yes') {
                setError((prevError) => ({...prevError, contact: false, symptoms: false}));
            }
        }

        if (name === 'individualSymptom') {
            setState((prevState) => ({...prevState, [event.target.value]: value === 'true' ? true : false}));
            setError((prevError) => ({...prevError, singleSymptom: false}));
            return;
        }

        if (name === 'social') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 7) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 11)) {
                return;
            }
        }

        if (name === 'phone') {
            const digitReg = /^[0-9]$/;

            if (value.length === 4 || value.length === 8) {
                if (value[value.length - 1] !== '-') {
                    value = value.substring(0, value.length - 1) + '-' + value[value.length - 1];
                } else {
                    value = value.substring(0, value.length - 1);
                }
            }

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 12)) {
                return;
            }
        }

        if (name === 'zip' || name === 'claimsZip') {
            const digitReg = /^[0-9]$/;

            if (value !== '' && (!digitReg.test(value[value.length - 1]) || value.length > 5)) {
                return;
            }
        }

        setState((prevState) => ({...prevState, [name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
    };

    const handlePhotoChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const file = event.target.files ? event.target.files[0] : null;
        const maxFileSize = 10 * 1024 * 1024; // 10MB
        const size = file ? file.size : 0;
        const type = file ? file.type : '';

        if (size > maxFileSize) {
            setError((prevError) => ({
                ...prevError,
                message: 'Your selected photo is too large.',
            }));
            return;
        }

        if (type !== 'image/jpg' && type !== 'image/jpeg' && type !== 'image/png' && type !== 'image/heic') {
            setError((prevError) => ({
                ...prevError,
                message: `Your selected photo's file type is not supported.`,
            }));
            return;
        }

        const reader = new FileReader();
        reader.addEventListener(
            'load',
            function () {
                setPhotos((prevPhotos) => ({...prevPhotos, [name]: reader.result}));
                setError((prevError) => ({...prevError, message: ''}));
            },
            false
        );

        reader.readAsDataURL(file!!);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (step === 1) {
            setError((prevError) => ({
                ...prevError,
                contact: state.contact === '',
                symptoms: state.symptoms === '',
                message: state.contact === '' || state.symptoms === '' ? 'Please fill in required questions' : '',
            }));

            if (state.contact === '' || state.symptoms === '') {
                // window.scrollTo({
                //     top: 0,
                //     behavior: 'smooth'
                // });
                return;
            }

            setError(initialError);

            if (state.symptoms === 'Yes') {
                setStep(2);
            } else if (state.contact === 'No' && state.symptoms === 'No') {
                setState((prevState) => ({...prevState, ...initialSymptoms}));
                setStep(3);
            } else {
                setState((prevState) => ({...prevState, ...initialSymptoms}));
                setStep(4);
            }
            return;
        }

        if (step === 2) {
            let selected = false;

            for (const key in initialSymptoms) {
                if (state[key]) {
                    selected = true;
                    break;
                }
            }

            if (!selected) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                setError((prevError) => ({
                    ...prevError,
                    singleSymptom: true,
                    message: 'Please select at least one symptom',
                }));
                return;
            }

            setError(initialError);
            setStep(4);
            return;
        }

        if (step === 4) {
            setError((prevError) => ({
                ...prevError,
                test: state.test === '',
                message: state.test === '' ? 'Please fill in required fields' : '',
            }));

            if (state.test === '') {
                return;
            }
        }

        if (step === 5) {
            const submitter = event.nativeEvent.submitter.value;

            if (submitter === 'Yes, I have health insurance') {
                setState((prevState) => ({
                    ...prevState,
                    insured: true,
                    license: '',
                    residenceState: '',
                    social: '',
                }));
            } else if (submitter === 'No, I am uninsured') {
                setState((prevState) => ({
                    ...prevState,
                    insured: false,
                    insuranceName: '',
                    subscriberId: '',
                    groupId: '',
                    claimsAddress: '',
                    claimsCity: '',
                    claimsState: '',
                    claimsZip: '',
                    social: '',
                }));
            }
        }

        if (step === 6) {
            setError((prevError) => ({
                ...prevError,
                firstName: state.firstName === '',
                lastName: state.lastName === '',
                birthday: state.birthday === '',
                gender: state.gender === '',
                ethnicity: state.ethnicity === '',
                message:
                    state.firstName === '' ||
                    state.lastName === '' ||
                    state.birthday === '' ||
                    state.gender === '' ||
                    state.ethnicity === ''
                        ? 'Please fill in required fields'
                        : '',
            }));

            if (
                state.firstName === '' ||
                state.lastName === '' ||
                state.birthday === '' ||
                state.gender === '' ||
                state.ethnicity === ''
            ) {
                return;
            }
        }

        if (step === 7) {
            const emailReg =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const phoneReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

            setError((prevError) => ({
                ...prevError,
                email: !emailReg.test(state.email),
                confirmEmail: !emailReg.test(state.confirmEmail) || state.email !== state.confirmEmail,
                phone: !phoneReg.test(state.phone),
                message:
                    !emailReg.test(state.email) || !emailReg.test(state.confirmEmail) || !phoneReg.test(state.phone)
                        ? 'Please fill in required fields'
                        : state.email !== state.confirmEmail
                        ? 'Emails must match'
                        : '',
            }));

            if (
                !emailReg.test(state.email) ||
                !emailReg.test(state.confirmEmail) ||
                !phoneReg.test(state.phone) ||
                state.email !== state.confirmEmail
            ) {
                return;
            }
        }

        if (step === 8) {
            setError((prevError) => ({
                ...prevError,
                address1: state.address1 === '',
                city: state.city === '',
                state: state.state === '',
                zip: state.zip === '',
                message:
                    state.address1 === '' || state.city === '' || state.state === '' || state.zip === ''
                        ? 'Please fill in required fields'
                        : '',
            }));

            if (state.address1 === '' || state.city === '' || state.state === '' || state.zip === '') {
                return;
            }

            if (!state.insured) {
                setError(initialError);
                setStep(12);
                return;
            }
        }

        if (step === 9) {
            setError((prevError) => ({
                ...prevError,
                insuranceName: state.insuranceName === '',
                subscriberId: state.subscriberId === '',
                message:
                    state.insuranceName === '' || state.subscriberId === '' ? 'Please fill in required fields' : '',
            }));

            if (state.insuranceName === '' || state.subscriberId === '') {
                return;
            }

            setError(initialError);
            setStep(11);
            return;
        }

        if (step === 10) {
            setError((prevError) => ({
                ...prevError,
                claimsAddress: state.claimsAddress === '',
                claimsCity: state.claimsCity === '',
                claimsState: state.claimsState === '',
                claimsZip: state.claimsZip === '',
                message:
                    state.claimsAddress === '' ||
                    state.claimsCity === '' ||
                    state.claimsState === '' ||
                    state.claimsZip === ''
                        ? 'Please fill in required fields'
                        : '',
            }));

            if (
                state.claimsAddress === '' ||
                state.claimsCity === '' ||
                state.claimsState === '' ||
                state.claimsZip === ''
            ) {
                return;
            }
        }

        if (step === 11) {
            setError((prevError) => ({
                ...prevError,
                message: !photos.frontInsurance || !photos.backInsurance ? 'Please upload all required photos' : '',
            }));

            if (!photos.frontInsurance || !photos.backInsurance) {
                return;
            }

            setError(initialError);
            setStep(15);
            return;
        }

        if (step === 12) {
            const submitter = event.nativeEvent.submitter.value;

            if (submitter === 'Social Security Number') {
                setStep(13);
                return;
            } else if (submitter === `Driver's License Number`) {
                setStep(14);
                return;
            }

            return;
        }

        if (step === 13) {
            if (submitting) {
                return;
            } else {
                const socialReg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{2}[-\s.]?[0-9]{4}$/;

                setError((prevError) => ({
                    ...prevError,
                    social: !socialReg.test(state.social),
                    residenceState: state.residenceState === '',
                    message:
                        !socialReg.test(state.social) || state.residenceState === ''
                            ? 'Please fill in required fields'
                            : '',
                }));

                if (!socialReg.test(state.social) || state.residenceState === '') {
                    return;
                }

                if (hasScheduler && state.insured) {
                    setSubmitting(true);

                    const claim = {
                        ...state,
                        ...photos,
                    };
                    delete claim.confirmEmail;
                    delete claim.confidentiality;

                    const requestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        data: JSON.stringify(claim),
                    };

                    const res = await axios(`${settings.apiURL}/api/insurance`, requestOptions);
                    setSubmitting(false);
                    if (res.status !== 200) {
                        const message = res.data.error;
                        setError((prevError) => ({...prevError, message: message}));
                        return;
                    }

                    setError(initialError);
                    setStep(17);
                    return;
                }

                if (!hasScheduler && state.insured) {
                    setError(initialError);
                    setStep(18);
                    return;
                }

                setState((prevState) => ({...prevState, license: ''}));
                setPhotos(initialPhotos);
                setError(initialError);
                setStep(16);
                return;
            }
        }

        if (step === 14) {
            setError((prevError) => ({
                ...prevError,
                license: state.license === '',
                residenceState: state.residenceState === '',
                message: state.license === '' || state.residenceState === '' ? `Please fill in required fields` : '',
            }));

            if (state.license === '' || state.residenceState === '') {
                return;
            }

            setState((prevState) => ({...prevState, social: ''}));
            setPhotos((prevPhotos) => ({...prevPhotos, frontInsurance: null, backInsurance: null}));
        }

        if (step === 15) {
            if (submitting) {
                return;
            } else {
                setError((prevError) => ({
                    ...prevError,
                    message: !photos.frontId ? 'Please upload required photo' : '',
                }));

                if (!photos.frontId) {
                    return;
                }

                if (hasScheduler && state.insured) {
                    setSubmitting(true);

                    const claim = {
                        ...state,
                        ...photos,
                    };
                    delete claim.confirmEmail;
                    delete claim.confidentiality;

                    const requestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        data: JSON.stringify(claim),
                    };

                    const res = await axios(`${settings.apiURL}/api/insurance`, requestOptions);
                    setSubmitting(false);
                    if (res.status !== 200) {
                        const message = res.data.error;
                        setError((prevError) => ({...prevError, message: message}));
                        return;
                    }

                    setError(initialError);
                    setStep(17);
                    return;
                }

                if (!hasScheduler && state.insured) {
                    setError(initialError);
                    setStep(18);
                    return;
                }
            }
        }

        if (step === 16) {
            if (submitting) {
                return;
            } else {
                if (hasScheduler) {
                    setSubmitting(true);

                    const claim = {
                        ...state,
                        ...photos,
                    };
                    delete claim.confirmEmail;
                    delete claim.confidentiality;

                    const requestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        data: JSON.stringify(claim),
                    };

                    const res = await axios(`${settings.apiURL}/api/insurance`, requestOptions);
                    setSubmitting(false);
                    if (res.status !== 200) {
                        const message = res.data.error;
                        setError((prevError) => ({...prevError, message: message}));
                        return;
                    }
                }

                setError(initialError);
                setStep(hasScheduler ? 17 : 18);
                return;
            }
        }

        if (step === 17) {
            if (submitting) {
                return;
            } else {
                setSubmitting(true);

                const req = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    data: JSON.stringify({email: state.email}),
                };

                const res = await axios(`${settings.apiURL}/api/appointments/recent`, req);
                setSubmitting(false);

                if (res.status !== 200) {
                    const message = `You have not yet finished scheduling your appointment. To finalize your appointment you must scroll down and click "Complete Appointment" after you choose a location and time.`;
                    setError((prevError) => ({...prevError, message: message}));
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    return;
                }
            }
        }

        if (step === 18) {
            if (submitting) {
                return;
            } else {
                setError((prevError) => ({
                    ...prevError,
                    confidentiality: !state.confidentiality,
                }));

                if (!state.confidentiality) {
                    return;
                }

                setSubmitting(true);

                const claim = {
                    ...state,
                    ...photos,
                };
                delete claim.confirmEmail;
                delete claim.confidentiality;

                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    data: JSON.stringify(claim),
                };

                const res = await axios(`${settings.apiURL}/api/insurance`, requestOptions);
                if (res.status !== 200) {
                    setSubmitting(false);
                    const message = res.data.error;
                    setError((prevError) => ({...prevError, message: message}));
                    return;
                }

                const productLine =
                    state.test === 'pcr'
                        ? 'COVID-19 PCR'
                        : state.test === 'rapid-antigen'
                        ? 'Rapid COVID-19 Antigen'
                        : 'COVID-19 PCR';

                const specimenType = 'Nasal Swab';

                const panels = state.test === 'pcr' ? ['001'] : state.test === 'rapid-antigen' ? ['RC19A'] : ['001'];

                const diagCodes = ['Z1152'];

                if (state.fever || state.other) {
                    diagCodes.push('R509');
                }
                if (state.chills) {
                    diagCodes.push('R6883');
                }
                if (state.cough) {
                    diagCodes.push('R059');
                }
                if (state.shortnessOfBreath) {
                    diagCodes.push('R0602');
                }
                if (state.fatigue) {
                    diagCodes.push('R5383');
                }
                if (state.muscleOrBodyAches) {
                    diagCodes.push('R52');
                }
                if (state.headache) {
                    diagCodes.push('R519');
                }
                if (state.lossOfTasteOrSmell) {
                    diagCodes.push('R439');
                }
                if (state.soreThroat) {
                    diagCodes.push('R070');
                }
                if (state.nausea) {
                    diagCodes.push('R110');
                }
                if (state.vomiting) {
                    diagCodes.push('R1111');
                }
                if (state.diarrhea) {
                    diagCodes.push('K591');
                }

                const documents: any = [];

                if (photos.frontInsurance) {
                    documents.push({
                        fileName: 'Front of Insurance Card',
                        documentCategoryName: 'Insurance Front',
                        base64Content: photos.frontInsurance.split('base64,')[1],
                    });
                }
                if (photos.backInsurance) {
                    documents.push({
                        fileName: 'Back of Insurance Card',
                        documentCategoryName: 'Insurance Back',
                        base64Content: photos.backInsurance.split('base64,')[1],
                    });
                }
                if (photos.frontId) {
                    documents.push({
                        fileName: 'Front of ID Card',
                        documentCategoryName: 'Drivers License',
                        base64Content: photos.frontId.split('base64,')[1],
                    });
                }

                let order: any = {
                    patient: {
                        firstName: state.firstName,
                        lastName: state.lastName,
                        gender: state.gender[0].toUpperCase(),
                        // categoryId: state.company,
                        birthDate: state.birthday,
                        email: state.email,
                        race: state.ethnicity,
                        address: {
                            phone: state.phone,
                            address1: state.address1,
                            address2: state.address2,
                            city: state.city,
                            state: state.state,
                            zipCode: state.zip,
                            country: 'United States',
                        },
                    },
                    providerNPI: '1346818218',
                    lab: settings.env === 'production' ? 'Default' : 'Test',
                    productLine: productLine,
                    specimenType: specimenType,
                    panels: panels,
                    diagCodes: diagCodes,
                    sendEmail: false,
                    doNotCollect: true,
                    createNoUser: true,
                    surveyAnswers: [
                        {
                            questionId: 1,
                            answerText: state.symptoms,
                        },
                        {
                            questionId: 2,
                            answerText: state.contact,
                        },
                        // {
                        //     questionId: 3,
                        //     answerText: state.vaccinated
                        // },
                        // {
                        //     questionId: 4,
                        //     answerText: state.vaccinated
                        // }
                    ],
                    insurances: [
                        {
                            insuranceProviderName: state.insuranceName || 'CARES Act',
                            planNumber: state.subscriberId,
                            groupNumber: state.groupId,
                            relationship: 'Self',
                            coverageStatus: 'Primary',
                            insuranceType: 'Unknown',
                        },
                    ],
                    documents: documents,
                };

                if (state.social) {
                    order.patient.ssn = state.social;
                } else if (state.license) {
                    order.patient.extra = {
                        idNumber: state.license,
                    };
                }

                const orderRequestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    data: JSON.stringify(order),
                };

                const orderRes = await axios(`${settings.apiURL}/api/order/create`, orderRequestOptions);
                setSubmitting(false);
                if (orderRes.status !== 200) {
                    // const message = (await res.json()).error;
                    setError((prevError) => ({...prevError, message: 'Could not create order'}));
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                    return;
                }
            }
        }

        setError(initialError);
        nextStep();
    };

    return (
        <>
            <Background />
            <PageContainer>
                <TopBar />
                <FormContainer isSurvey={step === 1} isAcuity={step === 17}>
                    {step === 0 && (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <FormSection>
                                    <ImageContainer>
                                        <Image className="fa-light fa-truck-medical" />
                                    </ImageContainer>
                                </FormSection>
                                <FormSection style={{marginTop: '24px'}}>
                                    <Paragraph className="Heading06Bold">
                                        You may be eligible for a no-cost test through your insurance program
                                    </Paragraph>
                                </FormSection>
                                <FormSection>
                                    <Paragraph className="ParagraphNormalRegular">
                                        We'll ask you a few questions to determine your eligibility for no-cost testing
                                    </Paragraph>
                                    <IconParagraph>
                                        <Icon className="fa-solid fa-id-card" />
                                        <p className="ParagraphSmallRegular">
                                            Please have your insurance info handy - we’ll make it quick and easy!
                                        </p>
                                    </IconParagraph>
                                </FormSection>
                                <FormSection>
                                    <SubmitContainer>
                                        <PrimaryButton value="Continue" width="100%" />
                                    </SubmitContainer>
                                </FormSection>
                            </Form>
                        </>
                    )}

                    {step === 1 && (
                        <Form onSubmit={handleSubmit} style={{gap: '24px'}}>
                            <SurveyContainer>
                                <p>
                                    <strong>Note:</strong> You must select "Yes" to one or both of the following
                                    questions for your test to qualify for insurance. If you are testing for any other
                                    purpose and cannot answer yes to either of the following questions, your test will
                                    not qualify for insurance. If you would like to explore other payment options,
                                    please click the button below.
                                </p>
                                <ExternalLink
                                    href="https://mylabsdirect.as.me/schedule.php?appointmentType=17688104"
                                    target="_blank">
                                    Explore paid testing options
                                </ExternalLink>
                            </SurveyContainer>
                            <SurveyContainer>
                                <Error message={error.message} />
                                <SurveyQuestion
                                    error={error.symptoms}
                                    style={{
                                        marginTop: error.message ? '16px' : '0',
                                        marginBottom: '32px',
                                        transition: '0.2s',
                                    }}>
                                    Do you have any of the following symptoms: fever, cough, chills, shortness of breath
                                    or difficulty breathing, headache, sore throat, loss of taste or smell, muscle pain?
                                    <SymptomInputContainer>
                                        <RadioInput
                                            error={error.symptoms}
                                            id="yesSymptoms"
                                            type="radio"
                                            name="symptoms"
                                            value="Yes"
                                            checked={state.symptoms === 'Yes'}
                                            onChange={handleChange}
                                        />
                                        <SymptomCheckLabel htmlFor="yesSymptoms">Yes</SymptomCheckLabel>
                                        <RadioInput
                                            error={error.symptoms}
                                            id="noSymptoms"
                                            type="radio"
                                            name="symptoms"
                                            value="No"
                                            checked={state.symptoms === 'No'}
                                            onChange={handleChange}
                                        />
                                        <SymptomCheckLabel htmlFor="noSymptoms">No</SymptomCheckLabel>
                                    </SymptomInputContainer>
                                </SurveyQuestion>
                                <SurveyQuestion error={error.contact}>
                                    Have you been in contact with someone who tested positive with COVID-19 within the
                                    last 14 days?
                                    <SymptomInputContainer>
                                        <RadioInput
                                            error={error.contact}
                                            id="yesContact"
                                            type="radio"
                                            name="contact"
                                            value="Yes"
                                            checked={state.contact === 'Yes'}
                                            onChange={handleChange}
                                        />
                                        <SymptomCheckLabel htmlFor="yesContact">Yes</SymptomCheckLabel>
                                        <RadioInput
                                            error={error.contact}
                                            id="noContact"
                                            type="radio"
                                            name="contact"
                                            value="No"
                                            checked={state.contact === 'No'}
                                            onChange={handleChange}
                                        />
                                        <SymptomCheckLabel htmlFor="noContact">No</SymptomCheckLabel>
                                    </SymptomInputContainer>
                                </SurveyQuestion>
                            </SurveyContainer>
                            <SubmitContainer>
                                <PrimaryButton value="Continue" width="100%" />
                            </SubmitContainer>
                        </Form>
                    )}

                    {step === 2 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">How are you feeling?</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <ImageContainer>
                                    <Image className="fa-light fa-face-thermometer" />
                                </ImageContainer>
                                <Paragraph className="ParagraphNormalRegular">
                                    Select all of the symptoms you are experiencing
                                </Paragraph>
                                <Symptoms>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="fever"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="fever"
                                            checked={state.fever}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="fever">
                                            Fever
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="chills"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="chills"
                                            checked={state.chills}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="chills">
                                            Chills
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="cough"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="cough"
                                            checked={state.cough}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="cough">
                                            Cough
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="shortnessOfBreath"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="shortnessOfBreath"
                                            checked={state.shortnessOfBreath}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="shortnessOfBreath">
                                            Shortness of breath
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="fatigue"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="fatigue"
                                            checked={state.fatigue}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="fatigue">
                                            Fatigue
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="muscleOrBodyAches"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="muscleOrBodyAches"
                                            checked={state.muscleOrBodyAches}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="muscleOrBodyAches">
                                            Muscle or body aches
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="headache"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="headache"
                                            checked={state.headache}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="headache">
                                            Headache
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="lossOfTasteOrSmell"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="lossOfTasteOrSmell"
                                            checked={state.lossOfTasteOrSmell}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="lossOfTasteOrSmell">
                                            Loss of taste or smell
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="soreThroat"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="soreThroat"
                                            checked={state.soreThroat}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="soreThroat">
                                            Sore Throat
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="nausea"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="nausea"
                                            checked={state.nausea}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="nausea">
                                            Nausea
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="vomiting"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="vomiting"
                                            checked={state.vomiting}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="vomiting">
                                            Vomiting
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="diarrhea"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="diarrhea"
                                            checked={state.diarrhea}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="diarrhea">
                                            Diarrhea
                                        </SymptomLabel>
                                    </Symptom>
                                    <Symptom>
                                        <SquareInput
                                            error={error.singleSymptom}
                                            id="other"
                                            type="checkbox"
                                            name="individualSymptom"
                                            value="other"
                                            checked={state.other}
                                            onChange={handleChange}
                                        />
                                        <SymptomLabel error={error.singleSymptom} htmlFor="other">
                                            Other
                                        </SymptomLabel>
                                    </Symptom>
                                </Symptoms>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 3 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <ImageContainer>
                                    <Image className="fa-light fa-clipboard-user" />
                                </ImageContainer>
                            </FormSection>
                            <FormSection style={{marginTop: '24px'}}>
                                <Paragraph className="Heading06Bold">
                                    You don't qualify for no-cost insurance testing
                                </Paragraph>
                            </FormSection>
                            <FormSection>
                                <Advertisement>
                                    <AdIcon className="fa-light fa-truck-medical" />
                                    <Paragraph style={{margin: '16px 16px 16px 0'}}>
                                        You can still get tested quickly and conveniently with MyLabsDirect! Only costs
                                        $99.
                                    </Paragraph>
                                </Advertisement>
                            </FormSection>
                            <FormSection>
                                <ShopLink
                                    className="ParagraphLargeRegular"
                                    to={{pathname: 'https://mylabsdirect.as.me/schedule.php?appointmentType=17688104'}}
                                    target="_blank">
                                    Pay Cash
                                </ShopLink>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setState(initialState);
                                        setError(initialError);
                                        setStep(hasScheduler ? 0 : 1);
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 4 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <CenterHeader className="Heading05Bold">You qualify for no-cost testing</CenterHeader>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <ImageContainer>
                                    <Image className="fa-light fa-clipboard-user" />
                                </ImageContainer>
                            </FormSection>
                            <FormSection style={{padding: '48px 24px'}}>
                                <Paragraph className="Heading06Bold">Which test do you want to take?</Paragraph>
                                <Label error={error.test} className="Heading05Regular">
                                    <LabelText>Test</LabelText>
                                    <Select
                                        error={error.test}
                                        name="test"
                                        value={state.test || ''}
                                        onChange={handleChange}>
                                        <option disabled value="">
                                            --
                                        </option>
                                        <option value="pcr">COVID-19 RT-PCR Test</option>
                                        <option value="rapid-antigen">Rapid COVID-19 Antigen Test</option>
                                    </Select>
                                </Label>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        for (const key in initialSymptoms) {
                                            if (state[key]) {
                                                setStep(2);
                                                return;
                                            }
                                        }
                                        setStep(1);
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 5 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <CenterHeader className="Heading05Bold">You qualify for no-cost testing</CenterHeader>
                            </FormSection>
                            <FormSection>
                                <ImageContainer>
                                    <Image className="fa-light fa-clipboard-user" />
                                </ImageContainer>
                            </FormSection>
                            <FormSection style={{padding: '48px 24px'}}>
                                <Paragraph className="Heading06Bold">
                                    We can bill your insurance provider for no-cost testing.
                                </Paragraph>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Yes, I have health insurance" width="100%" />
                                </SubmitContainer>
                                {/* <SubmitContainer>
                                    <PrimaryButton value="No, I am uninsured" width="100%" />
                                </SubmitContainer> */}
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 6 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">You qualify for no-cost testing</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection style={{gap: '32px'}}>
                                <Paragraph>Please fill in your patient information to continue</Paragraph>
                                <DualContainer>
                                    <DualLabel error={error.firstName} className="Heading05Regular">
                                        <LabelText>First Name</LabelText>
                                        <FullInput
                                            error={error.firstName}
                                            type="text"
                                            name="firstName"
                                            value={state.firstName || ''}
                                            placeholder="John"
                                            onChange={handleChange}
                                        />
                                    </DualLabel>
                                    <DualLabel error={error.lastName} className="Heading05Regular">
                                        <LabelText>Last Name</LabelText>
                                        <FullInput
                                            error={error.lastName}
                                            type="text"
                                            name="lastName"
                                            value={state.lastName || ''}
                                            placeholder="Doe"
                                            onChange={handleChange}
                                        />
                                    </DualLabel>
                                </DualContainer>
                                <Label
                                    error={error.birthday}
                                    className="Heading05Regular"
                                    onClick={(e) => e.preventDefault()}>
                                    <LabelText>Date of Birth</LabelText>
                                    <LeftIcon className="fa-solid fa-calendar-days" error={error.birthday} />
                                    {/* <FormInput error={error.birthday} type="date" name="birthday" value={state.birthday || ''} placeholder="MM/DD/YYY" onChange={handleChange} /> */}
                                    <StyledDatePicker
                                        error={error.birthday}
                                        selected={state.birthday}
                                        minDate={new Date('1/1/1870')}
                                        maxDate={new Date()}
                                        placeholderText="MM/DD/YYYY"
                                        showMonthDropdown
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dropdownMode="select"
                                        onChange={(date) => {
                                            setState({...state, birthday: date});
                                            setError({...error, birthday: false});
                                        }}
                                    />
                                </Label>
                                <SurveyQuestion error={error.gender}>
                                    <LabelText className="ParagraphNormalBold">Biological Sex</LabelText>
                                    <SymptomInputContainer style={{paddingLeft: '12px'}}>
                                        <RadioInput
                                            error={error.gender}
                                            id="male"
                                            type="radio"
                                            name="gender"
                                            value="Male"
                                            checked={state.gender === 'Male'}
                                            onChange={handleChange}
                                        />
                                        <SymptomCheckLabel htmlFor="male">Male</SymptomCheckLabel>
                                        <RadioInput
                                            error={error.contact}
                                            id="female"
                                            type="radio"
                                            name="gender"
                                            value="Female"
                                            checked={state.gender === 'Female'}
                                            onChange={handleChange}
                                        />
                                        <SymptomCheckLabel htmlFor="female">Female</SymptomCheckLabel>
                                    </SymptomInputContainer>
                                </SurveyQuestion>
                                <Label error={error.ethnicity} className="Heading05Regular">
                                    <LabelText>Ethnicity</LabelText>
                                    <Select
                                        error={error.ethnicity}
                                        name="ethnicity"
                                        value={state.ethnicity || ''}
                                        onChange={handleChange}>
                                        <option disabled value="">
                                            --
                                        </option>
                                        {Ethnicities.map((ethnicity, ide) => (
                                            <option key={ide} value={ethnicity}>
                                                {ethnicity}
                                            </option>
                                        ))}
                                    </Select>
                                </Label>
                                <IconParagraph>
                                    <Icon className="fa-light fa-circle-info" />
                                    <p className="ParagraphSmallRegular">
                                        We use this information to help process your no-cost testing claim
                                    </p>
                                </IconParagraph>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 7 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">You qualify for no-cost testing</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection style={{gap: '32px'}}>
                                <Paragraph>Please fill in your contact information</Paragraph>
                                <Label error={error.email} className="Heading05Regular">
                                    <LabelText>Email</LabelText>
                                    <LeftIcon className="fa-light fa-envelope" error={error.email} />
                                    <FormInput
                                        error={error.email}
                                        type="email"
                                        name="email"
                                        value={state.email || ''}
                                        placeholder="example@example.com"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={error.confirmEmail} className="Heading05Regular">
                                    <LabelText>Confirm Email</LabelText>
                                    <LeftIcon className="fa-light fa-envelope" error={error.confirmEmail} />
                                    <FormInput
                                        error={error.confirmEmail}
                                        type="email"
                                        name="confirmEmail"
                                        value={state.confirmEmail || ''}
                                        placeholder="example@example.com"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={error.phone} className="Heading05Regular">
                                    <LabelText>Phone Number</LabelText>
                                    <LeftIcon className="fa-light fa-phone-flip" error={error.phone} />
                                    <FormInput
                                        error={error.phone}
                                        type="tel"
                                        name="phone"
                                        value={state.phone || ''}
                                        placeholder="555-555-5555"
                                        onChange={handleChange}
                                    />
                                </Label>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 8 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">You qualify for no-cost testing</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection style={{gap: '32px'}}>
                                <Paragraph>Please fill in your address information</Paragraph>
                                <Label error={error.address1} className="Heading05Regular">
                                    <LabelText>Street Address</LabelText>
                                    <FullInput
                                        error={error.address1}
                                        placeholder="123 Apple Street"
                                        type="text"
                                        name="address1"
                                        value={state.address1 || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <OptionalContainer>
                                    <Label error={''} className="Heading05Regular">
                                        <LabelText>Address Cont. - Apt/Suite/Etc</LabelText>
                                        <FullInput
                                            error={''}
                                            placeholder="Apt 100"
                                            type="text"
                                            name="address2"
                                            value={state.address2 || ''}
                                            onChange={handleChange}
                                        />
                                    </Label>
                                    <Optional className="ParagraphSmallRegular">Optional</Optional>
                                </OptionalContainer>
                                <Label error={error.city} className="Heading05Regular">
                                    <LabelText>City</LabelText>
                                    <FullInput
                                        error={error.city}
                                        placeholder="Dallas"
                                        type="text"
                                        name="city"
                                        value={state.city || ''}
                                        onChange={handleChange}
                                    />
                                </Label>
                                <DualContainer>
                                    <DualLabel error={error.state} className="Heading05Regular">
                                        <LabelText>State</LabelText>
                                        <Select
                                            error={error.state}
                                            name="state"
                                            value={state.state || ''}
                                            onChange={handleChange}>
                                            <option disabled value="">
                                                --
                                            </option>
                                            {States.map((usstate, ids) => (
                                                <option key={ids} value={usstate.code}>
                                                    {usstate.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </DualLabel>
                                    <DualLabel error={error.zip} className="Heading05Regular">
                                        <LabelText>ZIP Code</LabelText>
                                        <FullInput
                                            error={error.zip}
                                            placeholder="10001"
                                            type="text"
                                            name="zip"
                                            value={state.zip || ''}
                                            onChange={handleChange}
                                        />
                                    </DualLabel>
                                </DualContainer>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 9 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">You qualify for no-cost testing</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection style={{gap: '32px'}}>
                                <Paragraph>Please fill in your insurance information to continue</Paragraph>
                                <Label error={error.insuranceName} className="Heading05Regular">
                                    <LabelText>Insurance name or company</LabelText>
                                    <FullInput
                                        error={error.insuranceName}
                                        type="text"
                                        name="insuranceName"
                                        value={state.insuranceName || ''}
                                        placeholder="United Healthcare"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={error.subscriberId} className="Heading05Regular">
                                    <LabelText>Subscriber or Member ID</LabelText>
                                    <FullInput
                                        error={error.subscriberId}
                                        type="text"
                                        name="subscriberId"
                                        value={state.subscriberId || ''}
                                        placeholder="12345678-01"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={''} className="Heading05Regular">
                                    <LabelText>Group ID (optional)</LabelText>
                                    <FullInput
                                        error={''}
                                        type="text"
                                        name="groupId"
                                        value={state.groupId || ''}
                                        placeholder="RX0000"
                                        onChange={handleChange}
                                    />
                                </Label>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 10 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold" style={{textTransform: 'capitalize'}}>
                                    {state.insuranceName}
                                </Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection style={{gap: '32px'}}>
                                <Paragraph>
                                    Please fill in your insurance claims address, which is typically found on the back
                                    of your insurance card
                                </Paragraph>
                                <Label error={error.claimsAddress} className="Heading05Regular">
                                    <LabelText>Claims Address</LabelText>
                                    <FullInput
                                        error={error.claimsAddress}
                                        type="text"
                                        name="claimsAddress"
                                        value={state.claimsAddress || ''}
                                        placeholder="123 Apple Street"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={error.claimsCity} className="Heading05Regular">
                                    <LabelText>City</LabelText>
                                    <FullInput
                                        error={error.claimsCity}
                                        type="text"
                                        name="claimsCity"
                                        value={state.claimsCity || ''}
                                        placeholder="Dallas"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <DualContainer>
                                    <DualLabel error={error.claimsState} className="Heading05Regular">
                                        <LabelText>State</LabelText>
                                        <Select
                                            error={error.claimsState}
                                            name="claimsState"
                                            value={state.claimsState || ''}
                                            onChange={handleChange}>
                                            <option disabled value="">
                                                --
                                            </option>
                                            {States.map((usstate, ids) => (
                                                <option key={ids} value={usstate.code}>
                                                    {usstate.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </DualLabel>
                                    <DualLabel error={error.claimsZip} className="Heading05Regular">
                                        <LabelText>ZIP Code</LabelText>
                                        <FullInput
                                            error={error.claimsZip}
                                            type="text"
                                            name="claimsZip"
                                            value={state.claimsZip || ''}
                                            placeholder="10001"
                                            onChange={handleChange}
                                        />
                                    </DualLabel>
                                </DualContainer>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" onClick={prevStep}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 11 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">Proof of insurance</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <Paragraph className="ParagraphNormalRegular">
                                    Please upload pictures of the front and back of your insurance card
                                </Paragraph>
                                <PhotoUploadContainer spacing="double">
                                    <PhotoSubmitLabel background={photos.frontInsurance}>
                                        <PhotoSubmit
                                            type="file"
                                            id="front-insurance-upload"
                                            name="frontInsurance"
                                            onChange={handlePhotoChange}
                                        />
                                        {!photos.frontInsurance && (
                                            <>
                                                <IdIcon className="fa-light fa-cloud-arrow-up" />
                                                <Paragraph className="ParagraphSmallRegular">Front side</Paragraph>
                                            </>
                                        )}
                                    </PhotoSubmitLabel>
                                    <PhotoSubmitLabel background={photos.backInsurance}>
                                        <PhotoSubmit
                                            type="file"
                                            id="back-insurance-upload"
                                            name="backInsurance"
                                            onChange={handlePhotoChange}
                                        />
                                        {!photos.backInsurance && (
                                            <>
                                                <IdIcon className="fa-light fa-cloud-arrow-up" />
                                                <Paragraph className="ParagraphSmallRegular">Back side</Paragraph>
                                            </>
                                        )}
                                    </PhotoSubmitLabel>
                                </PhotoUploadContainer>
                                <Spacer />
                                <IconParagraph>
                                    <IdIcon className="fa-light fa-camera" />
                                    <p className="ParagraphSmallRegular">
                                        Please make sure the pictures of your card are well-lit, and aren’t blurry or
                                        out-of-focus
                                    </p>
                                </IconParagraph>
                                <IconParagraph>
                                    <IdIcon className="fa-light fa-file-image" />
                                    <p
                                        className="ParagraphSmallRegular"
                                        style={{marginLeft: '8px', whiteSpace: 'pre-line'}}>
                                        {'Accepted file types: jpg, jpeg, png, heic \nMax file size: 10 MB'}
                                    </p>
                                </IconParagraph>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        setStep(9);
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 12 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <ImageContainer>
                                    <Image className="fa-light fa-id-card" />
                                </ImageContainer>
                            </FormSection>
                            <FormSection style={{paddingTop: '48px'}}>
                                <Paragraph className="Heading06Bold">Select a form of identification</Paragraph>
                            </FormSection>
                            <FormSection>
                                <Paragraph>We use this information to process your claim</Paragraph>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Social Security Number" width="100%" />
                                </SubmitContainer>
                                <SubmitContainer>
                                    <PrimaryButton value="Driver's License Number" width="100%" />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        setStep(8);
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 13 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">Identification</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <Paragraph>
                                    To process your no-cost claim, please fill in your social security number
                                </Paragraph>
                                <Label error={error.social} className="Heading05Regular">
                                    <LabelText>Social Security Number</LabelText>
                                    <LeftIcon className="fa-solid fa-lock" error={error.social} />
                                    <FormInput
                                        error={error.social}
                                        type="text"
                                        name="social"
                                        value={state.social || ''}
                                        placeholder="000-00-0000"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={error.residenceState} className="Heading05Regular">
                                    <LabelText>State of Residence</LabelText>
                                    <Select
                                        error={error.residenceState}
                                        name="residenceState"
                                        value={state.residenceState || ''}
                                        onChange={handleChange}>
                                        <option disabled value="">
                                            --
                                        </option>
                                        {States.map((usstate, ids) => (
                                            <option key={ids} value={usstate.code}>
                                                {usstate.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Label>
                            </FormSection>
                            <FormSection>
                                {submitting && (
                                    <Paragraph className="ParagraphSmallRegular">
                                        Uploading your photos and submitting your claim. Please be patient, this may
                                        take a few moments to complete.
                                    </Paragraph>
                                )}
                                <SubmitContainer>
                                    {submitting && <Spinner />}
                                    <PrimaryButton
                                        value={hasScheduler && state.insured ? 'Submit' : 'Continue'}
                                        width="100%"
                                        disabled={submitting}
                                    />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        if (state.insured) {
                                            setState((prevState) => ({...prevState, social: '', residenceState: ''}));
                                            setStep(15);
                                        } else {
                                            setStep(12);
                                        }
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 14 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">Identification</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <Paragraph>
                                    To process your no-cost claim, please fill in your driver's license number
                                </Paragraph>
                                <Label error={error.license} className="Heading05Regular">
                                    <LabelText>Driver's License Number</LabelText>
                                    <LeftIcon className="fa-solid fa-lock" error={error.license} />
                                    <FormInput
                                        error={error.license}
                                        type="text"
                                        name="license"
                                        value={state.license || ''}
                                        placeholder="0000-0000"
                                        onChange={handleChange}
                                    />
                                </Label>
                                <Label error={error.residenceState} className="Heading05Regular">
                                    <LabelText>State of Residence</LabelText>
                                    <Select
                                        error={error.residenceState}
                                        name="residenceState"
                                        value={state.residenceState || ''}
                                        onChange={handleChange}>
                                        <option disabled value="">
                                            --
                                        </option>
                                        {States.map((usstate, ids) => (
                                            <option key={ids} value={usstate.code}>
                                                {usstate.name}
                                            </option>
                                        ))}
                                    </Select>
                                </Label>
                            </FormSection>
                            <FormSection>
                                <SubmitContainer>
                                    <PrimaryButton value="Continue" width="100%" />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        setStep(12);
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 15 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">Proof of identification</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <Paragraph className="ParagraphNormalRegular">
                                    Please upload a picture of the front of your government issued ID
                                </Paragraph>
                                <PhotoUploadContainer spacing="single">
                                    <PhotoSubmitLabel background={photos.frontId}>
                                        <PhotoSubmit
                                            type="file"
                                            id="front-id-upload"
                                            name="frontId"
                                            onChange={handlePhotoChange}
                                        />
                                        {!photos.frontId && (
                                            <>
                                                <IdIcon className="fa-light fa-cloud-arrow-up" />
                                                <Paragraph className="ParagraphSmallRegular">Front side</Paragraph>
                                            </>
                                        )}
                                    </PhotoSubmitLabel>
                                </PhotoUploadContainer>
                                <Spacer />
                                <IconParagraph>
                                    <IdIcon className="fa-light fa-camera" />
                                    <p className="ParagraphSmallRegular">
                                        Please make sure the picture of your card is well-lit, and isn't blurry or
                                        out-of-focus
                                    </p>
                                </IconParagraph>
                                <IconParagraph>
                                    <IdIcon className="fa-light fa-file-image" />
                                    <p
                                        className="ParagraphSmallRegular"
                                        style={{marginLeft: '8px', whiteSpace: 'pre-line'}}>
                                        {'Accepted file types: jpg, jpeg, png, heic \nMax file size: 10 MB'}
                                    </p>
                                </IconParagraph>
                            </FormSection>
                            <FormSection>
                                {submitting && (
                                    <Paragraph className="ParagraphSmallRegular">
                                        Uploading your photos and submitting your claim. Please be patient, this may
                                        take a few moments to complete.
                                    </Paragraph>
                                )}
                                <SubmitContainer>
                                    {submitting && <Spinner />}
                                    <PrimaryButton
                                        value={hasScheduler && state.insured ? 'Submit' : 'Continue'}
                                        width="100%"
                                        disabled={submitting}
                                    />
                                </SubmitContainer>
                                {state.insured && (
                                    <BackButton
                                        className="ParagraphNormalRegular"
                                        onClick={() => {
                                            setError(initialError);
                                            setPhotos((prevPhotos) => ({...prevPhotos, frontId: null}));
                                            setStep(13);
                                        }}>
                                        I don't have a government issued ID
                                    </BackButton>
                                )}
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        if (state.insured) {
                                            setStep(11);
                                        } else {
                                            setStep(14);
                                        }
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 16 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Header className="Heading05Bold">Attestation</Header>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                <AttestationName>{`${state.firstName} ${state.lastName}`}</AttestationName>
                                <Paragraph>
                                    To process your no-cost claim, please read through the terms and continue if you
                                    agree
                                </Paragraph>
                                <p className="ParagraphSmallRegular">
                                    I do not have health care coverage such as individual, employer-sponsored, Medicare
                                    or Medicaid coverage. Therefore, I affirm and attest I qualify as uninsured
                                    according to the COVID-19 Uninsured Program in the Coronavirus Aid, Relief and
                                    Economic Security (CARES) Act (P.L. 116-136). I understand that in order to receive
                                    this service for free that my information will be ran through a system that
                                    identifies medical coverage’s and if it is determined that I had medical coverage
                                    after I have already been tested than the financial responsibility falls back on me
                                    and I will be billed for services at that time.
                                </p>
                            </FormSection>
                            <FormSection>
                                {submitting && (
                                    <Paragraph className="ParagraphSmallRegular">
                                        {`${
                                            state.social ? 'S' : 'Uploading your photos and s'
                                        }ubmitting your claim. Please be patient, this may take a few moments to complete`}
                                        .
                                    </Paragraph>
                                )}
                                <SubmitContainer>
                                    {submitting && <Spinner left="calc(50% - 140px)" />}
                                    <PrimaryButton
                                        value={hasScheduler ? 'I agree, submit my claim' : 'I agree'}
                                        width="100%"
                                        disabled={submitting}
                                    />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        if (state.social === '') {
                                            setStep(15);
                                        } else if (state.license === '') {
                                            setStep(13);
                                        } else {
                                            setStep(12);
                                        }
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 17 && (
                        <Form onSubmit={handleSubmit}>
                            <AcuityFormSection position="top">
                                <Header className="Heading05Bold">Schedule Appointment</Header>
                            </AcuityFormSection>
                            <PriorityBanner>
                                <Icon className="fa-light fa-calendar-check" />
                                <p className="ParagraphSmallRegular">
                                    Want A Priority Appointment?{' '}
                                    <ExternalLink
                                        href={`https://mylabsdirect.as.me/schedule.php?firstName=${
                                            state.firstName
                                        }&lastName=${state.lastName}&phone=${state.phone}&email=${
                                            state.email
                                        }&field:10422557=${state.birthday.toLocaleDateString()}&field:10422558=${
                                            state.gender
                                        }&field:10468942=${
                                            state.ethnicity
                                        }&field:10440614=${state.contact.toLowerCase()}&field:10440613=${state.symptoms.toLowerCase()}&field:9864758=${
                                            state.address2 ? state.address1 + `, ${state.address2}` : state.address1
                                        }&field:9864759=${state.city}&field:9864760=${
                                            states[state.state]
                                        }&field:9864761=${state.zip}&appointmentType=${
                                            state.test === 'pcr'
                                                ? '17688104'
                                                : state.test === 'rapid-antigen'
                                                ? '29103834'
                                                : ''
                                        }`}
                                        target="_blank">
                                        {'Pay Cash & Get Tested Right Away!'}
                                    </ExternalLink>
                                </p>
                            </PriorityBanner>
                            <Error message={error.message} />
                            <FormSection>
                                <iframe
                                    src={`https://mylabsdirect.as.me/schedule.php?firstName=${
                                        state.firstName
                                    }&lastName=${state.lastName}&phone=${state.phone}&email=${
                                        state.email
                                    }&field:10422557=${state.birthday.toLocaleDateString()}&field:10422558=${
                                        state.gender
                                    }&field:10468942=${state.ethnicity}&field:10584465=${
                                        state.fever ? 'yes' : 'no'
                                    }&field:10584477=${state.chills ? 'yes' : 'no'}&field:10938422=${
                                        state.cough ? 'yes' : 'no'
                                    }&field:10584478=${state.shortnessOfBreath ? 'yes' : 'no'}&field:10584479=${
                                        state.fatigue ? 'yes' : 'no'
                                    }&field:10584480=${state.muscleOrBodyAches ? 'yes' : 'no'}&field:10584481=${
                                        state.headache ? 'yes' : 'no'
                                    }&field:10584482=${state.lossOfTasteOrSmell ? 'yes' : 'no'}&field:10584483=${
                                        state.soreThroat ? 'yes' : 'no'
                                    }&field:10584484=${state.nausea ? 'yes' : 'no'}&field:10584485=${
                                        state.vomiting ? 'yes' : 'no'
                                    }&field:10584486=${state.diarrhea ? 'yes' : 'no'}&field:10584487=${
                                        state.other ? 'yes' : 'no'
                                    }&field:10498643=${state.contact.toLowerCase()}&field:10498648=${state.symptoms.toLowerCase()}&field:9864758=${
                                        state.address2 ? state.address1 + `, ${state.address2}` : state.address1
                                    }&field:9864759=${state.city}&field:9864760=${states[state.state]}&field:9864761=${
                                        state.zip
                                    }&appointmentType=${
                                        state.test === 'pcr'
                                            ? '26321317'
                                            : state.test === 'rapid-antigen'
                                            ? '30773809'
                                            : ''
                                    }`}
                                    width="100%"
                                    height="650"
                                    title="acuity"
                                />
                            </FormSection>
                            {/* <AcuityFormSection position="bottom">
                                <SubmitContainer>
                                    {submitting && <SubmitSpinner src={SpinnerIcon} alt="" />}
                                    <PrimaryButton value="Submit" width="100%" />
                                </SubmitContainer>
                                <BackButton className="ParagraphNormalRegular" style={{backgroundColor: 'var(--neutral-50)'}} onClick={() => {
                                    setError(initialError);
                                    if (state.insured) {
                                        if (state.social) {
                                            setStep(13);
                                        } else {
                                            setStep(15);
                                        }
                                    } else if (!state.insured) {
                                        setStep(16);
                                    } else {
                                        setStep(4);
                                    }
                                }}>Go back</BackButton>
                            </AcuityFormSection> */}
                        </Form>
                    )}

                    {step === 18 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <Paragraph className="ParagraphNormalRegular">Patient Confidentiality</Paragraph>
                                <Paragraph className="ParagraphNormalRegular" style={{textAlign: 'left'}}>
                                    The test results will only be released to you, to the referring health care provider
                                    (if any), to the ordering laboratory (if any), to other health care providers
                                    involved in your care and treatment or to others as entitled by law. In the case of
                                    positive COVID-19 test results, CDC and local public health authorities will be
                                    notified.
                                </Paragraph>
                                <ConfidentialityContainer>
                                    <SquareInput
                                        error={error.confidentiality}
                                        id="confidentiality"
                                        type="checkbox"
                                        name="confidentiality"
                                        value="confidentiality"
                                        checked={state.confidentiality}
                                        onChange={handleChange}
                                    />
                                    <ConfidentialityLabel error={error.confidentiality} htmlFor="confidentiality">
                                        I have read the{' '}
                                        <InternalLink href={ConsentForm} target="_blank">
                                            Informed Consent and Testing Agreement
                                        </InternalLink>{' '}
                                        and I give permission to MyLabsDirect to perform COVID-19 testing as described.
                                    </ConfidentialityLabel>
                                </ConfidentialityContainer>
                            </FormSection>
                            <Error message={error.message} />
                            <FormSection>
                                {submitting && (
                                    <Paragraph className="ParagraphSmallRegular">
                                        Uploading your photos and creating your order. Please be patient, this may take
                                        a few moments to complete.
                                    </Paragraph>
                                )}
                                <SubmitContainer>
                                    {submitting && <Spinner />}
                                    <PrimaryButton value="Submit" width="100%" disabled={submitting} />
                                </SubmitContainer>
                                <BackButton
                                    className="ParagraphNormalRegular"
                                    onClick={() => {
                                        setError(initialError);
                                        if (state.insured) {
                                            if (state.social) {
                                                setStep(13);
                                            } else {
                                                setStep(15);
                                            }
                                        } else if (!state.insured) {
                                            setStep(16);
                                        } else {
                                            setStep(4);
                                        }
                                    }}>
                                    Go back
                                </BackButton>
                            </FormSection>
                        </Form>
                    )}

                    {step === 19 && (
                        <Form onSubmit={handleSubmit}>
                            <FormSection>
                                <ImageContainer>
                                    <Image className="fa-light fa-circle-check" />
                                </ImageContainer>
                                <Paragraph className="ParagraphNormalRegular">
                                    {
                                        'Thank you! Your order has been created. \nPlease check your email to confirm your account.'
                                    }
                                </Paragraph>
                            </FormSection>
                            {/* <FormSection>
                                <Paragraph>Need to schedule another insurance appointment?
                                    <BackButton className="ParagraphNormalRegular" onClick={reset}>Click here.</BackButton>
                                </Paragraph>
                            </FormSection> */}
                        </Form>
                    )}
                </FormContainer>
            </PageContainer>
        </>
    );
};

export default Insurance;
