import React from 'react';
import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';

const ModalWindow = styled.div<{show: boolean}>`
    display: ${(props) => (props.show ? 'flex' : 'none')};
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
    //margin: auto;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    max-width: 544px;
    //height: 481px;
    flex-grow: 0;
    width: 80%;

    font-family: Nunito;
    line-height: 1.5;
    text-align: center;
`;

const Header = styled.div`
    color: var(--mld-scheme-shop-tests);
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;

    height: 56px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 12px 0;
`;

const IconContainer = styled.div`
    width: 60px;
    height: 60px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    margin: 0 auto;
`;

const HandIcon = styled.i`
    font-size: 58px;
    width: 72px;
    height: 72px;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Line = styled.div`
    width: 80%;
    height: 1px;
    margin: 24px 0;
    background-color: var(--neutral-100);
    align-self: center;
`;

interface Props {
    show: boolean;
    setShow: Function;
}

const CompTest: React.FC<Props> = ({show, setShow}) => {
    return (
        <ModalWindow show={show}>
            <Header>
                <i className="fa-light fa-cart-plus fa-lg" />
                <a>Component Testing Report</a>
                <a
                    href="#"
                    style={{marginLeft: 'auto'}}
                    onClick={(e: any) => {
                        setShow(false);
                        e.preventDefault();
                    }}>
                    <i className="fa-light fa-circle-xmark fa-xl" style={{marginLeft: 'auto', color: 'black'}} />
                </a>
            </Header>
            <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                <IconContainer>
                    <HandIcon className="fa-light fa-hand-dots" />
                </IconContainer>
                <p style={{fontWeight: 'bold'}}>
                    Unlock your Molecular Aspects for this lab report and get detailed information about your allergen
                    sensitivity
                </p>
                <p>
                    Please note that this purchase will unlock all Molecular Aspects for this lab report only, and does
                    not apply to previous and future lab reports
                </p>
                <Line />
                <PrimaryButton
                    value="Purchase for $29"
                    radius="24px"
                    margin="0 0 0 auto"
                    width="190px"
                    onClick={(e: any) => {
                        window.open('https://buy.stripe.com/test_6oE2az0BN5Os0DKaEI', '_blank');
                        e.preventDefault();
                    }}
                />
            </div>
        </ModalWindow>
    );
};

export default CompTest;
