import React, {useState, useEffect, ChangeEvent} from 'react';
import {useAuth} from '../../hooks/useAuth';
import {StepWizardChildProps} from 'react-step-wizard';
import {Link, useNavigate} from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import SupportAlert from '../../components/SupportAlert';
import Spinner from '../../components/Spinner';
import settings from '../../settings';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {selectKit, setKitParam} from '../../slices/kitSlice';
import {
    Form,
    FormSection,
    Header,
    Image,
    ImageContainer,
    Error,
    Paragraph,
    LabelText,
    LeftIcon,
    SubmitContainer,
    FormInput,
    Label,
    SubText
} from './styles/Step0';

const initialError = {
    kitId1: false,
    kitId2: false,
    promoCode1: false,
    promoCode2: false,
    company: false,
    contact: false,
    symptoms: false,
    vaccinated: false,
    firstName: false,
    lastName: false,
    birthday: false,
    address1: false,
    city: false,
    state: false,
    zip: false,
    phone: false,
    gender: false,
    ethnicity: false,
    message: ''
};

export const Step0: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState<{[x: string]: any}>(initialError);
    const {user, token} = useAuth();
    const dispatch = useAppDispatch();
    const kitInfo = useAppSelector(selectKit);

    const covidIds: {[index: string]: boolean} = {
        '01': true,
        '02': true,
        '03': true,
        '04': true,
        '05': true,
        '06': true
    };

    const promoIds: {[index: string]: boolean} = {
        '04': true,
        '05': true,
        '06': true
    };

    const hasNoFirstName = !user.userInfo.firstName;
    const hasNoLastName = !user.userInfo.lastName;
    const hasNoBirthday = !user.userInfo.birthday;
    const hasNoAddress = !user.userInfo.address1;
    const hasNoCity = !user.userInfo.city;
    const hasNoState = !user.userInfo.state;
    const hasNoZip = !user.userInfo.zip;
    const hasNoPhone = !user.userInfo.phone;
    const hasNoGender = !user.userInfo.gender;
    const hasNoEthnicity = !user.userInfo.ethnicity;

    const hasMissingInfo =
        hasNoFirstName ||
        hasNoLastName ||
        hasNoBirthday ||
        hasNoAddress ||
        hasNoCity ||
        hasNoState ||
        hasNoZip ||
        hasNoPhone ||
        hasNoGender ||
        hasNoEthnicity;

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        // ([a-zA-Z]{2}\-)? Optionally matches a two letter prefix and hyphen
        // (0[1-6]) Matches 01-06, our valid test identifiers
        // \-\d{4} Matches a hyphen then four digits, this match is then repeated once more
        const regex = /^([a-zA-Z]{2}\-)?(0[1-6])\-\d{4}\-\d{4}$/;
        const result = regex.exec(kitInfo.kitId1);

        if (!result) {
            setError(prevError => ({
                ...prevError,
                kitId1: true,
                kitId2: false,
                message: 'Please enter a valid Kit ID'
            }));
            return;
        }

        // TODO Move this into state to access in Payment step and anywhere else its required
        // Second capture group ie BW-(01)-0000-0000 or (01)-0000-0000, representing our test ID
        const testID = result[2];
        // First, optional capture group ie (BW)-01-0000-0000, our affiliate ID
        const affiliateID = result[1];
        // If we have an affiliate prefix, this is a blood test
        const isBlood = affiliateID?.length > 0;
        // If this is not a blood test, and the test ID is one of our promo IDs, this test is promotional
        const isPromo = !isBlood && promoIds[testID];

        if (kitInfo.kitId1 !== kitInfo.kitId2) {
            setError(prevError => ({
                ...prevError,
                kitId1: true,
                kitId2: true,
                message: 'Please ensure Kit IDs match'
            }));
            return;
        }

        //kitIDD = state.kitId1;
        //dispatch(setKitParam(state));
        setSubmitting(true);

        const kitIdRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({kit_id: kitInfo.kitId1})
        };

        const kitIdRes = await fetch(`${settings.apiURL}/api/kit/getKit`, kitIdRequestOptions);

        if (!isBlood) {
            setSubmitting(false);
        }

        if (!kitIdRes.ok) {
            // const message = (await res.json()).error;
            setError(prevError => ({...prevError, message: 'Could not check kit ID'}));
            setSubmitting(false);
            return;
        }

        const kitIdResponse = await kitIdRes.json();
        if (kitIdResponse.kit_id) {
            setError(prevError => ({
                ...prevError,
                message: 'This kit id has already been registered, please check the Kit ID and try again'
            }));
            setSubmitting(false);
            return;
        }

        if (isPromo) {
            setSubmitting(true);
            const purchasedKitIdRequestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({kit_id: kitInfo.kitId1})
            };

            const purchasedKitIdRes = await fetch(
                `${settings.apiURL}/api/purchasedKits/enabled`,
                purchasedKitIdRequestOptions
            );
            setSubmitting(false);
            if (!purchasedKitIdRes.ok) {
                setError(prevError => ({...prevError, message: 'Could not check kit ID'}));
                return;
            }

            setSubmitting(false);

            const purchasedKitIsEnabled = await purchasedKitIdRes.text();

            if (purchasedKitIsEnabled === 'true') {
                props.goToNamedStep?.('step1');
                return;
            } else if (purchasedKitIsEnabled === 'false') {
                props.goToNamedStep?.('payment');
                return;
            } else {
                setError(prevError => ({...prevError, message: 'Could not check kit ID'}));
                return;
            }
        }

        if (isBlood) {
            setError(initialError);
            setSubmitting(false);

            if (hasMissingInfo) {
                props.goToStep?.(3);
            } else {
                props.goToNamedStep?.('testType');
            }

            return;
        }

        // Not promo or blood
        setSubmitting(false);
        props.goToNamedStep?.('step1');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = event.target.name;
        // @TODO This is a bit hacky, is there a better way to handle this?
        let value =
            event.target.type === 'checkbox'
                ? (event.target as HTMLInputElement).checked.toString()
                : event.target.value;

        var htmlTarget = event.target as HTMLInputElement;
        var cursorPos = htmlTarget.selectionStart;

        if (name === 'kitId1' || name === 'kitId2') {
            const digitReg = /^[0-9]$/;
            const lowerReg = /^[a-z]$/;
            const letterReg = /^[A-Z]$/;
            const letterLayoutReg = /^([A-Z]{0,2})?([0-9]{0,2})?([0-9]{0,4})?([0-9]{0,4})?$/;
            const digitLayoutReg = /^([0-9]{0,2})?([0-9]{0,4})?([0-9]{0,4})?$/;
            var cursorWasAtEnd = false;

            if (cursorPos === value.length) cursorWasAtEnd = true;

            for (
                var i = 0;
                i < value.length;
                i++ //makes all lowercase letters uppercase
            ) {
                if (lowerReg.test(value[i])) {
                    value = value.toUpperCase();
                }
            }

            //handles adding hyphens and formatting automatically
            var cleanString = value.replaceAll('-', '');
            var match;
            if (letterReg.test(cleanString[0])) {
                //letter string
                match = cleanString.match(letterLayoutReg);
                if (match !== null) {
                    value = [
                        match[1],
                        match[2] ? '-' : '',
                        match[2],
                        match[3] ? '-' : '',
                        match[3],
                        match[4] ? '-' : '',
                        match[4],
                    ].join('');
                }
            } else {
                //digit string
                match = cleanString.match(digitLayoutReg);
                if (match !== null) {
                    value = [match[1], match[2] ? '-' : '', match[2], match[3] ? '-' : '', match[3]].join('');
                }
            }

            if (
                (value !== '' && !digitReg.test(value[value.length - 1]) && !letterReg.test(value[value.length - 1])) ||
                (/[a-zA-Z]/.test(value.substring(2, value.length)) && value.length > 2) ||
                (digitReg.test(value[0]) && value.length > 12) ||
                value.length > 15
            ) {
                return;
            }
        }

        dispatch(setKitParam({[name]: value}));
        setError((prevError) => ({...prevError, [name]: false}));
        setTimeout(() => {
            // restore cursor position
            if (cursorPos) {
                if (cursorWasAtEnd) {
                    cursorPos = value.length;
                }
                htmlTarget.setSelectionRange(cursorPos, cursorPos);
            }
        }, 0);
    };

    //Wipe kit state on first load.
    useEffect(() => {
        if (props.isActive) {
            dispatch(setKitParam({kitId1: '', kitId2: '', testType: ''}));
        }
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <FormSection>
                <Header className='Heading05Bold'>Kit Registration</Header>
            </FormSection>
            <Error className='ParagraphSmallRegular' hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <SupportAlert visible={!!error.message} />
            <FormSection>
                <ImageContainer>
                    <Image className='fa-light fa-box-open' />
                </ImageContainer>
                <Paragraph className='ParagraphNormalRegular'>
                    Let's start with your test Kit ID, which can be found inside of your kit.
                </Paragraph>
                <Label className='Heading05Regular'>
                    <LabelText>Kit ID</LabelText>
                    <LeftIcon className='fa-light fa-box' error={error.kitId1 ? 1 : 0} />
                    <FormInput
                        error={error.kitId1}
                        type='text'
                        name='kitId1'
                        value={kitInfo.kitId1 || ''}
                        placeholder='AB-00-0000-0000'
                        onChange={handleChange}
                    />
                </Label>
                <SubText className='ParagraphNormalRegular'>10 or 12 characters long.</SubText>
                <Label className='Heading05Regular'>
                    <LabelText>Confirm Kit ID</LabelText>
                    <LeftIcon className='fa-light fa-box' error={error.kitId2 ? 1 : 0} />
                    <FormInput
                        error={error.kitId2}
                        type='text'
                        name='kitId2'
                        value={kitInfo.kitId2 || ''}
                        placeholder='AB-00-0000-0000'
                        onChange={handleChange}
                    />
                </Label>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    {submitting && <Spinner left='30px' />}
                    <PrimaryButton value='Continue' width='100%' radius='24px' />
                </SubmitContainer>
            </FormSection>
        </Form>
    );
};
