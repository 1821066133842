import React from 'react';
import PanelDisplay from './PanelDisplay';
import {Panels} from './styles/PanelResult';

interface Props {
    setPinnedMetrics: any;
    result: any;
    test_type: string;
}

const PanelResult: React.FC<Props> = props => {
    const {setPinnedMetrics, result, test_type} = props;

    const panelNames = [];
    const resultsByPanelName: any = {};
    const panelTestRelations: any = {};
    const additionalTests = [];

    // Filter blood results by panel names
    for (let i = 0; i < result.results.length; i++) {
        let panel = result.results[i].panel_name;
        if (panel.includes('DTC - ')) {
            panel = panel.substring(6, panel.length);
        }

        if (!panel) {
            additionalTests.push(result.results[i]);
            continue;
        }

        if (!resultsByPanelName[panel]) {
            resultsByPanelName[panel] = [];
            panelNames.push(panel);
        }

        // Filters out duplicate results per panel
        const relation = `${panel} ${result.results[i].test_name}`;

        if (!panelTestRelations[relation]) {
            resultsByPanelName[panel].push(result.results[i]);
            panelTestRelations[relation] = true;
        }
    }

    // Groups individual tests
    if (result.results.length > 1) {
        for (let i = 0; i < panelNames.length; i++) {
            const panel = panelNames[i];
            const results = resultsByPanelName[panel];
            const relation = `Additional Tests ${results[0].test_name}`;

            if (results.length === 1) {
                if (!panelTestRelations[relation]) {
                    additionalTests.push(results[0]);
                    panelTestRelations[relation] = true;
                }

                resultsByPanelName[panelNames[i]] = [];
            }
        }
    }

    return (
        <Panels>
            {panelNames.map((panel, index) => {
                return (
                    <PanelDisplay
                        key={index}
                        setPinnedMetrics={setPinnedMetrics}
                        panel={panel}
                        results={resultsByPanelName[panel]}
                        test_type={test_type}
                    />
                );
            })}
            <PanelDisplay
                setPinnedMetrics={setPinnedMetrics}
                panel='Additional Tests'
                results={additionalTests}
                test_type={test_type}
            />
        </Panels>
    );
};

export default PanelResult;
