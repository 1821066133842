import React, {ReactNode} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper';
import {Mobile, Desktop} from './MediaQueries';
import 'swiper/css';
import 'swiper/css/pagination';

interface Props {
    children: ReactNode[];
}

const Slider: React.FC<Props> = ({children}) => {
    return (
        <>
            <Mobile>
                <Swiper modules={[Pagination]} pagination={{clickable: true}} slidesPerView={1} height={250}>
                    {children.map((item, index) => (
                        <React.Fragment key={index}>
                            <SwiperSlide key={index}>{item}</SwiperSlide>
                        </React.Fragment>
                    ))}
                </Swiper>
            </Mobile>
            {/* renders swiper if mobile, and not if desktop, 
            this allows <Slider> to wrap everything, not just mobile code */}
            <Desktop>{children}</Desktop>
        </>
    );
};

export default Slider;
