import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {StepWizardChildProps} from 'react-step-wizard';
import {useAuth} from '../../hooks/useAuth';
import PrimaryButton from '../../components/PrimaryButton';
import settings from '../../settings';
import {useAppSelector} from '../../hooks';
import {
    Form,
    FormSection,
    Error,
    BackButton,
    Header,
    Image,
    ImageContainer,
    Paragraph,
    SubmitContainer,
} from './styles/Step999';

export const Step999: React.FC<Partial<StepWizardChildProps>> = ({...props}) => {
    const kitInfo = useAppSelector((state) => state.kit);
    const {user, token} = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();

    const initialError = {
        kitId1: false,
        kitId2: false,
        promoCode1: false,
        promoCode2: false,
        company: false,
        contact: false,
        symptoms: false,
        vaccinated: false,
        firstName: false,
        lastName: false,
        birthday: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
        phone: false,
        gender: false,
        ethnicity: false,
        message: '',
    };
    const [error, setError] = useState<{[x: string]: any}>(initialError);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!props.isActive) return;

        const submitter = event.nativeEvent.submitter.value;
        if (submitter === 'Complete Registration') {
            setSubmitting(true);

            const kitIdRequestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({kit_id: kitInfo.kitId1}),
            };

            const kitIdRes = await fetch(`${settings.apiURL}/api/kit/getKit`, kitIdRequestOptions);
            setSubmitting(false);
            if (!kitIdRes.ok) {
                // const message = (await res.json()).error;
                setError((prevError) => ({...prevError, message: 'Could not check kit ID. Please try again.'}));
                return;
            }

            const kitIdResponse = await kitIdRes.json();
            if (kitIdResponse.kit_id) {
                setError((prevError) => ({
                    ...prevError,
                    message: 'This kit ID has already been registered. ' + '\nPlease contact support.',
                }));
                return;
            }

            const purchasedKitIdRequestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
                body: JSON.stringify({kit_id: kitInfo.kitId1}),
            };

            const purchasedKitIdRes = await fetch(
                `${settings.apiURL}/api/purchasedKits/enabled`,
                purchasedKitIdRequestOptions
            );
            setSubmitting(false);
            if (!purchasedKitIdRes.ok) {
                setError((prevError) => ({...prevError, message: 'Could not check kit ID. Please try again.'}));
                return;
            }

            const purchasedKitIsEnabled = await purchasedKitIdRes.text();

            if (purchasedKitIsEnabled === 'true') {
                props.goToNamedStep && props.goToNamedStep('step1');
                return;
            } else if (purchasedKitIsEnabled === 'false') {
                setError((prevError) => ({...prevError, message: 'There was a problem. Please try again.'}));
                return;
            } else {
                setError((prevError) => ({...prevError, message: 'Could not check kit ID. Please try again.'}));
                return;
            }
        }
        return;
    };

    const simpleRefresh = () => {
        setError(initialError);
        navigate('/newregisterkit/step/0');
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Error className="ParagraphSmallRegular" hasSubmitted={!!error.message} message={error.message || ''}>
                {error.message}
            </Error>
            <FormSection>
                <Header className="Heading05Bold">Payment Successful</Header>
            </FormSection>
            <FormSection>
                <ImageContainer>
                    <Image className="fa-light fa-circle-check" />
                </ImageContainer>
            </FormSection>
            <FormSection>
                <Paragraph className="ParagraphNormalRegular">{`You have successfully activated kit\n ${kitInfo.kitId1}`}</Paragraph>
            </FormSection>
            <FormSection>
                <SubmitContainer>
                    <PrimaryButton value="Complete Registration" width="100%" radius="24px" />
                </SubmitContainer>
                <BackButton className="ParagraphNormalRegular" onClick={simpleRefresh}>
                    Go Back
                </BackButton>
            </FormSection>
        </Form>
    );
};
