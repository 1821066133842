import styled, {css} from 'styled-components';
import Spinner from '../../components/Spinner';
import {Device} from '../../services/constants';

export const MainContainer = styled.div`
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    padding: 24px;
    display: flex;
`;

export const Header = styled.h4`
    margin: 0;
    padding: 0;
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: 1px;
`;

export const InfoContainer = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--neutral-50);
`;

export const StyledIcon = styled.i`
    width: 32px;
    height: 32px;
    font-size: 32px;
    flex-grow: 0;
    object-fit: contain;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const SmallKeyIcon = styled.i`
    font-size: 64px;
    flex-grow: 0;
    object-fit: contain;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const KeyIcon = styled.i`
    font-size: 72px;
    flex-grow: 0;
    object-fit: contain;
    background: linear-gradient(to right, #3ae7ff, #f328f8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const Info = styled.span`
    flex-grow: 1;
    font-family: Nunito;
    font-size: 0.833rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--text-600);
`;

export const GroupContainer = styled.div`
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 10px;
    padding: 8px;
    border-radius: 8px;
    background-color: var(--neutral-50);
    margin-top: 24px;
`;

export const HorizontalContainer = styled.div`
    height: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    padding: 0;
`;

export const SwitchWrapper = styled.label`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
`;

export const Switch = styled.div`
    position: relative;
    width: 45px;
    height: 16px;
    background: #b3b3b3;
    border-radius: 32px;
    padding: 4px;
    transition: 300ms all;
    &:before {
        transition: 300ms all;
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 35px;
        top: 50%;
        left: 4px;
        background: white;
        transform: translate(0, -50%);
    }
`;

export const Input = styled.input`
    opacity: 0;
    position: absolute;

    &:checked + ${Switch} {
        background: green;

        &:before {
            transform: translate(32px, -50%);
        }
    }
`;

export const KeyIconContainer = styled.div`
    width: 120px;
    height: 120px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 24px;
    border-radius: 32px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--text-0);
`;

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const FieldName = styled.div<{error: boolean | undefined}>`
    height: 75px;
    margin-bottom: 16px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: var(--text-600);
    flex-direction: column;
    display: flex;
    flex-basis: auto;
    justify-content: flex-start;
    position: relative;
    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            color: var(--error-500) !important;
        `}
`;

export const FieldNameText = styled.p`
    width: 100%;
    flex-grow: 1;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--text-600);
`;

export const LeftIcon = styled.i<{error: number | undefined}>`
    width: 20px;
    height: 20px;
    position: absolute;
    left: 12px;
    top: 43px;
    color: ${(props) => (props.error ? 'var(--error-500)' : 'var(--neutral-300)')};
    transition: 0.2s;
    line-height: 20px;
`;

export const RightIcon = styled.i`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 43px;
    color: var(--neutral-900);
    line-height: 20px;

    &:hover {
        cursor: pointer;
        color: var(--neutral-700);
    }
`;

export const FormInput = styled.input<{error: boolean | undefined}>`
    height: 48px;
    padding: 0 12px;
    border-radius: 8px;
    border: solid 2px #004e66;
    background-color: #ffffff;
    margin-top: 10px;
    flex-basis: auto;
    padding-left: 40px;

    ${(props) =>
        !!props.error &&
        css`
            border-color: var(--error-500) !important;
        `}
`;

export const OverlayingSpinner = styled(Spinner)`
    && {
        top: 10%;
    }
`;
export const Button = styled.button<{bgColor: string; activeBGColor: string; hoverBGColor: string}>`
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border-width: 0;
    background-color: ${(props) => props.bgColor};
    color: var(--text-0);
    font-size: 1.2rem;
    padding: 0 !important;
    margin: 0 auto;
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.hoverBGColor};
    }

    &:active {
        background-color: ${(props) => props.activeBGColor};
    }
`;

export const Cancel = styled.button`
    color: var(--error-500);
    background-color: var(--neutral-0);
    border: none;
    transition: 0.2s;
    align-self: center;

    &:hover {
        cursor: pointer;
        color: var(--error-50);
    }

    @media ${Device.mobile} {
        background-color: var(--neutral-100);
    }
`;

export const DigitContainer = styled.div`
    width: 24px;
    height: 45px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 10px;
    border-radius: 8px;
    border: solid 1px var(--neutral-600);
    background-color: var(--neutral-0);
`;

export const DigitInput = styled.input`
    width: 100%;
    height: 100%;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1.96rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
    color: var(--text-600);
    margin-bottom: 22px;
    padding: 0px;
    border: hidden;
    border-bottom: solid 2px var(--text-600);
    &:focus {
        outline: none;
        border-bottom: solid 3px #2565ae;
    }
`;

export const LinkButton = styled.button`
    background: none !important;
    margin-right: 14px;
    border: none;
    padding: 0 !important;
    color: #3c99dc;
    cursor: pointer;
`;

export const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--neutral-100);
`;

export const MethodIcon = styled.i`
    width: 24px;
    height: 24px;
    font-size: 24px;
    object-fit: contain;
`;

export const CancelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;

    @media ${Device.mobile} {
        padding: 24px;
    }
`;
