import React from 'react';
import {Layout} from '../Components';
import ShipDropBox from '../Resources/img/AntibodyStep1.png';
import shippingExclamation from '../Resources/img/single-shipping-exclamation.png';

const Step13 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={ShipDropBox} alt="Shipping Box" />
            </div>

            <h2 className="antibody-step-number">Step 11</h2>

            <h3 className="antibody-step-description">Ship</h3>

            <p className="has-text-centered">Deliver your prepaid and addressed return test kit to a FedEx drop box.</p>

            <p className="has-text-centered">
                <strong>
                    To maintain sample stability, it is recommended that you ship your sample to the lab within 24 hours
                    of collection.
                </strong>
            </p>

            <p className="has-text-centered">Drop the shipment in a FedEx drop box or at a FedEx location.</p>

            <p className="has-text-centered">
                Visit{' '}
                <a href="https://fedex.com/labreturns" target="_blank" rel="noreferrer">
                    fedex.com/labreturns
                </a>{' '}
                to find FedEx drop-off locations.
            </p>
            <p className="has-text-centered">
                Call 1.800.GoFedEx and tell the agent they are shipping an at-home UN3373 test kit if they need
                additional assistance.
            </p>

            <img
                src={shippingExclamation}
                alt="Testing on Friday? Please call or text 877-355-7978 for a Saturday delivery return label!"
            />
        </section>
    </Layout>
);
export default Step13;
