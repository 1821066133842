import React from 'react';
import {Layout} from '../Components';
import SamplePrep from '../Resources/img/AntibodyStep7.png';

const Step10 = () => (
    <Layout>
        <section className="white-bg">
            <div className="helper-media">
                <img src={SamplePrep} alt="Prep Sample" />
            </div>

            <h6 className="required-record">
                this step <strong>must</strong> be recorded{' '}
                <span className="record-icon">
                    <span></span>
                </span>
            </h6>

            <h2 className="antibody-step-number">Step 8</h2>

            <h3 className="antibody-step-description">Prepare Sample For Return</h3>

            <p className="has-text-centered">
                Insert sample tube into small biohazard bag with absorbent sheet. Seal tightly.
            </p>
        </section>
    </Layout>
);
export default Step10;
