import React from 'react';
import {SkeletonContainer, SkeletonItem} from './styles/SkeletonPatient';

interface Props {
    variant: number;
}

const PatientSelection: React.FC<Props> = ({variant}) => {
    return (
        <SkeletonContainer>
            <SkeletonItem width={76} height={76} isCircle={1} />
            {variant === 1 && <SkeletonItem width={90} height={29} isCircle={0} />}
            {variant === 2 && <SkeletonItem width={110} height={29} isCircle={0} />}
            {variant === 3 && <SkeletonItem width={140} height={29} isCircle={0} />}
            <SkeletonItem width={70} height={18} isCircle={0} />
        </SkeletonContainer>
    );
};

export default PatientSelection;
