import React, {useState} from 'react';
// import ProtectedRoute from '../components/ProtectedRoute';
import {Step0, Step1, Step2, Step3, Step4, Step5, Step99, Step999, TestType, Success, Payment, Error} from './steps';
import StepWizard from 'react-step-wizard';
import Logo from '../assets/MLD Logo@2x.png';
import {Desktop} from '../components/MediaQueries';
import {PageContainer, TopBar, LogoLink, FormContainer, MLDLogo} from './styles/RegisterKit2';

interface Props {
    isAuth?: boolean;
}

const RegisterKit: React.FC<Props> = (props: Props) => {
    // const [state, setState] = useState<{[x: string]: any}>({
    //     kitId1: '',
    //     kitId2: '',
    //     promoCode1: '',
    //     promoCode2: '',
    //     company: null,
    //     contact: '',
    //     symptoms: '',
    //     vaccinated: '',
    //     firstName: '',
    //     lastName: '',
    //     birthday: '',
    //     address1: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     phone: '',
    //     gender: '',
    //     ethnicity: '',
    //     location: '',
    // });

    // const setKitID = (kitID: string) => {
    //     setState((prevState) => ({...prevState, kitId1: kitID}));
    // };

    return (
        <PageContainer>
            <TopBar>
                <Desktop>
                    <LogoLink to="/">
                        <MLDLogo src={Logo} />
                    </LogoLink>
                </Desktop>
            </TopBar>
            <FormContainer>
                <StepWizard isHashEnabled={true}>
                    <Step0 stepName={'step0'} hashKey={'step0'} />
                    <Step1 stepName={'step1'} hashKey={'step1'} />
                    <Step2 stepName={'step2'} hashKey={'step2'} />
                    <Step3 stepName={'step3'} hashKey={'step3'} />
                    <Step4 stepName={'step4'} hashKey={'step4'} />
                    <Step5 stepName={'step5'} hashKey={'step5'} />
                    <Step99 stepName={'step99'} hashKey={'step99'} />
                    <Step999 stepName={'step999'} hashKey={'step999'} />
                    <TestType stepName={'testType'} hashKey={'testType'} />
                    <Success stepName={'success'} hashKey={'success'} />
                    <Error stepName={'error'} hashKey={'error'} />
                    <Payment stepName={'payment'} hashKey={'payment'} />
                </StepWizard>
            </FormContainer>
        </PageContainer>
    );
};

export default RegisterKit;
