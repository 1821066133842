import React from 'react';
import {MetricList, MetricListItem, MetricResult, Separator} from './styles/BinaryResult';
import {displayBinaryResultText} from '../utils/Test';

interface Props {
    result: any;
    hasMultipleResults: boolean;
}

const BinaryResult: React.FC<Props> = (props) => {
    const {result, hasMultipleResults} = props;
    const metrics: {[index: string]: any} = {};

    return (
        <MetricList>
            {result.results.map((r: any, index: any) => {
                let displayMetric = false;

                if (hasMultipleResults && !metrics[r.test_name]) {
                    displayMetric = true;
                    metrics[r.test_name] = true;
                }

                return (
                    <React.Fragment key={index}>
                        {index !== 0 && (!hasMultipleResults || displayMetric) && <Separator />}
                        {(!hasMultipleResults || displayMetric) && (
                            <MetricListItem>
                                <p className="ParagraphNormalRegular">
                                    {result.results.length === 1 ? 'Result' : r.test_name}
                                </p>
                                <MetricResult className="ParagraphNormalRegular">
                                    {displayBinaryResultText(r.result.trim(), r.test_name)}
                                </MetricResult>
                            </MetricListItem>
                        )}
                    </React.Fragment>
                );
            })}
        </MetricList>
    );
};

export default BinaryResult;
