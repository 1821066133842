import styled from 'styled-components';

export const PatientContainer = styled.div<{isCurrent: number}>`
    width: 280px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: var(--neutral-0);
    transition: 0.2s;

    &:hover {
        cursor: pointer;
        transform: translate(-5px, -5px);
    }

    ${(props) =>
        props.isCurrent
            ? `
    box-shadow: 0 6px 16px 3px rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to right, #59f 0%, #cb5eff 100%);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, var(--neutral-0), var(--neutral-0)), linear-gradient(to right, #59f 0%, #cb5eff 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
`
            : ''}
`;

export const PatientName = styled.p`
    text-align: center;
`;

export const PatientBirthday = styled.p`
    text-align: center;
    color: var(--text-300);
`;

export const CurrentCaption = styled.p`
    text-align: center;
    background-image: linear-gradient(to right, #59f 0%, #cb5eff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const PatientImage = styled.i`
    text-align: center;
    line-height: 72px;
    font-size: 46px;
    background: linear-gradient(to right, #c198ff 30%, #5cd9ff);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: var(--neutral-100);
    background-color: var(--neutral-0);
`;
