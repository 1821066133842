import React from 'react';
import MicroscopeIcon from '../assets/Lab Results.svg';
import {getNameString, getColor} from '../utils/Test';
import {
    ResultAccent,
    ResultBody,
    ResultContainer,
    ResultContent,
    ResultHeader,
    ResultItem,
    MicroscopePhoto,
    Icon,
    WarningIcon,
} from './styles/PendingResult';

interface Props {
    result: any;
}

const PendingResult: React.FC<Props> = (props) => {
    const {result} = props;
    const isBlood = result.oid.substring(result.oid.length - 10, result.oid.length - 8) === 'BW';
    const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC'};
    const date = result.createdDate ? new Date(result.createdDate) : new Date();
    const birthdate = result.birthdate ? new Date(result.birthdate) : new Date();
    const dateString = result.createdDate ? `${date.toLocaleDateString('en-us', dateOptions)}` : '';
    const test = getNameString(result);
    const color = getColor(result, test);

    return (
        <ResultContainer>
            <ResultAccent color={color} />
            <ResultContent>
                <ResultHeader>
                    <p className="Heading04ExtraBold">Lab Results</p>
                    <MicroscopePhoto color={color}>
                        <img src={MicroscopeIcon} alt="" />
                    </MicroscopePhoto>
                </ResultHeader>
                <ResultBody>
                    {(result.firstName || result.lastName) && (
                        <p className="Heading05ExtraBold">{`${result.firstName || ''} ${result.lastName || ''}${
                            result.birthdate ? ` (${birthdate.toLocaleDateString('en-us', {timeZone: 'UTC'})})` : ''
                        }`}</p>
                    )}
                    <p className="Heading05ExtraBold">{test ? test : isBlood ? 'Blood Wellness Report' : ''}</p>
                    <ResultItem>
                        <Icon className="fa-light fa-calendar-day fa-fw" />
                        <p className="ParagraphNormalRegular">{`Taken on ${dateString}`}</p>
                    </ResultItem>
                    <ResultItem>
                        <WarningIcon className="fa-light fa-clock" />
                        <p className="ParagraphNormalRegular">Your lab results are pending</p>
                    </ResultItem>
                    {result.unreleased && (
                        <ResultItem>
                            <WarningIcon className="fa-light fa-diamond-exclamation fa-fw" />
                            <p className="ParagraphNormalRegular">
                                Your initial results were released by mistake. We are working to rectify this now and we
                                will notify you when your final results are available. If you need immediate assistance
                                or require further explanation, please contact our customer service team at
                                info@mylabsdirect.com or (877) 355-7978.
                            </p>
                        </ResultItem>
                    )}
                </ResultBody>
            </ResultContent>
        </ResultContainer>
    );
};

export default PendingResult;
