import styled from 'styled-components';
import HexBackground from '../../assets/images/Hex Background.png';
import {Device} from '../../services/constants';
import {Link} from 'react-router-dom';

export const Container = styled.div`
    display: flex;
    height: fit-content;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;

    @media ${Device.mobile} {
        margin-top: 40px;
    }
`;

export const Background = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    background-image: url('${HexBackground}');
    background-color: rgba(253, 253, 253, 0.9);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
`;

export const MobileNavOverlay = styled.div<{isActive: boolean}>`
    visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s;
    background-color: ${(props) => (props.isActive ? 'rgba(0, 0, 0, 0.6)' : '')};
    z-index: 3;
`;

export const MobileHeader = styled.div`
    position: fixed;
    left: 0;
    width: calc(100% - 32px);
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: var(--neutral-0);
    transition: 0.2s;
    z-index: 4;

    @media ${Device.mobile} {
        height: 32px;
        padding: 16px;
    }
`;

export const MobileLogo = styled.img`
    height: 0;

    @media ${Device.mobile} {
        height: 44px;
    }
`;

export const LogoLink = styled(Link)`
    height: 0;

    &:hover {
        cursor: pointer;
    }

    @media ${Device.mobile} {
        height: 44px;
    }
`;

export const NavToggle = styled.button<{isRotated: boolean}>`
    position: absolute;
    left: 16px;
    margin-bottom: 2px;
    width: 36px;
    height: 0;
    overflow: hidden;
    padding: 0;
    border: none;
    background-color: var(--neutral-0);
    cursor: pointer;
    transform: rotate(0deg);
    transition: 0.2s;
    transform: ${(props) => (props.isRotated ? 'rotate(90deg)' : '')};

    @media ${Device.mobile} {
        height: 32px;
    }
`;

export const NavImage = styled.i`
    height: 0;
    font-size: 36px;

    @media ${Device.mobile} {
        height: 32px;
    }
`;
