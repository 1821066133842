import React from 'react';
import {CardContainer, VialsImage, Separator, KitLink, CardLink} from './styles/EmptyLabResultsCard';

const EmptyLabResultsCard: React.FC = () => {
    return (
        <CardContainer>
            <VialsImage className="fa-light fa-vials" />
            <p className="Heading05Bold">You Have No Lab Results</p>
            <Separator />
            <p className="ParagraphNormalRegular" style={{width: '100%'}}>
                <KitLink to="/newregisterkit/">register a kit</KitLink> or{' '}
                <CardLink href="https://mylabsdirect.com/at-home-test-kits/" target="_blank">
                    {' '}
                    shop for a test
                </CardLink>
            </p>
        </CardContainer>
    );
};

export default EmptyLabResultsCard;
