import styled from 'styled-components';
import {Device} from '../../services/constants';

export const Header = styled.div`
    display: flex;
    align-items: center;
    padding: 16px 24px;
    position: relative;
    padding: 32px;
    justify-content: left;

    @media ${Device.mobile} {
        justify-content: center;
        padding: 16px 24px;
    }
`;

export const HeaderButton = styled.button`
    position: absolute;
    left: 24px;
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    transition: 0.2s;

    &:hover {
        background-color: var(--neutral-200);
        cursor: pointer;
    }
    &:active {
        background-color: var(--neutral-100);
    }
`;

export const HeaderButtonImage = styled.i`
    width: 24px;
    height: 24px;
    font-size: 24px;
`;

export const HeaderText = styled.h4`
    margin: 0;
    letter-spacing: normal;

    @media ${Device.mobile} {
        letter-spacing: default;
    }
`;
